import axios from 'axios'   // npm install -f axios@^1.3.5

// - ApiService는 스프링부트 서버(보통 'http://localhost:8080/'으로 열린다.)와 연결해주는 역할을 한다.
// - 리액트에서 무언가 요청을 하면 스프링부트에서 받아 Oracle에서 데이터를 가져오거나 연결해주는 역할을 한다.
// - 전형적인 MVC 패턴이라고 할 수 있다.
// - 리액트에서 이를 구현하기 위해서 Axios를 사용한다. 기존 HTML이나 JSP에서 사용한 AJAX 역할을 한다고 보면 된다.
// npm install -f axios@^1.3.5

const APPR_API_BASE_URL = process.env.REACT_APP_BACK_HOST + "/appr";

// 이부분 추가
const getAuthToken = () => {
    return window.localStorage.getItem("auth_token");
}

let headers = {};


if(getAuthToken() !== null && getAuthToken !=="null") {
    headers = {"Authorization": `Bearer ${getAuthToken()}`};
    console.log('headers : ', headers);
}

// 여기 위까지 추가해주시면 됩니다.

class ApprApiService {
    
    // 기안문서함list
    fetchAllList(employeeId) {    // 3.
        console.log('fetchAllList() 호출')
        // Axios는 브라우저, Node.js를 위한 Promise API를 활용하는 HTTP 비동기 통신 라이브러리.
        return axios.get(APPR_API_BASE_URL + '/ApprAll/' + employeeId , {headers}); // 스프링 부트와 통신
    }

    // 결재대기문서함list
    fetchProgressList(employeeId) {    // 3.
        console.log('fetchProgressList() 호출')
        // Axios는 브라우저, Node.js를 위한 Promise API를 활용하는 HTTP 비동기 통신 라이브러리.
        return axios.get(APPR_API_BASE_URL + '/ProgressAppr/' + employeeId , {headers}); // 스프링 부트와 통신
    }

    // 결재예정문서함list
    fetchPendingList(employeeId) {    // 3.
        console.log('fetchPendingList() 호출')
        // Axios는 브라우저, Node.js를 위한 Promise API를 활용하는 HTTP 비동기 통신 라이브러리.
        return axios.get(APPR_API_BASE_URL + '/PendingApprList/' + employeeId , {headers}); // 스프링 부트와 통신
    }

    // 결재완료문서함list
    fetchCompletedList(employeeId) {    // 3.
        console.log('fetchCompletedList() 호출')
        // Axios는 브라우저, Node.js를 위한 Promise API를 활용하는 HTTP 비동기 통신 라이브러리.
        return axios.get(APPR_API_BASE_URL + '/CompletedAppr/' + employeeId , {headers}); // 스프링 부트와 통신
    }

    // insert
    addAppr(inputData) {
        console.log('addAppr() 호출');
        return axios.post(APPR_API_BASE_URL, inputData , {headers});
    }

    // 결재자 정보 가져오기
    approverInfo(apprNum) {
        console.log('approverInfo() 호출', apprNum)
        return axios.get(APPR_API_BASE_URL + '/DetailTest/' + apprNum , {headers});
    }

    // 문서 상세페이지 읽어오기
    apprDetail(apprNum, employeeId) {
        console.log('apprDetail() 호출', apprNum, employeeId)
        return axios.get(APPR_API_BASE_URL + '/ApprDetail/' + employeeId + '/' + apprNum , {headers});
    }

    // 기안자 정보 가져오기
    fetchUserInfo(employeeId) {
        console.log('fetchUserInfo() 호출', employeeId)
        return axios.get(APPR_API_BASE_URL + '/write/' + employeeId , {headers});
    }

    // 대기문서 상세페이지 읽어오기
    ProgressApprDetail(apprNum, employeeId) {
        console.log('apprDetail() 호출', apprNum, employeeId)
        return axios.get(APPR_API_BASE_URL + '/DetailTest/' + employeeId + '/' + apprNum , {headers});
    }

    // 회원정보 가져오기
    searchData(keyword) {
        if (!keyword.trim()) { // keyword가 빈 문자열인 경우
            // 특정 동작 수행
            // 예를 들어, 기본 검색어를 사용하거나 전체 목록을 가져옴
            return axios.get(APPR_API_BASE_URL + "/search/all" , {headers});
        }
        console.log('search 호출!!', keyword)
        return axios.get(APPR_API_BASE_URL + "/search/" + keyword , {headers});
    }
    
    // 결재 사유 + 상태 update
    updateOpinion (inputData, employeeId) {
        console.log('updateOpinion() 호출!!', inputData)
        return axios.put(APPR_API_BASE_URL + '/ApprDetail/' + employeeId, inputData , {headers} )
    }

    // 반려 사유 + 상태 update
    updateReject (inputData, employeeId) {
        console.log('updateReject() 호출!!', inputData)
        return axios.put(APPR_API_BASE_URL + '/Reject/' + employeeId, inputData , {headers} )
    }

    // 1건 삭제 delete
    deleteAppr(apprNum, employeeId) {
        console.log('deleteAppr() 호출', apprNum, employeeId);
        return axios.delete(APPR_API_BASE_URL + '/deleteAppr/' + employeeId + '/' + apprNum , {headers});
    }
    
    // 관리자 완료문서함 list 조회
    adminAllList(employeeId) {    // 3.
        console.log('adminAllList() 호출')
        // Axios는 브라우저, Node.js를 위한 Promise API를 활용하는 HTTP 비동기 통신 라이브러리.
        return axios.get(APPR_API_BASE_URL + '/adminAllList/' + employeeId , {headers}); // 스프링 부트와 통신
    }

    // 관리자 진행문서함 list 조회
    adminProgressList(employeeId) {    // 3.
        console.log('adminAllList() 호출')
        // Axios는 브라우저, Node.js를 위한 Promise API를 활용하는 HTTP 비동기 통신 라이브러리.
        return axios.get(APPR_API_BASE_URL + '/adminProgressList/' + employeeId , {headers}); // 스프링 부트와 통신
    }

    // 관리자 영구삭제
    deleteAdmin(apprNumList) {
        console.log('deleteAdmin() 호출', apprNumList);
        return axios.delete(APPR_API_BASE_URL + '/deleteAdmin/', { data: apprNumList, headers });
    }

}


export default new ApprApiService();