import React, { Component, history} from 'react';
import ApiService from "./AttentionApiService"
import moment from 'moment';
import {Table, TableHead, TableRow, TableCell, TableBody, Button, Typography} from '@mui/material';
import { useHistory } from "react-router-dom";

function transHoursAndMinutes(seconds) {
	if (seconds < 61) {
    	return '00:00:' + addZero(seconds)
	}
	// sec
	var hours = Math.floor(seconds/3600)
	var mins = Math.floor((seconds - hours*3600)/60)
	var secs = seconds - hours*3600 - mins*60
	return addZero(hours) + ':' + addZero(mins) + ':' + addZero(secs)
    
	function addZero(num) {
		return ((num < 10) ? '0' : '') + num
	}
}

class GetState extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            employee_id: this.props.employee_id,
            annual: 0,
            state: '',
            att_stime: '',
            att_etime: ''

        }
    }
    
    // 라이프 사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    // render 후 dom이 반영되었을 때(로딩이 끝났을 때) 
    // componentDidMount 상태로 진입 이 상태일 때 아래 함수를 실행
    // 개발단계에선 이 부분이 두번 호출됨
    // 두번 안보고 싶으면 index.js 에서 StrictMode 컴포넌트를 없애면 된다.

    componentDidMount() {
        this.getAttendState(this.props.employee_id);
       }

    getAttendState = (employee_id) => {
        ApiService.getAttendState(employee_id)  // 2.
        .then(res => {             // 4.
            
            this.setState({
                employee_id: employee_id,
                annual: res.data.annual,
                state: res.data.state,
                att_stime: res.data.att_stime,
                att_etime: res.data.att_etime
            });

        })
        .catch(err => {
            console.log('getAttendState() Error!', err);
        })
        
    }
    

    goWork = (info) => {
        ApiService.goToWork(info)  // 2.
        .then(res => {             // 4.
            //console.log('출근 요청 성공', res.data);
            alert("출근설정됨");
            this.setState({
                state: '출근',
                att_stime: moment(),
                att_etime: null
            })
            
            if(window.location.pathname === "/attend/today")
                window.location.replace("/attend/today");
        })
        .catch(err => {
            console.log('goWork() Error!', err);
            //window.location.reload();
        })
        
    }

    
    goHome = (info) => {
        ApiService.goToHome(info)  // 2.
        .then(res => {             // 4.
            console.log('퇴근 요청 성공', res.data);
            alert("퇴근 설정됨");
            this.setState({
                state: '퇴근',
                att_etime: moment()
            })
            if(window.location.pathname === "/attend/today")
                window.location.replace("/attend/today")
        })
        .catch(err => {
            console.log('goHome() Error!', err);
            //window.location.reload();
        })
        
    }

    deny = (message) => {
        alert(message);
    }


    render() {
        let att_stime = this.state.att_stime;
        let att_etime = this.state.att_etime;
        let today = moment();
        return (
            <div className={style.Info}>
                <p style={style.infoParagraph}>아이디 : {this.state.employee_id}</p>
                <p style={style.infoParagraph}>현 상태 : {this.state.state}</p>
                <p style={style.infoParagraph}>출근시간 : {att_stime != null && 
                    moment(att_stime).format('YYYY-MM-DD HH:mm')}</p>

                <p style={style.infoParagraph} >퇴근시간 : {att_etime != null && 
                    moment(att_etime).format('YYYY-MM-DD HH:mm')}</p>

                <p style={style.infoParagraph}>근무 시간 : {(att_stime != null && att_etime != null) ? 
                    transHoursAndMinutes(moment(att_etime).diff(moment(att_stime), "s")) : 
                    att_stime != null ? "근무중" :"첫근무"}</p>

                <br/>
                <button style={style.first_Button} sx={{margin:2}} 
                    onClick={this.state.state == '출근' ? () => this.deny("이미 출근된 상태입니다.") : 
                        moment(att_stime).format('YYYY-MM-DD') === today.format('YYYY-MM-DD') ? 
                            () => this.deny('하루 1회만 가능') : () => this.goWork(this.props)  
                    }> 출근 </button>
                <button style={style.first_Button} sx={{margin:2}} 
                    onClick={this.state.state == '퇴근' ? () => this.deny('이미 퇴근 된 상태입니다.') : 
                    this.state.state != '출근' ? () => this.deny("출근을 해야 퇴근이 가능합니다.") : () => this.goHome(this.props)
                    }  > 퇴근 </button>

            </div>

        );

    }

}

const style = {
    first_Button : {
        fontSize: '15px',
        border: '1px solid #25BAD6',
        backgroundColor:'white',
        color: '#25BAD6',
        margin: '3px',
        marginBottom: '9px',
        marginLeft: '5px',
        marginRight: '5px',
        
        padding: '10px 30px 10px 30px',
        borderRadius: '7px'
    },

    infoParagraph : {
        fontSize: '12px',
        padding: '2px',
    }
}

export default GetState;