import React, { Component} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { withRouter } from 'react-router-dom';  
import { TextField } from '@mui/material';
import Container from "@mui/material/Container";
import ApiService from './ApiService';
import htmlparser from 'html-react-parser';
import BoardService from '../Board/BoardService';

class NoticeEdit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            employee_id: localStorage.getItem('LoginID'),
            notice_no: '',
            notice_title: '',
            notice_content: ''
           
        }

    } 
    componentDidMount() {
        this.loadNotice();
    }
    
       // 수정할 샘플 데이터를 불러와 state에 설정하는 함수
       loadNotice = () => {
        const notice_no = window.localStorage.getItem("notice_no");
        BoardService.NoticeByID(notice_no)
            .then(res => {
                const list = res.data;
                this.setState({
                    notice_no: list.notice_no,
                    notice_title: list.notice_title,
                    notice_content: list.notice_content
                });
            })
            .catch(err => {
                console.log('loadNotice() Error !!', err);
            });
    }
       

    onChange = (e) => { // 1. 입력한 값으로 변경한다.
        this.setState({
            [e.target.name]: e.target.value,
            isContentChanged: true, // 내용이 변경되었음을 표시
        });
    }

  

    // 3.수정처리
    editNotice = (e) => {
        e.preventDefault();

        if (this.state.notice_title.trim() === '') {
            alert('제목을 입력해주세요.');
            return;
        }
    
        if (this.state.notice_content.trim() === '') {
            alert('내용을 입력해주세요.');
            return;
        }

        const isConfirmed = window.confirm('공지사항을 수정하시겠습니까?');
        const { notice_no, notice_title, notice_content } = this.state;

        if (isConfirmed) {
            let inputData = { 
                notice_no: notice_no,
                notice_title: notice_title,
                notice_content: notice_content
            }

            ApiService.editNotice(inputData)
                .then(res => {
                    console.log('update 성공 ', res.data);
                    this.props.history.push(`/admin/noticeList/`); // 글 수정시 이동할 페이지
                    alert('공지사항이 수정되었습니다.'); // 성공 시 알림
                })
                .catch(err => {
                    console.log('editNotice() 에러!! ', err);
                    alert('공지사항 수정이 실패했습니다.'); // 실패 시 알림
                })
        }
    }

    // 목록으로 돌아가기 버튼
    handleGoBack = () => {
        this.props.history.goBack();
    }
    
    render() {

        return (
            <Container>
                <Paper elevation={3}>
                    <Box padding={2} sx={{ marginTop: '20px' }}>
                        <Toolbar>
                            <TextField   sx={{width:"800px"}}
                             id="outlined-basic"
                             variant="outlined"
                             label="게시글 제목"
                             type="text"
                             name="notice_title"
                             value={this.state.notice_title}
                             placeholder="제목입력"
                             onChange={this.onChange}
                            />
                        </Toolbar>
                    </Box>
                </Paper>
                
                <Paper sx={{marginTop:"10px;"}} elevation={3}>
                    <Box padding={3}>
                    <div id='Boardeditor'>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.notice_content}
                            config={{
                                placeholder: '내용을 입력해주세요.',
                            }}
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                                this.setState({ notice_content: data, isContentChanged: true });
                                {htmlparser(this.state.notice_content)}
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                        </div>
                    </Box>
                </Paper>
                
                <Paper sx={{marginTop:"10px"}} elevation={3}>
                    <Toolbar sx={{ justifyContent: 'flex-end'}}>
                        <Button onClick={this.editNotice}> 수정 </Button>
                        <Button onClick={this.handleGoBack}> 뒤로 </Button>
                    </Toolbar>
                </Paper>
            </Container>

        )

    }

}

const style = {
    display: 'inline-block', 
    width: '100%'
}

export default withRouter(NoticeEdit);
