import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../page_hwang/Board/BoardService';
import { Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import "../mail/MailRouter.css";
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 메인페이지의 공지사항 미니페이지 */

const StyledTableWrap = styled.div`
  padding: 8px; /* 원하는 패딩 값으로 설정 */
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding-bottom: 10px;
  display: flex;
`;

const StyledIonIcon = styled(IonIcon)`
  font-size: 20px;
  color: black;
  margin-right: 6px;
`;

function MiniNotice(props) {
    const [notice, setNotice] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await ApiService.NoticeList();
                setNotice(res.data); // 데이터 설정
            } catch (error) {
                console.error('검색 에러:', error);
            }
        };

        fetchData();

    }, []);


    // 날짜 형식 변환 함수
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); 
    }

    return (
        <StyledTableWrap>
        <Table >
            <TableHead>
                <TableRow style={{borderBottom: "1px solid lightgrey"}}>
                <StyledTitle><StyledIonIcon icon={ alertCircleOutline } />최신공지</StyledTitle>
                </TableRow>
            </TableHead>
            <TableBody>
                {notice.length > 0 ? (
                    notice.slice(0, 5).map(dto => (
                        <TableRow key={dto.notice_no} style={{ textAlign: "center" }}>
                            <Link to={`/board/notice/detail/${dto.notice_no}`} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <TableCell style={{ width: "20%" }}>{dto.notice_no}</TableCell>
                                <TableCell style={{ width: "60%" }}>{dto.notice_title}</TableCell>
                                <TableCell style={{ width: "20%" }}>{formatDate(dto.notice_date)}</TableCell>
                            </Link>
                        </TableRow>
                    ))
                ) : (
                    <div>공지가 없습니다.</div>
                )}
            </TableBody>
        </Table>
        </StyledTableWrap>
    );
}

export default MiniNotice;
