import React, { Component } from 'react';
import './mainComponent.css'
import CurrentTimer from '../page_heojun/Attention/CurrentTimer';
import GetState from '../page_heojun/Attention/GetState';
import MaileList from '../page_kang/main/miniMailList';
import Weather from '../page_kang/main/weather';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import MiniNotice from '../page_kang/main/MiniNotice';
import MainMyinfo from '../page_kang/main/MainMyInfo';
import Todo from '../page_kang/main/Todo';
import ArrList from '../page_kang/main/miniApprList';
import ApiService from '../page_yoon/route/CalendarApiService';
import Modal from 'react-modal';
import News from '../page_heojun/Jsoup/Jsoup';
import { IonIcon } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 메인 페이지 */

class mainComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee_id: localStorage.getItem("LoginID"),
            annual: 0,
            state: '',
            att_stime: '',
            att_etime: '',
            value: new Date(), // Calendar 컴포넌트의 날짜 값
            events: [],
            showModal: false, // 모달 창 열림 여부
            selectedDateEvents: [] // 선택된 날짜의 이벤트
        };
    }

    componentDidMount() {
        ApiService.MyCalendar(this.state.employee_id)
            .then(response => {
                this.setState({ events: response.data }); // 가져온 데이터를 상태에 저장합니다.
            })
            .catch(error => {
                console.error('Error fetching events:', error);
            });
        Modal.setAppElement('#root');
    }

    handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        window.location.href = "/login";
    };

    onChange = (value) => {
        this.setState({ value });

        // 선택된 날짜의 이벤트 찾기
        const selectedDateEvents = this.state.events.filter(event => {
            const eventDate = new Date(event.calStartDT); // 이벤트 시작 일자
            return value.toDateString() === eventDate.toDateString();
        });

        // 선택된 날짜의 이벤트 상태 업데이트
        this.setState({ selectedDateEvents });

        // 모달 창 열기
        this.openModal();
    }

    formatDay = (locale, date) => {
        return date.getDate(); // 날짜의 일만 반환
    }

    openModal = (event) => {
        this.setState({ showModal: true });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    // Calendar의 tileContent prop에서 사용할 함수
    tileContent = ({ date }) => {
        // 이벤트가 있는 날짜에는 동그라미 표시
        const hasEvent = this.state.events.some(event => {
            const eventDate = new Date(event.calStartDT); // 이벤트 시작 일자
            return date.toDateString() === eventDate.toDateString();
        });

        return hasEvent ? <div className="dot"></div> : null;
    }

    render() {

        return (
            <div>
                <div className='main-container'>
                    <div className='leftside'>
                        <div>
                            {/* 개인 프로필 */}
                            <div className='mainMyinfo'>
                                <MainMyinfo />
                            </div>
                            {/* 근태관리 */}
                            <div className='mainState'>
                                <div className='title'>
                                    근태 관리
                                </div>    
                                <div className='goTo' style={{ textAlign: 'center' }}>
                                    <CurrentTimer />
                                    <GetState employee_id={this.state.employee_id}/>
                                </div>
                            </div>
                            {/* 캘린더 */}
                            <div className='miniCal' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                            <Calendar 
                                onChange={this.onChange} 
                                value={this.state.value} 
                                formatDay={this.formatDay} // 날짜 포맷팅 함수 사용
                                tileContent={this.tileContent}
                            />
                            {/* 모달 창 */}
                            <Modal className='minicalModal' isOpen={this.state.showModal} onRequestClose={this.closeModal}
                                style={{overlay: {backgroundColor: 'none'}}}>
                                {this.state.selectedDateEvents.length > 0 && (
                                    <h2>{this.state.selectedDateEvents[0].calStartDT}</h2>
                                )}
                                <ul>
                                    {this.state.selectedDateEvents
                                        .sort((a, b) => a.calStartTM.localeCompare(b.calStartTM)) // calStartTM을 기준으로 오름차순 정렬
                                        .map(event => (
                                            <li key={event.calNo}>
                                                <IonIcon icon={checkmarkOutline} /> {event.calStartTM} - {event.calTitle}
                                            </li>
                                        ))}
                                </ul>
                                <button onClick={this.closeModal}>Close</button>
                            </Modal>


                            </div>
                            {/* 캘린더 끝 */}
                        </div>
                    </div>

                    <div className='middleList'>


                        {/* 미니 공지사항 */}
                        <div className='MainMiniNotice'>
                            <MiniNotice />
                        </div>
                        {/* 미니 받은메일목록 */}
                        <div className='MainMaileList'>
                            <MaileList employee_id={this.state.employee_id}/>
                        </div>
                        {/* 미니 전자결재목록 */}
                        <div className='MainMaileList'> 
                            <ArrList employee_id={this.state.employee_id}/>
                        </div>
                        {/* 뉴스 */}
                        <div className='MainMaileList'> 
                            <News/>
                        </div>

                        

                    </div>
                    <div className='rightSide'>
                        {/* 날씨 API */}
                        <Weather />
                        {/* Todo 리스트 */}
                        <Todo />
                    </div>
                </div>
            </div>
        );
    }
}

export default mainComponent;
