import axios from 'axios';
import { request, request2 } from '../../helpers/axios_helper';


/* 작업자 : 강승재 */
/* SrpingBoot Controller와 연결된 ApiService */

const Sample_API_BASE_URL = process.env.REACT_APP_BACK_HOST + "/mail";


class ApiService {
 
    // 받은메일함 조회
    ToList(id) {    
        console.log('ToList() 호출!!')
        return request("GET",Sample_API_BASE_URL + "/mail/" + id); 
    }

    // 보낸메일함 조회
    FromList(id) {
        console.log('FromList() 호출!!')
        return request("GET",Sample_API_BASE_URL + "/from/" + id);
    }

    // 휴지통 조회
    TrashList(id) {
        console.log('TrashList() 호출!!')
        return request("GET",Sample_API_BASE_URL + "/trashlist/" + id);
    }

    // 임시보관함 조회
    TempList(id) {
        console.log('TempList() 호출!!')
        return request("GET",Sample_API_BASE_URL + "/templist/" + id);
    }

     // 읽음처리
     readMail(emailNos, id) {
        console.log('readMail 호출!!', emailNos)
        return request("PUT",Sample_API_BASE_URL + "/read/" + id, emailNos); // 주의 : "/" +
    }

    // 휴지통보내기
    trashMail(emailNos, id) {
        console.log('trashMail 호출!!', emailNos)
        return request("PUT",Sample_API_BASE_URL + "/trash/" + id, emailNos); // 주의 : "/" +
    }

    // 휴지통보내기(상세페이지)
    trashMail2(emailNo, id) {
        console.log('trashMail2 호출!!', emailNo)
        return request("PUT",Sample_API_BASE_URL + "/trash2/" + id + "/" + emailNo); // 주의 : "/" +
    }

    // 메일 1건 상세조회(받은메일)
    emailByNOTo(emailNO, id) {
        console.log('emailByNO 호출!!', emailNO)
        return request("GET",Sample_API_BASE_URL + "/detail/" + id + "/" + emailNO);
    }

    // 보낸메일 삭제
    deleteMail(emailNos) {
        console.log('deleteMail 호출!!', emailNos);
        // 요청 파라미터로 이메일 번호들을 전달합니다.
        return request("DELETE", `${Sample_API_BASE_URL}/delete?emailNos=${emailNos.join(",")}`);
    }


    // 메일 1건 상세조회(보낸메일)
    emailByNOFrom(emailNO) {
        console.log('emailByNOFrom 호출!!', emailNO)
        return request("GET",Sample_API_BASE_URL + "/detailFrom/" + emailNO);
    }

    // 보낸메일 삭제(상세페이지)
    deleteMail2(emailNO) {
        console.log('deleteMail2 호출!!', emailNO)
        return request("DELETE",Sample_API_BASE_URL + "/delete2/" + emailNO); 
    }

    // 휴지통 삭제
    deleteMailTrash(id, emailNos) {
        console.log('deleteMailTrash 호출!!', emailNos);
        return request("DELETE", `${Sample_API_BASE_URL}/trashListDelete/${id}?emailNos=${emailNos.join(",")}`);
    }


    // 휴지통 삭제(상세페이지)
    deleteMailTrash2(emailNo, id){
        console.log('restoreMails 호출!!', emailNo)
        return request("DELETE",Sample_API_BASE_URL + "/trashListDelete2/" + id + "/" + emailNo);
    }

    // 메일 1건 상세조회(휴지통)
    emailByNOTrash(emailNO, id) {
        console.log('emailByNO 호출!!', emailNO)
        return request("GET",Sample_API_BASE_URL + "/detailTrash/" + id + "/" + emailNO);
    }

    // 받은메일함 이동
    restoreMails(id, emailNos){
        console.log('restoreMails 호출!!', emailNos)
        return request("PUT",Sample_API_BASE_URL + "/restoreMails/" + id, emailNos);
    }

    // 받은메일함 이동(상세페이지)
    restoreMail(emailNo, id){
        console.log('restoreMails 호출!!', emailNo)
        return request("PUT",Sample_API_BASE_URL + "/restoreMail/" + id + "/" + emailNo);
    }

    // 임시보관메일 삭제
    deleteTempMails(emailNos) {
        console.log('deleteTempMails 호출!!', emailNos)
        return request("DELETE",`${Sample_API_BASE_URL}/deleteTemp?emailNos=${emailNos.join(",")}`);
    }

    // 임시보관메일 발송(상세페이지)
    resendMail(formData) {
        console.log('resendMail 호출!!', formData)
        return request2("PUT",Sample_API_BASE_URL + "/resendMail", formData);
    }
    
    // 임시보관메일 발송(상세페이지/ 첨부파일 없을때)
    resendMailnoFile(inputData) {
        console.log('resendMailnoFile 호출!!', inputData)
        return request("PUT",Sample_API_BASE_URL + "/resendMailnoFile", inputData);
    }

    // 임시저장메일 발송(리스트)
    resendMails(emailNos) {
        console.log('resendMails 호출!!', emailNos)
        return request("PUT",Sample_API_BASE_URL + "/resendMails/", emailNos);
    }

    
    // 메일 발송(첨부파일 있을때)
    sendMail(formData) {
        console.log('sendMail 호출!!', formData)
        return request2("POST", Sample_API_BASE_URL + "/sendMail", formData);
    }

    // 메일 발송(첨부파일 없을때)
    sendMailnoFile(inputData) {
        console.log('sendMailnoFile 호출!!', inputData)
        return request("POST", Sample_API_BASE_URL + "/sendMailnoFile", inputData);
    }

    // 임시저장(첨부파일 있을때)
    saveMail(formData) {
        console.log('saveMail 호출!!', formData)
        return request2("POST",Sample_API_BASE_URL + "/saveMail", formData);
    }

    // 임시저장(첨부파일 없을때)
    saveMailnoFile(inputData) {
        console.log('saveMailnoFile 호출!!', inputData)
        return request("POST",Sample_API_BASE_URL + "/saveMailnoFile", inputData);
    }

    // 임시저장 개수 확인
    countTemp(fromId) {
        console.log('countTemp 호출!!', fromId)
        return request("GET",Sample_API_BASE_URL + "/countTemp/" + fromId);
    }

    //첨부파일 다운로드
    download = (fileName) => {
        console.log('download 호출!!', fileName)
        return axios.get(Sample_API_BASE_URL + "/download/" + fileName, { responseType: 'blob' });
    };

    // 회원정보 가져오기
    searchData(keyword) {
        if (!keyword.trim()) { // keyword가 빈 문자열인 경우
            // 전체 목록을 가져옴
            return request("GET",Sample_API_BASE_URL + "/search/all");
        }
        console.log('search 호출!!', keyword)
        return request("GET",Sample_API_BASE_URL + "/search/" + keyword);
    }

    // 수신확인일 체크
    readDateCheck(emailNo) {
        console.log('readDateCheck 호출!!', emailNo)
        return request("GET",Sample_API_BASE_URL + "/readDate/" + emailNo);
    }


}

export default new ApiService();