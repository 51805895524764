import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography} from '@mui/material';
import { Link, Route } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApprMain from './ApprMain';
import CompletedAppr from './CompletedAppr';
import ApprAll from './ApprAll';
import ProgressAppr from './ProgressAppr';
import LeaveForm from './LeaveForm';
import ApprDetail from './ApprDetail';
import leaveDetail from './leaveDetail';
import WorkDetail from './WorkDetail';
import WorkForm from './WorkForm';
import CompletedApprDetail from './CompletedApprDetail';
import CompletedWorkDetail from './CompletedWorkDetail';
import PendingApprList from './PendingApprList';
import PendingleaveDetail from './PendingleaveDetail';
import PendingWorkDetail from './PendingWorkDetail';
import NewApprModal from './NewApprModal'; // 모달 
import '../../route/SubHeaderStyle.css';
import '../../route/SubHeaderLayout.css';
import ApprApiService from './ApprApiService'


// 아코디언 그림자 제거 함수
const theme = createTheme({
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none', // 아코디언 컴포넌트의 그림자 제거
            padding: '0px 10px',
          },
        },
      },
    },
  });

export default function ApprRouter() {
    const [expanded, setExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [progressListCount, setProgressListCount] = useState(0);
    const [pendingListCount, setPendingListCount] = useState(0);
    const [employeeId, setEmployeeId] = useState(localStorage.getItem('LoginID')); // employeeId 상태 추가

    const history = useHistory();
  
    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    // 모달 열기 함수
    const openModal = () => {
        console.log("selectedOption : ", selectedOption);
        setIsModalOpen(true);
    };

    // 모달 닫기 함수 및 동작 정의

    const closeModal = (selectedOption) => {
        setIsModalOpen(false);
        if (selectedOption === "휴가신청") {
            console.log("휴가신청 선택됨");
            history.push('/appr/write');
        } else if (selectedOption === "업무기안") {
            console.log("업무기안 선택됨");
            history.push('/appr/WorkForm');
        } else {
            console.log("올바르지 않은 선택");
        }
    };

    // 대기문서함 리스트 개수를 가져오는 함수
    const getProgressListCount = () => {
        ApprApiService.fetchProgressList(employeeId) // 변경된 메서드 사용
            .then(res => {
                setProgressListCount(res.data.length); // 리스트 개수 설정
            })
            .catch(err => {
                console.log('Error while fetching progress list count:', err);
            });
    };

    // 결재예정문서함 리스트 개수를 가져오는 함수
    const getPendingListCount = () => {
        ApprApiService.fetchPendingList(employeeId) // 변경된 메서드 사용
            .then(res => {
                setPendingListCount(res.data.length); // 리스트 개수 설정
            })
            .catch(err => {
                console.log('Error while fetching pending list count:', err);
            });
    };

    // 컴포넌트가 마운트될 때 대기문서함 리스트 개수를 가져옴
    useEffect(() => {
        getProgressListCount();
        getPendingListCount();
    }, []); // 컴포넌트가 마운트될 때 한 번만 호출

    return (
        <ThemeProvider theme={theme}>
        <div style={style}>
            <div className='main'>  {/* 고정 */}
                <div className='left'>  {/* 고정 */}
                    <div className='sub_Header'>  {/* 고정 */}
                        <div className='title' style={style.title}> {/* 고정 */}
                        <Link to="/appr/">전자결재</Link> {/* 각자수정 */}
                        </div>
                        <div className='apprWrite'> {/* 각자수정 */}
                            {/* 모달 컴포넌트 호출 . */}
                            <NewApprModal isOpen={isModalOpen} closeModal={closeModal} />
                            <Button onClick={openModal} variant="outlined" style={style.first_Button}>새 결재 진행</Button>
                            <div className='apprMenu' style={style.apprMenu}> {/* 각자수정 */}
                                <Accordion
                                    expanded={expanded}
                                    onChange={handleExpansion}
                                    slotProps={{ transition: { timeout: 400 } }}
                                    sx={{
                                        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                        boxShadow: 'none'
                                    }}
                                >
                                    {/* 각자 메뉴 수정하시면 됩니다. */}
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography style={style.middleMenu}>결재하기</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <Link to="/appr/ProgressAppr" style={style.link}>결재 대기 문서 <span style={style.count}>{progressListCount}</span></Link>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Typography>
                                            <Link to="/appr/PendingApprList" style={style.link}>결재 예정 문서 <span style={style.count}>{pendingListCount}</span></Link>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        style={style.back}
                                    >
                                        <Typography style={style.middleMenu}>개인문서함</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography style={style.hoverText}>
                                            <Link to="/appr/ApprAll" style={style.link}>기안문서함</Link>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Typography>
                                            <Link to="/appr/CompletedAppr" style={style.link}>결재문서함</Link>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>

                
                    <div className='right'>  {/* 클래스명은 고정, 아래 Route는 각자 수정하시면 됩니다. */}
                        <Route path="/appr/" exact={true} component={ApprMain}></Route>
                        <Route path="/appr/CompletedAppr" exact={true} component={CompletedAppr}></Route>
                        <Route path="/appr/ApprAll" exact={true} component={ApprAll}></Route>
                        <Route path="/appr/ProgressAppr" exact={true} component={ProgressAppr}></Route>
                        {/* <Route path="/appr/ProgressAppr" exact={true} render={(props) => <ProgressAppr {...props} setProgressListCount={setProgressListCount} />} /> */}
                        <Route path="/appr/write" exact={true} component={LeaveForm}></Route>
                        <Route path="/appr/ApprDetail" exact={true} component={ApprDetail}></Route>
                        <Route path="/appr/WorkForm" exact={true} component={WorkForm}></Route>
                        <Route path="/appr/leaveDetail" exact={true} component={leaveDetail}></Route>
                        <Route path="/appr/CompletedApprDetail" exact={true} component={CompletedApprDetail}></Route>
                        <Route path="/appr/WorkDetail" exact={true} component={WorkDetail}></Route>
                        <Route path="/appr/CompletedWorkDetail" exact={true} component={CompletedWorkDetail}></Route>
                        <Route path="/appr/PendingApprList" exact={true} component={PendingApprList}></Route>
                        <Route path="/appr/PendingleaveDetail" exact={true} component={PendingleaveDetail}></Route>
                        <Route path="/appr/PendingWorkDetail" exact={true} component={PendingWorkDetail}></Route>
                    </div>
                
            </div>
        </div>
        </ThemeProvider>
    );
}

// 모달 스타일
const customModalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        zIndex: "1000"
    },
    content: {
        width: "50%",
        height: "50%",
        margin: "auto",
        backgroundColor: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px"
    }
};


const style = {
    link: {
        textDecoration: "none",
        color: "#646464",
        fontSize: "15px",
        '&:hover': {
            color: "#25bad6", // hover 시에 변경되는 색상
            textDecoration: "underline" // hover 시에 밑줄 추가
        }
    },
    

    title: {
        marginTop: '30px',
        fontSize: '21px',
        textAlign: 'left',
        textDecoration: "none",
    },

    // title 아래 버튼
    first_Button: {
        marginTop: '5px',
        paddingTop: '10px',
        paddingRight: '60px',
        paddingBottom: '10px',
        paddingLeft: '60px',
        color: '#323232',
        borderColor: '#6e6e6e'
    },

    apprMenu: {
        marginTop: '30px'
    },

    middleMenu: {
        fontWeight: 550,
        color: '#5a5a5a'
    },

    count: {
        fontSize: "12px",
        color: "darkgray"
    },

};
