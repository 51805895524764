import React, { Component } from 'react';
import ApprApiService from '../approval/ApprApiService'
import styles from './ApprAll.module.css';
import Pagination from "../approval/Pagination";
import { Checkbox } from '@mui/material';
import { IonIcon } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';

class CompleteDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {
            employeeId: localStorage.getItem('LoginID'),
            allList: [],    // 5. 리스트 데이터
            message: null,
            limit: 10,
            page: 1,
            selectedCheckboxes: {} // 체크박스 선택 상태를 저장하는 객체
        }
    }

    // 라이프사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    componentDidMount() {   
        this.reloadAllList();    // 1.
    }

    // 기안문서함 리스트(매개변수없음)
    reloadAllList = () => {
        ApprApiService.adminAllList(this.state.employeeId)
         .then(res => {
            console.log('list: ', res.data)
            const selectedCheckboxes = {}; // 선택된 체크박스 상태 초기화
            res.data.forEach(dto => {
                selectedCheckboxes[dto.apprNum] = false;
            });

            this.setState({
                allList: res.data,
                selectedCheckboxes,
            });
         })
         .catch(err => {
            console.log('reloadAllList() Error!!', err);
         });
    }

    // 문서 상세페이지 확인
    detailAppr = (dto) => {
        console.log("Clicked apprNum:", dto.apprNum); // apprNum 값 확인
        console.log("Clicked employeeId:", dto.employeeId); // id 값 확인
        console.log("Clicked apprForm:", dto.apprForm);
        
        const values = {
            apprNum : dto.apprNum,
            employeeId : dto.employeeId,
            apprForm: dto.apprForm
        }

        window.localStorage.setItem("apprValue", JSON.stringify(values));
        if (dto.apprForm === "휴가신청") {
            this.props.history.push("/admin/DocumentDetail");
        } else if (dto.apprForm === "업무기안") {
            this.props.history.push("/admin/WorkDocumentDetail");
        }
    }

    // 체크박스 선택 이벤트 핸들러
    handleCheckboxChange = (apprNum) => {
        const { selectedCheckboxes } = this.state;
        selectedCheckboxes[apprNum] = !selectedCheckboxes[apprNum]; // 선택된 상태를 반전시킴
        this.setState({ selectedCheckboxes });
    }

    // 전체 선택 체크박스 핸들러
    handleSelectAll = () => {
        const { selectedCheckboxes } = this.state;
        const selectAll = !Object.values(selectedCheckboxes).every(checked => checked);
        for (let apprNum in selectedCheckboxes) {
            selectedCheckboxes[apprNum] = selectAll;
        }
        this.setState({ selectedCheckboxes });
    }

    // 삭제 버튼 클릭시 호출 함수
    handleCancelSubmission = () => {
        console.log('handleCancelSubmission 함수 호출됨'); // 함수 호출 확인용 로그
        console.log(this.state.selectedCheckboxes); // 선택된 체크박스 상태 확인용 로그
        
        // 확인 메시지를 표시하고 확인 버튼을 눌렀을 때만 deleteAppr 함수를 호출
        if (window.confirm("선택한 문서를 영구삭제 하시겠습니까?")) {
            const selectedApprNums = Object.keys(this.state.selectedCheckboxes).filter(apprNum => this.state.selectedCheckboxes[apprNum]);
            this.deleteAppr(selectedApprNums); // 선택된 문서를 deleteAppr 함수로 전달하여 삭제 진행
        }
    };

    // 삭제 (delete)함수 
    deleteAppr = (apprNumList) => {
        ApprApiService.deleteAdmin(apprNumList) // 변경된 매개변수 이름을 사용
            .then(res => {
                const updatedApprlist = this.state.allList.filter(dto => !apprNumList.includes(dto.apprNum));
    
                this.setState(prevState => ({
                    allList: updatedApprlist,
                }), () => {
                    alert('삭제되었습니다.');
                    this.reloadAllList();
                });
            })
            .catch(err => {
                console.error('삭제 중 오류 발생:', err);
            })
    }

    // 페이지 함수
    handlePageChange = (pageNumber) => {
        this.setState({ page: pageNumber }, () => {
            this.reloadAllList(); // 페이지 번호 변경 시 데이터 다시 로드
        });
    };

    // 페이지 limit 함수
    handleLimitChange = (event) => {
        this.setState({ limit: Number(event.target.value) });
    };

    render() {

        // 페이지
        const { allList, limit, page, selectedCheckboxes } = this.state;
        const offset = (page - 1) * limit;

        return (
            <div>
                <div className={styles.allListWrap}>       
                    <div className={styles.allListTitle}>
                        <sapn>완료 문서함</sapn>
                    </div>
                    
                    <div className={styles.tableTopWrap}>
                        {/* 삭제버튼 */}
                        <div className={styles.btnWrap}>
                            <button type="button" className={styles.btn} onClick={this.handleCancelSubmission}>
                                <div className={styles.icon}><IonIcon icon={trashOutline} /></div>
                                삭제
                            </button>
                        </div>
                        {/* 페이지 리스트 설정 */}
                        <label className={styles.pageLimit}>
                            <select
                                type="number"
                                value={limit}
                                onChange={this.handleLimitChange}
                                style={{ float: 'right' }}
                            >
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                            </select>
                        </label>
                    </div>
                    <div className={styles.CompleteTable}>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        <Checkbox
                                            checked={Object.values(selectedCheckboxes).every(checked => checked)}
                                            onChange={this.handleSelectAll}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    color: 'darkgray', // 체크박스 아이콘의 색상을 빨간색으로 변경
                                                    width: '20px',
                                                    height: '20px',
                                                },
                                            }}
                                        />
                                    </td>
                                    <td>기안일</td>
                                    <td>완료일</td>
                                    <td>결재양식</td>
                                    <td>긴급</td>
                                    <td>제목</td>
                                    <td>기안자</td>
                                    <td>문서번호</td>
                                    <td>결재상태</td>
                                </tr>
                            </thead>
                            <tbody>
                                {allList.slice(offset, offset + limit).map(dto => 
                                    <tr key={dto.apprNum} onClick={() => this.detailAppr(dto)}>
                                        <td onClick={(e) => e.stopPropagation()}> {/* 체크박스에서 onClick 방지 */}
                                            <Checkbox
                                                key={dto.emailNo}
                                                checked={selectedCheckboxes[dto.apprNum] || false}
                                                onChange={() => this.handleCheckboxChange(dto.apprNum)}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        color: 'darkgray', // 체크박스 아이콘의 색상을 빨간색으로 변경
                                                        width: '20px',
                                                        height: '20px',
                                                    },
                                                }}
                                            />
                                        </td>
                                        <td> {dto.apprDate} </td> 
                                        <td> {dto.apprEnd} </td>    {/* finalChkDate 가져오기*/}
                                        <td> {dto.apprForm} </td>
                                        <td> 
                                            {dto.apprUrgent === 'y' ? (
                                                <button className={styles.emergencyIcon}>긴급</button>
                                            ) : null} 
                                            </td>
                                        <td> {dto.apprTitle} </td>
                                        <td> {dto.employeeId} </td>
                                        <td component="th" scope="completedList">SW00-00{dto.apprNum} </td>
                                        <td> 
                                            {dto.apprStatus >= 0 && dto.apprStatus <= 2 ? (
                                                <button className={styles.ongoingIcon}>진행중</button>
                                            ) : dto.apprStatus === 3 ? (
                                                <button className={styles.completedBtn}>완료</button>
                                            ) : (
                                                <button className={styles.rejectBtn}>반려</button>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* 페이징처리 */}
                    <div className='apprPaging'>
                        <Pagination
                            total={allList.length}
                            limit={limit}
                            page={page}
                            setPage={this.handlePageChange}
                        />
                    </div>
                </div> 
             </div>
        );
    }
}

export default CompleteDocument;