import React, { useEffect, useState } from 'react';

const KakaoMap = ({ onAddressClick, onPlaceNameClick }) => {
  const [clickedAddress, setClickedAddress] = useState('');

  useEffect(() => {
    // Kakao 지도 스크립트 동적으로 로드
    const script = document.createElement('script');
    script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=78fc46f547d2154aed268be6f6b35271&libraries=services";
    script.async = true;

    const onScriptLoad = () => {
      // 지도 초기화
      const container = document.getElementById('map');
      const options = {
        center: new window.kakao.maps.LatLng(37.5525, 126.9380), // 초기 중심 좌표 설정
        level: 3 // 초기 줌 레벨 설정
      };
      const map = new window.kakao.maps.Map(container, options);
        // 마커 변수를 전역으로 선언하여 한 번만 생성합니다.
        let marker = null;

        // 마커 추가 함수
        const addMarker = (position) => {
            // 이미 마커가 생성되어 있는 경우에는 마커의 위치만 업데이트합니다.
            if (marker === null) {
                marker = new window.kakao.maps.Marker({
                position,
                map
                });
            }
            else {
                // 이미 생성된 마커가 있는 경우에는 위치만 업데이트합니다.
                marker.setPosition(position);
            }
        
            // 마커 클릭 이벤트 리스너
            window.kakao.maps.event.addListener(marker, 'click', () => {
            console.log('마커를 클릭했습니다.');
            });
        };

      // 좌표를 주소로 변환하는 Geocoder 객체 생성
      const geocoder = new window.kakao.maps.services.Geocoder();

      // 지도 클릭 이벤트 리스너
      window.kakao.maps.event.addListener(map, 'click', (mouseEvent) => {
        const latlng = mouseEvent.latLng;
        addMarker(latlng);

        // 클릭한 위치의 좌표를 주소로 변환
        geocoder.coord2Address(latlng.getLng(), latlng.getLat(), (result, status) => {
          if (status === window.kakao.maps.services.Status.OK) {
            if (result[0]) {
              const address = result[0].address.address_name;
              setClickedAddress(address);
              onAddressClick(address);
            }
          }
        });
      });
    };

    script.onload = onScriptLoad;

    // 스크립트를 body에 추가
    document.body.appendChild(script);

    // 컴포넌트 언마운트 시 스크립트 제거
    return () => {
      document.body.removeChild(script);
    };
  }, [onAddressClick, onPlaceNameClick]);

  return (
    <div>
      {/* Kakao 지도를 보여줄 div */}
      <div id="map" style={{ width: '850px', height: '400px', marginRight: '120px' }}></div>
      {/* 클릭한 위치의 주소와 업장명을 표시하는 부분 */}
    </div>
  );
};

export default KakaoMap;
