import React, { Component, useState } from 'react';
import ApiService from "./AttentionApiService"
import {Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



import AttendStatus from './AttendStatus';

function transHoursAndMinutes(seconds) {
	if (seconds < 61) {
    	return '00:00:' + addZero(seconds)
	}
	// sec
	var hours = Math.floor(seconds/3600)
	var mins = Math.floor((seconds - hours*3600)/60)
	var secs = seconds - hours*3600 - mins*60
	return addZero(hours) + ':' + addZero(mins) + ':' + addZero(secs)
    
	function addZero(num) {
		return ((num < 10) ? '0' : '') + num
	}
}

class AttendMonth extends Component {

    // this.state 와 setState 이거쓰면 반응이 늦게나옴
    setData = {
        employee_id: window.localStorage.getItem("LoginID"),
        thisMonth: moment(new Date()).startOf('month'),
        att_stime: moment(new Date()).startOf('month').startOf('isoWeek'),
        att_etime: moment(new Date()).endOf('month').endOf('isoWeek')
    }
    weekOfMonth = (m) => m.isoWeek() - moment(m).startOf('month').isoWeek();

    thisWeekVal = this.weekOfMonth(moment())
    
    constructor(props) {
        super(props);

        this.state = {
            employee_id: window.localStorage.getItem("LoginID"),
            thisMonth : '',
            att_stime: '',
            att_etime: '',
            expanded: 'panel' + this.thisWeekVal,
            monthData : []
        }

        
    }

    

    // 라이프 사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    // render 후 dom이 반영되었을 때(로딩이 끝났을 때) 
    // componentDidMount 상태로 진입 이 상태일 때 아래 함수를 실행
    // 개발단계에선 이 부분이 두번 호출됨
    // 두번 안보고 싶으면 index.js 에서 StrictMode 를 없애면 된다.

    

    componentDidMount() {
        this.loadAttendMonth(this.setData)

    }



    setItem() {
        this.setData.att_stime = moment(this.setData.thisMonth).startOf('month').startOf('isoWeek')
        this.setData.att_etime = moment(this.setData.thisMonth).endOf('month').endOf('isoWeek')
    }

    decrement() {
        this.setData.thisMonth.add(-1, 'month');
        
        this.setItem();

        this.loadAttendMonth(this.setData)
    }

    increment() {
        
        this.setData.thisMonth.add(1, 'month');
        this.setItem();

        this.loadAttendMonth(this.setData)
    }


    thisWeek = [];
    firstCheck = true;
    
    weeks = [
        {
            week: new Array,
        },
        {
            week: new Array,
        },
        {
            week: new Array,
        },
        {
            week: new Array,
        },
        {
            week: new Array,
        }
    ]
    ;

    resetWeeks = () => {
        this.weeks = [
            {
                week: new Array,
},
            {
                week: new Array,
            },
            {
                week: new Array,
            },
            {
                week: new Array,
            },
            {
                week: new Array,
            }
        ]
        ;
    }


    loadAttendMonth = (data) => {

        this.resetWeeks();

        ApiService.getAttendMonth(data)  // 2.
        .then(res => {             // 4.
            
            //console.log('내 근태 요청');
            //console.log(res.data);

            this.setState({
                monthData: res.data
            });

            res.data.map(dto => {

                
                if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(1, 'week').format('YYYYMMDD')) {
                    //console.log("1주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[0].week.push(dto);
                }
                    
                else if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(2, 'week').format('YYYYMMDD')) {
                    //console.log("2주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[1].week.push(dto);
                }
                else if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(3, 'week').format('YYYYMMDD')) {
                    //console.log("3주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[2].week.push(dto);
                }
                else if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(4, 'week').format('YYYYMMDD')) {
                    //console.log("4주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[3].week.push(dto);
                }
                    
                else if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(5, 'week').format('YYYYMMDD')) {
                    //console.log("5주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[4].week.push(dto);
                }
                //else console.log("asd2");
            });

            if(this.firstCheck) {
                this.thisWeek = this.weeks[this.thisWeekVal].week;
                this.firstCheck = false;
            }
        })
        .catch(err => {
            console.log('reloadAttendList() Error!', err);
        })

        
        
    }
  
    setExpanded = (v) => {
        this.setState({
            expanded: v
        })
    }

    handleChange = (panel) => (event, isExpanded) => {
        this.setExpanded(isExpanded ? panel : false);
    };
    

    render() {


        let weeks = [0, 1, 2, ,3, 4]

        let sum = [0, 0, 0, 0, 0]


        return (
            <div style={{ alignItems: 'center', justifyContent: 'space-between', padding: '50px 10px 25px 10px', width: '100%' }}>
                
                <h2 style={{textAlign:'center'}}>내 근태 현황 </h2>
                <br/>
                <h1 style={{textAlign:'center'}}>
                

                <Button onClick={this.decrement.bind(this)}>
                    <FontAwesomeIcon icon={faChevronLeft}  />
                </Button>

                {this.setData.thisMonth.format('YYYY.MM')}
                
                <Button onClick={this.increment.bind(this)} >
                <FontAwesomeIcon icon={faChevronRight} />
                </Button>
                </h1>

                <br/><br/>

                <AttendStatus attend_weekData={this.thisWeek} attend_MonthData={this.weeks} employee_id={this.state.employee_id}/>
                    
                    {
                        weeks.map( i => {

                            return (
                                <div>
                                    
                                    <Accordion expanded={this.state.expanded === 'panel' + i} onChange={this.handleChange('panel' + i)} sx={{borderBottom:'1px solid grey'}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={"panel" + i +"bh-content"}
                                        id={"panel" + i + "bh-header"}
                                        >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            {i + 1}  주차
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', margin: 'auto', alignItems: 'flex-end'}}> {this.weeks[i].week.map(dto => {

                                            if(dto.att_etime != null)
                                                sum[i] += moment(dto.att_etime).diff(moment(dto.att_stime), "s");

                                            }  )} 주간 누적 근무 시간 : {transHoursAndMinutes(sum[i])}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell> 아이디 </TableCell>
                                                                <TableCell> 출근시간 </TableCell>
                                                                <TableCell> 퇴근시간 </TableCell>
                                                                <TableCell> 근무시간 </TableCell>
                                                                <TableCell> 결과 </TableCell>
                                                                <TableCell> 지각여부 </TableCell>
                                                                <TableCell> 등록일 </TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                        {
                                                            this.weeks[i].week.map(dto => {
                            
                                                                return (
                                                                    <TableRow key={dto.att_num}>
                                                                        <TableCell width="15%">{dto.employee_id} </TableCell>
                                                                        <TableCell width="15%">{dto.att_stime != null && moment(dto.att_stime).format('YYYY-MM-DD HH:mm')} </TableCell>
                                                                        <TableCell width="15%">{dto.att_etime != null && moment(dto.att_etime).format('YYYY-MM-DD HH:mm')} </TableCell>                                
                                                                        <TableCell width="10%">{dto.att_etime != null && transHoursAndMinutes(moment(dto.att_etime).diff(moment(dto.att_stime), "s"))} </TableCell>
                                                                        <TableCell width="10%">{dto.att_state} </TableCell>
                                                                        <TableCell width="5%">{dto.att_result} </TableCell>
                                                                        <TableCell width="10%">{dto.att_rdate} </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }

                                                        </TableBody>

                                                    </Table>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>   
                                </div>
                            );
                            }
                        )
                    }

    

            </div>

        );

    }

}

export default AttendMonth;

