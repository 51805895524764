import React from 'react';
import styled from 'styled-components';

const TodoTemplateBlock = styled.div`
  width: 370px;
  height: 570px;

  position: relative;
  background: #25BAD6;
  border-radius: 7px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

function TodoTemplate({ children }) {
  return <TodoTemplateBlock>{children}</TodoTemplateBlock>;
}

export default TodoTemplate;
