import React from 'react';
import { createGlobalStyle } from 'styled-components';
import TodoTemplate from './TodoComponents/TodoTemplate';
import TodoHead from './TodoComponents/TodoHead';
import TodoList from './TodoComponents/TodoList';
import TodoCreate from './TodoComponents/TodoCreate';
import { TodoProvider } from './TodoContext';

const GlobalStyle = createGlobalStyle`
  body {
    background: #EEF1F6
  }
`;
function Todo() {
  return (
    <TodoProvider>
      <GlobalStyle />
      <TodoTemplate>
        <TodoHead />
        <TodoList />
        <TodoCreate />
      </TodoTemplate>
    </TodoProvider>
  );
}

export default Todo;
