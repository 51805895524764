import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import AttentionRouter from '../page_heojun/Attention/AttentionRouter';
import RouteTemplet from './RouteTemplet';
import CalendarRouter from '../page_yoon/route/CalendarRouter';
import ApprRouter from '../page_kim/approval/ApprRouter';
import BoardRouter from '../page_hwang/Board/BoardRouter';
import MailRouter from '../page_kang/mail/MailRouter';
import EmplRouter from '../page_yoo/employee/EmplRouter';
import AdminRouter from './AdminRouter';
import mainComponent from './mainComponent';
import ChatBotRouter from '../page_heojun/ChatBot/ChatBotRouter';
import apiService from '../page_yoo/employee/ApiService';


// 메인 라우터 입니다 아래 설명에 따라 본인의 서브라우터를 넣어주시면 감사드리겠습니다.
// 서브라우트 탬플릿은 같은 route 폴더 내 RouteTemplet.js와 SubHeaderLayout.css를 참고해주시면 감사드리겠습니다.
// 나머지 ComponentTest.js 들은 우측 화면 표시 예시라 신경안쓰셔도 됩니다.

const MainRouter = () => {
    const [userInfo, setUserInfo] = useState(null); // 사용자 정보를 저장할 상태

  useEffect(() => {
    // 사용자 정보를 가져오는 API 호출
    apiService.fetchEmployeeByID(window.localStorage.getItem("LoginID"))
        .then(response => {
            // API 호출 성공 시 상태에 정보 저장
            setUserInfo(response.data);
        })
        .catch(error => {
            console.error('사용자정보 조회 오류', error);
        });
}, []);

    return ( 
        <div>
            <Route path="/main" exact={true} component={mainComponent} />
            <Route path="/attend*" exact={true} component={AttentionRouter} />
            
            {/* 테스트 라우터는 마음껏 건드셔도 됩니다. */}
            <Route path="/test*" exact={true} component={RouteTemplet} />
            <Route path="/board*" exact={true} component={BoardRouter} />
            {/* 
                본인 서브라우터 넣으실 때
                <Route path="/사용할 중간링크*" exact={true} component={사용할 서브라우터(위에 임포트까지)} />
            */}
            <Route path="/cal*" exact={true} component={CalendarRouter} />
            <Route path="/appr*" exact={true} component={ApprRouter}/>
            <Route path="/mail*" exact={true} component={MailRouter}/>
            <Route path="/employee*" exact={true} component={EmplRouter}/>
            {userInfo && userInfo.authorityId === 1 && <Route path="/admin*" exact={true} component={AdminRouter}/>}
            {/* <Route path="/admin*" exact={true} component={AdminRouter}/> */}
            <Route path="/chatbot*" exact={true} component={ChatBotRouter}/>
        </div>
    );
}

export default MainRouter;