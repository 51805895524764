import React, { Component } from 'react';
import ApprApiService from './ApprApiService'
import styles from './ApprAll.module.css';
import Pagination from "./Pagination";
// import { IonIcon } from '@ionic/react';
// import { searchOutline } from 'ionicons/icons';

class ProgressAppr extends Component {

    constructor(props) {
        super(props);

        this.state = {
            employeeId: localStorage.getItem('LoginID'),
            progressList: [],    // 5. 리스트 데이터
            message: null,
            limit: 10,
            page: 1
        }
    }

    // 라이프사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    componentDidMount() {   
        this.reloadProgressList();    // 1.
    }

    // 대기문서함 리스트(매개변수없음)
    reloadProgressList = () => {
        ApprApiService.fetchProgressList(this.state.employeeId)
            .then(res => {
                console.log('list : ', res.data); // 데이터 확인
                this.setState({
                    progressList: res.data
                });
            })
            .catch(err => {
                console.log('reloadProgressList() Error!!', err);
            });
    }

    // 문서 상세페이지 확인
    detailAppr = (dto) => {
        console.log("Clicked apprNum:", dto.apprNum); // apprNum 값 확인
        console.log("Clicked employeeId:", dto.employeeId); // id 값 확인
        console.log("Clicked apprForm:", dto.apprForm);
    
        const values = {
            apprNum: dto.apprNum,
            employeeId: dto.employeeId,
            apprForm: dto.apprForm
        }
    
        window.localStorage.setItem("apprValue", JSON.stringify(values));
        if (dto.apprForm === "휴가신청") {
            this.props.history.push("/appr/leaveDetail");
        } else if (dto.apprForm === "업무기안") {
            this.props.history.push("/appr/WorkDetail");
        }
    }

    // 페이지 함수
    handlePageChange = (pageNumber) => {
        this.setState({ page: pageNumber }, () => {
            this.reloadProgressList(); // 페이지 번호 변경 시 데이터 다시 로드
        });
    };

    // 페이지 limit 함수
    handleLimitChange = (event) => {
        this.setState({ limit: Number(event.target.value) });
    };

    render() {

        // 페이지
        const { progressList, limit, page } = this.state;
        const offset = (page - 1) * limit;

        // this.props.setProgressListCount(progressList.length);

        return (
            <div>
                <div className={styles.allListWrap}>       
                    <div className={styles.progressTitle}>
                        <sapn>결재 대기 문서</sapn>
                    </div>
                    {/* 페이지 리스트 설정 */}
                    <label className={styles.pageLimit}>
                        <select
                            type="number"
                            value={limit}
                            onChange={this.handleLimitChange}
                            style={{ float: 'right' }}
                        >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                        </select>
                    </label>
                    <div className={styles.progressTable}>
                        <table>
                            <thead>
                                <tr>
                                    <td>기안일</td>
                                    <td>결재양식</td>
                                    <td>긴급</td>
                                    <td>제목</td>
                                    <td>첨부</td>
                                    <td>기안자</td>
                                </tr>
                            </thead>
                            <tbody>
                                {progressList.slice(offset, offset + limit).map(dto => 
                                    <tr key={dto.apprNum} onClick={() => this.detailAppr(dto)}>
                                        <td> {dto.apprDate} </td>
                                        <td> {dto.apprForm} </td>
                                        <td> 
                                            {dto.apprUrgent === 'y' ? (
                                                <button className={styles.emergencyIcon}>긴급</button>
                                            ) : null} 
                                        </td>
                                        <td> {dto.apprTitle} </td>
                                        <td>  </td>
                                        {/* <td component="th" scope="progressList"> {dto.apprNum} </td> */}
                                        <td> {dto.employeeId} </td>
                                        {/* <td> {dto.apprStatus} </td> */}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* 페이징처리 */}
                    <div className='apprPaging'>
                        <Pagination
                            total={progressList.length}
                            limit={limit}
                            page={page}
                            setPage={this.handlePageChange}
                        />
                    </div>
                </div>
             </div>
        );
    }
}

export default ProgressAppr;