import React, { Component } from 'react';
import './CalendarDetail.css'; // CSS 파일 임포트
import CalendarApiService from './CalendarApiService.js';
import { withRouter, Link } from 'react-router-dom';
import OrganizationChart from './AttListPop.js';
import { IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import ApiService from '../../page_yoo/employee/ApiService.js';
import KakaoEventModal from './KakaoEventModal.js';

class AdminCalendarDetail extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            eventTitle: '',
            startDate: props.selectedDate ? props.selectedDate.startStr : '',
            endDate: props.selectedDate ? props.selectedDate.endStr : '',
            startTime: '09:00',
            endTime: '09:00',
            timeOptions: this.generateTimeOptions(), // 시간 선택 옵션 생성
            allDay: false,
            attList: '',
            location: '',
            content:'',
            selectedDate: props.selectedDate,
            selectedEvent: null, // 선택된 이벤트 정보를 저장하는 상태를 추가
            eventDetail: null,
            employeeId: '',
            selectedRoom: '', // 선택된 회의실
            rooms: [
                { id: 1, name: 'Spring Room', reserved: false },
                { id: 2, name: 'Summer Room', reserved: false },
                { id: 3, name: 'Autumn Room', reserved: false },
                { id: 4, name: 'Winter Room', reserved: false }
            ],
            calNo : props.location.search.split('=')[1],
            attArr: [],
            isPopupActive: false,
            loginId: localStorage.getItem("LoginID"),
            employeeName:'',   // 직원 이름을 저장할 상태를 추가
            departmentName:'',
            positionName:''
        };
    }

    componentDidMount() {
        this.fetchEventDetail();
    }

    fetchEventDetail = () => {
        const { calNo } = this.state; // calNo 가져오기
    
        CalendarApiService.selectCalendar(calNo)
            .then(response => {
                console.log("===response===");
                console.log(response);
    
                const roomId = response.data.roomId; // 회의실 정보의 roomId
    
                // 회의실 예약 여부에 따라서 예약된 상태로 설정
                const updatedRooms = this.state.rooms.map(room => ({
                    ...room,
                    reserved: room.id === roomId
                }));
    
                
                // 종일 여부에 따라 allDay 상태를 설정
                const allDayValue = response.data.calAllday === 'Y';

                this.setState({ 
                    eventDetail: response.data,
                    eventTitle: response.data.calTitle,
                    startDate: response.data.calStartDT,
                    endDate: response.data.calEndDT,
                    startTime: response.data.calStartTM,
                    endTime: response.data.calEndTM,
                    allDay: allDayValue,
                    location: response.data.calLocation,
                    content: response.data.calContent,
                    attArr: response.data.attArr,
                    employeeId: response.data.employeeId,
                    roomId: roomId,
                    rooms: updatedRooms // 업데이트된 예약 상태를 상태에 반영
                });
    
                // fetchEmployeeData 메서드를 호출
                this.fetchEmployeeData(response.data.employeeId);
            })
            .catch(error => {
                console.error('Error fetching event detail:', error);
            });
    };
    

    fetchEmployeeData = async (employeeId) => {
        try {
            const employeeResponse = await ApiService.fetchEmployeeByID(employeeId);
            console.log("Employee Info:", employeeResponse.data);
            // 직원 정보를 가져와서 필요한 작업을 수행
            this.setState({ 
                employeeName: employeeResponse.data.employeeName, 
                departmentName: employeeResponse.data.departmentName, 
                positionName: employeeResponse.data.positionName 
            });
        } catch (error) {
            console.error('사용자정보 조회 오류 : ', error);
        }
    };



    componentDidUpdate(prevProps) {
        const { selectedDate } = this.props;
        if (selectedDate !== prevProps.selectedDate && selectedDate) {
            const startDate = selectedDate.startStr ? new Date(selectedDate.startStr).toISOString().slice(0, 10) : '';
            const endDate = selectedDate.endStr ? new Date(selectedDate.endStr).toISOString().slice(0, 10) : '';
            this.setState({
                startDate: startDate,
                endDate: endDate,
                selectedDate: selectedDate
            });
        }
    }

    generateTimeOptions() {
        let times = [];
        for (let i = 9; i <= 21; i++) {
            for (let j = 0; j < 60; j += 30) {
                let hour = i < 10 ? `0${i}` : `${i}`;
                let minute = j === 0 ? '00' : '30';
                times.push(`${hour}:${minute}`);
            }
        }
        times.push('22:00');
        return times;
    }

    onChangeHandler = (e) => {
        const { name, value, type, checked } = e.target;
        this.setState({ [name]: type === 'checkbox' ? checked : value });

        // 선택된 날짜 업데이트
        if (name === 'startDate' || name === 'endDate') {
            const newDate = name === 'startDate' ? 'startStr' : 'endStr';
            if (this.props.setSelectedDate) {
                this.props.setSelectedDate({ ...this.props.selectedDate, [newDate]: value });
            }
        }
        if (name === 'endDate' && this.state.startDate > value) {
            window.alert("종료일자는 시작일자 이후여야 합니다.");
            return;
        }
        else if (name === 'endTime' && this.state.startTime > value) {
            window.alert("종료시간은 시작시간 이후여야 합니다.");
            return;
        }
       
        else if (name === 'allDay') {
            const { allDay } = this.state;
            // startTime과 endTime을 변경하는 로직은 allDay 상태를 업데이트한 후에 실행
            const newStartTime = !allDay ? '09:00' : '09:00';
            const newEndTime = !allDay ? '22:00' : '09:00';
            // 상태를 업데이트
            this.setState({
                allDay: !allDay,
                startTime: newStartTime,
                endTime: newEndTime
            }, () => {
                // allDay 상태에 따라 calAllday 컬럼을 업데이트
                const calAlldayValue = allDay ? 'N' : 'Y'; // allDay가 true일 때 'N', false일 때 'Y'
                if (this.props.setSelectedDate) {
                    this.props.setSelectedDate({ ...this.props.selectedDate, calAllday: calAlldayValue });
                    console.log('calAllday updated:', calAlldayValue);
                }
            });
            return;
        }

    };
  

    handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        this.setState({ startDate: newStartDate });
        this.props.setSelectedDate({ ...this.props.selectedDate, startStr: newStartDate });
    };

    handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        this.setState({ endDate: newEndDate });
        this.props.setSelectedDate({ ...this.props.selectedDate, endStr: newEndDate });
    };


    handleRoomChange = (roomId) => {
        const selectedRoom = this.state.rooms.find(room => room.id === roomId);
    
        if (!selectedRoom) {
            return; // 선택된 회의실이 없는 경우 함수 종료
        }
    
        // 이미 선택된 방의 ID와 클릭한 방의 ID가 같은 경우 선택을 취소하고 디폴트 색상으로 변경
        if (this.state.roomId === roomId) {
            this.setState({ selectedRoom: null, roomId: null }, () => {
                console.log('Room deselected:', roomId);
            });
        } 
      
        else {
            this.setState({ selectedRoom: selectedRoom.id, roomId: selectedRoom.id }, () => {
                console.log('Room selected:', selectedRoom.id);
            });
        }
    };
    

    handleDateChange = (newDate) => {
        this.props.setSelectedDate(newDate);
        // 선택한 날짜에 대한 다른 작업을 수행
        console.log("날짜가 변경되었습니다:", newDate);
    };

    handleSaveChanges = (e) => {
        console.log("수정");
        e.preventDefault();
        // 수정된 일정 데이터를 가져와서 API 호출
        const { eventTitle, startDate, endDate, startTime, endTime, location, content, roomId } = this.state;

       // 타이틀이 비어 있는지 확인
       if (eventTitle.trim() === "") {
        window.alert("일정명을 입력해주세요.");
        return; // 타이틀이 비어 있으면 함수 실행을 중지
        } 
        else if (this.state.startDate > this.state.endDate) {
            window.alert("시작일자와 종료일자를 확인하세요.");
            return;
        }
        else if (this.state.endTime < this.state.startTime) {
            window.alert("시작시간은 종료시간 이전이여야 합니다.");
            return;
        } 
        else if (this.state.startDate === this.state.endDate && this.state.startTime === this.state.endTime) {
            window.alert("시작시간과 종료시간을 다시 설정해주세요."); 
            return;
        }
        else if (this.state.selectedRoom !== '' && this.state.startDate !== this.state.endDate) {
            window.alert("회의실은 하루 안에서 시간으로 예약해야 합니다.");
            return;
        }
        else {
            CalendarApiService.updateCalendar({
                calTitle: eventTitle,
                calStartDT: startDate,
                calStartTM: startTime,
                calEndDT: endDate,
                calEndTM: endTime,
                calAllday: this.state.allDay ? 'Y' : 'N', // allDay 상태에 따라 'Y' 또는 'N'으로 설정
                calLocation: location,
                calContent: content,
                calNo: this.state.calNo,
                attArr: this.state.attArr,
                roomId: this.state.roomId
            })
            .then(response => {
                console.log('일정 수정 완료:', response.data);
        
                // updateCnt 값 받아오기
                const updateCnt = response.data;
                console.log('updateCnt : ', updateCnt);

                // updateCnt 3인 경우에는 중복체크
                // 회의실은 그래도 두고 일시만 변경하는 경우도 가능하게 설정

                if (updateCnt === 3) {
                    window.alert("선택된 회의실은 이미 예약된 회의실입니다.");
                    return;
                }
                else {
                    // 캘린더 화면으로 이동
                    this.props.history.push('/admin/AdminCalendar');
                }
        
                // 수정 완료 후 적절한 동작 수행 (예: 일정 목록 다시 불러오기 등)
            })
            .catch(error => {
                console.error('일정 수정 실패:', error);
            });
        }
    };
    
    
    // 일정 클릭 이벤트 핸들러
    handleEventClick = (event) => {
        // 클릭된 일정의 데이터를 가져와서 새로운 컴포넌트를 나타내는 상태를 업데이트
        this.setState({ selectedEvent: event });
    };


    
    // 상세페이지에서의 삭제
    handleDelete = (e) => {
        e.preventDefault(); // 이벤트의 기본 동작 방지

        const { calNo } = this.state; // 현재 사용자의 ID
    
        let inputData = {
            calTitle: this.state.eventTitle,
            calStartDT: this.state.startDate,
            calStartTM: this.state.startTime,
            calEndDT: this.state.endDate,
            calEndTM: this.state.endTime,
            calLocation: this.state.location,
            calContent: this.state.content,
            employeeId: this.state.employeeId,
            attArr: this.state.attArr,
            roomId: this.state.roomId
        };
    
        // 일정 삭제를 위해 CalendarApiService의 적절한 메소드를 호출하고 Promise를 반환
        CalendarApiService.deleteCalendar(calNo, inputData)
            .then(() => {
                console.log('일정 삭제 완료');
    
                // 삭제가 완료된 후에 ./cal로 이동
                this.props.history.push('/admin/AdminCalendar');
            })
            .catch(error => {
                console.error('일정 삭제 실패:', error);
            });
    };
    


    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isPopupActive: !prevState.isPopupActive }));
    }

    // 팝업을 닫는 함수
    handleClosePopup = () => {
        this.setState({ isPopupActive: false });
    }

    // 참석자 선택 시 호출되는 함수
    handleEmployeeIdSelect = (employeeId) => {
        this.setState(prevState => {
            // 이전 상태의 attArr가 null이거나 배열이 아닌 경우 빈 배열로 초기화
            const prevAttArr = prevState.attArr || [];
    
            // 새로운 참석자 아이디가 이미 attArr에 있는지 확인
            const isAlreadySelected = prevAttArr.includes(employeeId);
    
            if (!isAlreadySelected) {
                // 새로운 참석자 아이디가 기존 attArr에 포함되어 있지 않으면 추가
                return { attArr: [...prevAttArr, employeeId] };
            } else {
                // 이미 선택된 경우, 해당 아이디를 제외하고 새로운 배열 생성
                const updatedAttArr = prevAttArr.filter(id => id !== employeeId);
                return { attArr: updatedAttArr };
            }
        });
    };
    
    handleAddressSelect = (address) => {
        this.setState({ location: address });
    };

    
    openMapModal = () => {
        this.setState({ isMapModalOpen: true });
    }

    closeMapModal = () => {
        this.setState({ isMapModalOpen: false });
    }

    render() {
        const { eventTitle, startDate, endDate, startTime, endTime, timeOptions, allDay, 
            location, isMapModalOpen, content, rooms} = this.state;
    
      
        // 현재 사용자의 employeeId
        // const currentUserEmployeeId = this.state.loginId;

        // 현재 사용자의 employeeId와 등록된 일정의 employeeId 비교하여 버튼 활성화 여부 결정
        // const isEditable = this.state.employeeId === currentUserEmployeeId;

        return (
            <div className="full-screen">
                <div className="schedule">
                    <header className="schedule-header">
                        일정상세
                    </header>
                    <div className="schedule-line"></div>
                    <div className="schedule-content">
                        <form onSubmit={this.handleSubmit}>
                            <div id="schedule-A" className="schedule-name">
                                <input
                                    className="event-input-A"
                                    type="text"
                                    // placeholder="일정을 입력하세요"
                                    name="eventTitle"
                                    value={eventTitle}
                                    onChange={this.onChangeHandler}
                                    />
                            </div>
                            <div id="date-time-A" className="startDate-time">
                                <div className="startDate-A">
                                    <input
                                        className="date-input-A"
                                        type="date" 
                                        name="startDate"
                                        value={startDate} // 시작일자
                                        onChange={this.onChangeHandler}
                                        />
                                </div>
                                <div className="startTime-A">
                                    <select 
                                    className="time-input-A"
                                    name="startTime"
                                    value={startTime} // 시작시간
                                    onChange={this.onChangeHandler}
                                    disabled={allDay}
                                    >
                                    {timeOptions.map((time, index) => (
                                        <option key={index} value={time}>
                                        {time}
                                        </option>
                                    ))}
                                    </select>
                                </div>
                                <div className="endDate-time-A">
                                    <label className="label-class-A">~</label>
                                    <input 
                                    className="date-input-A2"
                                    type="date" 
                                    name="endDate"
                                    value={endDate} // 종료일자
                                    onChange={this.onChangeHandler}
                                    />
                                </div>
                                <div className="endTime-A">
                                    <select
                                    className="time-input-A2"
                                    name="endTime"
                                    value={endTime} // 죵료시간
                                    onChange={this.onChangeHandler}
                                    disabled={allDay}
                                    >
                                    {timeOptions.map((time, index) => (
                                        <option key={index} value={time}>
                                        {time}
                                        </option>
                                    ))}
                                    </select>
                                </div>
                                <div>
                                    <div className="allDay-S">
                                        <input
                                            type="checkbox" 
                                            id="allDay-A" 
                                            className="allDay-A"
                                            name="allDay"
                                            checked={allDay} 
                                            onChange={this.onChangeHandler} 
                                        />
                                        <label htmlFor="allDay">종일</label>
                                    </div>
                                </div>
                            </div>
                            <div className='register-A'>
                                <label className="register-name">일정 등록자 정보</label>
                                <input
                                    className="register-input"
                                    type="text"
                                    name="register"
                                    value={`이름: ${this.state.employeeName}  부서: ${this.state.departmentName}  직급: ${this.state.positionName}` } // 일정 등록자의 값을 받아옴
                                    readOnly // 읽기 전용 필드로 설정
                                />
                            </div>
                            <div className="att-A">  {/* 참석자 입력란 */}
                                <label className="label-att-A">참석자</label>
                                <input
                                    id="standard-required"
                                    variant="standard"
                                    label="toId"
                                    className="att-input-A"
                                    type="text"
                                    name="attArr"
                                    value={this.state.attArr}
                                    readOnly // 읽기 전용으로 설정
                                    onChange={this.onChangeHandler}
                                />
                                <div className="mailPop">
                                    <div className="searchID">
                                        <div className="icon-container1">
                                            <IonIcon icon={addOutline}
                                                    onClick={this.togglePopup}
                                            />
                                        </div>
                                        <label className="att-search" onClick={this.togglePopup}> 참석자 선택</label>
                                    </div>
                                    {this.state.isPopupActive && <OrganizationChart handleClosePopup={this.handleClosePopup} onEmployeeIdSelect={this.handleEmployeeIdSelect} />}
                                </div>
                            </div>
                            <div className="location-A">  {/* 장소 입력란 */}
                                <label className="label-location-B">장소</label>
                                <input
                                    className="location-input-B"
                                    type="text"
                                    name="location"
                                    // placeholder="장소를 입력하세요"
                                    value={location}
                                    onChange={this.onChangeHandler}
                                />
                                    <button className="maps" type="button" title="지도보기" onClick={this.openMapModal}>지도보기</button>
                               {isMapModalOpen && <KakaoEventModal onClose={this.closeMapModal} onAddressSelect={this.handleAddressSelect} />}
                            </div>
                            <div className="content-A">  {/* 내용 입력란 */}
                                <label className="label-content-A">내용</label>
                                <textarea
                                    className="content-input-A"
                                    name="content"
                                    // placeholder="내용를 입력하세요"
                                    value={content}
                                    onChange={this.onChangeHandler}
                                />
                            </div>

                            <div className="reservation-container-B">
                                <label className="label-reservation-B">예약하기</label>
                                {rooms.map(room => (
                                    <button
                                        key={room.id}
                                        className={`room-box-edit ${this.state.roomId === room.id ? 'selected-B' : ''} ${room.reserved ? 'reserved-B' : ''}`}
                                        onClick={() => this.handleRoomChange(room.id)}
                                        type="button"
                                    >
                                        <span className="room-name">{room.name}</span>
                                        <span className="status-indicator"></span>
                                    </button>
                                ))}
                            </div>


                            <footer className="btn_layer_wrap2">
                                {(
                                    <>
                                        <button className="btn_major_s1" title="수정" onClick={this.handleSaveChanges}>
                                        수정
                                        </button>
                                        <button className="btn_major_s1" onClick={this.handleDelete} title="삭제">
                                        삭제
                                        </button>
                                    </>
                                )}
                                <Link to="/admin/AdminCalendar">
                                    <button className="btn_minor_s1" title="취소">
                                    취소
                                    </button>
                                </Link>
                            </footer>
                        </form>
                    </div>
                </div>
            </div>
        );
    } 
}

export default withRouter(AdminCalendarDetail);
