import React, { Component } from 'react';
import styles from './WorkForm.module.css';
import ApprMain from './ApprMain';
import ApprDetail from './ApprDetail';
import ApprApiService from './ApprApiService';
import { Link, Route } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { createOutline, closeCircleOutline, menuOutline, personAddOutline } from 'ionicons/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import OrganizationChart from './employeeListPop.js';
import SearchBar from './SearchBar';

class WorkForm extends Component {

    // 생성자
    constructor(props) {
        super(props);

        // 내가 입력한 값 들어오는곳
        this. state = { // 해당 변수들은 onChange로 인해 입력한 값으로 변경된 상태
          apprForm: '업무기안',
          apprLeaveType: '',
          supportDept: '',
          isModalOpen: false,
          approver: [],
          // 최초승인자 
          firstApprover:'', 
          // 중간승인자    
          midApprover: '',
          // 최종승인자 
          finalApprover: '',    
          employeeName: '', // 사용자 이름을 저장할 state 변수
          departmentName: '',
          apprNum: '',
          employeeId: localStorage.getItem('LoginID'),
          apprDate: new Date(),
          apprUrgent: 'n',
          apprTitle: '',
          apprContent: '',
          apprHalfType: '',
          apprUnusedLeave: '',
          apprStart: new Date(),
          apprEnd: new Date(),
          apprStatus: '',
          apprReason : '',
          apprReqLeave: 1,
          isPopupActive: false,
          startDay: false,
          morning: false,
          afternoon: false,
        };
        this.onChange = this.onChange.bind(this);
        this.handleRequestClick = this.handleRequestClick.bind(this);
        this.saveAppr = this.saveAppr.bind(this);
        this.handleSelectedDaysChange = this.handleSelectedDaysChange.bind(this);
    }

    // 기안자 정보 가져오기
    // 라이프사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    componentDidMount() {
        // 사용자 정보를 가져오는 함수 호출
        this.fetchUserInfo();
      }
    
      fetchUserInfo() {
        const employeeId = localStorage.getItem('LoginID'); // 예시로 직접 값 설정
        console.log('employeeId:', employeeId); // 요청하는 사용자 ID 콘솔 출력
    
        // ApiService에서 데이터 가져오기
        ApprApiService.fetchUserInfo(employeeId)
          .then(res => {
            console.log('Response data:', res.data); // 응답 데이터 콘솔 출력
    
            // JSON 데이터를 파싱하고 필요한 정보를 추출하여 상태 업데이트
            this.setState({
                employeeName: res.data.employeeName,
              departmentName: res.data.departmentName,
            });


          })
          .catch(error => console.error('에러:', error)); // 에러 발생 시 콘솔 출력
      }
    
    
    // startDate 설정
    handleStartDateChange = (date) => {
      if (date instanceof Date) {
        console.log("Received start date:", date);
        console.log("Type of start date:", typeof date); // date의 유형 확인
    
        // Date 객체로 변환되었으므로 그대로 사용
        this.setState({ apprStart: date }, this.calculateSelectedDays);
      } else {
        // 만약 DatePicker에서 Date 객체가 아닌 다른 형식의 값을 반환한다면,
        // 해당 값을 JavaScript의 Date 객체로 수동 변환해야 합니다.
        // 예를 들어, 다음과 같이 변환할 수 있습니다.
        const convertedDate = new Date(date);
        console.log("Received start date (converted):", convertedDate);
    
        // 변환된 Date 객체를 상태에 설정하고 계산 함수 호출
        this.setState({ apprStart: convertedDate }, this.calculateSelectedDays);
      }
    };
    
    // endDate 설정
    handleEndDateChange = (date) => {
      if (date instanceof Date) {
        console.log("Received end date:", date);
        console.log("Type of end date:", typeof date); // date의 유형 확인
    
        // Date 객체로 변환되었으므로 그대로 사용
        this.setState({ apprEnd: date }, this.calculateSelectedDays);
      } else {
        // 만약 DatePicker에서 Date 객체가 아닌 다른 형식의 값을 반환한다면,
        // 해당 값을 JavaScript의 Date 객체로 수동 변환해야 합니다.
        // 예를 들어, 다음과 같이 변환할 수 있습니다.
        const convertedDate = new Date(date);
        console.log("Received end date (converted):", convertedDate);
    
        // 변환된 Date 객체를 상태에 설정하고 계산 함수 호출
        this.setState({ apprEnd: convertedDate }, this.calculateSelectedDays);
      }
    };

    // 선택일수 계산 함수
    calculateSelectedDays = () => {
      console.log("calculateSelectedDays 함수가 호출되었습니다.");
    
      const { apprStart, apprEnd } = this.state;
    
      if (apprStart && apprEnd) {
        console.log("apprStart와 apprEnd가 모두 존재합니다.");
        const oneDay = 24 * 60 * 60 * 1000; // 하루의 밀리초
        const diffTime = Math.abs(apprEnd.getTime() - apprStart.getTime()); // 시작일과 종료일 사이의 시간 차이(밀리초)
        const diffDays = Math.ceil(diffTime / oneDay); // 일 수로 변환하여 올림 처리
        this.setState({ apprReqLeave: diffDays });
        console.log("diffDays:", diffDays);
      } else {
        console.error("날짜 형식이 올바르지 않습니다.");
      }
    };

    // 선택일수 값 가져오는 함수
    handleSelectedDaysChange(event) {
        const value = event.target.value;
        // 입력된 값이 숫자인지 확인하고 유효한 경우에만 selectedDays를 업데이트합니다.
        if (!isNaN(value)) {
            this.setState({ apprReqLeave: value });
        }
    }

    onChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    
    // 결재요청 핸들링
    handleRequestClick() {
        const { apprForm, apprLeaveType, supportDept, apprReqLeave, apprTitle, apprContent, approver } = this.state;

        if (approver.length === 0) {
            alert("결재자를 선택해주세요.");
            return;
        }

        if (!apprTitle) {
            alert("제목을 입력해주세요.");
            return;
        }

        if (!apprContent) {
            alert("내용을 입력해주세요.");
            return;
        }
        
        const result = window.confirm("결재요청 하시겠습니까?");
        if (result) {
            this.saveAppr({
                apprForm,
                apprLeaveType,
                supportDept,
                apprReqLeave
            });
        }
    }
    // 등록
    // let inputData 입력한 값 저장
    saveAppr = () => {

      let inputData = { // 3. state값을 읽어온 후 아래에서 호출해 부트로 넘겨줌
          employeeName: this.state.employeeName,
          departmentName: this.state.departmentName,
          apprNum: this.state.apprNum,
          employeeId: this.state.employeeId,
          apprForm: this.state.apprForm,
          apprDate: this.state.apprDate,
          apprUrgent: this.state.apprUrgent,
          apprTitle: this.state.apprTitle,
          supportDept: this.state.supportDept,
          apprContent: this.state.apprContent,
          apprLeaveType: this.state.apprLeaveType,
          apprHalfType: this.state.apprHalfType,
          apprUnusedLeave: this.state.apprUnusedLeave,
          apprReqLeave: this.state.apprReqLeave,
          apprStart: this.state.apprStart,
          apprEnd: this.state.apprEnd,
          apprStatus: this.state.apprStatus,
          apprReason : this.state.apprReason,
          approverDTO:   // approverDTO 정보 추가
            {
                firstApprover: this.state.firstApprover,
                midApprover: this.state.midApprover,
                finalApprover: this.state.finalApprover
            }
        
      }

    //   this.state.approver.forEach(approver => {
    //     inputData.approver = approver; // 결재자 정보를 추가
    // });

      // 4. AJAX 요청으로 등록처리(화면에서 입력한 값 -> onChange() -> setState() -> inputData)
      ApprApiService.addAppr(inputData) // 스프링부트와의 통신기능 호출
        .then(res => {  // Api넘겨서 정상이면.
            console.log('inputData:', inputData);
            this.setState({
            })
            console.log('상태 : ', res.data); // 컨트롤러에서 전달함(resultCode, resultMsg)
            const apprNum = res.data.apprNum;
            this.props.history.push('/appr/ApprAll'); //ApprRouter.js - ApprMain 호출 즉, 등록후 뜨는 화면
        })
        .catch(err => { // 문제 발생시
            console.log('addAppr() 에러!! : ', err);
        });

    }

    // 초기화버튼
    // resetValues = () => {
    //   this.setState({
    //       toId: '',
    //       emailTitle: '',
    //       emailContent: '',
    //       emailFile: ''
    //   });
    // }

    // 상단 작성중 취소버튼
    handleCancelClick = () => {
        const result = window.confirm("취소 하시겠습니까?");
        if (result) {
            alert("취소되었습니다.");
        }
    }

    // 목록으로 돌아가기
    handleListClick = () => {
        const result = window.confirm("작성중인 문서가 있습니다. 메인화면으로 돌아가시겠습니까?");
    }

    // 팝업
    setPopupActive = (value) => {
        this.setState({ isPopupActive: value });
    }

    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isPopupActive: !prevState.isPopupActive }));
    }

    // 팝업을 닫는 함수
    handleClosePopup = () => {
        this.setState({ isPopupActive: false });
    }

    // 결재자 누적
    handleEmployeeIdSelect = (employeeId, employeeName, positionName) => {

        // 이미 선택된 결재자인지 확인
        const isDuplicate = this.state.approver.some(approver => approver.employeeId === employeeId);

        if (isDuplicate) {
            alert("이미 선택된 결재자입니다.");
            return;
        }

        if (this.state.approver.length < 3) { // 최대 3명까지만 추가
            this.setState(prevState => {
                let newState = { 
                    ...prevState,
                    approver: [...prevState.approver, {employeeId, employeeName, positionName} ]
                };
                // 결재자 배열의 길이에 따라 firsApprover, midApprover, finalApprover에 할당
                if (newState.approver.length === 1) {
                    newState.firstApprover = employeeId;
                } else if (newState.approver.length === 2) {
                    newState.midApprover = employeeId;
                } else if (newState.approver.length === 3) {
                    newState.finalApprover = employeeId;
                }
                return newState;
            });
        } else {
            alert("최대 3명까지 선택할 수 있습니다.");
        }
    }
    
    
    onChangeCheckbox = (e) => {
        const { name, checked } = e.target;
    
        if (name === 'apprUrgent') {
            // 긴급여부 체크박스를 선택한 경우
            this.setState({
                apprUrgent: checked ? 'y' : 'n'
            }, () => {
                console.log("apprUrgent:", this.state.apprUrgent); // 추가한 부분
            });
        }  
        else if (name === 'startDay') {
            // 시작일 체크박스를 선택한 경우
            if (checked) {
                // 시작일을 체크한 경우에만 오전과 오후를 선택할 수 있도록 설정
                this.setState({
                    startDay: true,
                    morning: true,
                    afternoon: false
                });
            } else {
                // 시작일 체크 해제 시 모든 시간대 체크를 해제
                this.setState({
                    startDay: false,
                    morning: false,
                    afternoon: false
                });
            }
        } else { // 오전 또는 오후 체크박스를 선택한 경우
            if (this.state.startDay && checked) {
                // 시작일을 선택하고 해당 시간대 체크한 경우에만 업데이트
                this.setState({
                    [name]: checked,
                    morning: name === 'morning' ? checked : false,
                    afternoon: name === 'afternoon' ? checked : false
                });
            }
        }
    }

    // 반차 사용시 실행 함수
    // 휴가종류 변경 시 호출되는 함수
    handleLeaveTypeChange = (e) => {
        const { value } = e.target;
        // 휴가종류가 '반차'일 경우 시작일을 변경
        if (value === '반차') {
            const today = new Date();
            this.handleStartDateChange(today);
        }
        // 휴가종류를 상태에 설정
        this.setState({ apprLeaveType: value });
    }

    // 시작일 변경 시 호출되는 함수
    handleStartDateChange = (date) => {
        // 시작일 변경 시 종료일을 시작일로 설정
        this.setState({
            apprStart: date,
            apprEnd: date, // 종료일을 시작일로 설정
            apprReqLeave: 0.5 // 신청 연차를 0.5로 설정
        });
    }    

    // input 텍스트 입력함수
    onChangeTextInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    // 네이버 검색 API
    handleSearch = (query) => {
        // 여기서 검색어를 사용하여 API 호출 또는 검색 로직 수행
        console.log('검색어:', query);
    };

    
    render() {
    
      // 기안자 정보 가져오기  
      const { employeeName, departmentName, apprUrgent } = this.state;  
      const { apprForm, apprLeaveType, supportDept} = this.state;
      const { apprReqLeave } = this.state;

      // 날짜 형식 바꾸기 
      const days = ['일', '월', '화', '수', '목', '금', '토'];
      const date = new Date(this.state.apprDate);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} (${days[date.getDay()]})`;
        
        return (
            
            <div>
            <div className={styles.formTitle}>업무기안</div>
            <div className={styles['nav-wrap']}>
                <div className={styles.buttonWrap}>
                    <div className={styles['button-top']}>
                        {/* 결재요청 버튼 */}
                        <button type="button" className={styles.btn} onClick={this.handleRequestClick}>
                            <div className={styles.icon}><IonIcon icon={createOutline} /></div>결재요청</button>

                        {/* 취소 버튼 */}
                        <button type="button" className={styles.btn} onClick={this.handleCancelClick}>
                            <div className={styles.icon}><IonIcon icon={closeCircleOutline} /></div>
                            <Link to="/appr/">취소</Link>
                        </button>

                        {/* 목록버튼 */}
                        <button type="button" className={styles.btn} onClick={this.handleListClick}>
                            <div className={styles.icon}><IonIcon icon={menuOutline} /></div>
                            <Link to="/appr/">목록</Link>
                        </button>

                        {/* 결재자 선택 버튼 */}
                        <div className="apprPop">
                            <div className="employeeList">
                                {/* <Button onClick={this.togglePopup}>결재자</Button> */}
                                <button type="button" className={styles.btn} onClick={this.togglePopup}>
                            <div className={styles.icon}><IonIcon icon={personAddOutline} /></div>결재자</button>
                            </div>
                            {this.state.isPopupActive && <OrganizationChart handleClosePopup={this.handleClosePopup} onEmployeeIdSelect={this.handleEmployeeIdSelect} />}
                        </div>
                    </div>
                </div> {/* 상단 버튼 끝 */}

                    {/* <div className={styles['right-wrap']}> */}
                <div className={styles.formContent}>
                    <div className='leave-form'>
                        <div className={styles.headTitle}>업무기안</div>
                            <div className={styles.topInfo}>
                                {/*기안자 상세*/}
                                <div className={styles.userInfo}>
                                    <table >
                                        <thead>
                                        <tr>
                                            <td>기안자</td>
                                            <td>{employeeName}</td>
                                        </tr>
                                        <tr>
                                            <td >부서</td>
                                            <td>{departmentName}</td>
                                        </tr>
                                        <tr>
                                            <td>기안일</td>
                                            <td>{formattedDate}</td>
                                        </tr>
                                        <tr>
                                            <td>문서번호</td>
                                            <td></td>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>

                                {/* 결재자 정보 */}
                                <div className={styles.approverInfo}>
                                {this.state.approver.map((approver, index) => (
                                    <table key={index}>
                                        <thead>
                                            <tr>
                                                <td className={styles.approvalText} rowSpan={3}>승인</td>
                                                <td>{approver.positionName}</td>
                                            </tr>
                                            <tr>
                                                <td>{approver.employeeName}</td>
                                            </tr>
                                            <tr>
                                                <td>미승인</td>
                                            </tr>
                                        </thead>
                                    </table>
                                ))}
                                </div> {/* approverInfo */}
                            </div>
                        <div className={styles.apprContent}>
                            <table>
                                <tbody>
                                    {/* 결재양식 */}
                                    <tr> 
                                        <td>결재양식</td>
                                        <td>
                                            <div className='apprForm'>
                                                <select
                                                    name="apprForm"
                                                    value={apprForm}
                                                    onChange={this.onChange}>
                                                    <option value="업무기안">업무기안</option>
                                                    {/* <option value="업무협조">업무협조</option> */}
                                                </select>
                                            </div>
                                        </td>
                                        <td>긴급여부</td>
                                        <td>
                                            <div className='apprUrgent'>
                                                <input
                                                    type="checkbox"
                                                    name="apprUrgent"
                                                    checked={apprUrgent === 'y'}
                                                    onChange={this.onChangeCheckbox}
                                                />
                                                <label htmlFor="apprUrgent">긴급</label>
                                                {/* {this.state.apprUrgent} */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr> 
                                        <td>시행일자</td>
                                        <td>
                                        <DatePicker
                                                //className={style.ApprcClender}
                                                selected={this.state.apprStart}
                                                onChange={this.handleStartDateChange}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </td>    
                                        <td>협조부서</td>
                                        <td>
                                            <div className='supportDept'> 
                                                <select
                                                    name="supportDept"
                                                    value={supportDept}
                                                    onChange={this.onChange}>
                                                    <option value="인사팀">인사팀</option>
                                                    <option value="총무팀">총무팀</option>
                                                    <option value="개발팀">개발팀</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr> 
                                        <td>*제목</td>
                                        <td colspan="3">
                                            <div className='apprTitle'> 
                                                <input
                                                    type="text"
                                                    name="apprTitle"
                                                    value={this.state.apprTitle}
                                                    onChange={(e) => { 
                                                        this.setState({
                                                            [e.target.name]: e.target.value
                                                        });
                                                    }}
                                                    placeholder="제목을 입력하세요"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    {/* 내용 */}
                                    <tr> 
                                        <td colspan="4">
                                            <div className='apprContent'> 
                                                <textarea
                                                    name="apprContent"
                                                    value={this.state.apprContent}
                                                    onChange={this.onChange}
                                                    placeholder="내용을 입력하세요"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* 잔여연차 */}
                            {/* <div className='apprUnusedLeave'> 
                                <TextField
                                    sx={{ width: '50%' }}
                                    id="standard-required"
                                    variant="standard"
                                    label="apprUnusedLeave"
                                    type="text"
                                    name="apprUnusedLeave"
                                    value={this.state.apprUnusedLeave}
                                    placeholder="잔여연차"
                                    onChange={this.onChange}
                                />
                            </div> */}
                        </div>
                        <div className={styles.file}>
                            <ul>
                            <li>파일첨부</li>
                            </ul>
                            <div className={styles['file-add']}>
                            <ul>
                                <li><ion-icon name="attach-outline"></ion-icon><span>파일선택</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='right'>  
                    <Route path="/appr/" exact={true} component={ApprMain}></Route>
                    <Route path="/appr/ApprDetail" exact={true} component={ApprDetail}></Route>
                </div>
            </div>
        </div>
        );
    }
}


export default WorkForm;