import React, { useState, useEffect } from 'react';
import Header from "./header";
import MainRouter from "./route/MainRouter";
import { BrowserRouter as Router } from 'react-router-dom';
import Login from './page_yoo/employee/Login';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // 페이지가 로드될 때마다 로그인 상태를 확인합니다.
  useEffect(() => {
    // 여기서는 간단하게 로컬 스토리지에 로그인 상태를 저장하고 있는지 확인합니다.
    const loggedIn = localStorage.getItem('isLoggedIn');
    if (loggedIn === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true); // 로그인 상태를 변경합니다.
    localStorage.setItem('isLoggedIn', 'true'); // 로그인 상태를 로컬 스토리지에 저장합니다.
  };

  const handleLogout = () => {
    setIsLoggedIn(false); // 로그인 상태를 false로 변경합니다.
    localStorage.removeItem('isLoggedIn'); // 로컬 스토리지에서 로그인 상태를 제거합니다.
  };

  return (
    <div className="App">
      {!isLoggedIn && <Login onLogin={handleLogin} />}
      {isLoggedIn &&
        <Router>
          <table style={{ marginLeft: '0px', borderSpacing: '0', padding: '0' }}>
            <thead>
              <tr>
                <td style={{padding: '0'}}><Header /></td>
                <td style={{ width: "100%", padding: '0' }}><MainRouter /></td>
              </tr>
            </thead>
            
          </table>
        </Router>
      }
    </div>
  );
}

export default App;
