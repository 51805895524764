import axios from 'axios';  // npm install -f axios@^1.3.5

// - ApiService는 스프링부트 서버(보통 'http://localhost:8080/'으로 열린다.)와 연결해주는 역할을 한다.
// - 리액트에서 무언가 요청을 하면 스프링부트에서 받아 Oracle에서 데이터를 가져오거나 연결해주는 역할을 한다.
// - 전형적인 MVC 패턴이라고 할 수 있다.
// - 리액트에서 이를 구현하기 위해서 Axios를 사용한다. 기존 HTML이나 JSP에서 사용한 AJAX 역할을 한다고 보면 된다.
// npm install -f axios@^1.3.5

const Calendar_API_BASE_URL = process.env.REACT_APP_BACK_HOST + "/cal";

// AWS
const getAuthToken = () => {
    return window.localStorage.getItem("auth_token");
}

let headers = {};

if(getAuthToken() !== null && getAuthToken !=="null") {
    headers = {"Authorization": `Bearer ${getAuthToken()}`};
    console.log('headers : ', headers);
}



class CalendarApiService {

    // list
    calListAll() { 
        console.log('calListAll() 호출')
        // Axios는 브라우저, Node.js를 위한 Promise API를 활용하는 HTTP 비동기 통신 라이브러리.
        return axios.get(Calendar_API_BASE_URL, {headers}); // 스프링부트와 통신, 주의 : "/" +
    }

    // 내 캘린더
    MyCalendar(employeeId) { 
        console.log('MyCalendar() 호출', employeeId);
        return axios.get(Calendar_API_BASE_URL + "/MyCalendar/" + employeeId, {headers});
    }

    // insert
    addCalendar(inputData) {
        console.log('addCalendar 호출!!', inputData);
        return axios.post(Calendar_API_BASE_URL + "/insert/" , inputData, {headers}); 
    }

    // select
    selectCalendar(calNo) {
        //console.log('selectCalendar 호출!!', calNo)
        return axios.get(Calendar_API_BASE_URL + "/select/" + calNo, {headers}); 
    }

    
    // update
    updateCalendar(inputData) {
        console.log('updateCalendar() 호출!!', inputData);

        // roomId가 null이면 null로 설정
        if (!inputData.roomId) {
            inputData.roomId = null;
        }

        return axios.put(Calendar_API_BASE_URL + "/update/" + inputData.calNo, inputData, {headers}); 
    }


    // delete
    deleteCalendar(calNo) {
        console.log('addCalendar 호출!!', calNo);
        return axios.delete(Calendar_API_BASE_URL + "/delete/" + calNo, {headers}); 
    }

    // DetailAdd
    detailAddCal(inputData) {
        console.log('detailAddCal 호출!!', inputData);
        return axios.post(Calendar_API_BASE_URL + "/detailInsert/", inputData, {headers}); 
      }

      
    // 회원정보 가져오기
    searchData(keyword) {
        if (!keyword.trim()) { // keyword가 빈 문자열인 경우
            // 특정 동작 수행
            // 예를 들어, 기본 검색어를 사용하거나 전체 목록을 가져옴
            return axios.get(Calendar_API_BASE_URL + "/search/all", {headers});
        }
        console.log('search 호출!!', keyword)
        return axios.get(Calendar_API_BASE_URL + "/search/" + keyword, {headers});
    }
    
    // 당일예약현황
    selectReservation() {
        console.log('selectReservation() 호출');
        return axios.get(Calendar_API_BASE_URL + "/select/reservation", {headers}); 
    }

}

const CalendarApiServiceServ = new CalendarApiService();
export default CalendarApiServiceServ;