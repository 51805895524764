import React, { Component } from 'react';
import AdminEventModal from './AdminEventModal';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import koLocale from '@fullcalendar/core/locales/ko';
import './Calendar.css';
import CalendarApiService from './CalendarApiService';
import { Link} from 'react-router-dom';

class AdminCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        isModalOpen: false,
        selectedDate: null,
        eventTitle: '',
        endDate: '',
        events: [], // 이벤트 목록을 저장할 상태 추가
        calNo: null  // 이벤트 ID를 저장할 상태 추가
        
        };
        this.calendarRef = React.createRef();
        this.handleClose = this.handleClose.bind(this); // 메서드를 bind 해주어야함
    }

    componentDidMount() {
        CalendarApiService.calListAll() 
        .then(response => { 
        // 성공적으로 데이터를 가져온 경우
        console.log('이벤트 목록:', response.data);

        const eventsForFullCalendar = response.data.map(event => ({
            title: event.calTitle,
            start: `${event.calStartDT}T${event.calStartTM}`, 
            end: `${event.calEndDT}T${event.calEndTM}`, 
            calNo: event.calNo,
            backgroundColor: 'rgb(0, 193, 223)',  // 이벤트의 배경색
            borderColor: 'rgb(0, 193, 223)' // 이벤트의 테두리 색상
            }));
        this.setState({ events: eventsForFullCalendar });
        
        const { selectedDate } = this.props;

        if (selectedDate && selectedDate.startStr && selectedDate.endStr) {
            const startDate = new Date(selectedDate.startStr).toISOString().slice(0, 10);
            const endDate = new Date(selectedDate.endStr).toISOString().slice(0, 10);
            this.setState({
                startDate: startDate,
                endDate: endDate,
            });
        }
        console.log(this.calendarRef.current)
        this.calendarRef.current.requestResize()
        // this.setState({ timeOptions: this.generateTimeOptions() });
        })
        .catch(error => {
        // 오류가 발생한 경우
        console.error('이벤트 가져오기 실패:', error);
        }); 

        const { calNo } = this.props.match.params; // URL에서 이벤트 ID를 가져옴
            this.setState({ calNo: calNo }); // 가져온 이벤트 ID를 상태에 저장
    }

    handleDateChange = (newDate) => {
        this.setState({ selectedDate: newDate });
        // 선택한 날짜에 대한 다른 작업을 수행
        console.log("날짜가 변경되었습니다:", newDate);
    };

    handleTimeChange = (newTime) => {
        this.setState({ selectedTime: newTime });
        // 선택한 시간에 대한 다른 작업을 수행
        console.log("시간이 변경되었습니다:", newTime);
    };
    

    handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        this.setState({ endDate: newEndDate });
        const newDate = { ...this.state.selectedDate, endStr: newEndDate };
        this.setState({ selectedDate: newDate });   // setState를 이용하여 selectedDate를 업데이트
    };

    handleDateSelect = (arg) => {
        console.log('arg 객체:', arg); // arg 객체 출력
        let selectedStartDate;
        let selectedEndDate;
        let selectedStartTime;
        let selectedEndTime;
    
        // 일간 혹은 주간 뷰는 아직 Date형식임으로 변환해줘야함
        if (arg.startStr.includes('T')) {
            // 일간 혹은 주간 뷰에서 선택된 경우
            selectedStartDate = arg.startStr.split('T')[0]; // 시간 정보는 제외하고 날짜 정보만 가져옴
    
            // 종료일을 선택한 날짜의 다음 날로 설정
            const endDate = new Date(arg.endStr); // 종료일을 Date 객체로 변환
            endDate.setDate(endDate.getDate() + 1); // 종료일에 하루를 더함
            selectedEndDate = endDate.toISOString().split('T')[0]; // 시간 정보는 제외하고 날짜 정보만 가져옴
    
            // 시작 시간과 종료 시간 설정
            selectedStartTime = arg.startStr.split('T')[1].slice(0, 5); // 시간 정보만 가져옴 (hh:mm)
            selectedEndTime = arg.endStr.split('T')[1].slice(0, 5); // 시간 정보만 가져옴 (hh:mm)
            console.log("selectedStartTime",selectedStartTime);
            console.log("selectedEndTime",selectedEndTime);

            this.setState({ 
                selectedStartDate: selectedStartDate, 
                selectedEndDate: selectedEndDate,
                selectedStartTime: selectedStartTime,
                selectedEndTime: selectedEndTime,
                isModalOpen: true
            });

        } else {
            // 월간 뷰에서 선택된 경우
            selectedStartDate = arg.startStr;
            selectedEndDate = arg.endStr;
            // 시작 시간과 종료 시간은 기본값으로 설정
            this.setState({ 
                selectedStartDate: selectedStartDate, 
                selectedEndDate: selectedEndDate,
                selectedStartTime: '09:00',
                selectedEndTime: '09:00',
                isModalOpen: true
            });
        }
    };
  
  
    handleClose = () => {
        // 모달 창이 닫힌 후 입력값 초기화
        this.setState({ 
            isModalOpen: false, 
            eventTitle: '', 
            selectedDate: null, 
            startDate: null, 
            endDate: null 
        });
    };

    handleAddCalendar = async (title) => {
        const { selectedDate, eventTitle } = this.state;
        try {
            const response = await CalendarApiService.addCalendar(title, selectedDate);
            console.log('API 요청 성공:', response.data);
            // 모달 창 닫기 및 입력값 초기화
            this.setState({ isModalOpen: false, eventTitle: '' });
        } catch (error) {
            console.error('API 요청 실패:', error);
        }
    };

    handleSaveSuccess = () => {
        // 이벤트를 추가한 후 캘린더를 다시 렌더링하는 작업 수행
        // 새로고침 없이 이벤트를 다시 불러오는 기능
        this.fetchEvents();
    };


    fetchEvents = () => {
        CalendarApiService.calListAll()
            .then(response => {
            console.log('이벤트 목록:', response.data);
            const eventsForFullCalendar = response.data.map(event => ({
                title: event.calTitle,
                start: `${event.calStartDT}T${event.calStartTM}`, 
                end: `${event.calEndDT}T${event.calEndTM}`, 
                calNo: event.calNo,
                backgroundColor: 'rgb(0, 193, 223)',  // 이벤트의 배경색
                borderColor: 'rgb(0, 193, 223)' // 이벤트의 테두리 색상
            }));
            this.setState({ events: eventsForFullCalendar });
            })
            .catch(error => {
            console.error('이벤트 가져오기 실패:', error);
        });
    };


    // 이벤트를 렌더링하는 함수
    renderEvents() {

        return this.state.events.map(event => (
            <div key={event.calNo}>
                <Link to={`/admin/AdminCalendarDetail/${event.calNo}`}>
                    {event.calTitle} {/* 일정의 제목 또는 다른 정보를 표시 */}
                </Link>
            </div>
        ));
    }

    handleEventClick = (info) => {
        // 클릭한 이벤트의 ID를 가져와서 이동할 URL을 생성
        const calNo = info.event.extendedProps.calNo;
        const url = `/admin/AdminCalendarDetail?calNo=${calNo}`;
    
        // 이동할 URL로 페이지를 이동
        this.props.history.push(url);
    }
    

    render() {
        const { isModalOpen, eventTitle } = this.state;
        return (
            <div className="full-calendar">
                <FullCalendar
                    ref={this.calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView={'dayGridMonth'}
                    locale={'ko'}
                    locales={[koLocale]}
                    headerToolbar={{
                    start: 'prev next today',
                    center: 'title',
                    end: 'timeGridDay,timeGridWeek,dayGridMonth',
                    }}
                    height={'93.9vh'}
                    width={'100%'}
                    titleFormat={{ year: 'numeric', month: '2-digit' }}
                    dayHeaderContent={({ date }) => {
                    const day = date.getDay();
                    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
                    return weekdays[day];
                    }}
                    dayCellContent={({ date }) => {
                    return date.getDate();
                    }}
                    buttonText={{
                    day: '일간',
                    week: '주간',
                    month: '월간',
                    }}
                    selectable={true}
                    select={this.handleDateSelect}      // 사용자가 캘린더에서 날짜를 선택할 때 이벤트 발생(사용자가 날짜를 선택하면 일정을 추가하는 팝업)
                    slotMinTime={'09:00:00'}
                    slotMaxTime={'22:30:00'}
                    events={this.state.events}          // 캘린더에 표시될 각 이벤트의 정보
                    eventClick={this.handleEventClick}  // 사용자가 캘린더에서 이벤트를 클릭할 때 실행할 동작
                />
                <AdminEventModal
                    show={isModalOpen}  // 모달 열림/닫힘 상태를 props로 전달
                    handleClose={this.handleClose}
                    handleAddCalendar ={this.handleAddCalendar}
                    calTitle={eventTitle}
                    setCalTitle={(title) => this.setState({ eventTitle: title })}
                    selectedDate={this.state.selectedDate}
                    onEndDateChange={this.handleEndDateChange}
                    setSelectedDate={(date) => this.setState({ selectedDate: date })}
                    setSelectedTime={this.handleTimeChange}
                    onDateChange={this.handleDateChange}
                    onSaveSuccess={this.handleSaveSuccess} // 이벤트 저장 성공 시 호출될 핸들러 전달
                    selectedStartDate={this.state.selectedStartDate} 
                    selectedEndDate={this.state.selectedEndDate}
                    selectedStartTime={this.state.selectedStartTime}
                    selectedEndTime={this.state.selectedEndTime}
                />
            </div>
        );
    }
}

export default AdminCalendar;
