import React, { useState } from 'react';
import './DepartModal.css';
import ApiService from './ApiService';

function AddForm({ onClose }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [employeeName, setEmployeeName] = useState('');
    const [gender, setGender] = useState('');
    const [authorityId, setAuthorityId] = useState('');
    const [positionId, setPositionId] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [employDate, setEmployDate] = useState('');
    const [employeeTel, setEmployeeTel] = useState('');
    const [extensionTel, setExtensionTel] = useState('');
    const [employeeBirth, setEmployeeBirth] = useState('');
    const [employeeAddress, setEmployeeAddress] = useState('');

    const handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const employeeData = {
            selectedImage: selectedImage,
            employeeName: employeeName,
            gender: gender,
            authorityId: authorityId,
            departmentId: departmentId,
            positionId: positionId,
            employDate: employDate,
            employeeTel: employeeTel,
            extensionTel: extensionTel,
            employeeBirth: employeeBirth,
            employeeAddress: employeeAddress
        };

        ApiService.addEmployee(employeeData)
            .then(response => {
                console.log("사원 추가 응답 데이터:", response.data);
                onClose(); // 모달 닫기
            })
            .catch(error => {
                console.error('사원 추가 에러:', error);
                alert('사원을 추가하는데 문제가 발생했습니다.');
            });

    };

    // 오늘 날짜를 반환하는 함수
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // 한 자리 숫자는 앞에 0을 붙여 두 자리로 만듭니다.
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        // yyyy-mm-dd 형식으로 반환합니다.
        return `${year}-${month}-${day}`;
    };

    return (
        <div id="AddContent" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <h2 id="modal-title" style={{ padding: '15px 15px 8px 15px' }}>직원 추가</h2>
            <form onSubmit={handleSubmit}>
                <div className="inputContainer">
                    <div className="inputRow">
                        <p>사진</p>
                        <input type="file" onChange={handleImageChange} />
                        {selectedImage && (
                            <img src={selectedImage} alt="Selected" style={{ height: '150px', width: '150px' }} />
                        )}
                    </div>

                    <div className="inputRow">
                        <p>이름</p>
                        <input 
                            type="text" 
                            id="employeeName" 
                            label="employeeName" 
                            placeholder="" 
                            value={employeeName}
                            onChange={(e) => setEmployeeName(e.target.value)}
                            required
                        />
                    </div>
                    
                    <div className='inputRow'>
                        <p>성별</p>
                        <input 
                            type="text" 
                            id="gender" 
                            label="gender" 
                            placeholder="" 
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                        />
                    </div>

                    <div className="inputRow">
                        <p>권한</p>
                        <input 
                            type="text" 
                            id="authorityId" 
                            label="authorityId" 
                            placeholder="0/1"
                            value={authorityId}
                            onChange={(e) => setAuthorityId(e.target.value)}
                            required
                        />
                    </div>

                    <div className="inputRow">
                        <p>직위</p>
                        <input 
                            type="text" 
                            id="positionId" 
                            label="positionId" 
                            placeholder="W6O/45E"
                            value={positionId}
                            onChange={(e) => setPositionId(e.target.value)}
                            required
                        />
                    </div>

                    <div className="inputRow">
                        <p>부서</p>
                        <input 
                            type="text" 
                            id="departmentId" 
                            label="departmentId" 
                            placeholder="SGX2K"
                            value={departmentId}
                            onChange={(e) => setDepartmentId(e.target.value)}
                            required
                        />
                    </div>

                    <div className="inputRow">
                        <p>입사일</p>
                        <input 
                            type="date" 
                            id="employDate" 
                            label="employDate" 
                            defaultValue={getCurrentDate()}
                            onChange={(e) => setEmployDate(e.target.value)}
                            required
                        />
                    </div>

                    <div className="inputRow">
                        <p>Cell.</p>
                        <input 
                            type="text" 
                            id="employeeTel" 
                            label="employeeTel" 
                            placeholder=""
                            value={employeeTel}
                            onChange={(e) => setEmployeeTel(e.target.value)}
                            required
                        />
                    </div>

                    <div className="inputRow">
                        <p>Dir.</p>
                        <input 
                            type="text" 
                            id="extensionTel" 
                            label="extensionTel" 
                            placeholder=""
                            value={extensionTel}
                            onChange={(e) => setExtensionTel(e.target.value)}
                        />
                    </div>

                    <div className="inputRow">
                        <p>거주지</p>
                        <input 
                            type="text" 
                            id="employeeAddress" 
                            label="employeeAddress" 
                            placeholder=""
                            value={employeeAddress}
                            onChange={(e) => setEmployeeAddress(e.target.value)}
                        />
                    </div>

                    <div className="inputRow">
                        <p>생일</p>
                        <input 
                            type="date" 
                            id="employeeBirth" 
                            label="employeeBirth" 
                            value={employeeBirth || '1980-01-01'}
                            onChange={(e) => setEmployeeBirth(e.target.value)}
                        />
                    </div>
                </div>

                <div id="btnBundle">
                    <button id='AddBtn' type="submit">추가</button>
                    <button id='AddBtn' onClick={onClose}>취소</button>
                </div>
            </form>
        </div>
    );
}

export default AddForm;
