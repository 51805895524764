import React, { Component } from "react";
import { Button,Typography, TextField } from "@mui/material";
import BoardService from "./BoardService";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import { withRouter } from 'react-router-dom';  // 목록버튼 때문에 추가
import  List from '@mui/material/List';
import Comment from "./Comment";
import htmlparser from 'html-react-parser';
import { Link } from 'react-router-dom';


class Boarddetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employee_id: localStorage.getItem('LoginID'),
            fb_no: '',
            fb_title: '',
            fb_content: '',
            fb_readcnt: '',
            fb_comment_count: '',
            fb_date: '',
            cm_content: '',
            comments: [] 
        }
    }

    componentDidMount() {
        this.loadBoard();   // 상세페이지
        this.loadComments(); // 댓글 목록
    }

    // 댓글 목록 가져오기
    loadComments = () => {
        const fb_no  = this.props.match.params.fb_no;
        BoardService.commentList(fb_no)
            .then(response => {
                console.log(response.data);
                this.setState({ comments: response.data });
            })
            .catch(error => {
                console.error('Error fetching comments:', error);
            });
    }
    
    // 새로운 댓글 작성 함수
    submitComment = () => {
        const { fb_no, cm_content } = this.state;
        const loggedInUserID = localStorage.getItem('LoginID');

        // 작성한 댓글 내용이 비어있는지 확인
        if (cm_content.trim() === '') {
            alert('댓글을 입력해주세요.');
            return;
        }

        // 댓글 데이터 생성
        const inputData = {
            fb_no: fb_no,
            employee_id: loggedInUserID,
            cm_content: cm_content,
            

        };

        // 댓글 작성 요청
        BoardService.insertComment(fb_no, inputData)
        .then(response => {
            console.log('댓글 작성 성공:', response.data);
            this.setState({ cm_content: '' }); // 입력값 초기화
            this.loadComments(); // 댓글 목록 다시 불러오기
        })
        .catch(error => {
            console.error('댓글 작성 오류:', error);
        });
    }

        

        
    // increaseViews 함수 내부에 중복 조회를 방지하는 로직 추가
    increaseViews = async () => {
    const { fb_no } = this.state;
    console.log('increaseViews', fb_no);

    // 게시물 ID를 로컬 스토리지에서 가져옵니다.
    const visitedPosts = JSON.parse(localStorage.getItem('visitedPosts')) || [];

    // 현재 게시물이 이미 조회된 게시물인지 확인합니다.
    const isVisited = visitedPosts.includes(fb_no);

    if (!isVisited) {
        try {
            // 조회수 증가 요청을 보냅니다.
            const res = await BoardService.increaseViews(fb_no);
            console.log('increaseViews 성공 : ', res.data);

            // 조회된 게시물의 ID를 로컬 스토리지에 추가합니다.
            localStorage.setItem('visitedPosts', JSON.stringify([...visitedPosts, fb_no]));
        } catch (err) {
            console.log('increaseViews() Error !!', err);
        }
    } else {
        console.log('이미 조회된 게시물입니다.');
    }
}

    // 게시물 수정
    editBoard = (ID) => {
        window.localStorage.setItem("fb_no", ID);    
        this.props.history.push("/board/edit/:fb_no");
    }

    // 게시물 삭제
    deleteBoard = () => {
        if (window.confirm("정말로 삭제 하시겠습니까?")) {
        const { fb_no } = this.state;
        BoardService.deleteBoard(fb_no)
            .then(res =>{
                console.log('delete 성공 : ', res.data);
                alert('게시글이 삭제되었습니다.'); // 성공 시 알림
                this.props.history.push("/board");
            })
            .catch(err =>{
                console.log('delete() Error !! : ' , err);
                alert('게시글 삭제가 실패했습니다.'); // 실패 시 알림
            });
    }
}

// 상세페이지
loadBoard = () => {
    BoardService.BoardByID(this.props.match.params.fb_no)
        .then(res => {
            let list = res.data;
            this.setState({
                fb_no: list.fb_no,
                employee_id: list.employee_id,
                fb_title: list.fb_title,
                fb_content: list.fb_content,
                fb_readcnt: list.fb_readcnt,
                fb_comment_count: list.fb_comment_count,
                fb_date: list.fb_date
            });

            
        })
        .catch(err => {
            console.log('loadBoard() Error !!', err);
        });
}

   
     // 화면에서 수정한 값으로 state 변경
     onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        });
    }


    render() {
        const { employee_id , cm_content  } = this.state;
        const loggedInUserID = localStorage.getItem('LoginID');
        const isAuthor = loggedInUserID === employee_id;

        return (
            // 게시글 제목
            <Container>
                <Paper elevation={3} sx={{ marginTop: '20px' }}>
                    <Box padding={2}>
                        <Box sx={{textAlign:"center"}}>
                            <Typography variant="h6">
                            {this.state.fb_title}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>

                <Paper elevation={3}>
                        <Box sx={{marginTop:"10px"}}>
                            <Toolbar sx={{ justifyContent: 'flex-end'}}>
                                <Typography variant="body2">
                                {this.state.employee_id}
                                </Typography>
                                
                                &nbsp;&nbsp;

                                <Typography variant="body2">
                                    {this.state.fb_date}  
                                </Typography>

                                &nbsp;&nbsp;
                                
                                <Typography variant="body2">
                                조회:{this.state.fb_readcnt}  
                                </Typography>

                            </Toolbar>

                            <Divider/>
                                <Box padding={2} sx={{minHeight:"500px"}}>
                                 {/* 콘텐츠 부분 <P>내용<P> <P> 부분 안보이게 해줌 */}
                                      {htmlparser(this.state.fb_content)}
                                </Box>
                        <Divider/>
                        
                    </Box>
                </Paper>

            <Paper elevation={3}>
                <Box sx={{marginTop:"10px"}}>
                    <Toolbar sx={{ justifyContent: 'flex-end'}}>
                        
                        {/* 로그인한 아이디와 작성자가 같으면 수정/삭제 버튼이보임 */}
                        {isAuthor && (
                            <>
                                <Button>
                                    <Link to={`/board/edit/${this.state.fb_no}`}>수정</Link>
                                </Button>
                                <Button onClick={this.deleteBoard} color="secondary">삭제</Button>
                            </>
                        )}
                            <Button component={Link} to="/board">목록</Button>
                    </Toolbar>
                </Box>
            </Paper>
               
                <Divider/>

                  {/* 댓글 작성 폼 */}
                  <Box sx={{  position: 'relative', marginTop: '10px'}}>
                    <TextField
                        id="cm_content"
                        name="cm_content"
                        label="댓글을 남겨보세요"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={cm_content}
                        onChange={this.onChange}
                    />
                    <Button onClick={this.submitComment} color="primary" variant="contained" 
                    sx={{ position: 'absolute', right: 0, bottom: 0 }} >등록</Button>
                </Box>

                
                {/* 댓글 목록 */}
                <Box sx={{ marginTop: "10px" }}>
            <List>
                {this.state.comments.map(comment => (
                   <Comment
                   key={comment.cm_no}
                   isComment={comment.employee_id}
                   user={comment.employee_id === loggedInUserID} // 게시글 작성자와 같은지 확인
                   isEdit={false}
                   primary={comment.cm_content} // 댓글 내용
                   secondary={`${comment.cm_date} - ${comment.employee_id}`} // 댓글 작성자 + 작성일 시간 
                   commentNumber={comment.cm_no} // 댓글 번호 전달
                   postNumber={comment.fb_no} // 게시글 번호 전달
                   commentUserID={comment.employee_id} // 댓글을 작성한 사용자의 ID 전달
                   reloadComments={this.loadComments}
               />
                ))}
            </List>
                </Box>
            </Container>

        );
    }
}

export default withRouter(Boarddetail);
