import axios from 'axios';  // npm install -f axios@^1.3.5
import { request2 } from '../../helpers/axios_helper';

const SAMPLE_API_BASE_URL = process.env.REACT_APP_BACK_HOST + "/admin";
// const SAMPLE_API_BASE_URL = "http://localhost:8082/admin";

const getAuthToken = () => {
    return window.localStorage.getItem("auth_token");
}

let headers = {};


if(getAuthToken() !== null && getAuthToken !=="null") {
    headers = {"Authorization": `Bearer ${getAuthToken()}`};
    console.log('headers : ', headers);
}

class ApiService {

    // 직원 목록
    fetchEmployees() {
        console.log('fetchEmployees() 호출!!')
        return axios.get(SAMPLE_API_BASE_URL + "/emplList" , {headers});
    }

    // 부서 목록
    fetchDepartment() {
        console.log('fetchDepartment() 호출!!')
        return axios.get(SAMPLE_API_BASE_URL + "/departList" , {headers});
    }

    // 부서 추가
    addDepartment(departmentName) {
        console.log('addDepartment() 호출!!')
        return axios.post(SAMPLE_API_BASE_URL + "/addDepart", departmentName , {headers});
    }

    // 부서 상태변경
    updateDepartmentStatus = (departmentId, departmentActive) => {
        return axios.put(SAMPLE_API_BASE_URL + "/departlist/" + departmentId , departmentActive , {headers});
    };

    // 부서 상태 가져오기
    getDepartmentStatus = (departmentId) => {
        return axios.get(SAMPLE_API_BASE_URL + "/departlist/" + departmentId + "/status" , {headers});
    };

    // 직원 정보 확인
    fetchEmployeeByID = (employeeId) => {
        console.log('fetchEmployeeByID() 호출!!', employeeId);
        return axios.get(SAMPLE_API_BASE_URL + "/editempl/" + employeeId , {headers});
    }

    // 직원 정보 수정
    updateEmployee = (formData) => {
        console.log('updateEmployee() 호출!!', formData);
        return request2("PUT",SAMPLE_API_BASE_URL + "/editempl" , formData , {headers});
    }

    // 부서 목록 조회 (활성화된 부서만)
    fetchActiveDepart() {
        console.log('fetchActiveDepart() 호출!!');
        return axios.get(SAMPLE_API_BASE_URL + "/activeDepart" , {headers});
    }

    // 직원 추가
    addEmployee(formData) {
        console.log('addEmployee() 호출!!')
        return request2("POST",SAMPLE_API_BASE_URL + "/addempl", formData);
    }
    
    // 직원 퇴사 여부 업데이트
    updateEmployeeResignationDate = (employeeId, resignationDate) => {
        console.log('updateEmployeeResignationDate() 호출!!', employeeId, resignationDate);
        return axios.put(SAMPLE_API_BASE_URL + "/editempl/" + employeeId + "/resignationDate", { resignationDate } , {headers});
    }
    
}
const apiService = new ApiService();
export default apiService;