import React, { useState } from 'react';
import {useInterval} from 'react-use';
import { IonIcon } from '@ionic/react';
import { timeOutline  } from 'ionicons/icons';

export default function CurrentTimer() {
    const date = new Date();

    const [timer, setTimer] = useState(String(date.getHours()).padStart(2, "0") + ":" + String(date.getMinutes()).padStart(2, "0") + ":" + String(date.getSeconds()).padStart(2, "0"));
  
    const now = () => {
      const date = new Date();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      setTimer(`${hours}:${minutes}:${seconds}`)

      return;
    }
  
    useInterval(
      () => {
        now();
      }, 1000
    );
  
    const timerStyle = {
      display: 'flex', // 부모 요소를 Flexbox로 설정하여 자식 요소를 가로 중앙에 배치
      justifyContent: 'center',
      alignItems: 'center', // 수직 정렬을 중앙에 배치
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#3b3b3b',
    };
  
    const iconStyle = {
      marginRight: '4px', // 아이콘과 텍스트 사이의 간격 조정
      fontSize: '30px',
    };
  
    return (
      <>
        <div className="timer" style={timerStyle}>
          <IonIcon icon={timeOutline} style={iconStyle} />
          {timer}
        </div>
      </>
    )
  }