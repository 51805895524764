import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Weather.css';
import { IonIcon } from '@ionic/react';
import { locationOutline, sunnyOutline, thermometerOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 메인페이지의 실시간 날씨페이지 */

function Weather() { // 1. 대문자로 시작하는 컴포넌트 이름 사용
    const API_KEY = 'be6242446f4d7041023e2d9d6c657472';

    const [weather, setWeather] = useState({
        description: '',
        name: '',
        temp: 0,
        icon: ''
    });

    const weatherDescKo = {
        200: '천둥번개와 비',
        201: '천둥번개와 비',
        202: '천둥번개와 비',
        210: '약한 천둥번개',
        211: '천둥번개',
        212: '강한 천둥번개',
        221: '불규칙한 천둥번개',
        230: '약한 이슬비를 동반한 천둥번개',
        231: '이슬비를 동반한 천둥번개',
        232: '강한 이슬비를 동반한 천둥번개',
        300: '가벼운 이슬비',
        301: '이슬비',
        302: '강한 이슬비',
        310: '가벼운 이슬비와 비',
        311: '이슬비와 비',
        312: '강한 이슬비와 비',
        313: '소나기와 이슬비',
        314: '강한 소나기와 이슬비',
        321: '소나기',
        500: '가벼운 비',
        501: '비',
        502: '강한 비',
        503: '매우 강한 비',
        504: '극심한 비',
        511: '우박',
        520: '가벼운 소나기 비',
        521: '소나기 비',
        522: '강한 소나기 비',
        531: '불규칙한 소나기 비',
        600: '가벼운 눈',
        601: '눈',
        602: '강한 눈',
        611: '진눈깨비',
        612: '소나기 진눈깨비',
        615: '가벼운 비와 눈',
        616: '비와 눈',
        620: '가벼운 소나기 눈',
        621: '소나기 눈',
        622: '강한 소나기 눈',
        701: '박무',
        711: '연기',
        721: '연무',
        731: '모래 먼지',
        741: '안개',
        751: '모래',
        761: '먼지',
        762: '화산재',
        771: '돌풍',
        781: '토네이도',
        800: '맑음',
        801: '구름 조금',
        802: '구름',
        803: '구름 많음',
        804: '흐림',
        900: '토네이도',
        901: '태풍',
        902: '허리케인',
        903: '한랭',
        904: '고온',
        905: '바람이 강함',
        906: '우박',
        951: '바람이 거의 없음',
        952: '약한 바람',
        953: '산들바람',
        954: '중간 세기 바람',
        955: '신선한 바람',
        956: '강한 바람',
        957: '돌풍',
        958: '강한 돌풍',
        959: '심각한 돌풍',
        960: '폭풍',
        961: '강한 폭풍',
        962: '허리케인'
    };

    const cityName = weather.name;
 

    useEffect(() => {
        const lat = "37.5701619";
        const lon = "126.942005";
        getWeather(lat, lon);

        // 로컬에서는 자동으로 위도경도가 나오는데 aws에서는 안됨
        // navigator.geolocation.getCurrentPosition((position) => {
        //     //const lat = position.coords.latitude;

        //     //const lon = position.coords.longitude;
            
        //     console.log(lat+"/"+lon);
        //     getWeather(lat, lon);
        // });
    }, []);

    const getWeather = async (lat, lon) => {
        try {
            const res = await axios.get(
                `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${API_KEY}&units=metric`
            );

            const weatherId = res.data.weather[0].id;
            const weatherKo = weatherDescKo[weatherId];
            const weatherIcon = res.data.weather[0].icon;
            const weatherIconAdrs = `http://openweathermap.org/img/wn/${weatherIcon}@2x.png`;
            const temp = Math.round(res.data.main.temp);

            setWeather({
                description: weatherKo,
                name: res.data.name,
                temp: temp,
                icon: weatherIconAdrs,
            });
        } catch (err) {
            console.error('Error fetching weather data:', err);
        }
    };

    return (
        <div className="weather-container">
            <div className="weatherTitle">오늘의 날씨</div>
            <div className="weather-info">
                <div><IonIcon icon={locationOutline} />  {weather.name}</div>
                <div><IonIcon icon={sunnyOutline} />  {weather.description}</div>
                <div><IonIcon icon={thermometerOutline} />  {weather.temp} °C</div>
                <img src={weather.icon} alt="weather-icon" />
            </div>
        </div>
    );
}

export default Weather; // 2. 함수 컴포넌트로 변환 및 export
