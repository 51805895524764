import React, { Component, useState } from 'react';
import ApiService from "./AttentionApiService"
import {Table, TableHead, TableRow, TableCell, TableBody, Button, Typography} from '@mui/material';
import {Create, Delete} from '@mui/icons-material'; // npm install -f @mui/icons-material@^5.11.16
import moment from 'moment';


function transHoursAndMinutes(seconds) {
	if (seconds < 61) {
    	return '00:00:' + addZero(seconds)
	}
	// sec
	var hours = Math.floor(seconds/3600)
	var mins = Math.floor((seconds - hours*3600)/60)
	var secs = seconds - hours*3600 - mins*60
	return addZero(hours) + ':' + addZero(mins) + ':' + addZero(secs)
    
	function addZero(num) {
		return ((num < 10) ? '0' : '') + num
	}
}

class AttentionToday extends Component {

    constructor(props) {
        super(props);

        this.state = {
            employee_id: window.localStorage.getItem("LoginID"),
            att_stime: moment(new Date()).startOf('days'),
            att_etime: moment(new Date()).endOf('days'),
            todayAttend: []
        }

        
    }

    
    

    // 라이프 사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    // render 후 dom이 반영되었을 때(로딩이 끝났을 때) 
    // componentDidMount 상태로 진입 이 상태일 때 아래 함수를 실행
    // 개발단계에선 이 부분이 두번 호출됨
    // 두번 안보고 싶으면 index.js 에서 StrictMode 컴포넌트를 없애면 된다.

    componentDidMount() {
        this.loadAttendList(this.state);    // 1.

    }

    loadAttendList = (data) => {
        ApiService.fetchAttend(data)  // 2.
        .then(res => {             // 4.
            
            //console.log('내 근태 요청');
            console.log(res.data);
            this.setState({
                todayAttend: res.data
            });
        })
        .catch(err => {
            console.log('reloadAttendList() Error!', err);
        })
        
    }

    


    render() {
        let att_stime;
        let att_etime;
        let att_resulttime;
        return (
            <div>
                <br/><br/>
                <Typography variant="h4" style={style}>내 근태 현황</Typography><br/>
                <br/>
                
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell> 아이디 </TableCell>
                            <TableCell> 출근시간 </TableCell>
                            <TableCell> 퇴근시간 </TableCell>
                            <TableCell> 근무시간 </TableCell>
                            <TableCell> 결과 </TableCell>
                            <TableCell> 등록일 </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {/* 
                            map은 Script나 React의 반복문 
                            map(item => ... )   또는
                            map((item, idx) => ...)
                            
                        */}
                        {this.state.todayAttend.map(dto => 
                        
                            <TableRow key={dto.att_num}>
                                <TableCell width="20%">{dto.employee_id} </TableCell>
                                <TableCell width="15%">{dto.att_stime != null && moment(dto.att_stime).format('YYYY-MM-DD hh:mm')} </TableCell>
                                <TableCell width="15%">{dto.att_etime != null && moment(dto.att_etime).format('YYYY-MM-DD hh:mm')} </TableCell>                                
                                <TableCell width="20%">{dto.att_etime != null && transHoursAndMinutes(moment(dto.att_etime).diff(moment(dto.att_stime), "s"))} </TableCell>
                                <TableCell width="10%">{dto.att_state} </TableCell>
                                <TableCell width="10%">{dto.att_rdate} </TableCell>
                            </TableRow>
                            )}
                    </TableBody>
                </Table>

            </div>

        );

    }

}

const style = {
    display: 'flex',
    justifyContent: 'center'
}

export default AttentionToday;