import React, { createContext, useReducer, useContext, useRef, useEffect } from 'react';

const TodoStateContext = createContext(null);
const TodoDispatchContext = createContext(null);
const TodoNextIdContext = createContext(null);

function todoReducer(state, action) {
  switch (action.type) {
    case 'CREATE':
        const todoExists = state.some(todo => todo.id === action.todo.id);
        if (!todoExists) {
          const newState = state.concat(action.todo);
          document.cookie = `todos=${JSON.stringify(newState)}`;
          return newState;
        }
        return state;
      
    case 'TOGGLE':
        const updatedState = state.map(todo =>
            todo.id === action.id ? { ...todo, done: !todo.done } : todo
        );
        document.cookie = `todos=${JSON.stringify(updatedState)}`;
        return updatedState;

    case 'REMOVE':
        const updatedTodos = state.filter(todo => todo.id !== action.id);
        document.cookie = `todos=${JSON.stringify(updatedTodos)}`;
        return updatedTodos;
  }
}

export function TodoProvider({ children }) {
    const cookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('todos='));
    const savedTodos = cookie ? JSON.parse(cookie.split('=')[1]) : [];
    const initialTodos = savedTodos || [];

    const [state, dispatch] = useReducer(todoReducer, initialTodos);
    const nextId = useRef(initialTodos.length + 1);

    useEffect(() => {
    // 컴포넌트가 마운트될 때 한 번만 로컬 스토리지에서 데이터를 불러옴
    const savedTodos = JSON.parse(localStorage.getItem('todos'));
    dispatch({ type: 'CREATE', todo: savedTodos || [] });
    }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때만 실행

    return (
    <TodoStateContext.Provider value={state}>
        <TodoDispatchContext.Provider value={dispatch}>
        <TodoNextIdContext.Provider value={nextId}>
            {children}
        </TodoNextIdContext.Provider>
        </TodoDispatchContext.Provider>
    </TodoStateContext.Provider>
    );
}

export function useTodoState() {
  return useContext(TodoStateContext);
}

export function useTodoDispatch() {
  return useContext(TodoDispatchContext);
}

export function useTodoNextId() {
  return useContext(TodoNextIdContext);
}
