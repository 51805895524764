import React, { Component} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Toolbar, Box, Paper, Button, TextField, Container } from '@mui/material';
import { withRouter } from 'react-router-dom';
import BoardService from './BoardService';
import './WriteBoard.css';

class WriteBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee_id: localStorage.getItem('LoginID'),
            fb_title: '',
            fb_content: ''
        };
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        });
    }

    insertBoard = (e) => {
        e.preventDefault();

        if (this.state.fb_title.trim() === '') {
            alert('제목을 입력해주세요.');
            return;
        }

        if (this.state.fb_content.trim() === '') {
            alert('내용을 입력해주세요.');
            return;
        }
        
        const isConfirmed = window.confirm('게시글을 등록하시겠습니까?');

        if (isConfirmed) {
            const inputData = {
                employee_id: this.state.employee_id,
                fb_title: this.state.fb_title,
                fb_content: this.state.fb_content
            };

            BoardService.insertBoard(inputData)
            .then(res => {
                console.log('컨트롤러 전달 성공: ', res.data);
                this.props.history.push('/board');
                alert('게시글이 등록되었습니다.');
            })
            .catch(err => {
                console.log('insertBoard() 에러!! : ', err);
                alert('게시글 등록에 실패했습니다.');
            });
        }
    }

    handleGoBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <Container>
                <Paper elevation={3}>
                    <Box padding={2} sx={{ marginTop: '20px' }}>
                        <Toolbar>
                            <TextField
                                sx={{width:"1300px"}}
                                id="outlined-basic"
                                variant="outlined"
                                label="게시글 제목"
                                type="text"
                                name="fb_title"
                                value={this.state.fb_title}
                                placeholder="제목입력"
                                onChange={this.onChange}
                            />
                        </Toolbar>
                    </Box>
                </Paper>
                
                <Paper sx={{marginTop:"10px"}} elevation={3}>
                    <Box padding={3}>
                        <div id='Boardeditor'>
                        <CKEditor
                                editor={ClassicEditor}
                                data={this.state.fb_content}
                                config={{
                                    placeholder: '내용을 입력해주세요.',
                                    ckfinder: {
                                        uploadUrl: 'http://localhost:5005/api/upload'
                                    }
                                }}
                                onReady={editor => {
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log({ event, editor, data });
                                    this.setState({ fb_content: data });
                                }}
                                onBlur={(event, editor) => {
                                    //console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    //console.log('Focus.', editor);
                                }}
                            />
                        </div>    
                    </Box>
                </Paper>
                
                <Paper sx={{marginTop:"10px", marginBottom:"20px"}} elevation={3}>
                    <Toolbar sx={{ justifyContent: 'flex-end'}}>
                        <Button onClick={this.insertBoard}> 등록 </Button>
                        <Button onClick={this.handleGoBack}> 목록 </Button>
                    </Toolbar>
                </Paper>
            </Container>
        );
    }
}

export default withRouter(WriteBoard);
