import React, { Component } from 'react';
import './CalendarAdd.css'; // CSS 파일 임포트
import CalendarApiService from './CalendarApiService.js';
import { withRouter, Link } from 'react-router-dom';  
import OrganizationChart from './AttListPop.js';
import { IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import KakaoEventModal from './KakaoEventModal.js';

class AdminCalendarAdd extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        const day = new Date().toISOString().slice(0, 10);
        this.state = {
            eventTitle: '',
            startDate: props.location.state ? props.location.state.startDate : day,
            endDate: props.location.state ? props.location.state.endDate : day,
            startTime: props.location.state ? props.location.state.startTime : '09:00',
            endTime: props.location.state ? props.location.state.endTime : '09:00',
            timeOptions: [],
            allDay: false,
            attList: '',
            location: '',
            content: '',
            selectedDate: null, // 초기 상태 null로 설정
            employeeId: localStorage.getItem('LoginID'),
            selectedRoom: '', // 선택된 회의실
            rooms: [
                { id: 1, name: 'Spring Room', reserved: false },
                { id: 2, name: 'Summer Room', reserved: false },
                { id: 3, name: 'Autumn Room', reserved: false },
                { id: 4, name: 'Winter Room', reserved: false }
            ],
            roomId: '',
            attArr: [],
            isPopupActive: false,
            isMapModalOpen: false
        };
    }
    componentDidMount() {
    // timeOptions 초기화
    this.setState({ timeOptions: this.generateTimeOptions() });
    }

   /*  componentDidUpdate(prevProps) {
        if (this.props.selectedDate !== prevProps.selectedDate) {
        this.setState({
            startDate: this.props.selectedDate ? this.props.selectedDate.startStr : '',
            endDate: this.props.selectedDate ? this.props.selectedDate.endStr : '',
        }, () => {
            console.log('startDate and endDate updated:', this.state.startDate, this.state.endDate);
          });
        }
      } */

    generateTimeOptions() {
        let times = [];
        for (let i = 9; i <= 21; i++) {
        for (let j = 0; j < 60; j += 30) {
            let hour = i < 10 ? `0${i}` : `${i}`;
            let minute = j === 0 ? '00' : '30';
            times.push(`${hour}:${minute}`);
        }
        }
        times.push('22:00');
        return times;
    }

    onChangeHandler = (e) => {
        const { name, value, type, checked } = e.target;

        // 상태 업데이트 후에 실행되는 콜백 함수
        const callback = () => {
            // 선택된 날짜 업데이트
            if (name === 'startDate' || name === 'endDate') {
                const newDate = name === 'startDate' ? 'startStr' : 'endStr';
                // setSelectedDate 함수가 있는지 확인한 후 호출
                if (this.props.setSelectedDate) {
                        this.props.setSelectedDate({ ...this.props.selectedDate, [newDate]: value });
                        console.log('Selected date updated:', newDate, value);
                }
            }
        };

        if (name === 'endDate' && this.state.startDate > value) {
            window.alert("종료일자는 시작일자 이후여야 합니다.");
            return;
        }
        else if (name === 'endTime' && this.state.startTime > value) {
            window.alert("종료시간은 시작시간 이후여야 합니다.");
            return;
        }
        else if (name === 'allDay') {
            const { allDay } = this.state;
            // startTime과 endTime을 변경하는 로직은 allDay 상태를 업데이트한 후에 실행
            const newStartTime = !allDay ? '09:00' : '09:00';
            const newEndTime = !allDay ? '22:00' : '09:00';
            // 상태를 업데이트
            this.setState({
                allDay: !allDay,
                startTime: newStartTime,
                endTime: newEndTime
            }, () => {
                // allDay 상태에 따라 calAllday 컬럼을 업데이트
                const calAlldayValue = allDay ? 'N' : 'Y'; // allDay가 true일 때 'N', false일 때 'Y'
                if (this.props.setSelectedDate) {
                    this.props.setSelectedDate({ ...this.props.selectedDate, calAllday: calAlldayValue });
                    console.log('calAllday updated:', calAlldayValue);
                }
            });
            return;
        }

        else {
            // 상태 업데이트 후에 실행되는 콜백 함수를 setState의 두 번째 매개변수로 전달
            this.setState({ [name]: type === 'checkbox' ? checked : value }, callback);
        }
    };



    handleSubmit = (e) => {
        e.preventDefault();

        if(this.state.eventTitle === ""){
            window.alert("일정명을 입력해주세요.");
            return;
        } 
        else if(this.state.startDate > this.state.endDate) {
            window.alert("시작일자와 종료일자를 확인하세요.");
            return;
        }
        else if (this.state.endTime < this.state.startTime) {
            window.alert("시작시간은 종료시간 이전이여야 합니다.");
            return;
        } 
        else if (this.state.startDate === this.state.endDate && this.state.startTime === this.state.endTime) {
            window.alert("시작시간과 종료시간을 다시 설정해주세요."); 
            return;
        }
        else if (this.state.selectedRoom !== '' && this.state.startDate !== this.state.endDate) {
            window.alert("회의실은 하루 안에서 시간으로 예약해야 합니다.");
            return;
        }
        else {
            let inputData = {
            calTitle: this.state.eventTitle,
            calStartDT: this.state.startDate,
            calStartTM: this.state.startTime,
            calEndDT: this.state.endDate,
            calEndTM: this.state.endTime,
            calAllday: this.state.allDay ? 'Y' : 'N', // allDay 상태에 따라 'Y' 또는 'N'으로 설정
            calLocation: this.state.location,
            calContent: this.state.content,
            calNo: this.state.calNo,
            roomId: this.state.roomId,
            employeeId: this.state.employeeId,
            attArr: this.state.attArr,
            };


            CalendarApiService.detailAddCal(inputData)
            .then(response => {
                console.log('일정 저장 완료:', response.data);
            
                // insertCnt 값 받아오기
                const insertCnt = response.data;
                console.log('insertCnt : ', insertCnt);
                if (insertCnt === 3) {
                    // insertCnt가 3인 경우에는 중복
                    window.alert("선택된 회의실은 이미 예약된 회의실입니다.");
                    return;
                } 
                else {
                    // 캘린더 화면으로 이동
                    this.props.history.push('/admin/AdminCalendar');
            
                    // 이벤트 저장 후 새로운 데이터를 가져오거나 캘린더를 다시 렌더링하는 작업 수행
                    if (this.props.onSaveSuccess) {
                        this.props.onSaveSuccess();
                    }
                }
            })
        };
    };

    handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        this.setState({ startDate: newStartDate });
        this.props.setSelectedDate({ ...this.props.selectedDate, startStr: newStartDate });
    };

    handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        this.setState({ endDate: newEndDate });
        // setSelectedDate 함수가 존재하는지 확인한 후에 호출
        if (this.props.setSelectedDate) {
            this.props.setSelectedDate({ ...this.props.selectedDate, endStr: newEndDate });
        }
    };

    handleStartTimeChange = (e) => {
        const newStartTime = e.target.value;
        this.setState({ startTime: newStartTime });
        // setSelectedDate 함수가 존재하는지 확인한 후에 호출
        if (this.props.setSelectedDate) {
        this.props.setSelectedDate({ ...this.props.selectedDate, startTime: newStartTime });
        }
    };

  
    handleEndTimeChange = (e) => {
        const newEndTime = e.target.value;
        this.setState({ endTime: newEndTime });
        // Check if setSelectedDate function is available before calling it
        if (this.props.setSelectedDate) {
        this.props.setSelectedDate({ ...this.props.selectedDate, endTime: newEndTime });
        }
    };


    handleRoomChange = (roomId) => {
        const selectedRoom = this.state.rooms.find(room => room.id === roomId); // 선택된 회의실 객체 찾기
        
        // 만약 선택된 방이 없는 경우, 선택을 취소하고 디폴트 색상으로 변경
        if (this.state.selectedRoom === selectedRoom.name) {
            this.setState({ selectedRoom: null, roomId: null }, () => {
                console.log('Room deselected:', roomId);
            });
            return;
        }
        
        if(this.state.startDate === '') {
            window.alert("날짜를 선택하세요.");
            return;
        }
        else if (this.state.startDate !== this.state.endDate) {
            window.alert("회의실은 하루 안에서 시간으로 예약해야 합니다.");
            return;
        }
        
        // 선택된 방이 있고, 날짜가 유효한 경우 선택된 회의실의 이름과 id 설정
        if (selectedRoom) {
            console.log('Selected roomId:', roomId);
            this.setState({ selectedRoom: selectedRoom.name, roomId: selectedRoom.id });
        }
    };
    

    handleDateChange = (newDate) => {
        this.props.setSelectedDate(newDate);
        // 선택한 날짜에 대한 다른 작업을 수행
        console.log("날짜가 변경되었습니다:", newDate);
    };
  
    handleTimeChange = (newTime) => {
        // 시간 변경 데이터 처리 로직을 작성
        console.log("시간이 변경되었습니다:", newTime);
        // 필요한 경우 상태를 업데이트하거나 다른 작업을 수행
    };

    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isPopupActive: !prevState.isPopupActive }));
    }

    // 팝업을 닫는 함수
    handleClosePopup = () => {
        this.setState({ isPopupActive: false });
    }

    // 참석자 선택 시 호출되는 함수
    handleEmployeeIdSelect = (employeeId) => {
        this.setState(prevState => {
            const prevAttArr = prevState.attArr || [];
            const isAlreadySelected = prevAttArr.includes(employeeId);
    
            if (!isAlreadySelected) {
                // 새로운 참석자 아이디가 기존 attArr에 포함되어 있지 않으면 추가
                return { attArr: [...prevAttArr, employeeId] };
            } else {
                // 이미 선택된 경우, 해당 아이디를 제외하고 새로운 배열 생성
                const updatedAttArr = prevAttArr.filter(id => id !== employeeId);
                return { attArr: updatedAttArr };
            }
        });
    };

    handleAddressSelect = (address) => {
        this.setState({ location: address });
    };

    openMapModal = () => {
        this.setState({ isMapModalOpen: true });
    }

    closeMapModal = () => {
        this.setState({ isMapModalOpen: false });
    }


    render() {
        const {startDate, endDate, eventTitle, startTime, endTime, timeOptions, allDay, location, isMapModalOpen, rooms} = this.state;
    
        return (
            <div className="full-screen">
            <div className="schedule">
                <header className="schedule-header">
                    일정등록
                </header>
                <div className="schedule-line"></div>
                <div className="schedule-content">
                    <form onSubmit={this.handleSubmit}>
                        <div id="schedule-A" className="schedule-name">
                            <input
                                className="event-input-A"
                                type="text"
                                // placeholder="일정을 입력하세요"
                                name="eventTitle"
                                value={eventTitle}
                                onChange={this.onChangeHandler}
                                />
                        </div>
                        <div id="date-time-A" className="startDate-time">
                            <div className="startDate-A">
                                <input
                                className="date-input-A"
                                type="date" 
                                name="startDate"
                                value={startDate} // 시작일자
                                onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="startTime-A">
                                <select 
                                className="time-input-A"
                                name="startTime"
                                value={startTime} // 시작시간
                                onChange={this.onChangeHandler}
                                disabled={allDay}
                                >
                                {timeOptions.map((time, index) => (
                                    <option key={index} value={time}>
                                    {time}
                                    </option>
                                ))}
                                </select>
                            </div>
                            <div className="endDate-time-A">
                                <label className="label-class-A">~</label>
                                <input 
                                className="date-input-A2"
                                type="date" 
                                name="endDate"
                                value={endDate} // 종료일자
                                onChange={this.onChangeHandler}
                                />
                            </div>
                            <div className="endTime-A">
                                <select
                                className="time-input-A2"
                                name="endTime"
                                value={endTime} // 죵료시간
                                onChange={this.onChangeHandler}
                                disabled={allDay}
                                >
                                {timeOptions.map((time, index) => (
                                    <option key={index} value={time}>
                                    {time}
                                    </option>
                                ))}
                                </select>
                            </div>
                            <div>
                                <div className="allDay-S">
                                    <input
                                        type="checkbox" 
                                        id="allDay-A" 
                                        className="allDay-A"
                                        name="allDay"
                                        checked={allDay} 
                                        onChange={this.onChangeHandler} 
                                    />
                                    <label htmlFor="allDay">종일</label>
                                </div>
                            </div>
                        </div>
                        <div className="att-A">  {/* 참석자 입력란 */}
                            <label className="label-att-A">참석자</label>
                            <input
                                id="standard-required"
                                variant="standard"
                                label="toId"
                                className="att-input-A"
                                type="text"
                                name="attArr"
                                value={this.state.attArr}
                                readOnly // 읽기 전용으로 설정
                                onChange={this.onChangeHandler}
                            />
                            <div className="mailPop">
                                <div className="searchID">
                                    <div className="icon-container1">
                                        <IonIcon icon={addOutline}
                                                onClick={this.togglePopup}
                                        />
                                    </div>
                                    <label className="att-search" onClick={this.togglePopup}> 참석자 선택</label>
                                </div>
                                {this.state.isPopupActive && <OrganizationChart handleClosePopup={this.handleClosePopup} onEmployeeIdSelect={this.handleEmployeeIdSelect} />}
                            </div>
                        </div>
                        <div className="location-A">  {/* 장소 입력란 */}
                            <label className="label-location-B">장소</label>
                            <input
                                className="location-input-B"
                                type="text"
                                name="location"
                                // placeholder="장소를 입력하세요"
                                value={location}
                                onChange={this.onChangeHandler}
                            />
                               <button className="maps" type="button" title="지도보기" onClick={this.openMapModal}>지도보기</button>
                               {isMapModalOpen && <KakaoEventModal onClose={this.closeMapModal} onAddressSelect={this.handleAddressSelect} />}
                        </div>
                        <div className="content-A">  {/* 내용 입력란 */}
                            <label className="label-content-A">내용</label>
                            <textarea
                                className="content-input-A"
                                name="content"
                                // placeholder="내용를 입력하세요"
                                value={this.state.content}
                                onChange={this.onChangeHandler}
                            />
                        </div>

                        <div className="reservation-container">
                            <label className="label-reservation-A">예약하기</label>
                            {/* 각 회의실 별 박스 표시 */}
                            {rooms.map((room, index) => (
                                <button
                                key={index}
                                className={`room-box ${room.name === this.state.selectedRoom ? 'selected' : ''} ${room.reserved ? 'reserved' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault(); // 기본 동작 방지
                                    this.handleRoomChange(room.id); // room.id를 사용하여 roomId 설정
                                }}
                                type="button"
                                >
                                    <span className="room-name">{room.name}</span>
                                    <span className="status-indicator"></span>
                                </button>
                            ))}
                        </div>

                        <footer className="btn_layer_wrap1">
                            <button className="btn_major_s1" type="submit" title="확인">
                            확인
                            </button>
                            <Link to="/admin/AdminCalendar">
                                <button className="btn_minor_s1" title="취소">
                                    취소
                                </button>
                            </Link>
                        </footer>
                    </form>
                </div>
            </div>
            </div>
        );
    } 
}

export default withRouter(AdminCalendarAdd);
