import React, { Component} from 'react';
import "./MailRouter.css";
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material';
import ApiService from './ApiService';
import Pagination from "./Pagination";
import { IonIcon } from '@ionic/react';
import { trashBinOutline } from 'ionicons/icons';


/* 작업자 : 강승재 */
/* 보낸메일함 목록페이지 */

class FromMailComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toList: [],
            limit: 10,
            page: 1,
            message: null,
            id: localStorage.getItem('LoginID'),
            selectedCheckboxes: {} // 체크박스 선택 상태를 저장하는 객체
        }
    }

    componentDidMount() {
        this.reloadToList();
    }

    // 받은메일 목록 조회
    reloadToList = () => {
        ApiService.FromList(this.state.id)
            .then(res => {
                const selectedCheckboxes = {}; // 선택된 체크박스 상태 초기화
                res.data.forEach(dto => {
                    selectedCheckboxes[dto.emailNo] = false; // 각 체크박스의 초기 상태는 false로 설정
                });
    
                this.setState({
                    toList: res.data,
                    selectedCheckboxes
                });
            })
            .catch(err => {
                console.log('reloadToList 에러!!', err);
            });
    }

    handleLimitChange = (event) => {
        this.setState({ limit: Number(event.target.value) });
    };

    handlePageChange = (pageNumber) => {
        this.setState({ page: pageNumber }, () => {
            this.reloadToList(); // 페이지 번호 변경 시 데이터 다시 로드
        });
    };

    // 체크박스 선택 이벤트 핸들러
    handleCheckboxChange = (emailNo) => {
        const { selectedCheckboxes } = this.state;
        selectedCheckboxes[emailNo] = !selectedCheckboxes[emailNo]; // 선택된 상태를 반전시킴
        this.setState({ selectedCheckboxes });
    }

    // 전체 선택 체크박스 핸들러
    handleSelectAll = () => {
        const { selectedCheckboxes } = this.state;
        const selectAll = !Object.values(selectedCheckboxes).every(checked => checked);
        for (let emailNo in selectedCheckboxes) {
            selectedCheckboxes[emailNo] = selectAll;
        }
        this.setState({ selectedCheckboxes });
    }

    // 보낸메일 삭제
    deleteMail = (emailNos) => {
        ApiService.deleteMail(emailNos)
        .then(res => {
            const updatedToList = this.state.toList.filter(dto => !emailNos.includes(dto.emailNo));
            
            this.setState(prevState => ({
                toList: updatedToList,
            }), () => {
                console.log('삭제처리 성공 : ', res.data);
                this.reloadToList();
            });
        })
        .catch(err => {
            console.log('삭제처리 에러!! : ', err);
        });
    }

    // 메일 확인(세부페이지) 연결
    detailMailFrom = (NO) => {
        window.localStorage.setItem("emailNO", NO);
        this.props.history.push("/mail/detailFrom");
    }

    // 날짜 형식 변환 함수
    formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // 브라우저 설정에 따라 날짜 형식이 달라집니다.
    }

    render() {
        const { toList, limit, page, selectedCheckboxes } = this.state;
        const offset = (page - 1) * limit;

        return (
            <div className='top' style={style}>
                    <div className='mailHeader'>
                        <h1>보낸메일함</h1>
                        <ul className='meta'>
                            <li className='metaNum'>
                            보낸메일
                                <strong>{toList.length}</strong>
                            </li>
                        </ul>
                    </div>
                

                    <div style={{marginBottom: '30px'}}>
                        <hr />
                        
                        <TableHead className='mailList'>
                            <TableRow style={{display: 'flex', padding: '10px'}} >
                                    <Checkbox
                                        checked={Object.values(selectedCheckboxes).every(checked => checked)}
                                        onChange={this.handleSelectAll}
                                    />
                                <button onClick={() => {
                                    const selectedEmailNos = Object.keys(selectedCheckboxes).filter(emailNo => selectedCheckboxes[emailNo]);
                                    this.deleteMail(selectedEmailNos);
                                    this.reloadToList();
                                }}> <IonIcon icon={trashBinOutline} /> 삭제 </button> 
                            </TableRow>
                        </TableHead>
                        <Table style={{marginBottom: '40px'}}>
                            <TableBody style={{ borderTop: '3px solid #ccc' }}>

                            {toList.slice(offset, offset + limit).map(dto =>
                                <TableRow key={dto.id} className='mailList_data'>
                                    <TableCell>
                                        <Checkbox
                                            key={dto.emailNo}
                                            checked={selectedCheckboxes[dto.emailNo] || false}
                                            onChange={() => this.handleCheckboxChange(dto.emailNo)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: "10%", marginLeft: "20px"}}>{dto.toId}</TableCell>
                                    <TableCell style={{ width: "60%"}} 
                                        onClick={() => this.detailMailFrom(dto.emailNo)}>{dto.emailTitle}</TableCell>
                                    <TableCell style={{ width: "20%"}}>{this.formatDate(dto.sendDate)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>



                    <div className='mail.paging_div'>
                        <Pagination
                            total={toList.length}
                            limit={limit}
                            page={page}
                            setPage={this.handlePageChange}
                        />
                    </div>
                </div>

                <label>
                    메일리스트 표시 개수 :&nbsp;
                    <select
                        type="number"
                        value={limit}
                        onChange={this.handleLimitChange}
                    >
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                    </select>
                </label>
            </div>
        );

    }

}

const style = {
    display: 'inline-block', 
    width: '100%'
}

export default FromMailComponent;