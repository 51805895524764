import React, { Component} from 'react';
import styles from './DetailTest.module.css';
import ApprMain from '../approval/ApprMain';
import ApprApiService from '../approval/ApprApiService';
import InfoApiService from '../../page_yoo/employee/ApiService';
import { Link, Route } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { menuOutline, closeCircleOutline } from 'ionicons/icons';
import 'react-datepicker/dist/react-datepicker.css';

class CompletedApprDetail extends Component {

    // 생성자
    constructor(props) {
        super(props);

        this. state = { 
          isModalOpen: false, 
          apprNum: '',
          employeeId: '',
          employeeName: '',
          departmentName: '',
          positionName: '',
          apprDate: '',
          apprTitle: '',
          apprContent: '',
          apprLeaveType: '',
          apprHalfType: '',
          apprReqLeave: '',
          apprStart: '',
          apprEnd: '',
          apprStatus: '',
          supportDept: '',
          firstApprover: '',
          midApprover: '',
          finalApprover: '',
          firstChkDate: '',
          midChkDate: '',
          finalChkDate: '',
          employeedto: {
            name: '',
            departmen: '',
          },
          firstApproverDTO: {
            name: '',
            position: '',
          },
          midApproverDTO: {
            name: '',
            position: '',
          },
          finalApproverDTO: {
            name: '',
            position: '',
          }
        }

        // 모달 값 전송
        this.handleApprovalClick = this.handleApprovalClick.bind(this); 
        this.handleRejectClick = this.handleRejectClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    // 기안자 정보 가져오기
    // 라이프사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    componentDidMount() {   
        this.loadDetail();  
        // this.loadApprover();
    }

    // 결재문서읽어오기
    loadDetail = () => {
        const apprValueString = window.localStorage.getItem('apprValue');
        const apprValue = JSON.parse(apprValueString);

        ApprApiService.ProgressApprDetail(apprValue.apprNum, apprValue.employeeId)
            .then(res => { //db에서 읽어온 데이터 덮어쓰기
                console.log('loadDetail API 응답:', res);
                let loadDetail = res.data;
                console.log('결재문서 데이터:', loadDetail);
                this.setState({
                    apprNum: loadDetail.apprNum,
                    departmentName: loadDetail.departmentName,
                    employeeId: loadDetail.employeeId,
                    apprTitle: loadDetail.apprTitle,
                    apprDate: loadDetail.apprDate,
                    apprContent: loadDetail.apprContent,
                    apprLeaveType: loadDetail.apprLeaveType,
                    apprHalfType: loadDetail.apprHalfType,
                    apprReqLeave: loadDetail.apprReqLeave,
                    apprStart: loadDetail.apprStart,
                    apprEnd: loadDetail.apprEnd,
                    apprStatus: loadDetail.apprStatus,
                    supportDept: loadDetail.supportDept,
                    firstApprover: loadDetail.approverDTO.firstApprover,
                    midApprover: loadDetail.approverDTO.midApprover,
                    finalApprover: loadDetail.approverDTO.finalApprover,
                    firstChkDate: loadDetail.approverDTO.firstChkDate,
                    midChkDate: loadDetail.approverDTO.midChkDate,
                    finalChkDate: loadDetail.approverDTO.finalChkDate,
                })
                console.log('아이디확인1 : ', loadDetail.employeeId);
                this.adminInfo(loadDetail.employeeId);
                this.firstApproverInfo(loadDetail.approverDTO.firstApprover);
                this.midApproverInfo(loadDetail.approverDTO.midApprover);
                this.finalApproverInfo(loadDetail.approverDTO.finalApprover);
            })
            .catch(err => {
                console.log('ProgressApprDetail() Error!!', err);
            });
    }
    // 기안자 정보 가져오기
    adminInfo = (employeeId) => {
        console.log('아이디확인 : ', employeeId);
        InfoApiService.fetchEmployeeByID(employeeId)
            .then(res=> {
                console.log('fetchEmployeeByID API 응답:', res);
                let InfoDetail = res.data;
                console.log('사원 데이터:', InfoDetail);
                this.setState({
                    employeedto: {
                        name: InfoDetail.employeeName,
                        departmentName: InfoDetail.departmentName
                    }
                })
            })
            .catch(err => {
                console.log('adminInfo() Error!!', err);
            });
    }

    // 최초승인자 정보 가져오기
    firstApproverInfo = (employeeId) => {
        console.log('아이디확인 : ', employeeId);
        InfoApiService.fetchEmployeeByID(employeeId)
            .then(res=> {
                console.log('fetchEmployeeByID API 응답:', res);
                let firstInfoDetail = res.data;
                console.log('firstApprover 데이터:', firstInfoDetail);
                this.setState({
                    firstApproverDTO: {
                        name: firstInfoDetail.employeeName,
                        positionName: firstInfoDetail.positionName,
                      },
                })
            })
            .catch(err => {
                console.log('firstApproverInfo() Error!!', err);
            });
    }

    // 중간승인자 정보 가져오기
    midApproverInfo = (employeeId) => {
        console.log('아이디확인 : ', employeeId);
        InfoApiService.fetchEmployeeByID(employeeId)
            .then(res=> {
                console.log('fetchEmployeeByID API 응답:', res);
                let midInfoDetail = res.data;
                console.log('midApproverInfo 데이터:', midInfoDetail);
                this.setState({
                    midApproverDTO: {
                        name: midInfoDetail.employeeName,
                        positionName: midInfoDetail.positionName,
                      },
                })
            })
            .catch(err => {
                console.log('midApproverInfo() Error!!', err);
            });
    }

    // 최종승인자 정보 가져오기
    finalApproverInfo = (employeeId) => {
        console.log('아이디확인 : ', employeeId);
        InfoApiService.fetchEmployeeByID(employeeId)
            .then(res=> {
                console.log('fetchEmployeeByID API 응답:', res);
                let finalInfoDetail = res.data;
                console.log('finalInfoDetail 데이터:', finalInfoDetail);
                this.setState({
                    finalApproverDTO: {
                        name: finalInfoDetail.employeeName,
                        positionName: finalInfoDetail.positionName,
                      },
                })
            })
            .catch(err => {
                console.log('firstApproverInfo() Error!!', err);
            });
    }

    // 상신취소 버튼 클릭시 호출 함수
    handleCancelSubmission = () => {
        // 확인 메시지를 표시하고 확인 버튼을 눌렀을 때만 deleteAppr 함수를 호출
        if (window.confirm("영구삭제 하시겠습니까?")) {
            const { apprNum, employeeId } = this.state;
            this.deleteAppr(apprNum, employeeId); // deleteAppr 함수 호출하여 삭제 진행
            this.props.history.push('/appr/CompleteDocument');
        }
    };

    // 상신취소 (delete)함수 
    deleteAppr = (apprNum, employeeId) => {
        ApprApiService.deleteAppr(apprNum, employeeId)
            .then(res => {
                console.log('삭제되었습니다.', res);
            })
            .catch(err => {
                console.error('삭제 중 오류 발생:', err);
            })
    }

    // 목록으로 돌아가기
    handleListClick = () => {
        this.props.history.push('/appr/ProgressAppr');
    }

    // 결재 모달 열기
    handleApprovalClick() {
        // 결재 버튼을 눌렀을 때만 모달을 열도록 함
        this.setState({ isModalOpen: true });
        console.log("상태 : 모달 open");
    }

    // 결재 버튼 클릭 시
    handleApproval = (apprNum, apprReason, apprStatus) => {
        console.log("결재 처리 함수 호출");
        console.log("결재 문서 번호:", apprNum);
        console.log("결재 의견:", apprReason);
        console.log("결재 상태:", apprStatus);
        this.setState({ apprNum, apprReason, apprStatus });
    };

    // 반려 모달 열기
    handleRejectClick() {
        this.setState({ isRejectModalOpen: true });
        console.log("상태 : 반려모달 open");
    }

    // 모달 닫기
    closeModal() {
        this.setState({ isModalOpen: false, isRejectModalOpen: false });
    }
    
    render() {
    
      // 모달
      const { isModalOpen, isRejectModalOpen } = this.state;

      // 날짜 형식 바꾸기 
      const days = ['일', '월', '화', '수', '목', '금', '토'];
      const date = new Date(this.state.apprDate);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} (${days[date.getDay()]})`;

      // 결재자의 ID를 가져옵니다.
      const { firstApprover, midApprover, finalApprover } = this.state;

      // 결재 버튼을 노출할지 여부를 결정합니다.
      const showApprovalButton = firstApprover === 'employeeId' || midApprover === 'employeeId' || finalApprover === 'employeeId';

      // 상신취소 버튼
      const { apprStatus, employeeId} = this.state;

      return (
            
            <div>
            <div className={styles.formTitle}>휴가신청서</div>
            <div className={styles['nav-wrap']}>
                <div className={styles['button-top']}>
                    {/* 삭제 */}
                    { (apprStatus === 3 || apprStatus === 4) && (
                        <button type="button" className={styles.btn} onClick={this.handleCancelSubmission}>
                            <div className={styles.icon}><IonIcon icon={closeCircleOutline} /></div>
                            삭제
                        </button>
                    )}
                    {/* 목록버튼 */}
                    <button type="button" className={styles.btn} onClick={this.handleListClick}>
                        <div className={styles.icon}><IonIcon icon={menuOutline} /></div>
                        <Link to="/appr/ProgressAppr">목록</Link>
                    </button>
                </div> {/* 상단 버튼 끝 */}

                    {/* <div className={styles['right-wrap']}> */}
                <div className={styles.formContent}>
                    <div className='leave-form'>
                        <div className={styles.headTitle}>연차신청서</div>
                            <div className={styles.topInfo}>
                                {/*기안자 상세*/}
                                <div className={styles.userInfo}>
                                    <table >
                                        <thead>
                                        <tr>
                                            <td>기안자</td>
                                            <td>{this.state.employeedto.name}</td>
                                        </tr>
                                        <tr>
                                            <td >부서</td>
                                            <td>{this.state.employeedto.departmentName}</td>
                                        </tr>
                                        <tr>
                                            <td>기안일</td>
                                            <td>
                                                <div className='apprDate'> 
                                                    {this.state.apprDate}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>문서번호</td>
                                            <td>
                                                <div className='apprNum'> 
                                                    {this.state.apprNum}
                                                </div>
                                            </td>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>

                                {/* 결재자 정보 */}
                                <div className={styles.approverInfo}>
                                <table>
                                        <thead>
                                            <tr>
                                                <td rowSpan="3" className={styles.approvalHeader}>승인</td>
                                                <td>{this.state.firstApproverDTO.positionName}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {this.state.apprStatus >= 1 && this.state.apprStatus <= 3 && (
                                                        <div className={styles.apprStamp}>승인</div>
                                                    )}
                                                    {this.state.apprStatus === 4 && (
                                                        <div className={styles.apprStamp}>반려</div>
                                                    )}
                                                    {this.state.firstApproverDTO.name}
                                                </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                {this.state.apprStatus === 4 ? (
                                                    <div style={{ color: 'red' }}>
                                                        {this.state.firstChkDate}
                                                    </div>
                                                ) : (
                                                    <div style={{ color: 'black' }}>
                                                        {this.state.firstChkDate}
                                                    </div>
                                                )}
                                            </td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td rowSpan="3" className={styles.approvalHeader}>승인</td>
                                                <td>{this.state.midApproverDTO.positionName}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {this.state.apprStatus >= 2 && this.state.apprStatus <= 3 && (
                                                        <div className={styles.apprStamp}>승인</div>
                                                    )}
                                                    {this.state.apprStatus === 4 && (
                                                        <div className={styles.apprStamp}>반려</div>
                                                    )}
                                                    {this.state.midApproverDTO.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {this.state.apprStatus === 4 ? (
                                                        <div style={{ color: 'red' }}>
                                                            {this.state.midChkDate}
                                                        </div>
                                                    ) : (
                                                        <div style={{ color: 'black' }}>
                                                            {this.state.midChkDate}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td rowSpan="3" className={styles.approvalHeader}>승인</td>
                                                <td>{this.state.finalApproverDTO.positionName}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td>
                                                    {this.state.apprStatus === 3 ? (
                                                        <div className={styles.apprStamp}>승인</div>
                                                    ) : this.state.apprStatus === 4 ? (
                                                        <div className={styles.apprStamp}>반려</div>
                                                    ) : null}
                                                    {this.state.finalApproverDTO.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {this.state.apprStatus === 4 ? (
                                                        <div style={{ color: 'red' }}>
                                                            {this.state.finalChkDate}
                                                        </div>
                                                    ) : (
                                                        <div style={{ color: 'black' }}>
                                                            {this.state.finalChkDate}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div> {/* approverInfo */}
                            </div>
                        <div className={styles.apprContent}>
                            <table>
                                <tbody>
                                    {/* 연차종류 */}
                                    <tr> 
                                        <td>휴가종류</td>
                                        <td>
                                            <div className='apprLeaveType'> 
                                                {this.state.apprLeaveType}
                                            </div>
                                        </td>
                                    </tr>
                                    {/* 협조부서 */}
                                    <tr> 
                                        <td>협조부서</td>
                                        <td>
                                            <div className='supportDept'> 
                                            {this.state.supportDept}
                                            </div>
                                        </td>
                                    </tr>
                                    {/* 반차여부 */}
                                    <tr> 
                                        <td>반차여부</td>
                                        <td>
                                            <div className='apprHalfType'> 
                                                {this.state.apprHalfType}
                                            </div>
                                        </td>
                                    </tr>
                                    {/* 시작날짜 + 종료날짜 */}
                                    <tr> 
                                        <td>기간 및 일시</td>
                                        <td>
                                            <div>
                                                <span>시작일: {this.state.apprStart}</span>
                                                &nbsp; ~ &nbsp;
                                                <span>종료일: {this.state.apprEnd}&nbsp;&nbsp;&nbsp;( 신청연차 : {this.state.apprReqLeave} )</span>
                                            </div>
                                        </td>
                                    </tr>
                                    {/* 내용 */}
                                    <tr> 
                                        <td>* 휴가 사유</td>
                                        <td>
                                            <div className='apprContent'> 
                                                {this.state.apprContent.split("\n").map((line) => { //this.props.data.content: 내용
                                                    return (
                                                    <span>
                                                        {line}
                                                        <br />
                                                    </span>
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr> 
                                        <td colspan="2">
1. 연차의 사용은 근로기준법에 따라 전년도에 발생한 개인별 잔여 연차에 한하여 사용함을 원칙으로 한다. <br />
단, 최초 입사시에는 근로 기준법에 따라 발생 예정된 연차를 차용하여 월 1회 사용 할 수 있다. <br />
2. 경조사 휴가는 행사일을 증명할 수 있는 가족 관계 증명서 또는 등본, 청첩장 등 제출 <br />
3. 공가(예비군/민방위)는 사전에 통지서를, 사후에 참석증을 반드시 제출</td> <br />
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.file}>
                            <ul>
                            <li>파일첨부</li>
                            </ul>
                            <div className={styles['file-add']}>
                            <ul>
                                <li><ion-icon name="attach-outline"></ion-icon><span>파일선택</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='right'>  
                    <Route path="/appr/" exact={true} component={ApprMain}></Route>
                </div>
            </div>
        </div>
        );
    }
}


export default CompletedApprDetail;