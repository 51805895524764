import axios from 'axios'   // npm install -f axios@^1.3.5

// - ApiService는 스프링부트 서버(보통 'http://localhost:8080/'으로 열린다.)와 연결해주는 역할을 한다.
// - 리액트에서 무언가 요청을 하면 스프링부트에서 받아 Oracle에서 데이터를 가져오거나 연결해주는 역할을 한다.
// - 전형적인 MVC 패턴이라고 할 수 있다.
// - 리액트에서 이를 구현하기 위해서 Axios를 사용한다. 기존 HTML이나 JSP에서 사용한 AJAX 역할을 한다고 보면 된다.
// npm install -f axios@^1.3.5

const Attend_API_BASE_URL = process.env.REACT_APP_BACK_HOST + "/attend";


// 이부분 추가
const getAuthToken = () => {
    return window.localStorage.getItem("auth_token");
}

let headers = {};


if(getAuthToken() !== null && getAuthToken !=="null") {
    headers = {"Authorization": `Bearer ${getAuthToken()}`};
    console.log('headers : ', headers);
}

// 여기 위까지 추가해주시면 됩니다.
    

class AttentionApiService {
   
    // 일일 근태내역

    // 호출할 API 이름
    fetchAttend(data) {
        //console.log('fetchAttend() 호출');

        return axios.post(Attend_API_BASE_URL + '/today', data , {headers});    // 기존에서 , {headers} 를 추가해주세요
    }

    goToWork(data) {
        //console.log('출근버튼에서 서비스 호출');

        return axios.post(Attend_API_BASE_URL + '/goToWork', data , {headers});
    }
    
    goToHome(data) {
        //console.log('퇴근버튼에서 서비스 호출');

        return axios.post(Attend_API_BASE_URL + '/goToHome', data , {headers});
    }

    getAttendState(employee_id) {
        //console.log('근무상태 호출');

        return axios.get(Attend_API_BASE_URL + '/state/' + employee_id, {headers});
    }

    getAttendMonth(data) {
        
        return axios.post(Attend_API_BASE_URL + '/month', data, {headers});
    }
    
    

    getMyAttend(data) {
        // console.log('연가 호출');
        // console.log(data);
        // console.log(data.start);
        // console.log(data.end);

        return axios.get(Attend_API_BASE_URL + '/myannual?id=' + data.employee_id + '&start=' + data.start + '&end=' + data.end , {headers});
    }

    addVacData(data) {
        
        return axios.post(Attend_API_BASE_URL + '/addvac', data, {headers});
    }
    
    getNews() {
        //console.log('fetchAttend() 호출');

        return axios.get(Attend_API_BASE_URL + '/news' , {headers});
    }

    deleteVac(data) {

        return axios.post(Attend_API_BASE_URL + '/deletevac', data, {headers});
    }
    

}

const AttentionApiServiceApiServ = new AttentionApiService();

export default AttentionApiServiceApiServ;

// export const request = (method, url, data) => {
    
    
//     console.log('axios~~~');
//     console.log('method : ', method );
//     console.log('url : ', url );
//     console.log('data : ', data );

//     return axios({
//         method: method,
//         headers: headers,
//         url: url,
//         data: data
//     });
// };
