import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { Component, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {Table, TableHead, TableRow, TableCell, TableBody, Button, Typography} from '@mui/material';
import { Link, Route } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MyInfo from './MyInfo';

const EmplRouter = () => {

    // 아코디언 그림자 제거 함수
    const theme = createTheme({
        components: {
          MuiAccordion: {
            styleOverrides: {
              root: {
                boxShadow: 'none', // 아코디언 컴포넌트의 그림자 제거
                padding: '0px 10px',
              },
            },
          },
        },
      });

    // 로컬스토리지에서 id 받아와야할듯 임시로 고정값

    let employee_info = {
        employee_id:'test1',
        annual: 0,
        state: '',
        att_stime: '',
        att_etime: ''
    };

    /*
    const testa = useRef({
        employee_id:'',
        annual: 0,
        state: '',
        att_stime: '',
        att_etime: ''
    });

    */
    /*
    const getAttendState = (employee_id) => {
        console.log(employee_id);
        ApiService.getAttendState(employee_id)  // 2.
        .then(res => {             // 4.
            console.log('근태 상태정보 요청 성공');
            let a = res.data;
            
            testa.current = a;

            console.log(testa);
        })
        .catch(err => {
            console.log('getAttendState() Error!', err);
        })
        
    }

    */

    
    useEffect(() => {
 
        setTimeout (() => {
            console.log("대기");
            console.log("상태는 : " + window.localStorage.getItem('Attend_STATE'))
        }, 1000);

        

    })

    const [expanded, setExpanded] = React.useState(false);

    const history = useHistory();
  
    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem("LoginID");
        window.location.href = "/login";
    };

    return (
        <ThemeProvider theme={theme}>
        <div style={style}>
        
            

            <div className='main'>
                <div className='left'>
                    <div className='sub_Header'>
                        <div className='title' style={style.title}>
                            내 정보 확인
                        </div>
                        <div className='apprMenu'> {/* 각자수정 */}
                            <Button variant="outlined" onClick={handleLogout} style={style.first_Button}>로그아웃</Button>
                        </div>
                    </div>
                </div>

                <div className='right'>
                    <Route path="/employee/" exact={true} component={MyInfo}></Route>
                </div>

            </div>
        
        </div>
        </ThemeProvider>
    );
}

const style = {
    link: {
        textDecoration: "none",
        color: "#646464",
        fontSize: "15px"
      },
  
      title: {
          marginTop: '30px',
          fontSize: '21px',
          textAlign: 'left',
      },
  
      // title 아래 버튼
      first_Button: {   
        marginTop: '5px',
        paddingTop: '10px',
        paddingRight: '60px',
        paddingBottom: '10px',
        paddingLeft: '60px',
        color: '#323232',
        borderColor: '#6e6e6e'
      },
  
      apprMenu: {
          marginTop: '30px'
      },
  
      middleMenu: {
          fontWeight: 550,
          color: '#5a5a5a'
      }
}

export default EmplRouter;