import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import { Delete} from '@mui/icons-material';
import './AddVac.css'
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import OrganizationChart from './IdListPop.js';
import ApiService from "../Attention/AttentionApiService.js"

class AddVac extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {

            employee_id : '',
            isPopupActive: false,
            annualData : []

        };

    }
    componentDidMount() {

    }

    setData = {
        employee_id: '',
        thisYear: moment(new Date()).startOf('year'),
        start: moment(new Date()).startOf('year').format('YYYY-MM-DD'),
        end: moment(new Date()).endOf('year').format('YYYY-MM-DD')
    }

    

    componentDidUpdate(prevProps) {
        if (this.props.selectedDate !== prevProps.selectedDate) {
        this.setState({
            startDate: this.props.selectedDate ? this.props.selectedDate.startStr : '',
            endDate: this.props.selectedDate ? this.props.selectedDate.endStr : '',
        }, () => {
            console.log('startDate and endDate updated:', this.state.startDate, this.state.endDate);
          });
        }
      }

    
      setItem = () => {
        this.setData.start = moment(this.setData.thisYear).startOf('year').format('YYYY-MM-DD');
        this.setData.end = moment(this.setData.thisYear).endOf('year').format('YYYY-MM-DD');
    }

    decrement() {
        this.setData.thisYear.add(-1, 'years');
        
        this.setItem();

        if(this.state.employee_id != '')
            this.loadMyVac(this.setData)
    }

    increment() {
        
        this.setData.thisYear.add(1, 'years');

        this.setItem();



        // 데이터 로드하기 전까지는 이거 활성화시켜서 render 시켜야함 실제 데이터는 바뀌는데 화면단이 안바뀜
        /*
        this.setState({
            statecheck: '1'
        })
        */

        if(this.state.employee_id != '')
            this.loadMyVac(this.setData)
    }  

    loadMyVac = (data) => {


        ApiService.getMyAttend(data)  // 2.
        .then(res => {             // 4.
            
            //console.log('내 연가 요청');
            //console.log(res.data);

            this.setState({
                annualData: res.data
            });
        })
        .catch(err => {
            console.log('loadMyVac() Error!', err);
        })

        
        
    }

    deleteVac = (data) => {

        
        if(moment(data.vac_sdate) < moment()) {
            alert("이미 시작된 휴가계는 삭제 할 수 없습니다!!")
            return;
        }


        ApiService.deleteVac(data)  // 2.
        .then(res => {             // 4.
            
            //console.log('연가 삭제 요청');
            //console.log(res.data);

            this.setState({
                annualData: this.state.annualData.filter(annualData => annualData.vac_num !== data.vac_num)
            });
        })
        .catch(err => {
            console.log('deleteVac() Error!', err);
        })

        
        
    }

    
    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isPopupActive: !prevState.isPopupActive }));
    }

    // 팝업을 닫는 함수
    handleClosePopup = () => {
        this.setState({ isPopupActive: false });
    }

    // 조직도 선택 시 호출되는 함수
    handleEmployeeIdSelect = (employeeId) => {
        
        this.setData.employee_id = employeeId
        if(this.setData.employee_id != '')
            this.loadMyVac(this.setData)
        
        this.setState({
                employee_id: employeeId
        });
    };



    render() {
        const {startDate, endDate, vactype} = this.state;
    
        return (
            <div style={{ alignItems: 'center', justifyContent: 'space-between', padding: '50px 50px 25px 50px', width: '100%' }}>
                <h1 style={{ textAlign: 'left', margin: 0 }}>
                    휴가계 조회
                </h1>
                <br/>
                <div style={{margin: 'auto', paddingLeft: '50px'}}>
   
                    
                    <div>
                    <br/>
                        <label className="hlabel-att-A"> 대상 </label>
                        <input
                            id="standard-required"
                            variant="standard"
                            label="toId"
                            className=""
                            type="text"
                            name="attArr"
                            value={this.state.employee_id}
                            readOnly // 읽기 전용으로 설정
                            onChange={this.onChangeHandler}
                        />
                        
                            <div style={{display:'inline'}}>
                                <div style={{display:'inline'}}>
                                <div className="hicon-container1" style={{display: 'inline-block', alignItems:'center'}}>
                                    <IonIcon icon={addOutline}
                                            onClick={this.togglePopup}
                                    />
                                </div>
                                </div>
                                <label className="hatt-search" onClick={this.togglePopup}> 조직도 선택</label>
                                {this.state.isPopupActive && <OrganizationChart handleClosePopup={this.handleClosePopup} onEmployeeIdSelect={this.handleEmployeeIdSelect} />}
                            </div>
                        
                        

                    </div>

                </div>

                <h1 style={{textAlign:'center'}}>
                
                <Button onClick={this.decrement.bind(this)}>
                    <FontAwesomeIcon icon={faChevronLeft}  />
                </Button>

                {this.setData.thisYear.format('YYYY')}
                
                <Button onClick={this.increment.bind(this)} >
                <FontAwesomeIcon icon={faChevronRight} />
                </Button>
                </h1>

                <br/><br/>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="15%"> 아이디 </TableCell>
                            <TableCell width="10%"> 종류 </TableCell>
                            <TableCell width="15%"> 시작일 </TableCell>
                            <TableCell width="15%"> 종료일 </TableCell>
                            <TableCell width="10%"> 사용연차 </TableCell>
                            <TableCell width="10%"> 남은연차 </TableCell>
                            <TableCell width="15%"> 등록일 </TableCell>
                            <TableCell width="5%"> 삭제 </TableCell>
                            
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {/* 
                            map은 Script나 React의 반복문 
                            map(item => ... )   또는
                            map((item, idx) => ...)
                            
                        */}
                        {this.state.annualData.map(dto => {



                            return (
                            <TableRow key={dto.vac_num}>
                                <TableCell width="15%">{dto.employee_id} </TableCell>
                                <TableCell width="10%">{dto.vac_type} </TableCell>
                                <TableCell width="15%">{dto.vac_sdate} </TableCell>
                                <TableCell width="15%">{dto.vac_edate} </TableCell>                                
                                <TableCell width="10%">{dto.vac_use} </TableCell>
                                <TableCell width="10%">{dto.vac_remain} </TableCell>
                                <TableCell width="15%">{dto.vac_rdate} </TableCell>
                                <TableCell width="5%" onClick={() => this.deleteVac(dto)}>
                                    <Delete />
                                </TableCell>
                            </TableRow>
                            )
                            
                        }

                            )}
                    </TableBody>
                </Table>

            </div>
            
        );
    } 
}

export default AddVac;
