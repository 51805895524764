import React, { Component } from 'react';
import ApiService from './ApiService';
import { Table, TableBody, TableRow, TableCell, Select, Tooltip, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import DaumPostcode from 'react-daum-postcode';
import sampleImage from '../employee/qq.jpeg';
import './DetailEmpl.css'

class EditEmpl extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            employeeInfo: {
                employeeName: '',
                gender: '',
                authorityName: '',
                departmentName: '',
                positionId: '',
                employeeAddress: '',
                employDate: '',
                employeeTel: '',
                extensionTel: '',
                employeeBirth: '',
                resignationDate: ''
            },
            isEditingName: false,
            isEditingGender: false,
            isEditingAuthority: false,
            isEditingDepartment: false,
            isEditingPosition: false,
            isEditingAddress: false,
            isEditingeEployDate: false,
            isEditingTel: false,
            isEditingDir: false,
            isEditingBirth: false,
            isEditingResigned: false,
            editingField: null, // 수정 중인 필드 추적
            isModalOpen: false,
            isAddressModalOpen: false 
        };

        // 함수 바인딩
        this.handleResignedChange = this.handleResignedChange.bind(this);
    }

    componentDidMount() {
        this.loadEmployee();
    }

    loadEmployee = () => {
        const selectEmployeeId = window.localStorage.getItem("selectEmployeeId");
        // 직원 정보 조회 API 호출
        ApiService.fetchEmployeeByID(selectEmployeeId)
            .then(response => {
                console.log("서버 응답 데이터:", response);
                const { employeeInfo, activeDepartList } = response.data;
                // this.setState({ employeeInfo: employeeInfo, departments: departments });
                this.setState({ activeDepartList, employeeInfo });
            })
            .catch(error => {
                console.error('직원 정보 조회 에러:', error);
            });
    }

    handleEditClick = (field) => {
        this.setState({ [field]: true, editingField: field });
    };    

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            employeeInfo: {
                ...prevState.employeeInfo,
                [name]: value
            }
        }));
    }
    
    handleTelPrefixChange = (e) => {
        const { value } = e.target;
        this.setState(prevState => ({
            employeeInfo: {
                ...prevState.employeeInfo,
                employeeTel: value + prevState.employeeInfo.employeeTel.substring(3) // 전화번호 맨 앞 3자리 변경
            }
        }));
    }    

    handleMiddleTelChange = (e) => {
        const { value } = e.target;
        if (/^\d+$/.test(value) && value.length <= 4) {
            this.setState(prevState => ({
                employeeInfo: {
                    ...prevState.employeeInfo,
                    employeeTel: prevState.employeeInfo.employeeTel.substring(0, 3) + value + prevState.employeeInfo.employeeTel.substring(7)
                }
            }));
        }
    }
    
    handleLastTelChange = (e) => {
        const { value } = e.target;
        this.setState(prevState => ({
            employeeInfo: {
                ...prevState.employeeInfo,
                employeeTel: prevState.employeeInfo.employeeTel.substring(0, 6) + value
            }
        }));
    }

    handleSaveClick = () => {
        const selectEmployeeId = window.localStorage.getItem("selectEmployeeId");  
        const { employeeInfo, imageFile } = this.state;
        
        // 하나라도 입력되지 않은 경우 경고창 표시
        if (!employeeInfo.employeeName || !employeeInfo.gender || !employeeInfo.authorityName || !employeeInfo.departmentName || !employeeInfo.positionId || !employeeInfo.employDate || !employeeInfo.employeeTel || !employeeInfo.extensionTel || !employeeInfo.employeeBirth || !employeeInfo.resignationDate || !imageFile) {
            alert("모든 항목을 입력해주세요.");
            return;
        }

        const formData = new FormData();        
        formData.append('file', imageFile);
        console.log('fileasdfsdfsdfsd', imageFile);
        formData.append('data', JSON.stringify(employeeInfo));

        ApiService.updateEmployee(formData)
            .then(res => {
                console.log("정보 수정 API 응답 데이터:", res.data);
                if (res.data) {
                    console.log("정보 수정 성공");
                    // this.setState({ isModalOpen: true });
                } else {
                    console.log("정보 수정 실패");
                    alert("");
                }
            })
            .catch(err => {
                console.log('정보 수정 에러!! : ', err);
                alert("정보 수정 에러");
            });
    
        // 수정 상태 초기화
        this.setState({
            isEditingName: false,
            isEditingGender: false,
            isEditingAuthority: false,
            isEditingDepartment: false,
            isEditingPosition: false,
            isEditingAddress: false,
            isEditingeEployDate: false,
            isEditingTel: false,
            isEditingDir: false,
            isEditingBirth: false,
            isEditingResigned: false,
        });
    };

    handleImageChange = (e) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
        
        reader.onloadend = () => {
            this.setState({
                selectedImage: reader.result,
                imageFile: e.target.files[0],
                employeeInfo: {
                    ...this.state.employeeInfo
                    // employeeImg: reader.result
                }
            });
        };
    
        if (file) {
            reader.readAsDataURL(file);
            console.log('file', file);
        } else {
            this.setState({
                selectedImage: this.state.defaultImage,
                file: null // 파일 선택되지 않은 경우에는 file 상태를 null로 설정
            });
        }
    };    

    // 퇴사 여부 체크박스 상태 변경 핸들러
    handleResignedChange = () => {
        this.setState(prevState => ({
            isEditingResigned: !prevState.isEditingResigned
        }));
    };     
    
    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    handleCancelClick = () => {
        this.props.history.goBack();
    };    

    // 다이얼로그 열기 함수
    openAddressModal = () => {
        this.setState({ isAddressModalOpen: true });
    };

    // 다이얼로그 닫기 함수
    closeAddressModal = () => {
        this.setState({ isAddressModalOpen: false });
    };

    // Daum 우편번호 선택 시 처리 함수
    handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress +=
                    extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        this.setState({
            employeeAddress: fullAddress,
        }, () => {
            console.log('employeeAddress', this.state.employeeAddress);
        });
    };  

    render() {
        const { isAddressModalOpen, isEditingName,isEditingGender, isEditingAuthority, isEditingDepartment, isEditingPosition, isEditingAddress, isEditingeEployDate, isEditingTel, isEditingDir, isEditingBirth, employeeInfo, isModalOpen, isEditingResigned, activeDepartList } = this.state;

        return (
            <div align="center">
                <div id="modalContainer" className={isModalOpen ? "" : "hidden"}>
                    <div id="modalContent">
                        <span id="modalCloseButton" className="close" onClick={this.closeModal}>&times;</span>
                        <div id="modalTxt">
                            <h2 style={{ paddingBottom: '8px' }}>저장되었습니다.</h2>
                            <p>변경사항이 저장되었습니다.</p>
                        </div>
                        <button id="modalClose" onClick={this.closeModal}>닫기</button>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '40px 50px 25px 24px', width: '100%' }}>
                    <h2 style={{ textAlign: 'left', margin: 0 }}> {isEditingName ? '이름 수정 중' : `${employeeInfo.employeeName}의 정보 수정하기`} </h2>
                </div>

                <Table className="info-table" style={{ borderCollapse: "collapse", border: 'none', padding: '0 0 0 24px' }}>
                    <TableBody>
                    <TableRow>
                            <TableCell className="custom-cell" style={{ verticalAlign: 'top', border: 'none' }}><span className='info-title'>사진</span></TableCell>
                            <TableCell style={{ border: 'none' }}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={this.handleImageChange}
                                    style={{ display: 'none' }}
                                    multiple='multiple'
                                    id="employeeImg"
                                    name='employeeImg'
                                />
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <label htmlFor="employeeImg" style={{ cursor: 'pointer', position: 'absolute', top: '75%', left: '12%', zIndex: '1' }}> 사진 올리기 </label>
                                    {this.state.selectedImage ? (
                                        <img
                                            src={this.state.selectedImage}
                                            alt="프로필 이미지"
                                            style={{ height: '100px', width: '100px', borderRadius: '3px', position: 'relative', zIndex: '0' }}
                                        />
                                    ) : (
                                        <img
                                            src={'/profiles/' + employeeInfo.employeeImg}
                                            alt="프로필 이미지"
                                            style={{ height: '100px', width: '100px', borderRadius: '3px', position: 'relative', zIndex: '0' }}
                                        />
                                    )}
                                </div>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>이름</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingName')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingName')}>
                                        {isEditingName ? 
                                            <input 
                                                type="text" 
                                                name="employeeName"
                                                value={employeeInfo.employeeName} 
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value.length <= 10) {
                                                        this.handleInputChange(e);
                                                    }
                                                }}
                                                placeholder="최대 10자 까지만 입력 가능"
                                                style={{ height: '26px', width: '300px', borderRadius: '3px', padding: '5px' }}
                                            /> :
                                            employeeInfo.employeeName
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>성별</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingGender')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingGender')}>
                                        {isEditingGender ? 
                                            <Select
                                                value={employeeInfo.gender}
                                                onChange={this.handleInputChange}
                                                name="gender"
                                                style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                            >
                                                <MenuItem value="M">남성</MenuItem>
                                                <MenuItem value="W">여성</MenuItem>
                                            </Select> :
                                            (employeeInfo.gender === 'M' ? '남성' : '여성')
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>권한</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingAuthority')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingAuthority')}>
                                        {isEditingAuthority ? 
                                            <Select
                                                value={employeeInfo.authorityId} 
                                                onChange={this.handleInputChange}
                                                name="authorityId"
                                                style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                                >
           
                                                    <MenuItem value="0">사원</MenuItem>
                                                    <MenuItem value="1">관리자</MenuItem>
                                            </Select> :
                                            employeeInfo.authorityName
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>부서</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingDepartment')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingDepartment')}>
                                    {activeDepartList && activeDepartList.length > 0 && isEditingDepartment ? 
                                        <Select
                                            value={employeeInfo.departmentId}
                                            onChange={this.handleInputChange}
                                            name="departmentId"
                                            style={{ height: '26px', width: '200px', borderRadius: '3px' }}
                                        >
                                            {activeDepartList.map(department => (
                                                <MenuItem key={department.id} value={department.departmentId}>{department.departmentName}</MenuItem>
                                            ))}
                                        </Select> :
                                        employeeInfo.departmentName
                                    }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>직위</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingPosition')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingPosition')}>
                                        {isEditingPosition ? 
                                            <Select
                                                value={employeeInfo.positionId}
                                                onChange={this.handleInputChange}
                                                name="positionId"
                                                style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                            >
                                                <MenuItem value="BNK">사원</MenuItem>
                                                <MenuItem value="W6O">과장</MenuItem>
                                                <MenuItem value="45E">팀장</MenuItem>
                                                <MenuItem value="AW6">부장</MenuItem>
                                                <MenuItem value="BEN">사장</MenuItem>
                                            </Select> :
                                            employeeInfo.positionName
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>거주지</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingAddress')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingAddress')}>
                                        {isEditingAddress ? 
                                        <input
                                            type="text"
                                            id="employeeAddress"
                                            label="employeeAddress"
                                            placeholder=""
                                            value={this.state.employeeAddress}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value.length <= 10) {
                                                    this.handleInputChange(e);
                                                }
                                            }}
                                        />:
                                        employeeInfo.employeeAddress
                                    }
                                    </span>
                                </Tooltip>
                                <Button onClick={this.openAddressModal}>우편번호 찾기</Button>
                            </TableCell>
                        </TableRow>

                        {/* 다이얼로그 추가 */}
                        <Dialog open={isAddressModalOpen} onClose={this.closeAddressModal}>
                            <DialogTitle>우편번호 찾기</DialogTitle>
                            <DialogContent>
                                <DaumPostcode
                                    onComplete={this.handleComplete}
                                    autoClose
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeAddressModal}>닫기</Button>
                            </DialogActions>
                        </Dialog>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>입사일</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingeEployDate')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingeEployDate')}>
                                        {isEditingeEployDate ? 
                                            <input 
                                                type="date" 
                                                name="employDate"
                                                value={employeeInfo.employDate} 
                                                onChange={this.handleInputChange}
                                                style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                            /> :
                                            employeeInfo.employDate
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>Cell.</span></TableCell>
                            <TableCell className="custom-cell2"  style={{ position: 'fixed' }}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingTel')}>
                                        {isEditingTel ?
                                            <div>
                                                {/* <select
                                                    value={employeeInfo.employeeTel.substring(0, 3)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        this.setState(prevState => ({
                                                            employeeInfo: {
                                                                ...prevState.employeeInfo,
                                                                employeeTel: value + prevState.employeeInfo.employeeTel.substring(3)
                                                            }
                                                        }));
                                                    }}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginRight: '5px', padding: '5px' }}
                                                >
                                                    <option value="010">010</option>
                                                    <option value="010">011</option>
                                                </select> */}
                                                <input
                                                    type="text"
                                                    value={employeeInfo.employeeTel.substring(0, 3)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    employeeTel: value + prevState.employeeInfo.employeeTel.substring(3)
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="000"
                                                    maxLength={3}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginRight: '5px', padding: '5px' }}
                                                />
                                                -
                                                <input
                                                    type="text"
                                                    value={employeeInfo.employeeTel.substring(3, 7)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    employeeTel: prevState.employeeInfo.employeeTel.substring(0, 3) + value + prevState.employeeInfo.employeeTel.substring(7)
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="0000"
                                                    maxLength={4}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', margin: '0 5px', padding: '5px' }}
                                                />
                                                -
                                                <input
                                                    type="text"
                                                    value={employeeInfo.employeeTel.substring(7)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    employeeTel: prevState.employeeInfo.employeeTel.substring(0, 7) + value
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="0000"
                                                    maxLength={4}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginLeft: '5px', padding: '5px' }}
                                                />
                                            </div> :
                                            `${employeeInfo.employeeTel.substring(0, 3)}-${employeeInfo.employeeTel.substring(3, 7)}-${employeeInfo.employeeTel.substring(7)}`
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>Dir.</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingDir')} style={{ position:'fixed' }}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingDir')}>
                                        {isEditingDir ? 
                                            <div>
                                                {/* 앞에 3자리 */}
                                                <input 
                                                    type="text" 
                                                    name="extensionTelPrefix"
                                                    value={employeeInfo.extensionTel.substring(0, 3)} 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    extensionTel: value + prevState.employeeInfo.extensionTel.substring(3)
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="000"
                                                    maxLength={3}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', padding: '5px' }}
                                                /> -
                                                {/* 가운데 4자리 */}
                                                <input 
                                                    type="text" 
                                                    name="extensionTelMiddle"
                                                    value={employeeInfo.extensionTel.substring(3, 7)} 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    extensionTel: prevState.employeeInfo.extensionTel.substring(0, 3) + value + prevState.employeeInfo.extensionTel.substring(7)
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="0000"
                                                    maxLength={4}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', margin: '0 5px', padding: '5px' }}
                                                /> -
                                                {/* 뒤에 4자리 */}
                                                <input 
                                                    type="text" 
                                                    name="extensionTelLast"
                                                    value={employeeInfo.extensionTel.substring(7)} 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    extensionTel: prevState.employeeInfo.extensionTel.substring(0, 7) + value
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="0000"
                                                    maxLength={4}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginLeft: '5px', padding: '5px' }}
                                                />
                                            </div> :
                                            `${employeeInfo.extensionTel.substring(0, 3)}-${employeeInfo.extensionTel.substring(3, 7)}-${employeeInfo.extensionTel.substring(7)}`
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>생일</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingBirth')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingBirth')}>
                                        {isEditingBirth ? 
                                            <input 
                                                type="date" 
                                                name="employeeBirth"
                                                value={employeeInfo.employeeBirth} 
                                                onChange={this.handleInputChange}
                                                style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                            /> :
                                            employeeInfo.employeeBirth
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className='info-title'>퇴사여부</span>
                                <Checkbox
                                    checked={isEditingResigned}
                                    onChange={this.handleResignedChange}
                                    size="small"
                                />
                            </TableCell>
                            <TableCell className="custom-cell2">
                                {isEditingResigned ? 
                                    <input 
                                        type="text" 
                                        name="resignationDate"
                                        value={employeeInfo.resignationDate} 
                                        onChange={this.handleInputChange}
                                        placeholder="YYYY-MM-DD"
                                        style={{ height: '26px', width: '100px', borderRadius: '3px', padding: '5px' }}
                                    /> :
                                    employeeInfo.resignationDate
                                }
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#ccc', margin: '20px 10px' }}/>
                <br /><br />
                <div>
                    <input className="infoBtn" type="submit" value="저장" onClick={this.handleSaveClick} />
                    <input className="infoBtn" type="submit" value="취소" onClick={this.handleCancelClick} />
                </div>
            </div>
        );
    }
}

export default EditEmpl;

