import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './OrganizationChart.css';
import ApiService from '../../page_kang/mail/ApiService';
// import { Modal } from '@mui/material';
// import Modal from '@mui/material/Modal';
import Modal from 'react-modal';

function OrganizationChart(props) {
    const [popupActive, setPopupActive] = useState(false);
    const [isTableHeightExceeded, setIsTableHeightExceeded] = useState(false); // max-height를 초과하는지 여부 상태
    const [userInput, setUserInput] = useState('');
    const [searchLists, setSearchLists] = useState([]); // 검색 결과 목록을 담을 상태
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const history = useHistory();
    const tableRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.searchData('');
                setSearchLists(response.data);
            } catch (error) {
                console.error('검색 에러:', error);
            }
        };
    
        fetchData(); // 초기 렌더링 시에 데이터를 가져옴

        const checkTableHeight = () => {
            if (!tableRef.current) return; // 요소가 존재하지 않으면 종료

            if (tableRef.current.scrollHeight > tableRef.current.clientHeight) {
                setIsTableHeightExceeded(true);
            } else {
                setIsTableHeightExceeded(false);
            }
        };

        checkTableHeight(); // 컴포넌트가 마운트될 때 최초 한 번 실행하여 초기 상태 설정

        const handleResize = () => {
            checkTableHeight();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); 

    const getSearchData = async (e) => {
        setUserInput(e.target.value);
        try {
            // 검색 API를 호출하여 검색 결과를 가져옴
            const response = await ApiService.searchData(e.target.value);
            setSearchLists(response.data); // 검색 결과를 상태에 설정
        } catch (error) {
            console.error('검색 에러:', error);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

    const handleModalOpen = (e, item) => {
        setIsModalOpen(true);
        setSelectedEmployee(item);
        setModalPosition({
            x: e.clientX + 15,
            y: e.clientY
        });
    };
    
    // 모달 스타일
    const customStyles = {
        content: {
        top: '55%',
        left: '48%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '550px',
        height: '350px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        zIndex: '2000000000'
        },
        overlay: {
        backgroundColor: 'none', // 블러 효과 제거
        zIndex: '2000000000'
        }
    };
    

    return (
        <div className={`popup ${popupActive ? 'active' : ''}`} id="popup-1">
            <div className="overlay"></div>
            <div className="ocContent">
                <input onChange={getSearchData} type="text" placeholder="검색하기" className="search-input" autoFocus style={{padding: '5px'}} />
                <div className="scrollable-content">
                    <div className="table1" ref={tableRef}>
                        <hr className="popup-hr"/>
                        <ul className="tree">
                            {searchLists
                                .filter(item => item.resignationDate === 'N')
                                .map((item, index) => (
                                    <li key={index} className="parent" onClick={(e) => handleModalOpen(e, item)}>
                                        <details>
                                            <summary>
                                                <i className="fa-solid fa-user-tie">{item.employeeName} ({item.employeeId})</i>
                                            </summary>
                                        </details>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
                {isTableHeightExceeded && <div className="scrollbar"></div>}
            </div>
            {isModalOpen&&(
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
                style={customStyles}
                contentLabel="Example Modal"
                >
                <h2 style={{margin: '10px 10px'}}>직원 정보</h2>
                <div className='emplModal' >
                    <div className='modalLeft'>
                        <p><img className='emplImg' src={ '/profiles/' + selectedEmployee.employeeImg} alt="직원 사진" /></p>
                        <p><h3>{selectedEmployee.employeeName}</h3></p>
                    </div>
                    <div className='modalRight'>
                        <div>
                            <strong>부서</strong>
                            <strong>직위</strong>
                            <strong>이메일</strong>
                            <strong>내선번호</strong>
                        </div>
                        <div>
                            <p>{selectedEmployee.departmentName}</p>
                            <p>{selectedEmployee.positionName}</p>
                            <p>{selectedEmployee.employeeEmail}</p>
                            <p>{selectedEmployee.extensionTel}</p>
                        </div>
                    </div>

                </div>
            </Modal>
            )}
        </div>
    );
}

export default OrganizationChart;
