import React, { Component } from 'react';
import ApprApiService from './ApprApiService'
import styles from './ApprAll.module.css';
import Pagination from "./Pagination";
// import { IonIcon } from '@ionic/react';
// import { searchOutline } from 'ionicons/icons';

class CompletedAppr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeId: localStorage.getItem('LoginID'),
            completedList: [],    // 5. 리스트 데이터
            message: null,
            limit: 10,
            page: 1
        };
    }

    // 라이프사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    componentDidMount() {   
        this.reloadCompletedList();    // 1.
    }

    // 기안문서함 리스트(매개변수없음)
    reloadCompletedList = () => {
        ApprApiService.fetchCompletedList(this.state.employeeId)
            .then(res => {
                console.log('list : ', res.data); // 데이터 확인
                this.setState({
                    completedList: res.data
                });
            })
            .catch(err => {
                console.log('reloadCompletedList() Error!!', err);
            });
    }

    // 문서 상세페이지 확인
    detailAppr = (dto) => {
        console.log("Clicked apprNum:", dto.apprNum); // apprNum 값 확인
        console.log("Clicked employeeId:", dto.employeeId); // id 값 확인
        console.log("Clicked apprForm:", dto.apprForm);
    
        const values = {
            apprNum: dto.apprNum,
            employeeId: dto.employeeId,
            apprForm: dto.apprForm
        }
    
        window.localStorage.setItem("apprValue", JSON.stringify(values));
        if (dto.apprForm === "휴가신청") {
            this.props.history.push("/appr/CompletedApprDetail");
        } else if (dto.apprForm === "업무기안") {
            this.props.history.push("/appr/CompletedWorkDetail");
        }
    }
    
    // 페이지 함수
    handlePageChange = (pageNumber) => {
        this.setState({ page: pageNumber }, () => {
            this.reloadCompletedList(); // 페이지 번호 변경 시 데이터 다시 로드
        });
    };

    // 페이지 limit 함수
    handleLimitChange = (event) => {
        this.setState({ limit: Number(event.target.value) });
    };

    render() {

        // 페이지
        const { completedList, limit, page } = this.state;
        const offset = (page - 1) * limit;

        return (
            <div>
                <div className={styles.allListWrap}>       
                    <div className={styles.completedTitle}>
                        <sapn>결재 문서함</sapn>
                    </div>
                    {/* 페이지 리스트 설정 */}
                    <label className={styles.pageLimit}>
                        <select
                            type="number"
                            value={limit}
                            onChange={this.handleLimitChange}
                            style={{ float: 'right' }}
                        >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                        </select>
                    </label>
                    <div className={styles.completedTable}>
                        <table>
                            <thead>
                                <tr>
                                    <td>기안일</td>
                                    <td>완료일</td>
                                    <td>결재양식</td>
                                    <td>긴급</td>
                                    <td>제목</td>
                                    <td>기안자</td>
                                    <td>문서번호</td>
                                    <td>결재상태</td>
                                </tr>
                            </thead>
                            <tbody>
                                {completedList.slice(offset, offset + limit).map(dto => 
                                    <tr key={dto.apprNum} onClick={() => this.detailAppr(dto)}>
                                        <td> {dto.apprDate} </td> 
                                        <td> {dto.apprEnd} </td>    {/* finalChkDate 가져오기*/}
                                        <td> {dto.apprForm} </td>
                                        <td> 
                                            {dto.apprUrgent === 'y' ? (
                                                <button className={styles.emergencyIcon}>긴급</button>
                                            ) : null} 
                                            </td>
                                        <td> {dto.apprTitle} </td>
                                        <td> {dto.employeeId} </td>
                                        <td component="th" scope="completedList">SW00-00{dto.apprNum} </td>
                                        <td> 
                                            {dto.apprStatus >= 0 && dto.apprStatus <= 2 ? (
                                                <button className={styles.ongoingIcon}>진행중</button>
                                            ) : dto.apprStatus === 3 ? (
                                                <button className={styles.completedBtn}>완료</button>
                                            ) : (
                                                <button className={styles.rejectBtn}>반려</button>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* 페이징처리 */}
                    <div className='apprPaging'>
                        <Pagination
                            total={completedList.length}
                            limit={limit}
                            page={page}
                            setPage={this.handlePageChange}
                        />
                    </div>
                </div>

                </div>
        );
    }
}

export default CompletedAppr;
