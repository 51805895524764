import React, { Component } from 'react';
import ApiService from "./AttentionApiService"
import {Table, TableHead, TableRow, TableCell, TableBody, Button} from '@mui/material';

import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";



class MyAnnual extends Component {

    // this.state 와 setState 이거쓰면 반응이 늦게나옴
    setData = {
        employee_id: window.localStorage.getItem("LoginID"),
        thisYear: moment(new Date()).startOf('year'),
        start: moment(new Date()).startOf('year').format('YYYY-MM-DD'),
        end: moment(new Date()).endOf('year').format('YYYY-MM-DD')
    }

    
    constructor(props) {
        super(props);

        this.state = {
            employee_id: window.localStorage.getItem("LoginID"),
            statecheck : '',
            annualData : []
        }

        
    }

    


    componentDidMount() {
        this.loadMyAttend(this.setData)

    }


    setItem = () => {
        this.setData.start = moment(this.setData.thisYear).startOf('year').format('YYYY-MM-DD');
        this.setData.end = moment(this.setData.thisYear).endOf('year').format('YYYY-MM-DD');
    }

    decrement() {
        this.setData.thisYear.add(-1, 'years');
        
        this.setItem();

        this.loadMyAttend(this.setData)
    }

    increment() {
        
        this.setData.thisYear.add(1, 'years');

        this.setItem();



        // 데이터 로드하기 전까지는 이거 활성화시켜서 render 시켜야함 실제 데이터는 바뀌는데 화면단이 안바뀜
        /*
        this.setState({
            statecheck: '1'
        })
        */

        this.loadMyAttend(this.setData)
    }


    loadMyAttend = (data) => {


        ApiService.getMyAttend(data)  // 2.
        .then(res => {             // 4.
            
            //console.log('내 연가 요청');
            //console.log(res.data);

            this.setState({
                annualData: res.data
            });
        })
        .catch(err => {
            console.log('loadMyAttend() Error!', err);
        })

        
        
    }
  
    

    render() {

        return (
            <div style={{ alignItems: 'center', justifyContent: 'space-between', padding: '50px 10px 25px 10px', width: '100%' }}>
                
                <h2 style={{textAlign:'center'}}>내 연가 현황 </h2>
                <br/>

                <h1 style={{textAlign:'center'}}>
                
                <Button onClick={this.decrement.bind(this)}>
                    <FontAwesomeIcon icon={faChevronLeft}  />
                </Button>

                {this.setData.thisYear.format('YYYY')}
                
                <Button onClick={this.increment.bind(this)} >
                <FontAwesomeIcon icon={faChevronRight} />
                </Button>
                </h1>

                <br/><br/>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="15%"> 아이디 </TableCell>
                            <TableCell width="10%"> 종류 </TableCell>
                            <TableCell width="15%"> 시작일 </TableCell>
                            <TableCell width="15%"> 종료일 </TableCell>
                            <TableCell width="10%"> 사용연차 </TableCell>
                            <TableCell width="10%"> 남은연차 </TableCell>
                            <TableCell width="15%"> 등록일 </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {/* 
                            map은 Script나 React의 반복문 
                            map(item => ... )   또는
                            map((item, idx) => ...)
                            
                        */}
                        {this.state.annualData.map(dto => {



                            return (
                            <TableRow key={dto.vac_num}>
                                <TableCell width="15%">{dto.employee_id} </TableCell>
                                <TableCell width="10%">{dto.vac_type} </TableCell>
                                <TableCell width="15%">{dto.vac_sdate} </TableCell>
                                <TableCell width="15%">{dto.vac_edate} </TableCell>                                
                                <TableCell width="10%">{dto.vac_use} </TableCell>
                                <TableCell width="10%">{dto.vac_remain} </TableCell>
                                <TableCell width="15%">{dto.vac_rdate} </TableCell>
                            </TableRow>
                            )
                            
                        }

                            )}
                    </TableBody>
                </Table>   
                    

            </div>

        );

    }

}

export default MyAnnual;

