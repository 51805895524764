import React, { Component } from "react";
import { Button,Typography, TextField } from "@mui/material";
import BoardService from "./BoardService";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import { withRouter } from 'react-router-dom';  // 목록버튼 때문에 추가
import htmlparser from 'html-react-parser';
import { Link } from 'react-router-dom';


class Noticedetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authority_id: localStorage.getItem('LoginID'),
            notice_no: '',
            notice_title: '',
            notice_content: '',
            notice_readcnt: '',
            notice_date: ''
        }
    }

    componentDidMount() {
        this.loadNotice();   // 공지사항 상세페이지
        
    }

    // 조회수 증가 메서드
    increaseReadCount = async () => {
        const { notice_no } = this.state;
        console.log('increaseReadCount 호출!!', notice_no);
    
        // 게시물 ID를 로컬 스토리지에서 가져옵니다.
        const visitedPosts = JSON.parse(localStorage.getItem('visitedPosts')) || [];
    
        // 현재 게시물이 이미 조회된 게시물인지 확인합니다.
        const isVisited = visitedPosts.includes(notice_no);
    
        if (!isVisited) {
            try {
                // 조회수 증가 요청을 보냅니다.
                const res = await BoardService.increaseReadCount(notice_no);
                console.log('increaseReadCount 성공 : ', res.data);
    
                // 조회된 게시물의 ID를 로컬 스토리지에 추가합니다.
                localStorage.setItem('visitedPosts', JSON.stringify([...visitedPosts, notice_no]));
            } catch (err) {
                console.log('increaseReadCount() Error !!', err);
            }
        } else {
            console.log('이미 조회된 게시물입니다.');
        }
    }

// 상세페이지
loadNotice = () => {
    BoardService.NoticeByID(this.props.match.params.notice_no)
        .then(res => {
            let list = res.data;
            this.setState({
                notice_no: list.notice_no,
                employee_id: list.employee_id,
                notice_title: list.notice_title,
                notice_content: list.notice_content,
                notice_readcnt: list.notice_readcnt,
                notice_date: list.notice_date
            });

             
            
        })
        .catch(err => {
            console.log('loadNotice() Error !!', err);
        });
}

   
     // 화면에서 수정한 값으로 state 변경
     onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        });
    }


    render() {

        return (
            // 공지사항 제목
            <Container>
                <Paper elevation={3} sx={{ marginTop: '20px' }}>
                    <Box padding={2}>
                        <Box sx={{textAlign:"center"}}>
                            <Typography variant="h6">
                            {this.state.notice_title}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>

                <Paper elevation={3}>
                        <Box sx={{marginTop:"10px"}}>
                            <Toolbar sx={{ justifyContent: 'flex-end'}}>
                                <Typography variant="body2">
                                {this.state.authority_id}
                                </Typography>
                                
                                &nbsp;&nbsp;

                                <Typography variant="body2">
                                    {this.state.notice_date}  
                                </Typography>

                                &nbsp;&nbsp;
                                
                                <Typography variant="body2">
                                조회:{this.state.notice_readcnt}  
                                </Typography>

                            </Toolbar>

                            <Divider/>
                                <Box padding={2} sx={{minHeight:"500px"}}>
                                 {/* 콘텐츠 부분 <P>내용<P> <P> 부분 안보이게 해줌 */}
                                      {htmlparser(this.state.notice_content)}
                                </Box>
                        <Divider/>
                        
                       

                    </Box>
                </Paper>

            <Paper elevation={3}>
                <Box sx={{marginTop:"10px"}}>
                    <Toolbar sx={{ justifyContent: 'flex-end'}}>
                        <Button component={Link} to="/board/notice">목록</Button>
                    </Toolbar>
                </Box>
            </Paper>
               
            </Container>

        );
    }
}

export default withRouter(Noticedetail);
