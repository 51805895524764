import React, { Component} from 'react';
import styles from './WriteAppr.module.css';
import ApprMain from './ApprMain';
import ApprApiService from './ApprApiService';
import { Link, Route } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { createOutline, closeCircleOutline, menuOutline, exitOutline } from 'ionicons/icons';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField } from '@mui/material'
import OpinionModal from './OpinionModal';
import RejectModal from './RejectModal';
import ProgressAppr from './ProgressAppr';


class ApprDetail extends Component {

    // 생성자
    constructor(props) {
        super(props);

        // 내가 입력한 값 들어오는곳
        this. state = { // 해당 변수들은 onChange로 인해 입력한 값으로 변경된 상태
          isModalOpen: false, // 모달 열기/닫기 상태
          //userInfo: [],
          apprNum: '',
          employeeId: localStorage.getItem('LoginID'),
          //apprForm: '',
          apprDate: new Date(),
          //apprUrgent: '',
          apprTitle: '',
          //supportDept: '',
          apprContent: '',
          apprLeaveType: '',
          apprHalfType: '',
          apprUnusedLeave: '',
          apprReqLeave: '',
          apprStart: new Date(),
          apprEnd: new Date(),
          apprStatus: '',
          apprReason : '',
          selectedDays: 0,
        }

        // 모달 값 전송
        this.handleApprovalClick = this.handleApprovalClick.bind(this); 
        this.handleRejectClick = this.handleRejectClick.bind(this);
        this.closeModal = this.closeModal.bind(this);

    }

    // 기안자 정보 가져오기
    // 라이프사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    componentDidMount() {   
        this.reloadDetail();  
    }

    // 결재문서읽어오기
    reloadDetail = () => {
        const apprValueString = window.localStorage.getItem('apprValue');

        const apprValue = JSON.parse(apprValueString);

        ApprApiService.apprDetail(apprValue.apprNum, apprValue.employeeId)
        
            .then(res => {
                console.log('API 응답:', res);
                let loadDetail = res.data;
                this.setState({
                    apprNum: loadDetail.apprNum,
                    employeeId: loadDetail.employeeId,
                    apprTitle: loadDetail.apprTitle,
                    apprDate: new Date(loadDetail.apprDate),
                    apprContent: loadDetail.apprContent,
                    apprLeaveType: loadDetail.apprLeaveType,
                    apprHalfType: loadDetail.apprHalfType,
                    apprUnusedLeave: loadDetail.apprUnusedLeave,
                    apprReqLeave: loadDetail.apprReqLeave,
                    apprStart: new Date(loadDetail.apprStart),
                    apprEnd: new Date(loadDetail.apprEnd),
                    apprStatus: loadDetail.apprStatus,
                    selectedDays: 0,
                })
            })
            .catch(err => {
                console.log('reloadDetail() Error!!', err);
            });
    }

    // 선택일수 값 가져오는 함수
    handleSelectedDaysChange = (event) => {
      const value = event.target.value;
      // 입력된 값이 숫자인지 확인하고 유효한 경우에만 selectedDays를 업데이트합니다.
      if (!isNaN(value)) {
        this.setState({ selectedDays: value });
      }
    };
    
    // e는 event의 줄임말
    onChange = (e) => { // 1. 입력한 값으로 변경
      this.setState({
        // 각각의 name을 각각의 value로 덮어쓰기
        [e.target.name] : e.target.value
      });
    }

    // 목록으로 돌아가기
    handleListClick = () => {
        this.props.history.push('/appr/ProgressAppr');
    }

    // 결재 모달 열기
    handleApprovalClick() {
        // 결재 버튼을 눌렀을 때만 모달을 열도록 함
        this.setState({ isModalOpen: true });
        console.log("상태 : 모달 open");

        // handleApproval 함수 호출
        // this.handleApproval(this.state.apprNum, this.state.apprReason);
    }

    // 결재 버튼 클릭 시
    handleApproval = (apprNum, apprReason, apprStatus) => {
        console.log("결재 처리 함수 호출");
        console.log("결재 문서 번호:", apprNum);
        console.log("결재 의견:", apprReason);
        console.log("결재 상태:", apprStatus);
        this.setState({ apprNum, apprReason, apprStatus });
    };

    // 반려 모달 열기
    handleRejectClick() {
        this.setState({ isRejectModalOpen: true });
    }

    // 모달 닫기
    closeModal() {
        this.setState({ isModalOpen: false, isRejectModalOpen: false });
    }

      
    render() {

      // 모달
      const { isModalOpen, isRejectModalOpen } = this.state;

      // 날짜 형식 바꾸기 
      const days = ['일', '월', '화', '수', '목', '금', '토'];
      const date = new Date(this.state.apprDate);
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} (${days[date.getDay()]})`;

        
        return (
            
            <div>
            <div className={styles.formTitle}>휴가신청서</div>
            <div className={styles['nav-wrap']}>
                <div className={styles['button-top']}>

                    {/* 결재버튼 */}
                    <OpinionModal 
                        isOpen={this.state.isModalOpen}
                        closeModal={this.closeModal}
                        apprNum={this.state.apprNum}
                        apprStatus={this.state.apprStatus}
                        handleApproval={this.handleApproval}
                    />
                    <button type="button" className={styles.btn} onClick={this.handleApprovalClick}>
                        <div className={styles.icon}><IonIcon icon={createOutline} /></div>
                        결재
                    </button>

                    {/* 반려버튼 */}
                    <RejectModal isOpen={this.state.isRejectModalOpen} closeModal={this.closeModal} />
                    <button type="button" className={styles.btn} onClick={this.handleRejectClick}>
                        <div className={styles.icon}><IonIcon icon={exitOutline} /></div>
                        <Link to="/appr/ProgressAppr">반려</Link>
                    </button>

                    {/* 목록버튼 */}
                    <button type="button" className={styles.btn} onClick={this.handleListClick}>
                        <div className={styles.icon}><IonIcon icon={menuOutline} /></div>
                        <Link to="/appr/ProgressAppr">목록</Link>
                    </button>
                </div> {/* 상단 버튼 끝 */}

                <div className={styles['inner-wrap']}>
                    {/* <div className={styles['right-wrap']}> */}
                        <div className={styles.wrap}>
                          <div className={styles.formContent}>
                            <div className='leave-form'>
                            <div>
                            <div className="wrap_container">
                              {/* 3단 레이아웃 멀티클래스 */}
                                {/*전자결제 상세*/}
                                <div className="report_type approval_type">{/*전자결재 내용*/}
                                  <div className="approval_import ie9-scroll-fix">
                                    {/* 문서 내용 표시 테스트 */}
                                  </div>
                                </div>
                            </div>
                            </div>

                            {/* test */}

                            <div className='test'>
                                

                            <div className='apprNum'> 
                                <TextField
                                    sx={{ width: '50%' }}
                                    id="standard-required"
                                    variant="standard"
                                    label="apprNum"
                                    type="text"
                                    name="apprNum"
                                    value={this.state.apprNum}
                                    placeholder="문서번호"
                                    readOnly
                                />
                            </div> 

                    <div className='apprDate'> 
                        <TextField
                            sx={{ width: '50%' }}
                            id="standard-required"
                            variant="standard"
                            label="apprDate"
                            type="text"
                            name="apprDate"
                            value={formattedDate}
                            placeholder="기안일"
                            readOnly
                        />
                    </div>

                    <div className='apprTitle'> 
                        <TextField
                            sx={{ width: '50%' }}
                            id="standard-required"
                            variant="standard"
                            label="apprTitle"
                            type="text"
                            name="apprTitle"
                            value={this.state.apprTitle}
                            placeholder="제목"
                            readOnly
                        />
                    </div>

                    <div className='apprContent'> 
                        <TextField
                            sx={{ width: '95%' }}
                            id="standard-required"
                            variant="standard"
                            label="apprContent"
                            type="text"
                            name="apprContent"
                            value={this.state.apprContent}
                            placeholder="내용"
                            readOnly
                        />
                    </div>

                    <div className='apprLeaveType'> 
                        <TextField
                            sx={{ width: '50%' }}
                            id="standard-required"
                            variant="standard"
                            label="apprLeaveType"
                            type="text"
                            name="apprLeaveType"
                            value={this.state.apprLeaveType}
                            placeholder="연차종류"
                            readOnly
                        />
                    </div>

                    <div className='apprHalfType'> 
                        <TextField
                            sx={{ width: '50%' }}
                            id="standard-required"
                            variant="standard"
                            label="apprHalfType"
                            type="text"
                            name="apprHalfType"
                            value={this.state.apprHalfType}
                            placeholder="반차종류"
                            readOnly
                        />
                    </div>

                    <div className='apprUnusedLeave'> 
                        <TextField
                            sx={{ width: '50%' }}
                            id="standard-required"
                            variant="standard"
                            label="apprUnusedLeave"
                            type="text"
                            name="apprUnusedLeave"
                            value={this.state.apprUnusedLeave}
                            placeholder="잔여연차"
                            readOnly
                        />
                    </div>

                    <div className='apprReqLeave'> 
                        <TextField
                            sx={{ width: '50%' }}
                            id="standard-required"
                            variant="standard"
                            label="apprReqLeave"
                            type="text"
                            name="apprReqLeave"
                            value={this.state.apprReqLeave}
                            placeholder="신청연차"
                            readOnly
                        />
                    </div>

                    <div>
    <span>시작일: {this.state.apprStart.toLocaleDateString()}</span>
    &nbsp; ~ &nbsp;
    <span>종료일: {this.state.apprEnd.toLocaleDateString()}</span>
</div>

                    <div className='apprStatus'> 
                        <TextField
                            sx={{ width: '50%' }}
                            id="standard-required"
                            variant="standard"
                            label="apprStatus"
                            type="text"
                            name="apprStatus"
                            value={this.state.apprStatus}
                            placeholder="결재상태"
                            readOnly
                        />
                    </div>

                            </div>

                            {/* test */}
                                    
                                <div className={styles.file}>
                                    <ul>
                                    <li>파일첨부</li>
                                    </ul>
                                    <div className={styles['file-add']}>
                                    <ul>
                                        <li><ion-icon name="attach-outline"></ion-icon><span>파일선택</span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                     {/* </div> right-wrap */}
                    {/* <div className={styles['right-menu']}>
                        <div className={styles['top-button']}>
                            <ul className={styles['tab_nav']}>
                                <li id="tab_1" className={styles.first}><span>결재선</span></li>
                            </ul>
                        </div>
                        <div className={styles['profile1']}>
                            <img src="${path}/Approval/profile.jpg" />
                            <ul>
                                <li>김상후 대표이사</li>
                                <li>결재대기</li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className='right'>  
                <Route path="/appr/" exact={true} component={ApprMain}></Route>
                <Route path="/appr/ProgressAppr'" exact={true} component={ProgressAppr}></Route>
            </div>
        </div>
        </div>
        );
    }
}


export default ApprDetail;
