import styled, { keyframes } from "styled-components";
import Bar from "./header/Bar";
import Menu from "./header/Menu";
import { useState, useRef, useEffect } from "react";
import {debounce} from 'lodash';
import Popup from "./page_yoo/employee/OrganizationChart";

const Container = styled.div`
  width: ${({ containerwidth }) => containerwidth}px;
  height: 100%;
`;

const Close = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #25BAD6;
  border: 0;
  padding: 22px;
  z-index: 10;
  cursor: pointer;
`;

const Outside = styled.div`
  width: 25vw;
  min-width: 200px;
  max-width: 250px;
  height: 100vh;
  transform: translateX(-101%);
  transition: all 0.5s cubic-bezier(0, 0.05, 0, 1.3);
  overflow: hidden; 

  &.in {
    transform: translateX(0);
    transition: 0;
  }

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0 100% 100% 0;
    transition: all 0.25s cubic-bezier(1, 0.05, 1, 1.5);
    transition-delay: 0.5s;
    background-color: #150187;
    overflow: hidden; 
  }

  &.in > div {
    border-radius: 0;
    transition-delay: 0s;
  }

`;

export default function Header() {
  const [isActive, setActive] = useState(true);
  const [containerwidth, setContainerWidth] = useState(50); // 초기값 설정
  const menuRef = useRef(null); // Menu 컴포넌트의 ref 생성
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // 여기부분 수정했습니다 2024-03-14 15:35
  useEffect(() => {
    // 컴포넌트가 마운트될 때와 메뉴의 너비가 변경될 때 containerWidth를 설정합니다.
    if (menuRef.current) {
      const width = menuRef.current.offsetWidth;
      setContainerWidth(isActive ? width : 50);
    }
    window.addEventListener('resize', setHeaderWidth);

    return () => {
      window.removeEventListener('resize', setHeaderWidth);
    }
  }, [isActive]);

  // debounce는 이 함수를 여러번 호출 했을 때 가장 마지막꺼 호출함
  const setHeaderWidth = debounce(() => {
    if (menuRef.current) {
      const width = menuRef.current.offsetWidth;
      setContainerWidth(isActive ? width : 50);
    }
  }, 100);  // 여기 뒤에가 실행 주기

  const handleClick = () => {
    setActive(!isActive);
  };

  // 팝업 열기 함수
  const openPopup = () => {
    setIsPopupOpen(true);
    if(isPopupOpen === true){
      setIsPopupOpen(false);
    }
  }

  return (
    <>
      <Container containerwidth={containerwidth}> 
      <div style={{position: 'absolute', top: '0'}}>
        <Close onClick={handleClick}>
          <Bar isActive={isActive} />
        </Close>
        <Outside className={isActive ? "in" : null}>
          <div className="inside" ref={menuRef}> {/* Menu 컴포넌트의 ref 설정 */}
            <Menu isActive={isActive} openPopup={openPopup}/>

          {/* 팝업 */}
          </div>
        </Outside>
          {isPopupOpen && <Popup />}
        </div>
      </Container>
    </>
  );
}
