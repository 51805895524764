import React from 'react';
import {Route} from 'react-router-dom'
import ComponentTest from './ComponentTest';
import ComponentTest2 from './mainComponent';
// 여기 있는 css를 경로에 맞춰서 가져오시면 됩니다. 아니면 route폴더내 css를 통으로 가져가셔도 좋습니다.
import './SubHeaderLayout.css'


const RouteTemplet = () => {

    return (
        <div style={style}>
        
            
            
            <div className='main'>  {/* 공통 레이아웃 */}
                <div className='left'> {/* 공통 레이아웃 - 좌측 소메뉴 영역*/}
                    <div className='sub_Header'> 
                        <div className='title'>
                            <h1> 라우터 템플릿입니다. </h1>
                        </div>

                        <div>
                            <p> 테스트 </p>
                            <p> 테스트 </p>
                            
                        </div>
                    </div>
                </div>
                    <div className='right'> {/* 공통 레이아웃 - 우측 소메뉴 영역*/}
                        {/* 2024-03-05 18:51 부터 BrowserRouter 부분 사라졌습니다. */}
                        {/* 이 부분에서 /test/ 는 초기 페이지의 컴포넌트를 넣어주시고 */}
                        <Route path="/test/" exact={true} component={ComponentTest}></Route>
                        {/* 여기부터 소메뉴 링크 들어갔을 때 각각의 컴포넌트를 표시하도록 유도해주세요 */}
                        <Route path="/test/1" exact={true} component={ComponentTest}></Route>
                        {/* <Route path="/test/2" exact={true} component={ComponentTest2}></Route> */}
                    </div>
                
                
            </div>
        
        </div>
    );
}

const style = {

}

export default RouteTemplet;