import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import {Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './AddVac.css'
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import OrganizationChart from './IdListPop.js';
import ApiService from "../Attention/AttentionApiService.js"
import AttendStatus from '../Attention/AttendStatus.js';


function transHoursAndMinutes(seconds) {
	if (seconds < 61) {
    	return '00:00:' + addZero(seconds)
	}
	// sec
	var hours = Math.floor(seconds/3600)
	var mins = Math.floor((seconds - hours*3600)/60)
	var secs = seconds - hours*3600 - mins*60
	return addZero(hours) + ':' + addZero(mins) + ':' + addZero(secs)
    
	function addZero(num) {
		return ((num < 10) ? '0' : '') + num
	}
}

class AttendList extends Component {
    
    setData = {
        employee_id: '',
        thisMonth: moment(new Date()).startOf('month'),
        att_stime: moment(new Date()).startOf('month').startOf('isoWeek'),
        att_etime: moment(new Date()).endOf('month').endOf('isoWeek')
    }
    
    weekOfMonth = (m) => m.isoWeek() - moment(m).startOf('month').isoWeek();

    thisWeekVal = this.weekOfMonth(moment())

    constructor(props) {
        super(props);
        console.log(props)
        this.state = {

            employee_id : '',
            thisMonth : '',
            att_stime: '',
            att_etime: '',
            expanded: 'panel' + this.thisWeekVal,
            monthData : [],
            isPopupActive: false,

        };

    }
    componentDidMount() {

    }
    
    setItem = () => {
        this.setData.att_stime = moment(this.setData.thisMonth).startOf('month').startOf('isoWeek')
        this.setData.att_etime = moment(this.setData.thisMonth).endOf('month').endOf('isoWeek')
    }

    decrement() {
        this.setData.thisMonth.add(-1, 'month');
        
        this.setItem();

        if(this.state.employee_id != '')
            this.loadAttendMonth(this.setData)
    }

    increment() {
        
        this.setData.thisMonth.add(1, 'month');

        this.setItem();



        // 데이터 로드하기 전까지는 이거 활성화시켜서 render 시켜야함 실제 데이터는 바뀌는데 화면단이 안바뀜
        /*
        this.setState({
            statecheck: '1'
        })
        */

        if(this.state.employee_id != '')
            this.loadAttendMonth(this.setData)
    }  

    thisWeek = [];
    firstCheck = true;
    
    weeks = [
        {
            week: new Array,
        },
        {
            week: new Array,
        },
        {
            week: new Array,
        },
        {
            week: new Array,
        },
        {
            week: new Array,
        }
    ]
    ;

    resetWeeks = () => {
        this.weeks = [
            {
                week: new Array,
},
            {
                week: new Array,
            },
            {
                week: new Array,
            },
            {
                week: new Array,
            },
            {
                week: new Array,
            }
        ]
        ;
    }

    loadAttendMonth = (data) => {

        this.resetWeeks();

        ApiService.getAttendMonth(data)  // 2.
        .then(res => {             // 4.
            
            //console.log('내 근태 요청');
            //console.log(res.data);

            this.setState({
                monthData: res.data
            });

            res.data.map(dto => {

                
                if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(1, 'week').format('YYYYMMDD')) {
                    //console.log("1주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[0].week.push(dto);
                }
                    
                else if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(2, 'week').format('YYYYMMDD')) {
                    //console.log("2주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[1].week.push(dto);
                }
                else if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(3, 'week').format('YYYYMMDD')) {
                    //console.log("3주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[2].week.push(dto);
                }
                else if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(4, 'week').format('YYYYMMDD')) {
                    //console.log("4주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[3].week.push(dto);
                }
                    
                else if(moment(dto.att_stime).format('YYYYMMDD') < moment(data.att_stime).add(5, 'week').format('YYYYMMDD')) {
                    //console.log("5주차" + moment(dto.att_stime).format('YYYYMMDD'));
                    this.weeks[4].week.push(dto);
                }
                //else console.log("asd2");
            });

            if(this.firstCheck) {
                this.thisWeek = this.weeks[this.thisWeekVal].week;
                this.setData.thisMonth= moment(new Date()).startOf('month')
                this.setItem();
                this.firstCheck = false;
            }
        })
        .catch(err => {
            console.log('reloadAttendList() Error!', err);
        })

        
        
    }

    setExpanded = (v) => {
        this.setState({
            expanded: v
        })
    }

    handleChange = (panel) => (event, isExpanded) => {
        this.setExpanded(isExpanded ? panel : false);
    };

    
    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isPopupActive: !prevState.isPopupActive }));
    }

    // 팝업을 닫는 함수
    handleClosePopup = () => {
        this.setState({ isPopupActive: false });
    }

    // 조직도 선택 시 호출되는 함수
    handleEmployeeIdSelect = (employeeId) => {
        
        this.setData.employee_id = employeeId
        if(this.setData.employee_id != ''){
            this.firstCheck = true;
            this.loadAttendMonth(this.setData)
            
        }
        
        this.setState({
                employee_id: employeeId
        });
    };



    render() {

        let weeks = [0, 1, 2, ,3, 4]

        let sum = [0, 0, 0, 0, 0]
    
        return (
            <div style={{ alignItems: 'center', justifyContent: 'space-between', padding: '50px 50px 25px 50px', width: '100%' }}>
                <h1 style={{ textAlign: 'left', margin: 0 }}>
                    근태 조회
                </h1>
                <br/>
                <div style={{margin: 'auto', paddingLeft: '50px'}}>
   
                    
                    <div>
                    <br/>
                        <label className="hlabel-att-A"> 대상 </label>
                        <input
                            id="standard-required"
                            variant="standard"
                            label="toId"
                            className=""
                            type="text"
                            name="attArr"
                            value={this.state.employee_id}
                            readOnly // 읽기 전용으로 설정
                            onChange={this.onChangeHandler}
                        />
                        
                            <div style={{display:'inline'}}>
                                <div style={{display:'inline'}}>
                                <div className="hicon-container1" style={{display: 'inline-block', alignItems:'center'}}>
                                    <IonIcon icon={addOutline}
                                            onClick={this.togglePopup}
                                    />
                                </div>
                                </div>
                                <label className="hatt-search" onClick={this.togglePopup}> 조직도 선택</label>
                                {this.state.isPopupActive && <OrganizationChart handleClosePopup={this.handleClosePopup} onEmployeeIdSelect={this.handleEmployeeIdSelect} />}
                            </div>
                        
                        

                    </div>

                </div>

                <h1 style={{textAlign:'center'}}>
                
                <Button onClick={this.decrement.bind(this)}>
                    <FontAwesomeIcon icon={faChevronLeft}  />
                </Button>

                {this.setData.thisMonth.format('YYYY.MM')}
                
                <Button onClick={this.increment.bind(this)} >
                <FontAwesomeIcon icon={faChevronRight} />
                </Button>
                </h1>

                <br/><br/>

                {
                    this.state.employee_id != '' &&
                <AttendStatus attend_weekData={this.thisWeek} attend_MonthData={this.weeks} employee_id={this.state.employee_id}/>
                }
                    
                {
                        weeks.map( i => {

                            return (
                                <div>
                                    
                                    <Accordion expanded={this.state.expanded === 'panel' + i} onChange={this.handleChange('panel' + i)} sx={{borderBottom:'1px solid grey'}}>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={"panel" + i +"bh-content"}
                                        id={"panel" + i + "bh-header"}
                                        >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            {i + 1}  주차
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', margin: 'auto', alignItems: 'flex-end'}}> {
                                        
                                        this.state.employee_id != '' &&
                                        this.weeks[i].week.map(dto => {

                                            if(dto.att_etime != null)
                                                sum[i] += moment(dto.att_etime).diff(moment(dto.att_stime), "s");

                                            }  )} 주간 누적 근무 시간 : {transHoursAndMinutes(sum[i])}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell> 아이디 </TableCell>
                                                                <TableCell> 출근시간 </TableCell>
                                                                <TableCell> 퇴근시간 </TableCell>
                                                                <TableCell> 근무시간 </TableCell>
                                                                <TableCell> 결과 </TableCell>
                                                                <TableCell> 지각여부 </TableCell>
                                                                <TableCell> 등록일 </TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                        {
                                                            this.state.employee_id != '' &&
                                                            this.weeks[i].week.map(dto => {
                            
                                                                return (
                                                                    <TableRow key={dto.att_num}>
                                                                        <TableCell width="15%">{dto.employee_id} </TableCell>
                                                                        <TableCell width="15%">{dto.att_stime != null && moment(dto.att_stime).format('YYYY-MM-DD HH:mm')} </TableCell>
                                                                        <TableCell width="15%">{dto.att_etime != null && moment(dto.att_etime).format('YYYY-MM-DD HH:mm')} </TableCell>                                
                                                                        <TableCell width="10%">{dto.att_etime != null && transHoursAndMinutes(moment(dto.att_etime).diff(moment(dto.att_stime), "s"))} </TableCell>
                                                                        <TableCell width="10%">{dto.att_state} </TableCell>
                                                                        <TableCell width="5%">{dto.att_result} </TableCell>
                                                                        <TableCell width="10%">{dto.att_rdate} </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }

                                                        </TableBody>

                                                    </Table>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>   
                                </div>
                            );
                            }
                        )
                    }

            </div>
            
        );
    } 
}

export default AttendList;
