import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../mail/ApiService';
import { Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { mailOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 메인페이지의 로그인된 사원의 받은메일함 미니페이지 */

const StyledTableWrap = styled.div`
  padding: 8px; /* 원하는 패딩 값으로 설정 */
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding-bottom: 10px;
  display: flex;
`;

const StyledIonIcon = styled(IonIcon)`
  font-size: 20px;
  color: black;
  margin-right: 6px;
`;

function MiniMailList(props) {
    const [mails, setMails] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await ApiService.ToList(props.employee_id);
                setMails(res.data); // 데이터 설정
            } catch (error) {
                console.error('검색 에러:', error);
            }
        };

        fetchData();

    }, [props.employee_id]);

    // 메일 확인(세부페이지)
    const detailMail = (mailNo) => {
        const values = {
            emailNO: mailNo,
            id: props.employee_id
        }

        window.localStorage.setItem("emailValue", JSON.stringify(values));
    };

    // 날짜 형식 변환 함수
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); 
    }

    return (
        <StyledTableWrap>
        <Table>
            <TableHead>
                <TableRow style={{borderBottom: "1px solid lightgrey"}}>
                <StyledTitle><StyledIonIcon icon={ mailOutline } /> 최신메일</StyledTitle>
                </TableRow>
            </TableHead>
            <TableBody>
                {mails.length > 0 ? (
                    mails.slice(0, 5).map(dto => (
                        <TableRow key={dto.emailNo} style={{ textAlign: "center" }}>
                            <Link to={`/mail/detailTo/`} onClick={() => detailMail(dto.emailNo)} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <TableCell style={{ width: "20%" }}>{dto.fromId}</TableCell>
                                <TableCell style={{ width: "60%" }}>{dto.emailTitle}</TableCell>
                                <TableCell style={{ width: "20%" }}>{formatDate(dto.sendDate)}</TableCell>
                            </Link>
                        </TableRow>
                    ))
                ) : (
                    <div>메일이 없습니다.</div>
                )}
            </TableBody>
        </Table>
        </StyledTableWrap>
    );
}

export default MiniMailList;
