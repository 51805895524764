import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ApiService from '../../page_kim/approval/ApprApiService';
import { Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { createOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 메인페이지의 로그인된 사원의 전자결재 대기함 미니페이지 */

const StyledTableWrap = styled.div`
  padding: 8px; /* 원하는 패딩 값으로 설정 */
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding-bottom: 10px;
  display: flex;
`;

const StyledIonIcon = styled(IonIcon)`
  font-size: 20px;
  color: black;
  margin-right: 5px;
`;

function MiniApprList(props) {
    const [Appr, setAppr] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await ApiService.fetchProgressList(props.employee_id);
                setAppr(res.data); // 데이터 설정
            } catch (error) {
                console.error('검색 에러:', error);
            }
        };

        fetchData();

    }, [props.employee_id]);

    // 메일 확인(세부페이지)
    const detailAppr = (apprNum, apprForm) => {
        console.log("Employee ID:", props.employee_id);
        console.log("Appr Form:", apprForm);

        const values = {
            apprNum: apprNum,
            apprForm: apprForm,
            employeeId: props.employee_id
        }

        window.localStorage.setItem("apprValue", JSON.stringify(values));
        setTimeout(() => {
            if (apprForm === "휴가신청") {
                props.history.push("/appr/leaveDetail");
            } else if (apprForm === "업무기안") {
                props.history.push("/appr/WorkDetail");
            }
        }, 0); // 비동기로 처리
    };

    // 날짜 형식 변환 함수
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); 
    }

    return (
        <StyledTableWrap>
        <Table >
            <TableHead>
                <TableRow style={{borderBottom: "1px solid lightgrey"}}>
                    <StyledTitle><StyledIonIcon icon={ createOutline } />최신결재</StyledTitle>
                </TableRow>
            </TableHead>
            <TableBody>
                {Appr.length > 0 ? (
                    Appr.slice(0, 5).map(dto => (
                        <TableRow key={dto.apprNum} style={{ textAlign: "center" }}>
                            <Link to={`/appr/DetailTest`} onClick={() => detailAppr(dto.apprNum, dto.apprForm)} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <TableCell style={{ width: "10%" }}>{dto.employeeId}</TableCell>
                                <TableCell style={{ width: "20%" }}>{dto.apprForm}</TableCell>
                                <TableCell style={{ width: "50%" }}>{dto.apprTitle}</TableCell>
                                <TableCell style={{ width: "20%" }}>{formatDate(dto.apprDate)}</TableCell>
                            </Link>
                        </TableRow>
                    ))
                ) : (
                    <div>대기중인 결재가 없습니다.</div>
                )}
            </TableBody>
        </Table>
        </StyledTableWrap>
    );
}

export default withRouter(MiniApprList);
