import React, { Component } from 'react';
import './EventModal.css'; // CSS 파일 임포트
import CalendarApiService from './CalendarApiService';
import { withRouter, Link } from 'react-router-dom';  

class AdminEventModal extends Component {
    constructor(props) {
        super(props);
        console.log("render EventModal!")
        this.state = {
            eventTitle: '',
            startDate: props.selectedStartDate || '', // selectedStartDate로 초기화
            endDate: props.selectedEndDate || '', // selectedEndDate로 초기화
            startTime: props.selectedStartTime || '09:00',
            endTime: props.selectedEndTime || '09:00',
            timeOptions: [],
            allDay: false,
            location: '',
            selectedDate: props.selectedDate,
            employeeId: localStorage.getItem('LoginID'),
        };
    }

    componentDidMount() {
        const { selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime } = this.props;
    
        if (selectedStartDate) {
            // selectedStartDate가 존재하는 경우 처리
            this.setState({ startDate: selectedStartDate });
        }
        if (selectedEndDate) {
            // selectedEndDate가 존재하는 경우 처리
            this.setState({ endDate: selectedEndDate });
        }
        if (selectedStartTime) {
            // selectedStartTime이 존재하는 경우 처리
            this.setState({ startTime: selectedStartTime });
        }
        if (selectedEndTime) {
            // selectedEndTime이 존재하는 경우 처리
            this.setState({ endTime: selectedEndTime });
        }
    
        this.setState({ timeOptions: this.generateTimeOptions() });
    }
        

    componentDidUpdate(prevProps) {
        const { show, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime } = this.props;
        if (show && !prevProps.show) {
            // 모달이 열릴 때 state를 초기화
            this.setState({
                eventTitle: '',
                location: '',
                allDay: false,
                startTime: (selectedStartTime !== undefined) ? selectedStartTime : '09:00', // 선택한 시작 시간이 있는 경우에만 초기화
                endTime: (selectedEndTime !== undefined) ? selectedEndTime : '09:00', // 선택한 종료 시간이 있는 경우에만 초기화

            });
        }

        if (!show && prevProps.show) { 
            // 모달이 닫힐 때 state를 초기화.
            this.setState({
                eventTitle: '',
                location: '',
                allDay: false,
                startTime: (selectedStartTime !== undefined) ? selectedStartTime : '09:00', // 선택한 시작 시간이 있는 경우에만 초기화
                endTime: (selectedEndTime !== undefined) ? selectedEndTime : '09:00', // 선택한 종료 시간이 있는 경우에만 초기화
            });
        }

        // 선택된 종료일(selectedEndDate) 또는 시작일(selectedStartDate)이 변경되었을 때 종료일을 조정
        if ((selectedEndDate !== prevProps.selectedEndDate && selectedEndDate) ||
            (selectedStartDate !== prevProps.selectedStartDate && selectedStartDate)) {
            // 종료일을 조정하는 로직을 실행
            const adjustedEndDate = new Date(selectedEndDate);
            adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);

            this.setState({
                startDate: selectedStartDate,
                endDate: adjustedEndDate.toISOString().slice(0, 10)
            });
        }
    }
    
    

    generateTimeOptions() {
        let times = [];
        for (let i = 9; i <= 21; i++) {
        for (let j = 0; j < 60; j += 30) {
            let hour = i < 10 ? `0${i}` : `${i}`;
            let minute = j === 0 ? '00' : '30';
            times.push(`${hour}:${minute}`);
        }
        }
        times.push('22:00');
        return times;
    }

    onChangeHandler = (e) => {
        const { name, value, type, checked } = e.target;
        
        // 선택된 날짜 업데이트
        if (name === 'startDate' || name === 'endDate') {
            const newDate = name === 'startDate' ? 'startStr' : 'endStr';
            this.props.setSelectedDate({ ...this.props.selectedDate, [newDate]: value });
        }

        if (name === 'endDate' && this.state.startDate > value) {
            window.alert("종료일자는 시작일자 이후여야 합니다.");
        }
        else if (name === 'endTime' && this.state.startTime > value) {
            window.alert("종료시간은 시작시간 이후여야 합니다.");
        }
       
        else if (name === 'allDay') {
            const { allDay } = this.state;
            // startTime과 endTime을 변경하는 로직은 allDay 상태를 업데이트한 후에 실행
            const newStartTime = !allDay ? '09:00' : '09:00';
            const newEndTime = !allDay ? '22:00' : '09:00';
            // 상태를 업데이트
            this.setState({
                allDay: !allDay,
                startTime: newStartTime,
                endTime: newEndTime,
            }, () => {
                // allDay 상태에 따라 calAllday 컬럼을 업데이트
                const calAlldayValue = !allDay ? 'Y' : 'N'; // allDay가 true일 때 'N', false일 때 'Y'
                if (this.props.setSelectedDate) {
                    this.props.setSelectedDate({ ...this.props.selectedDate, calAllday: calAlldayValue });
                    console.log('calAllday updated:', calAlldayValue);
                }
            });
        }
        else {
            // 상태를 업데이트하는 setState 함수를 호출
            this.setState({ [name]: type === 'checkbox' ? checked : value });
        }
    
    };


    handleSubmit = (e) => {
        e.preventDefault();

        if(this.state.eventTitle === ""){
            window.alert("일정명을 입력해주세요.");
        }
        else if(this.state.startDate > this.state.endDate) {
            window.alert("시작일자와 종료일자를 확인하세요.");
            return;
        }
        else if (this.state.endTime < this.state.startTime) {
            window.alert("시작시간은 종료시간 이전이여야 합니다.");
            return;
        } 
        else if (this.state.startDate === this.state.endDate && this.state.startTime === this.state.endTime) {
            window.alert("시작시간과 종료시간을 다시 설정해주세요."); 
            return;
        }
        else {
            let inputData = {
                calTitle: this.state.eventTitle,
                employeeId: this.state.employeeId,
                calStartDT: this.state.startDate,
                calStartTM: this.state.startTime,
                calEndDT: this.state.endDate,
                calEndTM: this.state.endTime,
                calAllday: this.state.allDay ? 'Y' : 'N', // allDay 상태에 따라 'Y' 또는 'N'으로 설정
                calLocation: this.state.location,
            };

            CalendarApiService.addCalendar(inputData)
            .then(response => {
                console.log('일정 저장 완료:', response.data);
                // response에서 calNo 값을 가져옴
                const calNo = response.data.calNo;

                // inputData 객체에 calNo 값을 설정
                inputData = {
                    ...inputData, // 기존 inputData를 복사
                    calNo: calNo // calNo를 추가
                };

                console.log('일정 저장 완료:', response.data);
                this.props.setSelectedDate(null);
            
                // 이벤트 저장 후 새로운 데이터를 가져오거나 캘린더를 다시 렌더링하는 작업 수행
                if (this.props.onSaveSuccess) {
                    this.props.onSaveSuccess();
                }

                // 모달 닫기
                this.props.handleClose();
            })
            .catch(error => {
                console.error('일정 저장 실패:', error);
            });
        };    
    };

    handleCancel = () => {
        // 모달 닫기
        this.props.handleClose();
      };    

    handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        this.setState({ startDate: newStartDate });
        this.props.setSelectedDate({ ...this.props.selectedDate, startStr: newStartDate });
    };

    handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        this.setState({ endDate: newEndDate });
        this.props.setSelectedDate({ ...this.props.selectedDate, endStr: newEndDate });
    };


    
    // 자식 컴포넌트 내에서는 setSelectedDate를 호출하여 부모 컴포넌트의 상태를 업데이트
    handleDateChange = (newDate) => {
        this.props.setSelectedDate(newDate);
        // 선택한 날짜에 대한 다른 작업을 수행
        console.log("날짜가 변경되었습니다:", newDate);
    };

    handleTimeChange = (newTime) => {
        // 시간 변경 데이터 처리 로직을 작성
        console.log("시간이 변경되었습니다:", newTime);
        // 필요한 경우 상태를 업데이트하거나 다른 작업을 수행
      };


    render() {
        const { eventTitle, startDate, endDate, startTime, endTime, timeOptions, allDay, location } = this.state;
        const { handleClose, show} = this.props;

        return (
        <div className={`modal ${show  ? 'display-block' : 'display-none'}`}>
            <div className="modal-content">
                <header className="modal-header">
                    <button className="btn_layer_x" onClick={handleClose}>
                    x {/* X 표시 */}
                    </button>
                    <h1>일정등록</h1>
                </header>
                <div className="content">
                    <form onSubmit={this.handleSubmit}>
                        <div id="schedule" className="d-flex">
                            <label>일정명<ins className="asterisk">*</ins></label>
                            <input
                            className="event-input"
                            type="text"
                            placeholder="일정을 입력하세요"
                            name="eventTitle"
                            value={eventTitle}
                            onChange={this.onChangeHandler}
                            />
                        </div>
                        <div id="date-time" className="d-flex">
                            <div className="d-flex">
                            <label>일시</label>
                            <input
                                className="date-input"
                                type="date" 
                                name="startDate"
                                value={startDate} // 시작일자
                                onChange={this.onChangeHandler}
                            />
                            </div>
                            <div className="d-flex">
                            <select 
                                className="time-input"
                                name="startTime"
                                value={startTime} // 시작시간
                                onChange={this.onChangeHandler}
                                disabled={allDay}
                                >
                                {timeOptions.map((time, index) => (
                                <option key={index} value={time}>
                                    {time}
                                </option>
                                ))}
                            </select>
                            </div>
                            <div className="d-flex">
                            <label className="label-class">~</label>
                            <input 
                                className="date-input"
                                type="date" 
                                name="endDate"
                                value={endDate} // 종료일자
                                onChange={this.onChangeHandler}
                            />
                            </div>
                            <div className="d-flex">
                            <select
                                className="time-input"
                                name="endTime"
                                value={endTime} // 죵료시간
                                onChange={this.onChangeHandler}
                                disabled={allDay}
                                >
                                {timeOptions.map((time, index) => (
                                <option key={index} value={time}>
                                    {time}
                                </option>
                                ))}
                            </select>
                            </div>
                            <div className="d-flex">
                            <input
                            type="checkbox" 
                            id="allDay" 
                            name="allDay"
                            checked={allDay} 
                            onChange={this.onChangeHandler} 
                            />
                            <label htmlFor="allDay">종일</label>
                            </div>
                        </div>
                        <div className="d-flex"> {/* 장소 입력란 */}
                            <div className="location">
                            <label>장소</label>
                            <input
                                className="location-input"
                                type="text"
                                name="location"
                                placeholder="장소를 입력하세요"
                                value={location}
                                onChange={this.onChangeHandler}
                            />
                            </div>
                        </div>
                        <footer className="btn_layer_wrap">
                            <Link to={{ pathname: "/admin/AdminCalendarAdd", 
                                    state: { startDate: this.state.startDate, endDate: this.state.endDate, 
                                                startTime: this.state.startTime, endTime: this.state.endTime } }} 
                                    className="btn_major_i" 
                                    title="일정등록">
                                <span>일정상세 입력</span> 
                            </Link> 
                            <button className="btn_major_s" type="submit" title="확인">
                                확인
                            </button>
                            <button className="btn_minor_s" type="button" onClick={handleClose} title="취소">
                                취소
                            </button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
        );
    } 
}

export default withRouter(AdminEventModal);


