import React, { Component } from 'react';
import styles from './ApprMain.module.css';
import ApprApiService from './ApprApiService'

// 예시용 컴포넌트2 입니다
class ApprMain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            employeeId: localStorage.getItem('LoginID'),
            progressList: [],    // 5. 리스트 데이터
            allList: [],
            message: null
        }
    }

    // 라이프사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    componentDidMount() {   
        this.reloadProgressList();    // 1.
        this.reloadAllList();
    }

    // 대기문서함 리스트
    reloadProgressList = () => {
        ApprApiService.fetchProgressList(this.state.employeeId)
            .then(res => {
                console.log('list : ', res.data); // 데이터 확인
                this.setState({
                    progressList: res.data
                });
            })
            .catch(err => {
                console.log('reloadProgressList() Error!!', err);
            });
    }

    // 대기문서 상세페이지 확인
    ProgressdetailAppr = (dto) => {
        console.log("Clicked apprNum:", dto.apprNum); // apprNum 값 확인
        console.log("Clicked employeeId:", dto.employeeId); // id 값 확인
        
        const values = {
            apprNum : dto.apprNum,
            employeeId : dto.employeeId,
            apprForm: dto.apprForm
        }

        window.localStorage.setItem("apprValue", JSON.stringify(values));
        if (dto.apprForm === "휴가신청") {
            this.props.history.push("/appr/leaveDetail");
        } else if (dto.apprForm === "업무기안") {
            this.props.history.push("/appr/WorkDetail");
        }
    }

    // 기안문서함 리스트(매개변수없음)
    reloadAllList = () => {
        ApprApiService.fetchAllList(this.state.employeeId)
         .then(res => {
            console.log('list: ', res.data)
            this.setState({
                allList: res.data
            });
         })
         .catch(err => {
            console.log('reloadAllList() Error!!', err);
         });
    }

    // 기안문서 상세페이지 확인
    detailAppr = (dto) => {
        console.log("Clicked apprNum:", dto.apprNum); // apprNum 값 확인
        console.log("Clicked employeeId:", dto.employeeId); // id 값 확인
        
        const values = {
            apprNum : dto.apprNum,
            employeeId : dto.employeeId,
            apprForm: dto.apprForm
        }

        window.localStorage.setItem("apprValue", JSON.stringify(values));
        if (dto.apprForm === "휴가신청") {
            this.props.history.push("/appr/CompletedApprDetail");
        } else if (dto.apprForm === "업무기안") {
            this.props.history.push("/appr/CompletedWorkDetail");
        }
    }

    render() {

        return (
            <div>
                <div className={styles.apprMainWrap}>
                    <div className={styles.apprMainTitle}>전자결재 홈</div>
                        <div className={styles.dashBoard}>
                        {this.state.progressList.slice(0, 5).map(dto =>
                        <div class={styles.apprCard} key={dto.apprNum} onClick={() => this.ProgressdetailAppr(dto)}>
                            <section class={styles.homCard}>
                                <div class={styles.cardWrap}>
                                        <div class={styles.buttonWrap}> 
                                            <span>
                                                {dto.apprUrgent === 'y' ? (
                                                            <button className={styles.emergencyIcon}>긴급</button>
                                                        ) : null}
                                            </span>
                                            <span>
                                                {dto.apprStatus >= 0 && dto.apprStatus <= 2 ? (
                                                            <button className={styles.ongoingIcon}>진행중</button>
                                                        ) : dto.apprStatus === 3 ? (
                                                            <button className={styles.completedBtn}>완료</button>
                                                        ) : (
                                                            <button className={styles.rejectBtn}>반려</button>
                                                        )}
                                            </span>
                                        </div>
                                        <div class={styles.cardSubject}>
                                            <span class={styles.apprTitle}>{dto.apprTitle}</span>
                                        </div>
                                        <div class={styles.cardContent}>
                                            <div class={styles.cardForm}>
                                                <div class={styles.employeeform}>
                                                    <div class={styles.employeeTitle}>기안자 :</div>
                                                    <div class={styles.employeeName}>{dto.employeeId}</div>
                                                </div>
                                                <div class={styles.apprDateForm}>
                                                    <div class={styles.apprDateTitle}>기안일 :</div>
                                                    <div class={styles.apprDate}>{dto.apprDate}</div>
                                                </div>
                                                <div class={styles.apprTypeForm}>
                                                    <div class={styles.apprFormTitle}>결재양식 :</div>
                                                    <div class={styles.apprType}>{dto.apprForm}</div>
                                                </div>
                                            </div>
                                        </div>
                                    <div class={styles.cardAction}>
                                        <a class={styles.ApprBtn}>결재하기</a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        )} 
                    </div>
                        {/* 결재 대기 문서 */}
                        <div className={styles.ProgressApprlist}>
                            <div className={styles.ProgressApprTitle}>
                                <span>결재 대기 문서</span>
                            </div>
                            <div className={styles.progressList}>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>기안일</td>
                                            <td>결재양식</td>
                                            <td>긴급</td>
                                            <td>제목</td>
                                            <td>첨부</td>
                                            <td>결재상태</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.progressList.slice(0, 5).map(dto =>
                                            <tr key={dto.apprNum} onClick={() => this.detailAppr(dto)}>
                                                <td>{dto.apprDate}</td>
                                                <td>{dto.apprForm}</td>
                                                <td> 
                                                    {dto.apprUrgent === 'n' ? (
                                                        <button className={styles.emergencyIcon}>긴급</button>
                                                    ) : null} 
                                                </td>
                                                <td>{dto.apprTitle}</td>
                                                <td></td>
                                                <td>
                                                    {dto.apprStatus >= 0 && dto.apprStatus <= 2 ? (
                                                        <button className={styles.ongoingIcon}>진행중</button>
                                                    ) : dto.apprStatus === 3 ? (
                                                        <button className={styles.completedBtn}>완료</button>
                                                    ) : (
                                                        <button className={styles.rejectBtn}>반려</button>
                                                    )}
                                                </td>
                                            </tr>
                                        )}    
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* 기안 문서 */}
                        <div className={styles.ApprAllList}>
                            <div className={styles.ApprAllTitle}>
                                <span>기안 문서</span>
                            </div>
                            <div className={styles.apprList}>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>기안일</td>
                                            <td>결재양식</td>
                                            <td>긴급</td>
                                            <td>제목</td>
                                            <td>첨부</td>
                                            <td>결재상태</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.allList.slice(0, 5).map(dto => 
                                            <tr key={dto.apprNum} onClick={() => this.detailAppr(dto)}>
                                                <td>{dto.apprDate}</td>
                                                <td>{dto.apprForm}</td>
                                                <td>
                                                    {dto.apprUrgent === 'n' ? (
                                                        <button className={styles.emergencyIcon}>긴급</button>
                                                    ) : null}
                                                </td>
                                                <td>{dto.apprTitle}</td>
                                                <td></td>
                                                <td>
                                                    {dto.apprStatus >= 0 && dto.apprStatus <= 2 ? (
                                                        <button className={styles.ongoingIcon}>진행중</button>
                                                    ) : dto.apprStatus === 3 ? (
                                                        <button className={styles.completedBtn}>완료</button>
                                                    ) : (
                                                        <button className={styles.rejectBtn}>반려</button>
                                                    )}
                                                </td>
                                            </tr>
                                        )}    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
            </div>

        );

    }

}

export default ApprMain;