import React, { Component } from 'react';
import './Boardlist.css';
import BoardService from "./BoardService";
import BoardPagination from "./BoardPagination";
import { Link } from 'react-router-dom';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

class Boardlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],  // 리스트 데이터
      page: 1,
      limit: 10,
      searchQuery: '',
      searchOption: 'both' // 기본 검색 옵션 설정
    };
  }


  componentDidMount() {
    this.reloadBoardList();
  }

  // 게시판 전체조회
  reloadBoardList = () => {
    const { page, limit, searchOption, searchQuery} = this.state;
    const offset = (page - 1) * limit;
    let apiPath = '';

    // 검색 옵션에 따라 API 경로 설정
    if (searchQuery.trim() !== '') {
      apiPath = `/search/${searchOption}/${searchQuery}`;
    }
   
    BoardService.BoardList(offset, limit, apiPath)
    .then(res => {
      // 게시판 리스트를 받아온 후에 각 게시물의 댓글 수를 조회합니다.
      this.setState({
        list: res.data
      });
    })
    .catch(err => {
      console.log('reloadBoardList() 에러!!', err);
    });
  };

  // 페이징처리
  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      this.reloadBoardList();
    });
  };

  // 게시글 조회수
  handlePostClick = (fb_no) => {
    BoardService.increaseViews(fb_no)
      .then(() => {
        
        // 자유게시판 목록 다시 불러오기
        this.reloadBoardList();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  // 검색어 실시간 검색
handleSearchChange = (e) => {
  this.setState({ searchQuery: e.target.value });
};



  // 검색 옵션 변경 시 호출되는 메서드
  handleSearchOptionChange = (e) => {
    this.setState({ searchOption: e.target.value }, () => {
      // 검색 옵션이 변경될 때마다 게시판 다시 불러오기
      this.reloadBoardList();
    });
  };


  render() {
    const { list, limit, page, searchQuery, searchOption } = this.state;

    // 검색어에 따라 리스트 필터링
    const filteredList = list.filter(item => {

      // 제목으로 검색
      if (searchOption === 'title') {
        return item.fb_title.toLowerCase().includes(searchQuery.toLowerCase());

        // 내용으로 검색
      } else if (searchOption === 'content') {
        return item.fb_content.toLowerCase().includes(searchQuery.toLowerCase());

        // 작성자로 검색
       } else if (searchOption === 'author') {
          return item.employee_id.toLowerCase().includes(searchQuery.toLowerCase());
          
      } else {
        // 제목과 내용 모두에서 검색
        return (
          item.fb_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.fb_content.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    });

    // 현재 페이지에 대한 시작 및 끝 인덱스를 계산합니다.
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const currentPageData = filteredList.slice(startIndex, endIndex);

    return (
      <div className='List'>
        <div className='list_title'>자유게시판</div>

        <Table style={{marginBottom: '40px'}}>
          <TableHead className='list_grid list_data'>
            <TableRow style={{ borderBottom: '3px solid #ccc' }}>
              <TableCell>번호</TableCell>
              <TableCell>제목</TableCell>
              <TableCell>작성자</TableCell>
              <TableCell>조회</TableCell>
              <TableCell className='acenter'>날짜</TableCell>
              
            </TableRow>
          </TableHead>

          {filteredList.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <div className='message'>등록된 게시글이 없습니다.</div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {filteredList.length > 0 && (
            <TableBody>
              {currentPageData.map(item => (
                <TableRow key={item.fb_no}>
                  <TableCell>{item.fb_no}</TableCell>
                  <TableCell>
                    <Link to={`/board/detail/${item.fb_no}`} onClick={() => this.handlePostClick(item.fb_no)}>
                      {item.fb_title} <span className="red-text">[{item.fb_comment_count}]</span>
                    </Link>
                  </TableCell>
                  <TableCell>{item.employee_id}</TableCell>
                  <TableCell>{item.fb_readcnt}</TableCell>
                  <TableCell className='acenter'>{item.fb_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      
        <div className='paging_div'>
          <BoardPagination
            total={filteredList.length}
            limit={limit}
            page={page}
            setPage={this.handlePageChange}
          />

          {/* 검색 입력 필드 */}
          <div className='search_div'>
          <select className="searchBox" value={searchOption} onChange={this.handleSearchOptionChange}>
            <option value="title">제목</option>
            <option value="content">내용</option>
            <option value="author">작성자</option>
            <option value="both">제목+내용</option>
          </select>
        
          &nbsp;&nbsp;
          
            <input className="search-bar"
              type="text"
              placeholder="검색어를 입력하세요"
              value={searchQuery}
              onChange={this.handleSearchChange}
            />
         </div> 
        </div>
      </div>

    );
  }
}

const style = {
  display: 'inline-block',
  width: '100%',
};

export default Boardlist;
