import React, { Component } from 'react';

// 예시용 컴포넌트1 입니다
class Attention2 extends Component {
    render() {

        return (
            <div>
                <p>테스트 입니다</p>
                <p>테스트 입니다</p>
                <p>테스트 입니다</p>
                <p>테스트 입니다</p>
                <p>테스트 입니다</p>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <p>테스트 입니다</p>
            </div>

        );

    }

}

export default Attention2;