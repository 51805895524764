import axios from 'axios';  // npm install -f axios@^1.3.5
import { request, request2 } from '../../helpers/axios_helper';

// - ApiService는 스프링부트 서버(보통 'http://localhost:8080/'으로 열린다.)와 연결해주는 역할을 한다.
// - 리액트에서 무언가 요청을 하면 스프링부트에서 받아 Oracle에서 데이터를 가져오거나 연결해주는 역할을 한다.
// - 전형적인 MVC 패턴이라고 할 수 있다.
// - 리액트에서 이를 구현하기 위해서 Axios를 사용한다. 기존 HTML이나 JSP에서 사용한 AJAX 역할을 한다고 보면 된다.
// npm install -f axios@^1.3.5

const SAMPLE_API_BASE_URL = process.env.REACT_APP_BACK_HOST;

class ApiService {

    getAuthToken = () => {
        return window.localStorage.getItem("auth_token");
    }

    // 로그인
    loginApi(inputData) {
        console.log('loginApi() 호출!!', inputData)
        return axios.post(SAMPLE_API_BASE_URL + "/login", inputData);
    }

    // 내 정보 확인
    fetchEmployeeByID = (employee_id) => {
        let headers = {};
        const authToken = this.getAuthToken();
        if(authToken !== null && authToken !== "null") {
            headers = {"Authorization": `Bearer ${authToken}`};
            console.log('headers : ', headers);
        } 
        console.log('fetchEmployeeByID() 호출!!', employee_id);
        return axios.get(SAMPLE_API_BASE_URL + "/employee/" + employee_id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    // 비밀번호 확인
    pwchk(inputData) {
        console.log('pwchk() 호출!!')
        return request("POST",SAMPLE_API_BASE_URL + "/employeepw" , inputData);
    }

    // 내 정보 수정
    updateEmployee = (formData) => {
        console.log('updateEmployee() 호출!!', formData);
        return request2("PUT",SAMPLE_API_BASE_URL + "/employee" , formData);
    }

    register = (inputData) => {
        console.log('register() 호출!!', inputData);
        return axios.post(SAMPLE_API_BASE_URL + "/register", inputData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    
}
const apiService = new ApiService();
export default apiService;