import React, { Component, useEffect, useState, useRef } from 'react';
import Chat from './Chat';


const ChatBotRouter = () => {
  

    return (

        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Chat />
        </div>

    );
}

const style = {
    
}

export default ChatBotRouter;