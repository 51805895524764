import axios from 'axios';  // npm install -f axios@^1.3.5

const Board_API_BASE_URL = process.env.REACT_APP_BACK_HOST + "/admin";

// 이부분 추가
const getAuthToken = () => {
    return window.localStorage.getItem("auth_token");
}

let headers = {};


if(getAuthToken() !== null && getAuthToken !=="null") {
    headers = {"Authorization": `Bearer ${getAuthToken()}`};
    console.log('headers : ', headers);
}

class ApiService {

    // 자유게시판 전체조회
    BoardList() {
        console.log('BoardList() 호출!!')
        return axios.get(Board_API_BASE_URL + "/boardList",{headers});
    }

    // 자유게시판 보이기
    showFree(fb_no) {
        console.log('showFree 호출', fb_no);
        return axios.put(Board_API_BASE_URL + "/BoardOn/" + fb_no, null,{ headers });
    }

    // 자유게시판 숨기기
    hideBoard(fb_no) {
        console.log('hideBoard 호출', fb_no);
        return axios.put(Board_API_BASE_URL + "/BoardOff/" + fb_no, null, { headers });
    }
    // 자유게시판 영구삭제
    deleteBoard(fb_no) {
        console.log('deleteBoard 호출',fb_no);
        return axios.delete(Board_API_BASE_URL + "/deleteBoard/" + fb_no,{headers});
    }

    // 공지사항 전체조회
    NoticeList() {
        console.log('NoticeList() 호출!!')
        return axios.get(Board_API_BASE_URL + "/noticeList",{headers});
    }

    // 공지사항 상세
    getNotice(notice_no) {
    console.log('getNotice 호출', notice_no);
    return axios.get(`${Board_API_BASE_URL}/notice/${notice_no}`,{headers});
    }

    // 공지사항 작성
    NoticeWrite(inputData) {
        console.log('NoticeWrite() 호출!!',inputData)
        return axios.post(Board_API_BASE_URL + "/noticeWrite", inputData,{headers});
    }

    // 공지사항 수정
    editNotice(inputData) {
        console.log('editNotice 호출', inputData);
        return axios.put(`${Board_API_BASE_URL}/notice/${inputData.notice_no}`, inputData,{headers});
    }
    
    // 공지사항 보이기
    showNotice(notice_no) {
        console.log('showNotice 호출', notice_no);
        return axios.put(`${Board_API_BASE_URL}/noticeOn/${notice_no}`,null,{headers});
    }
    // 공지사항 숨기기
    hideNotice(notice_no) {
        console.log('hideNotice 호출', notice_no);
        return axios.put(`${Board_API_BASE_URL}/noticeOff/${notice_no}`,null,{headers});
    }
    // 공지사항 영구삭제
    deleteNotice(notice_no) {
        console.log('deleteSample 호출',notice_no);
        return axios.delete(Board_API_BASE_URL + "/noticeList/" + notice_no,{headers});
    }
    // 댓글 전체조회
    CommentList() {
        console.log('CommentList() 호출!!')
        return axios.get(Board_API_BASE_URL + "/commentList",{headers});
    }

    // 댓글 보이기
    showComment(cm_no) {
        console.log('showComment 호출', cm_no);
        return axios.put(`${Board_API_BASE_URL}/CommentOn/${cm_no}`,null,{headers});
    }  
    // 댓글 숨기기
    hideComment(cm_no) {
        console.log('hideComment 호출', cm_no);
        return axios.put(`${Board_API_BASE_URL}/CommentOff/${cm_no}`,null,{headers});
    }    
    // 댓글 영구삭제
    deleteComment(cm_no) {
        console.log('deleteComment 호출', cm_no);
        return axios.delete(`${Board_API_BASE_URL}/deleteComment/${cm_no}`,{headers});
    }    
}

export default new ApiService();