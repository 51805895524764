import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import './AddVac.css'
import OrganizationChart from './IdListPop.js';
import ApiService from "../Attention/AttentionApiService.js"
import moment from 'moment';

class AddVac extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        const day = new Date().toISOString().slice(0, 10);
        this.state = {
            employee_id: '',
            annual: '',
            startDate: day,
            endDate: day,
            employee_id : '',
            vactype: '연가',

            isPopupActive: false
        };
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }
    componentDidMount() {

    }

    

    componentDidUpdate(prevProps) {
        if (this.props.selectedDate !== prevProps.selectedDate) {
        this.setState({
            startDate: this.props.selectedDate ? this.props.selectedDate.startStr : '',
            endDate: this.props.selectedDate ? this.props.selectedDate.endStr : '',
        }, () => {
            console.log('startDate and endDate updated:', this.state.startDate, this.state.endDate);
          });
        }
      }

    onChangeHandler = (e) => {
        const { name, value, type, checked } = e.target;

        // 상태 업데이트 후에 실행되는 콜백 함수
       

        

        if (name === 'endDate' && this.state.startDate > value) {
            window.alert("종료일자는 시작일자 이후여야 합니다.");
            return;
        }
        

        else {
            this.setState({ [name]: value });
        }
    };

    getAttendState = (employee_id) => {
        ApiService.getAttendState(employee_id)  // 2.
        .then(res => {             // 4.
            
            this.setState({
                employee_id: employee_id,
                annual: res.data.annual,
            });

        })
        .catch(err => {
            console.log('getAttendState() Error!', err);
        })
        
    }

    addVacData = (data) => {
        ApiService.addVacData(data)  // 2.
        .then(res => {             // 4.
            
            //console.log('내 근태 요청');
            console.log(res.data);
            alert('휴가계 등록 성공!');
        })
        .catch(err => {
            console.log('addVacData() Error!', err);
        })
    }

    handleSubmit = () => {

        console.log(moment(this.state.endDate))
        console.log(moment(this.state.startDate))
        console.log(
            
            moment.duration(moment(this.state.endDate).diff(moment(this.state.startDate))).asDays()
            
            )
        if(this.state.startDate > this.state.endDate) {
            window.alert("시작일자와 종료일자를 확인하세요.");
            return;
        }
        else if (this.state.endTime < this.state.startTime) {
            window.alert("시작시간은 종료시간 이전이여야 합니다.");
            return;
        } else if (this.state.employee_id === '') {
            window.alert("대상을 조직도에서 선택해 주세요");
            return;
        } else if (this.state.vactype === '반차' && this.state.startDate != this.state.endDate) {
            window.alert("반차는 시작일과 종료일을 일치시켜 주세요!!");
            return;
        } else if (this.state.annual < moment.duration(moment(this.state.endDate).diff(moment(this.state.startDate))).asDays()) {
            window.alert("가지고 있는 연가 수보다 많습니다!!");
            return;
        }

        else {
            let data = {
                vac_sdate: this.state.startDate,
                vac_edate: this.state.endDate,
                employee_id: this.state.employee_id,
                vac_type: this.state.vactype
            };

            console.log(data);

            this.addVacData(data);

            
        };
    };

    
    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isPopupActive: !prevState.isPopupActive }));
    }

    // 팝업을 닫는 함수
    handleClosePopup = () => {
        this.setState({ isPopupActive: false });
    }

    // 조직도 선택 시 호출되는 함수
    handleEmployeeIdSelect = (employeeId) => {
        this.setState({ 
            employee_id: employeeId 
        });

        this.getAttendState(employeeId);
    };



    render() {
        const {startDate, endDate, vactype} = this.state;

        let annual = this.state.annual;
    
        return (
            <div style={{ alignItems: 'center', justifyContent: 'space-between', padding: '50px 50px 25px 50px', width: '100%' }}>
                <h1 style={{ textAlign: 'left', margin: 0 }}>
                    휴가계 등록
                </h1>
                <br/>
                <div style={{margin: 'auto', paddingLeft: '50px'}}>

                    <div>
                        <br/>
                        <label className="hlabel-att-A"> 대상 </label>
                        <input
                            id="standard-required"
                            variant="standard"
                            label="toId"
                            className=""
                            type="text"
                            name="attArr"
                            value={this.state.employee_id}
                            readOnly // 읽기 전용으로 설정
                            onChange={this.onChangeHandler}
                        />
                        
                        <div style={{display:'inline'}}>
                                <div style={{display:'inline'}}>
                                <div className="hicon-container1" style={{display: 'inline-block', alignItems:'center'}}>
                                    <IonIcon icon={addOutline}
                                            onClick={this.togglePopup}
                                    />
                                </div>
                                </div>
                                <label className="hatt-search" onClick={this.togglePopup}> 조직도 선택</label>
                                {this.state.isPopupActive && <OrganizationChart handleClosePopup={this.handleClosePopup} onEmployeeIdSelect={this.handleEmployeeIdSelect} />}

                                <br/>
                                남은 연가 수 : {annual}
                        </div>
                        
                        <br/><br/>
                    </div>

                    <input
                    className="hdate-input-A"
                    type="date" 
                    name="startDate"
                    value={startDate} // 시작일자
                    onChange={this.onChangeHandler}
                    />
                
                
                
                    <label className="hlabel-class-A">~</label>
                    <input 
                    className="hdate-input-A2"
                    type="date" 
                    name="endDate"
                    value={endDate} // 종료일자
                    onChange={this.onChangeHandler}
                    />
                


                
                    <select
                    
                        name="vactype"
                        value={vactype}
                        onChange={this.onChangeHandler}>
                        <option value="연가">연가</option>
                        <option value="반차">반차</option>
                    </select>
                
                        
                        
                    
                    

                </div>
                    
            



                    <footer className="btn_layer_wrap1">
                        <button className="btn_major_s1" type="submit" title="확인" onClick={() => this.handleSubmit()}>
                        확인
                        </button>
                            <button className="btn_minor_s1" title="취소" type="reset">
                                취소
                            </button>
                    </footer>
                
            </div>
            
        );
    } 
}

export default AddVac;
