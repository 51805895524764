import React, { Component} from 'react';
import { TextField, Button } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./MailRouter.css";
import ApiService from './ApiService';
import OrganizationChart from './IdListPop.js';
import { IonIcon } from '@ionic/react';
import { sendOutline, refreshOutline, personCircleOutline, saveOutline, searchOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 메일쓰기 페이지 */

class WriteMailComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fromId: localStorage.getItem('LoginID'),
            toIdArr: [],
            emailTitle: '',
            emailContent: '',
            emailFile: '',
            countTemp: '',
            file: '',
            isPopupActive: false
        }
    }
    
    componentDidMount() {
        this.countTemp();
    }
    

    // 임시저장 개수 확인
    countTemp = () => {
        ApiService.countTemp(this.state.fromId)
        .then(res => {
            this.setState({
                countTemp: res.data
            });
        })
        .catch(err => {
            console.log('countTemp 에러!!', err);
        });
    }

    // 초기화버튼
    resetValues = () => {
        this.setState({
            toIdArr: [],
            emailTitle: '',
            emailContent: '',
            emailFile: ''
        });
    }

    onChange = (e) => {   
        if (e.target.name === 'emailFile') {
            this.setState({ 
                emailFile: e.target.value,
                file: e.target.files[0]
            }); 
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    // 발송
    sendMail = (e) => {
        e.preventDefault();

        if(this.state.toId === ""){
            window.alert("받는 사람을 입력해주세요.");

        }else if(this.state.emailTitle === ""){
            window.alert("제목을 입력해주세요.");
        } else {

            let emailFile = this.state.file;
            let formData = new FormData();

            let inputData = {  
                fromId: this.state.fromId,
                toIdArr: this.state.toIdArr,
                emailTitle: this.state.emailTitle,
                emailContent: this.state.emailContent,
            }

            formData.append('data', JSON.stringify(inputData));

            if (emailFile !== '') {
                formData.append('file', emailFile);
                ApiService.sendMail(formData)      
                .then(res => {
                    this.setState({
                    })
                    console.log('sendMail 성공 : ', res.data); 
                    this.props.history.push('/mail');    
                })
                .catch(err => {
                    console.log('sendMail() 에러!! : ', err);
                });
            } else {
                ApiService.sendMailnoFile(inputData)     
                .then(res => {
                    this.setState({
                    })
                    console.log('sendMailnoFile 성공 : ', res.data); 
                    this.props.history.push('/mail');    
                })
                .catch(err => {
                    console.log('sendMailnoFile() 에러!! : ', err);
                });
            }
        }
    }
    // 임시저장
    saveMail = (e) => {
        e.preventDefault();

        if(this.state.toId === ""){
            window.alert("받는 사람을 입력해주세요.");

        }else if(this.state.emailTitle === ""){
            window.alert("제목을 입력해주세요.");

        } else {
            let emailFile = this.state.file;
            let formData = new FormData();

            let inputData = {   
                fromId: this.state.fromId,
                toIdArr: this.state.toIdArr,
                emailTitle: this.state.emailTitle,
                emailContent: this.state.emailContent,
            }

            formData.append('data', JSON.stringify(inputData));

            if (emailFile !== '') {
                formData.append('file', emailFile);

                ApiService.saveMail(formData) 
                    .then(res => {
                        this.setState({
                        })
                        console.log('saveMail 성공 : ', res.data); 
                        this.props.history.push('/mail/Temp');    
                    })
                    .catch(err => {
                        console.log('saveMail() 에러!! : ', err);
                    });

            } else {
                ApiService.saveMailnoFile(inputData) 
                    .then(res => {
                        this.setState({
                        })
                        console.log('saveMailnoFile 성공 : ', res.data); 
                        this.props.history.push('/mail/Temp');    
                    })
                    .catch(err => {
                        console.log('saveMail() 에러!! : ', err);
                    });
            }
        }     
    }
    
    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isPopupActive: !prevState.isPopupActive }));
    }

    // 팝업을 닫는 함수
    handleClosePopup = () => {
        this.setState({ isPopupActive: false });
    }

    // 수신 아이디 누적
    handleEmployeeIdSelect = (employeeId) => {
        this.setState(prevState => ({
            toIdArr: Array.from(new Set([...prevState.toIdArr, employeeId])) // 기존 배열에 선택한 값을 추가하여 설정
        }));
    }


    render() {

        return (
            <div className='top' style={style}>
                <div className='mailHeader'>
                    <h1>메일쓰기</h1>
                    <ul className='meta'>
                        <li className='metaNum'>
                        임시보관 메일 
                            <strong> {this.state.countTemp} </strong>
                        </li>
                    </ul>
                </div>
                

                <div className='write'>
                    <hr/>
                    <div className='mailWrite'>
                        <button onClick={this.sendMail}> <IonIcon icon={sendOutline} /> 발송 </button>
                        <button onClick={this.resetValues}> <IonIcon icon={refreshOutline} /> 초기화 </button>
                        <button style={{width: '90px'}} onClick={ () => this.setState({ toIdArr: this.state.fromId })}> 
                        <IonIcon icon={personCircleOutline} />내게쓰기 </button>
                        <button style={{width: '90px'}} onClick={this.saveMail}> <IonIcon icon={saveOutline} />임시저장 </button>
                    </div>
                    <hr/>

                    <div className='writeTo'> 
                        <TextField
                            sx={{ width: '30%' }}
                            id="standard-required"
                            variant="standard"
                            label="To"
                            type="text"
                            name="toIdArr"
                            value={this.state.toIdArr}
                            placeholder="받는사람"
                            onChange={this.onChange}
                        />

                        <div className="mailPop">
                            <div className="searchID">
                                <Button onClick={this.togglePopup}> <IonIcon icon={searchOutline} /> 검색</Button>
                            </div>
                            {this.state.isPopupActive && <OrganizationChart handleClosePopup={this.handleClosePopup} onEmployeeIdSelect={this.handleEmployeeIdSelect} />}

                        </div>
                    </div>


                    <div className='writeTitle'> 
                        <TextField
                            sx={{ width: '95%' }}
                            id="standard-required"
                            variant="standard"
                            label="Title"
                            type="text"
                            name="emailTitle"
                            value={this.state.emailTitle}
                            placeholder="제목입력"
                            onChange={this.onChange}
                        />

                    <label htmlFor="fileInput" className="fileInput">파일첨부</label>
                    <input
                        id='fileInput'
                        type='file'
                        name='emailFile'
                        multiple='multiple'
                        style={{ display: 'none' }}
                        onChange={this.onChange}
                    />

                        <div className='prevFile'>
                            {this.state.emailFile ? this.state.emailFile : "첨부파일없음"}
                        </div>

                    </div>
                    <div className='writeCK'>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.emailContent}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                                this.setState({ emailContent: data });
                                
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                        }}
                        />
                    </div>

                </div>
            </div>
        );

    }

}

const style = {
    display: 'inline-block', 
    width: '100%',
}

export default WriteMailComponent;