import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { Component, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {Table, TableHead, TableRow, TableCell, TableBody, Button, Typography} from '@mui/material';
import { Link, Route } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';

import test from './test';
//import test2 from './test2';
import Timeline from './Timeline';

import VacList from '../admin/VacList';
import AttentionToday from './AttentionToday';
import CurrentTimer from './CurrentTimer';
import AttendMonth from './AttendMonth';
import MyAnnual from './MyAnnual';
import './AttentionRouter.css'
import '../../route/SubHeaderLayout.css'
import '../../route/SubHeaderStyle.css';
import { construct } from 'ionicons/icons';


import ApiService from "./AttentionApiService"
import GetState from './GetState';
import { render } from '@testing-library/react';
import { useRaf } from 'react-use';

const AttentionRouter = () => {

    // 아코디언 그림자 제거 함수
    const theme = createTheme({
        components: {
          MuiAccordion: {
            styleOverrides: {
              root: {
                boxShadow: 'none', // 아코디언 컴포넌트의 그림자 제거
                padding: '0px 10px',
              },
            },
          },
        },
      });

    // 로컬스토리지에서 id 받아와야할듯 임시로 고정값

    let employee_info = {
        employee_id: window.localStorage.getItem("LoginID"),
        annual: 0,
        state: '',
        att_stime: '',
        att_etime: ''
    };

    /*
    const testa = useRef({
        employee_id:'',
        annual: 0,
        state: '',
        att_stime: '',
        att_etime: ''
    });

    */
    /*
    const getAttendState = (employee_id) => {
        console.log(employee_id);
        ApiService.getAttendState(employee_id)  // 2.
        .then(res => {             // 4.
            console.log('근태 상태정보 요청 성공');
            let a = res.data;
            
            testa.current = a;

            console.log(testa);
        })
        .catch(err => {
            console.log('getAttendState() Error!', err);
        })
        
    }

    */

/*    
    useEffect(() => {
 
        setTimeout (() => {
            console.log("대기");
            console.log("상태는 : " + window.localStorage.getItem('Attend_STATE'))
        }, 1000);

        

    })
*/  

    //getAttendState(employee_info.employee_id);    
    
    // goWork, goHome 두개랑 버튼 GetState에 옮겨야 할듯
    const goWork = (info) => {
        ApiService.goToWork(info)  // 2.
        .then(res => {             // 4.
            console.log('출근 요청 성공', res.data);
            alert("출근설정됨");
            window.location.reload();
        })
        .catch(err => {
            console.log('goWork() Error!', err);
            window.location.reload();
        })
        
    }

    
    const goHome = (info) => {
        ApiService.goToHome(info)  // 2.
        .then(res => {             // 4.
            console.log('퇴근 요청 성공', res.data);
            alert("퇴근 설정됨");
            window.location.reload();
        })
        .catch(err => {
            console.log('goHome() Error!', err);
            window.location.reload();
        })
        
    }

    

    

    const [expanded, setExpanded] = React.useState(true);
  
    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    

    return (
        <ThemeProvider theme={theme}>
        <div style={style}>
        
            

            <div className='main'>
                <div className='left'>
                    <div className='sub_Header'>
                        <div className='title' style={style.title}>
                            근태 관리
                        </div>

                        <div className=''>
                            <CurrentTimer />
                            
                            <GetState employee_id={employee_info.employee_id}/>
{/* 
                            <p>아이디 : {testa.current.employee_id}</p>
                            <p>현 상태 : {testa.current.state}</p>
                            <p>출근시간 : {testa.current.att_stime}</p>
                            <p>퇴근시간 : {testa.current.att_etime}</p>
                             */}
                            
                            
                        </div>

                        <div className='apprMenu' style={style.apprMenu}> {/* 각자수정 */}
                                <Accordion
                                    
                                    expanded={expanded}
                                    onChange={handleExpansion}
                                    slots={{ transition: Fade }}
                                    slotProps={{ transition: { timeout: 400 } }}
                                    sx={{
                                        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                        boxShadow: 'none'
                                    }}
                                >
                                    {/* 각자 메뉴 수정하시면 됩니다. */}
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography style={style.middleMenu}>근태 관리</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <Link to="/attend/month" style={style.link}>내 근태 현황</Link>
                                        </Typography>
                                    </AccordionDetails>
                                    
                                    <AccordionDetails>
                                        <Typography>
                                            <Link to="/attend/myanuual" style={style.link}>내 연가 현황</Link>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                
                            </div>
                    </div>
                </div>


                <div className='right'>
                    <Route path="/attend/" exact={true} component={AttendMonth}></Route>
                    <Route path="/attend/myanuual" exact={true} component={MyAnnual}></Route>
                    <Route path="/attend/today" exact={true} component={AttentionToday}></Route>
                    <Route path="/attend/vacList" exact={true} component={VacList}></Route>
                    <Route path="/attend/month" exact={true} component={AttendMonth}></Route>
                    {/* <Route path="/attend/testa" exact={true} component={test2}></Route> */}
                    <Route path="/attend/test2" exact={true} component={Timeline}></Route>
                </div>

                
            </div>
        
        </div>
        </ThemeProvider>
    );
}

const style = {
    link: {
        textDecoration: "none",
        color: "#646464",
        fontSize: "15px"
      },
  
      title: {
          marginTop: '30px',
          fontSize: '21px',
          textAlign: 'left',
      },
  
      // title 아래 버튼
      first_Button: {   
          color: '#323232',
          borderColor: '#6e6e6e'
      },
  
      apprMenu: {
          marginTop: '30px'
      },
  
      middleMenu: {
          fontWeight: 550,
          color: '#5a5a5a'
      }
}

export default AttentionRouter;