import React, { useState } from 'react';
import './Login.css';
import apiService from "./ApiService";
import logo from './logo.png';

function LoginForm({ onLogin }) {
  const [employeeId, setEmployeeId] = useState('');
  const [employeePw, setEmployeePw] = useState('');
  const [userInfo, setUserInfo] = useState(null);

  const namecall = () => {
    apiService.fetchEmployeeByID(employeeId)
      .then(res => {
        console.log("알럿알럿", res.data);
        if(res.data) {
          console.log("조회 성공!!");
          alert(res.data.employeeName +"님 환영합니다.");
          window.location.href = "/main";
        } else {
          console.log("조회 실패..");
        }
      })
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const inputData = {
      employeeId: employeeId,
      employeePw: employeePw,
    };


    apiService.loginApi(inputData)
      .then(res => {
        console.log("로그인 API 응답 데이터:", res.data);

        if (res.data) {
          apiService.fetchEmployeeByID(inputData.employeeId)
            .then(response => {
              const userInfo = response.data;
              console.log(response.data);
              // resignation_date가 'N'인 경우에만 로그인 및 관련 동작 실행
              if (userInfo.resignationDate === 'N') {
                console.log("로그인 성공");
                onLogin();
                window.localStorage.setItem("auth_token", res.data.token);
                localStorage.setItem('LoginID', inputData.employeeId);
                namecall();
                // 사용자 정보 상태에 저장
                setUserInfo(userInfo);
              } else {
                console.log("퇴사자 로그인");
                window.localStorage.setItem("auth_token", null);
                alert("퇴사한 직원입니다.");
              }
            })
            .catch(error => {
              console.error('사용자정보 조회 오류', error);
            });

        } else {
          console.log("로그인 실패");
          window.localStorage.setItem("auth_token", null);
          alert("입력하신 ID나 비밀번호가 일치하지 않습니다.");
        }
      })
      .catch(err => {
        console.log('로그인() 에러!! : ', err);
        alert("로그인 오류");
      });
  };

  const handleChangeId = (e) => {
    setEmployeeId(e.target.value);
  };
  
  const handleChangePw = (e) => {
    setEmployeePw(e.target.value);
  };

  // const onRegister = () => {
  //   const inputData = {
  //     employeeId: employeeId,
  //     employeePw: employeePw,
  //   };
  //   apiService.register(inputData)
  //     .then(res => {
  //       console.log("회원가입 데이터:", res.data);
  //       if (res.data) {
  //         console.log("회원가입 성공");
  //         window.localStorage.setItem("auth_token", res.data.token);
  //         window.location.href = "/login";
  //       } else {
  //         console.log("회원가입 실패");
  //         window.localStorage.setItem("auth_token", null);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('회원가입() 에러!! : ', err);
  //       alert("회원가입 오류");
  //     });
  // }

  return (
    <div id="container"><br/><br/>
      <div id="logo">
        <img 
          src={logo} 
          alt="logo" 
        />
      </div>
      <form onSubmit={handleSubmit}>
      <ul id="logininfo">
            <li className="logininput">
              <input
                required
                id="standard-required"
                variant="standard"
                label="employeeId"
                placeholder="계정"
                type="text"
                value={employeeId}
                onChange={handleChangeId}
              />
            </li>
            <li className="logininput">
              <input
                required
                id="standard-required"
                variant="standard"
                label="employeePw"
                placeholder="비밀번호"
                type="password"
                value={employeePw}
                onChange={handleChangePw}
              />
            </li>
            <li>
              <input className="loginButton" type="submit" value="로그인" />
            </li>
            
          </ul>
      </form>
          {/* <div id="pills-register" >
                <form onSubmit={onRegister}>
                  <div className="form-outline mb-4">
                    <input type="text" id="standard-required"   onChange={handleChangeId}/>
                    <label >ID</label>
                  </div>

                  <div className="form-outline mb-4">
                    <input type="password" id="standard-required"  onChange={handleChangePw}/>
                    <label>Password</label>
                  </div>

                  <button type="submit">Sign up</button>
                </form>
              </div> */}
    </div>
  );
}

export default LoginForm;