import React, { Component } from "react";
import { Avatar, Divider, IconButton, ListItem, ListItemAvatar, ListItemText, TextField, Button } from "@mui/material";
import WorkIcon from '@mui/icons-material/Work';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BoardService from "./BoardService";

class Comment extends Component {
    constructor(props) {
        super(props);
        // 여기에 상태를 초기화할 수 있음
        this.state = {
            isEditing: false,
            cm_content: '', // 기존 댓글 내용을 저장할 상태 추가
            cm_no: props.commentNumber, // 댓글 번호 설정
            fb_no: props.postNumber, // 게시글 번호 설정
            loggedInUserID: localStorage.getItem('LoginID')
        };
    }

    // 댓글 삭제
    handleDeleteClick = () => {
        const confirmDelete = window.confirm("댓글을 삭제하시겠습니까?");
        if (confirmDelete) {

         // 댓글 데이터 생성
         const { fb_no, cm_no } = this.state;
         
         const inputData = {
            fb_no: fb_no,
            cm_no: cm_no,
            employee_id: this.state.loggedInUserID,
            
        }; 
       
        BoardService.deleteComment(fb_no, cm_no, inputData)
            .then(() => {
                console.log("Comment deleted successfully!");
                this.props.reloadComments(); // 댓글 목록 다시 불러오기
            })
            .catch((error) => {
                console.error("Error deleting comment:", error);
            });
        }
    };
    // 댓글 수정
    handleSaveEdit = () => {
         const { fb_no, cm_no, newCommentContent } = this.state;
         const loggedInUserID = localStorage.getItem('LoginID');

        // 댓글 데이터 생성
         const updatedCommentData = {
            fb_no: fb_no,
            cm_no: cm_no,
            employee_id: loggedInUserID,
            cm_content: newCommentContent 
        }; 

        console.log(updatedCommentData);
        
        // 댓글 수정 요청
        BoardService.updateComment(updatedCommentData)
        .then(response => {
            console.log('댓글 수정 성공:', response.data);
            this.setState({ isEditing: false });   // 수정 상태 변경
            this.props.reloadComments(); // 댓글 목록 다시 불러오기
        })
        .catch((error) => {
            console.error('댓글 수정 오류:', error);
        });
    };

    handleChange = (event) => {
        this.setState({ newCommentContent: event.target.value });
    };

    // 수정 버튼
    handleEditClick = () => {
        const { primary } = this.props;
        this.setState({ isEditing: true, newCommentContent: primary });
        
    };

    // 수정 취소 버튼
    handleCancelEdit = () => {
        this.setState({ isEditing: false, newCommentContent: this.props.primary });
    };

    render() {
        const { isEditing, loggedInUserID, newCommentContent } = this.state;
        const { user, isComment, primary, secondary, commentUserID } = this.props;

        return (
            <ListItem>
                {isComment &&
                    <Divider
                        orientation="vertical"
                        sx={{ marginLeft: '10px', height: '100%', borderLeft: '2px solid rgba(0,0,0,0.12)' }}
                    />
                }
                <ListItemAvatar>
                    <Avatar>
                        <WorkIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={isEditing ? (
                    <TextField
                        fullWidth
                        value={newCommentContent}
                        onChange={this.handleChange}
                    />
                ) : primary} secondary={secondary} />
                {user && loggedInUserID === commentUserID && !isEditing &&
                    <>  
                        {/* 댓글수정 아이콘버튼 */}
                        <IconButton aria-label="edit" variant="contained"  onClick={this.handleEditClick}>
                            <EditIcon />
                        </IconButton>
                        {/* 댓글삭제 아이콘버튼 */}
                        <IconButton aria-label="delete" onClick={this.handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                }
                {isEditing &&
                    <>
                        <Button onClick={this.handleSaveEdit}>등록</Button>
                        <Button onClick={this.handleCancelEdit}>취소</Button>
                    </>
                }
            </ListItem>
        );
    }
}

export default Comment;
