import React, { useState } from 'react';
import Modal from 'react-modal';
import ApprApiService from './ApprApiService';
import styles from './OpinionModal.module.css';
import { Route, withRouter } from 'react-router-dom';

const OpinionModal = ({ isOpen, closeModal, apprNum, apprStatus, history, handleApproval }) => {
    const [apprReason, setApprReason] = useState(""); // 결재 의견 상태

    // 의견 입력 시 상태 업데이트
    const handleReasonChange = (e) => {
        setApprReason(e.target.value);
        console.log('handleReasonChange() => 상태 업데이트');
    };

    // 결재 의견을 DB에 저장하는 함수
    const saveApprovalOpinion = () => {
        console.log('문서번호확인 : ', apprNum);
        handleApproval(apprNum, apprReason, apprStatus);
        
        let inputData = {
            employeeId: localStorage.getItem('LoginID'),
            apprNum: apprNum, 
            apprStatus: apprStatus,
            apprReason: apprReason
        };

        console.log('inputData:', inputData);

        ApprApiService.updateOpinion(inputData)
            .then(res => {
                console.log('apprNum:', apprNum);
                console.log('apprStatus:', apprStatus);
                console.log('inputData:', inputData);
                console.log('update :', res.data);
                history.push('/appr/ProgressAppr');
            })
            .catch(err => {
                console.log('결재 의견 저장 실패:', err);
            });
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customModalStyles}
            ariaHideApp={false}
            contentLabel="Modal"
        >
            {/* 모달 제목 */}
            <div className={styles.modalTitle}>결재하기</div>
            <br/><br/>
            {/* 결재의견 입력 폼 */}
            <div className={styles.contentWrap1}>
                <div className={styles.OpinionTitle}>결재의견</div>
                <div className={styles.OpinionContent}> 
                    <textarea
                        value={apprReason}
                        onChange={handleReasonChange}
                        placeholder="의견을 작성해 주세요."
                    />
                </div>
            </div>
            <br/><br/>
            {/* 확인 및 취소 버튼 */}
            <button className={styles.confirmBtn} onClick={saveApprovalOpinion} >승인</button>
            <button className={styles.closeBtn} onClick={closeModal}>취소</button>
        </Modal>
    );
};

const customModalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        zIndex: "1000"
    },
    content: {
        width: "400px",
        height: "300px",
        margin: "auto",
        backgroundColor: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "25px",
    }
};

export default withRouter(OpinionModal);
