import React, { useState, useEffect } from 'react';
import ApiService from './ApiService';
import './ReadPop.css';

/* 작업자 : 강승재 */
/* 보낸메일 세부페이지의 수신자의 수신날짜 확인 팝업페이지 */

function Popup({ emailNo }) {
    const [readDate, setReadDate] = useState([]);

    useEffect(() => {
        const readDateCheck = async () => {
            try {
                const response = await ApiService.readDateCheck(emailNo);
                setReadDate(response.data);
            } catch (error) {
                console.error('검색 에러:', error);
            }

        };

        readDateCheck(); // 팝업이 열릴 때 API를 호출하여 데이터를 가져옵니다.
    }, [emailNo]);

    // 날짜 형식 변환 함수
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

  return (
    <div>
        <div className="datepopup">
        {readDate.map(dto =>
            <div className="datepopup-content" key={dto.id}>
                <p>{dto.toId} : {dto.checkDate ? formatDate(dto.checkDate) : "수신안함"}</p>
            </div>
        )}
        </div>
    </div>
  );
}

export default Popup;