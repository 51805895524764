import React, { Component} from 'react';
import { TextField, Button } from '@mui/material';
import "./MailRouter.css";
import ApiService from './ApiService';
import htmlparser from 'html-react-parser';
import OrganizationChart from './ReadCheck';
import { IonIcon } from '@ionic/react';
import { trashBinOutline, cloudDoneOutline, downloadOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 보낸메일함 세부페이지 */

class FromDetailMailComponent extends Component {

    constructor(props){
        super(props)

        this.state = {
            emailNo: '',
            toId: '',
            emailTitle: '',
            emailContent: '',
            checkDate: '',
            emailFile: '',
            isPopupActive: false,
        }
    }

    componentDidMount() {
        this.loadMail();
    }



    // 이메일 내용 읽어오기
    loadMail = () => {
        ApiService.emailByNOFrom(window.localStorage.getItem('emailNO'))
            .then(res => {
                let email = res.data;
                this.setState({
                    emailNo: email.emailNo,
                    toId: email.toId,
                    emailTitle: email.emailTitle,
                    emailContent: email.emailContent,
                    checkDate: email.checkDate,
                    emailFile: email.emailFile
                })
            })
            .catch(err => {
                console.log('loadMail() Error!!', err);
            });
    }


    // 메일삭제
    deleteMail = (emailNo) => {
        ApiService.deleteMail2(emailNo)
        .then(res => {
            this.setState({

            })
            console.log('deleteMail2 성공 : ', res.data);
            this.props.history.push('/mail/From');
        })
        .catch(err => {
            console.log('deleteMail2() 에러 : ', err);
        });
    }

    // 첨부파일 다운로드
    download = () => {
        const fileName = this.state.emailFile;
        ApiService.download(fileName)
        .then(res => {
            console.log('download 성공');
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            // 다운로드 후 생성한 URL을 해제
            window.URL.revokeObjectURL(url);

        })
        .catch(err => {
            console.log('download() 에러 : ', err);
        });
    }

    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ isPopupActive: !prevState.isPopupActive }));
    }


    render() {

        return (
            <div className='top' style={style}>
                <div className='mailHeader'>
                    <h1>보낸 메일 확인</h1>
                </div>
                

                <div className='write'>
                    <hr/>
                    <div className='writeBox'>
                        <div className='mailWrite'>
                            <button onClick={() => this.deleteMail(this.state.emailNo)}> <IonIcon icon={trashBinOutline} />  삭제 </button>
                        </div>
                        <div className="mailPop">
                            <div className="readCheck">
                            <Button onClick={this.togglePopup}> <IonIcon icon={cloudDoneOutline} /> 수신확인</Button>
                            </div>
                            {this.state.isPopupActive && <OrganizationChart handleClosePopup={this.handleClosePopup} emailNo={this.state.emailNo} />}
                        </div>
                            
                    </div>
                    <hr/>

                    <div className='writeTo'> 
                        <TextField
                            sx={{ width: '30%' }}
                            id="standard-required"
                            variant="standard"
                            label="To"
                            type="text"
                            name="To"
                            value={this.state.toId}
                            placeholder="받는사람"
                            readOnly
                        />
                    </div>


                    <div className='writeTo'> 
                        <TextField
                            sx={{ width: '95%', marginTop: '20px' }}
                            id="standard-required"
                            variant="standard"
                            label="Title"
                            type="text"
                            name="Title"
                            value={this.state.emailTitle}
                            placeholder="제목입력"
                            readOnly
                        />
                        <div className='fileButton'>
                        <IonIcon icon={downloadOutline}/> <Button onClick = {this.download}> 첨부파일 다운로드</Button>
                        </div>
                        
                        <div className='prevFile'>
                            {this.state.emailFile ? this.state.emailFile : "첨부파일없음"}
                        </div>

                    </div>
                    <div className='readCK'>
                        {htmlparser(this.state.emailContent)}
                    </div>

                </div>
            </div>
        );

    }

}

const style = {
    display: 'inline-block', 
    width: '100%',
}

export default FromDetailMailComponent;