import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {Button, Typography} from '@mui/material';
import { Link, Route } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Calendar from './Calendar';
import CalendarAdd from './CalendarAdd';
import CalendarDetail from './CalendarDetail';
import Reservation from './Reservation';
import '../../route/SubHeaderStyle.css';
import '../../route/SubHeaderLayout.css';
import MyCalendar from './MyCalendar';


// 아코디언 그림자 제거 함수
const theme = createTheme({
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none', // 아코디언 컴포넌트의 그림자 제거
            padding: '0px 10px',
          },
        },
      },
    },
  });

export default function ApprRouter() {
    const [expanded, setExpanded] = React.useState(false);
    const history = useHistory();
  
    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    // 삭제 예정
    // const Write = () => {
    //     history.push('/appr/write');
    //   };

    return (
        <ThemeProvider theme={theme}>
        <div style={style}>
            <div className='main'>  {/* 고정 */}
                <div className='left'>  {/* 고정 */}
                    <div className='sub_Header'>  {/* 고정 */}
                        <div className='title' style={style.title}> {/* 고정 */}
                            캘린더 {/* 각자수정 */}
                        </div>
                        <div className='CalendarAdd'> {/* 각자수정 */}
                            {/* <Button onClick={Write} variant="outlined" style={style.first_Button}>새 결재 진행</Button> -> 삭제 예정 */}
                            <Button component={Link} to="/cal/CalendarAdd" variant="outlined" style={style.first_Button}>일정등록</Button>
                            <div className='calMenu' style={style.calMenu}> {/* 각자수정 */}
                                <Accordion
                                    expanded={expanded}
                                    onChange={handleExpansion}
                                    slotProps={{ transition: { timeout: 400 } }}
                                    sx={{
                                        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                        boxShadow: 'none'
                                    }}
                                >
                                    {/* 각자 메뉴 수정하시면 됩니다. */}
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography style={style.middleMenu}>일정</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <Link to="/cal/MyCalendar" style={style.link}>내 캘린더</Link>
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                            <Link to="/cal" style={style.link}>전체 캘린더</Link>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        style={style.back}
                                    >
                                        <Typography style={style.middleMenu}>예약</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <Link to="/cal/Reservation" style={style.link}>회의실 예약현황</Link>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className='right'>
                        {/* 이 부분에서 /test/ 는 초기 페이지의 컴포넌트를 넣어주시고 */}
                        <Route path="/cal/" exact={true} component={Calendar}></Route>
                        {/* 여기부터 소메뉴 링크 들어갔을 때 각각의 컴포넌트를 표시하도록 유도해주세요 */}
                        <Route path="/cal/Calendar" exact={true} component={Calendar}></Route>
                        <Route path="/cal/MyCalendar" exact={true} component={MyCalendar}></Route>
                        <Route path="/cal/CalendarAdd" exact={true} component={CalendarAdd}></Route>
                        <Route path="/cal/CalendarDetail" exact={true} component={CalendarDetail}></Route>
                        <Route path="/cal/Reservation" exact={true} component={Reservation}></Route>
                        
                   </div>

                
            </div>
        </div>
        </ThemeProvider>
    );
}

const style = {
    link: {
      textDecoration: "none",
      color: "#646464",
      fontSize: "15px"
    },

    title: {
        marginTop: '30px',
        fontSize: '21px',
        textAlign: 'left',
    },

    // title 아래 버튼
    first_Button: {
        marginTop: '5px',
        paddingTop: '10px',
        paddingRight: '60px',
        paddingBottom: '10px',
        paddingLeft: '60px',
        color: '#323232',
        borderColor: '#6e6e6e'
    },

    apprMenu: {
        marginTop: '30px'
    },

    middleMenu: {
        fontWeight: 550,
        color: '#5a5a5a'
    }
};
