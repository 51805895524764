import React, { Component } from 'react';
import ApiService from './ApiService';
import { Table, TableBody, TableRow, TableCell, Tooltip } from '@mui/material';
import './MyInfo.css';
import './InfoModal.css';

class MyInfo extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
            employeeInfo: {
                employeeName: '',
                gender: '',
                authorityName: '',
                departmentName: '',
                positionId: '',
                employeeAddress: '',
                employDate: '',
                employeeTel: '',
                extensionTel: '',
                employeeBirth: '',
                resignationDate: ''
            },
            isEditingTel: false,
            isEditingDir: false,
            isEditingBirth: false,
            isEditingPassword: false,
            password: '',
            currentPassword: '',
            isPasswordConfirmed: false,
            editingField: null,
            isModalOpen: false,
            imageFile: null
        };
    }

    componentDidMount() {
        this.loadEmployee();
    }

    loadEmployee = () => {
        ApiService.fetchEmployeeByID(window.localStorage.getItem("LoginID"))
            .then(res => {
                console.log("API 응답 데이터:", res.data);
                const employee = res.data;
                this.setState({ employeeInfo: employee });
            })
            .catch(err => {
                console.log('loadInfo() Error!!', err);
            });
    }

    handleEditClick = (field) => {
        this.setState({ [field]: true, editingField: field });
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            employeeInfo: {
                ...prevState.employeeInfo,
                [name]: value
            }
        }));
    }

    handleImageChange = (e) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
        
        reader.onloadend = () => {
            this.setState({
                selectedImage: reader.result,
                imageFile: e.target.files[0],
                employeeInfo: {
                    ...this.state.employeeInfo
                    // employeeImg: reader.result
                }
            });
        };
    
        if (file) {
            reader.readAsDataURL(file);
            console.log('file', file);
        } else {
            this.setState({
                selectedImage: this.state.defaultImage,
                file: null // 파일 선택되지 않은 경우에는 file 상태를 null로 설정
            });
        }
    };    

    handleTelPrefixChange = (e) => {
        const { value } = e.target;
        this.setState(prevState => ({
            employeeInfo: {
                ...prevState.employeeInfo,
                employeeTel: value + prevState.employeeInfo.employeeTel.substring(3) // 전화번호 맨 앞 3자리 변경
            }
        }));
    }    

    handleMiddleTelChange = (e) => {
        const { value } = e.target;
        if (/^\d+$/.test(value) && value.length <= 4) {
            this.setState(prevState => ({
                employeeInfo: {
                    ...prevState.employeeInfo,
                    employeeTel: prevState.employeeInfo.employeeTel.substring(0, 3) + value + prevState.employeeInfo.employeeTel.substring(7)
                }
            }));
        }
    }
    
    handleLastTelChange = (e) => {
        const { value } = e.target;
        this.setState(prevState => ({
            employeeInfo: {
                ...prevState.employeeInfo,
                employeeTel: prevState.employeeInfo.employeeTel.substring(0, 6) + value
            }
        }));
    }

    handleSaveClick = (event) => {
        event.preventDefault();
        if (!this.state.isPasswordConfirmed) {
            alert("먼저 현재 비밀번호를 확인하세요.");
            return;
        } 
    
        const { isEditingTel, isEditingDir, isEditingBirth, isEditingPassword, employeeInfo, imageFile } = this.state;
        let inputData = { ...employeeInfo };
    
        if (isEditingTel) {
            inputData.employeeTel = employeeInfo.employeeTel;
        }
        if (isEditingDir) {
            inputData.extensionTel = employeeInfo.extensionTel;
        }
        if (isEditingBirth) {
            inputData.employeeBirth = employeeInfo.employeeBirth;
        }
        if (isEditingPassword) {
            inputData.employeePw = this.state.password;
        } if (imageFile === null ) {
            alert("사진을 첨부해주세요.");
            return;
        }
        console.log('inputData.password ',inputData.employeePw );

        const employeeId = window.localStorage.getItem("LoginID");

        const formData = new FormData();        
        
        formData.append('data', JSON.stringify(inputData));

        formData.append('file', imageFile);

        ApiService.updateEmployee(formData)
            .then(res => {
                if (res.data) {
                    console.log("정보 수정 성공");
                    // alert('정보 수정 성공');
                    // this.setState({ isModalOpen: true });
                    window.location.reload();
                    // this.props.history.push('/employee');   
                } else {
                    console.log("정보 수정 실패");
                    alert("정보 수정 실패");
                }
            })
            .catch(err => {
                console.log('정보 수정 에러!! : ', err);
                alert("정보 수정 실패");
            });

        this.setState({
            isEditingTel: false,
            isEditingDir: false,
            isEditingBirth: false
        });
    };  
    
    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    handleEditPasswordClick = () => {
        this.setState(prevState => ({
            isEditingPassword: !prevState.isEditingPassword
        }));
    };

    handlePasswordInputChange = (e) => {
        const { value } = e.target;
        this.setState({ password: value });
    };

    handleCurrentPassword = (e) => {
        const { value } = e.target;
        this.setState({ currentPassword: value });
    };

    handleConfirmPasswordClick = (event) => {
        event.preventDefault();
        let inputData = {  
            employeeId: window.localStorage.getItem("LoginID"),
            employeePw: this.state.currentPassword,
        }

        ApiService.pwchk(inputData)
        .then(res => {
            console.log("비밀번호 확인 API 응답 데이터:", res.data);
            if (res.data == 1 ) {
                this.setState({ isPasswordConfirmed: true });
                console.log('비밀번호 일치');
                alert("비밀번호가 일치합니다.");
            } else {
                alert("비밀번호가 일치하지 않습니다.");
            }
        })
        .catch(err => {
            console.log('비밀번호 확인 실패!! : ', err);
            alert("비밀번호 확인 실패");
        });

    };
    

    handleCancelClick = () => {
        window.location.reload();
    }; 

    // 팝업을 열고 닫는 함수
    togglePopup = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            isPopupActive: !prevState.isPopupActive
        }));
    };

    // 팝업을 닫는 함수
    handleClosePopup = () => {
        this.setState({ isPopupActive: false });
    };

    render() {
        const { isEditingTel, isEditingDir, isEditingBirth, isEditingPassword, employeeInfo, isModalOpen, isPopupActive } = this.state;

        return (
            <div align="center">
                {/* 모달 렌더링 */}
                <div id="modalContainer" className={isModalOpen ? "" : "hidden"} style={{zIndex: '100000000'}}>
                    {/* 모달 내용 */}
                    <div id="modalContent">
                        <span id="modalCloseButton" className="close" onClick={this.closeModal}>&times;</span>
                        <div id="modalTxt">
                            <h2 style={{ paddingBottom: '8px' }}>저장되었습니다.</h2>
                            <p>변경사항이 저장되었습니다.</p>
                        </div>
                        <button id="modalClose" onClick={this.closeModal}>닫기</button>
                    </div>
                </div>
                <br /><br />
                <Table className="my-info-table">
                    <TableBody>
                        <TableRow>
                            <TableCell className="custom-cell" style={{ verticalAlign: 'top', border: 'none' }}><span className='info-title'>사진</span></TableCell>
                            <TableCell style={{ border: 'none' }}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={this.handleImageChange}
                                    style={{ display: 'none' }}
                                    multiple='multiple'
                                    id="employeeImg"
                                    name='employeeImg'
                                />
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <label htmlFor="employeeImg" style={{ cursor: 'pointer', position: 'absolute', top: '75%', left: '12%', zIndex: '1' }}> 사진 올리기 </label>
                                    {this.state.selectedImage ? (
                                        <img
                                            src={this.state.selectedImage}
                                            alt="프로필 이미지"
                                            style={{ height: '100px', width: '100px', borderRadius: '3px', position: 'relative', zIndex: '0' }}
                                        />
                                    ) : (
                                        <img
                                            src={'/profiles/' + employeeInfo.employeeImg}
                                            alt="프로필 이미지"
                                            style={{ height: '100px', width: '100px', borderRadius: '3px', position: 'relative', zIndex: '0' }}
                                        />
                                    )}
                                </div>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>이름</span></TableCell>
                            <TableCell className="custom-cell2">{employeeInfo.employeeName}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>비밀번호</span></TableCell>
                            <TableCell className="custom-cell2" onClick={this.handleEditPasswordClick}>
                                <span onClick={this.handleEditPasswordClick}>
                                    {isEditingPassword ? 
                                        <div>
                                            <input 
                                                type="password" 
                                                name="currentPw"
                                                onChange={this.handleCurrentPassword}
                                                placeholder="현재 비밀번호를 입력하세요."
                                                style={{ padding: '5px', height: '26px', width: '300px', borderRadius: '3px', marginTop: '5px' }}
                                            />
                                            <button className="confirmPw" onClick={this.handleConfirmPasswordClick}>확인</button>
                                            <br />
                                            <input 
                                                type="password" 
                                                name="employeePw"
                                                onChange={this.handlePasswordInputChange}
                                                placeholder="새 비밀번호를 입력하세요."
                                                style={{ padding: '5px', height: '26px', width: '300px', borderRadius: '3px' }}
                                            />
                                        </div>
                                        :
                                        '********'
                                    }
                                </span>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>부서</span></TableCell>
                            <TableCell className="custom-cell2">{employeeInfo.departmentName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>직위</span></TableCell>
                            <TableCell className="custom-cell2">{employeeInfo.positionName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>이메일</span></TableCell>
                            <TableCell className="custom-cell2">{employeeInfo.employeeEmail}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>Cell.</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingTel')} style={{ position:'fixed' }}>
                            <Tooltip title="수정">
                                <span onClick={() => this.handleEditClick('isEditingTel')}>
                                {isEditingTel ?
                                            <div>
                                                <input
                                                    type="text"
                                                    value={employeeInfo.employeeTel.substring(0, 3)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    employeeTel: value + prevState.employeeInfo.employeeTel.substring(3)
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="000"
                                                    maxLength={3}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginRight: '5px', padding: '5px' }}
                                                />
                                                -
                                                <input
                                                    type="text"
                                                    value={employeeInfo.employeeTel.substring(3, 7)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    employeeTel: prevState.employeeInfo.employeeTel.substring(0, 3) + value + prevState.employeeInfo.employeeTel.substring(7)
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="0000"
                                                    maxLength={4}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', margin: '0 5px', padding: '5px' }}
                                                />
                                                -
                                                <input
                                                    type="text"
                                                    value={employeeInfo.employeeTel.substring(7)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    employeeTel: prevState.employeeInfo.employeeTel.substring(0, 7) + value
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="0000"
                                                    maxLength={4}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginLeft: '5px', padding: '5px' }}
                                                />
                                            </div> :
                                            `${employeeInfo.employeeTel.substring(0, 3)}-${employeeInfo.employeeTel.substring(3, 7)}-${employeeInfo.employeeTel.substring(7)}`
                                        }
                                </span>
                            </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>Dir.</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingDir')} style={{ position:'fixed' }}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingDir')}>
                                        {isEditingDir ? 
                                            <div>
                                                {/* 앞에 3자리 */}
                                                <input 
                                                    type="text" 
                                                    name="extensionTelPrefix"
                                                    value={employeeInfo.extensionTel.substring(0, 3)} 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    extensionTel: value + prevState.employeeInfo.extensionTel.substring(3)
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="000"
                                                    maxLength={3}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', padding: '5px' }}
                                                /> -
                                                {/* 가운데 4자리 */}
                                                <input 
                                                    type="text" 
                                                    name="extensionTelMiddle"
                                                    value={employeeInfo.extensionTel.substring(3, 7)} 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    extensionTel: prevState.employeeInfo.extensionTel.substring(0, 3) + value + prevState.employeeInfo.extensionTel.substring(7)
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="0000"
                                                    maxLength={4}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', margin: '0 5px', padding: '5px' }}
                                                /> -
                                                {/* 뒤에 4자리 */}
                                                <input 
                                                    type="text" 
                                                    name="extensionTelLast"
                                                    value={employeeInfo.extensionTel.substring(7)} 
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState(prevState => ({
                                                                employeeInfo: {
                                                                    ...prevState.employeeInfo,
                                                                    extensionTel: prevState.employeeInfo.extensionTel.substring(0, 7) + value
                                                                }
                                                            }));
                                                        }
                                                    }}
                                                    placeholder="0000"
                                                    maxLength={4}
                                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginLeft: '5px', padding: '5px' }}
                                                />
                                            </div> :
                                            `${employeeInfo.extensionTel.substring(0, 3)}-${employeeInfo.extensionTel.substring(3, 7)}-${employeeInfo.extensionTel.substring(7)}`
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>생일</span></TableCell>
                            <TableCell className="custom-cell2" onClick={() => this.handleEditClick('isEditingBirth')}>
                                <Tooltip title="수정">
                                    <span onClick={() => this.handleEditClick('isEditingBirth')}>
                                        {isEditingBirth ? 
                                            <input 
                                                type="date" 
                                                name="employeeBirth"
                                                value={employeeInfo.employeeBirth} 
                                                onChange={this.handleInputChange}
                                                style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                            /> :
                                            employeeInfo.employeeBirth
                                        }
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#ccc', margin: '20px 10px' }}/>
                <br /><br />
                <div>
                    <input className="infoBtn" type="submit" value="저장" onClick={this.handleSaveClick} />
                    <input className="infoBtn" type="submit" value="취소" onClick={this.handleCancelClick} />
                </div>
            </div>
        );
    }
}

export default MyInfo;
