import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './employeeListPop.css';
import ApprApiService from './ApprApiService'; // ApprApiService 실제로 사용하는 API 서비스의 파일이어야 합니다.
import { IonIcon } from '@ionic/react';
import { closeOutline  } from 'ionicons/icons';

function OrganizationChart(props) {
    const [popupActive, setPopupActive] = useState(false);
    const [isTableHeightExceeded, setIsTableHeightExceeded] = useState(false); // max-height를 초과하는지 여부 상태
    const [userInput, setUserInput] = useState('');
    const [searchLists, setSearchLists] = useState([]); // 검색 결과 목록을 담을 상태 추가
    const history = useHistory();
    const tableRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApprApiService.searchData('');
                setSearchLists(response.data);
            } catch (error) {
                console.error('검색 에러:', error);
            }
        };
    
        fetchData(); // 초기 렌더링 시에 데이터를 가져옴

        const checkTableHeight = () => {
            if (!tableRef.current) return; // 요소가 존재하지 않으면 종료

            if (tableRef.current.scrollHeight > tableRef.current.clientHeight) {
                setIsTableHeightExceeded(true);
            } else {
                setIsTableHeightExceeded(false);
            }
        };

        checkTableHeight(); // 컴포넌트가 마운트될 때 최초 한 번 실행하여 초기 상태 설정

        const handleResize = () => {
            checkTableHeight();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // tableRef.current가 변경될 때마다 실행

    const getSearchData = async (e) => {
        setUserInput(e.target.value);
        try {
            // 검색 API를 호출하여 검색 결과를 가져옴
            const response = await ApprApiService.searchData(e.target.value);
            setSearchLists(response.data); // 검색 결과를 상태에 설정
        } catch (error) {
            console.error('검색 에러:', error);
        }
    };

    const pushId = (employeeId, employeeName, positionName) => {
        console.log('positionName:', positionName);
        props.onEmployeeIdSelect(employeeId, employeeName, positionName);
    }

    // 팝업 닫기
    const [isOpen, setIsOpen] = useState(true); // 팝업이 열려있는지 여부를 나타내는 state

    const handleClosePopup = () => {
        setIsOpen(false); // 팝업을 닫음
    };

    return (
        <div>
        {isOpen && ( // isOpen이 true일 때만 팝업을 렌더링함
        <div className={`popup ${popupActive ? 'active' : ''}`} id="popup-1">
            <div className="overlay"></div>
            <div className="content1">
                    <input onChange={getSearchData} type="text" placeholder="  이름/아이디" className="search-input" autoFocus />
                    <div className="closeBtn"><IonIcon icon={closeOutline} onClick={handleClosePopup}/></div>
                <div className="scrollable-content">
                    <div className="table2" ref={tableRef}>
                        <hr className="popup-hr"/>
                        <ul className="tree2">
                            {searchLists.map((item, index) => (
                            <li key={index} className="parent" onClick={() => pushId(item.employeeId, item.employeeName, item.positionName)}>
                                <details>
                                    <summary>
                                        <i className="fa-solid fa-user-tie">{item.employeeName} ({item.employeeId})</i>
                                    </summary>
                                </details>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {isTableHeightExceeded && <div className="scrollbar"></div>}
            </div>
        </div>
        )}
        </div>
    );
}

export default OrganizationChart;
