import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Table, TableCell, TableHead, TableRow, TableBody, Checkbox } from '@mui/material';
import ApiService from './ApiService';
import BoardPagination from '../Board/BoardPagination';


class CommentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOn: false,
            open: false,
            page: 1,
            limit: 10,
            list: [], // 게시판 목록을 저장할 배열
            allChecked: false, // 전체 선택 체크 상태
            checkedItems: {} // 개별 항목의 체크 상태
            
        };
    }

    componentDidMount() {
        this.CommentList();
        this.reloadCommentList();
    }

    // 전체목록 가져오기
    CommentList() {
        ApiService.CommentList()
            .then(res => {
                console.log(res.data);
                this.setState({ list: res.data });
            })
            .catch(err => {
                console.error('CommentList 에러', err);
            });
    }

    // 페이징처리 게시물 전체조회
    reloadCommentList = () => {
        const { page, limit } = this.state;
        const offset = (page - 1) * limit;

        ApiService.CommentList(offset, limit)
            .then(res => {
                this.setState({
                    list: res.data,
                    checkedItems: {}, // 페이지 변경 시 checkedItems 초기화
                    allChecked: false // 페이지 변경 시 allChecked 초기화
                });
            })
            .catch(err => {
                console.log('CommentList() 에러!!', err);
            });
    }

    // 페이징처리
    handlePageChange = (pageNumber) => {
        this.setState({ page: pageNumber }, () => {
            this.reloadCommentList();
        });
    };

    // 삭제된 게시글 보기 토글
    toggleSwitch = () => {
        this.setState({ isOn: !this.state.isOn });
    }

    // 게시물 show 가 'y'인것만 조회
    handleClose = () => {
        this.setState({ open: false });
    };

       // 전체 선택 체크박스 토글
       handleAllCheckChange = (event) => {
        const { checked } = event.target;
        const { list, page, limit } = this.state;
        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;
        const currentPageData = list.slice(startIndex, endIndex);
        
        const checkedItems = {};
        currentPageData.forEach(item => {
            checkedItems[item.cm_no] = checked;
        });
        this.setState({
            allChecked: checked,
            checkedItems: checkedItems
        });
    }

    // 개별 항목의 체크 상태 업데이트
    handleCheckChange = (event, cm_no) => {
        const { checked } = event.target;
        this.setState(prevState => ({
            checkedItems: {
                ...prevState.checkedItems,
                [cm_no]: checked
            }
        }));
    }

    // 댓글 보이기
    showComment = () => {
        const { checkedItems } = this.state;
        const selectedComment = Object.keys(checkedItems).filter(cm_no => checkedItems[cm_no]);

        // 선택된 댓글을 보이기 처리

        if (selectedComment.length === 0) {
            alert('보이기할 항목을 선택하세요.');
            return;
        }

        selectedComment.forEach(cm_no => {
            ApiService.showComment(cm_no)
                .then(res => {
                    console.log('댓글 보이기 성공:', res.data);
                    this.reloadCommentList(); // 댓글 목록 새로고침
                })
                .catch(err => {
                    console.error('댓글 보이기 에러:', err);
                });
        });
    }

    // 보이기 버튼 클릭 핸들러
    handleShowButtonClick = () => {
        this.showComment(); // 보이기 기능 호출
    }

    // 댓글 숨기기
     hideComment = () => {
        const { checkedItems } = this.state;
        const selectedComment = Object.keys(checkedItems).filter(cm_no => checkedItems[cm_no]);

     // 선택된 댓글을 숨김 처리
     if (selectedComment.length === 0) {
        alert('숨기기할 항목을 선택하세요.');
        return;
    }
    
    selectedComment.forEach(cm_no => {
        ApiService.hideComment(cm_no)
            .then(res => {
                // 성공적으로 숨김 처리된 댓글은 리스트에서 제거
                this.setState(prevState => ({
                    list: prevState.list.filter(item => item.cm_no !== cm_no)
                }));
                console.log('댓글 숨기기 성공:', res.data);
                this.reloadCommentList();
            })
            .catch(err => {
                console.error('댓글 숨기기 에러:', err);
            });
    });
}

    // 댓글 영구삭제
    deleteComment = () => {
    const { checkedItems } = this.state;
    const selectedComment = Object.keys(checkedItems).filter(cm_no => checkedItems[cm_no]);

    // 선택된 댓글들 영구삭제 처리
    selectedComment.forEach(cm_no => {
        ApiService.deleteComment(cm_no)
            .then(res => {
                console.log('댓글 영구삭제 성공:', res.data);
                // 영구삭제된 댓글 리스트에서 제거
                this.setState(prevState => ({
                    list: prevState.list.filter(item => item.cm_no !== cm_no)
                }));
                this.reloadCommentList(); // 댓글 목록 새로고침
                alert('영구삭제 되었습니다.');
            })
            .catch(err => {
                console.error('댓글 영구삭제 에러:', err);
            });
    });
}

// 영구삭제 버튼 클릭 핸들러
handleDeleteButtonClick = async () => {
    const { checkedItems } = this.state;
    const selectedComment = Object.keys(checkedItems).filter(cm_no => checkedItems[cm_no]);

    if (selectedComment.length === 0) {
        alert('삭제할 항목을 선택하세요.');
        return;
    }

    const isConfirmed = await new Promise(resolve => {
        resolve(window.confirm('영구삭제 하시겠습니까?'));
    });

    if (isConfirmed) {
        await this.deleteComments(selectedComment);
    }
}

deleteComments = async (commentIds) => {
    for (const cm_no of commentIds) {
        try {
            await ApiService.deleteComment(cm_no);
            console.log('댓글 영구삭제 성공:', cm_no);
            this.setState(prevState => ({
                list: prevState.list.filter(item => item.cm_no !== cm_no)
            }));
        } catch (err) {
            console.error('댓글 영구삭제 에러:', err);
        }
    }
    this.reloadCommentList(); // 댓글 목록 새로고침
    alert('선택된 댓글이 영구삭제되었습니다.');
}
   


    render() {
        const { isOn, list, limit, page, allChecked, checkedItems } = this.state;

        // 현재 페이지에 대한 시작 및 끝 인덱스를 계산합니다.
        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;
        const currentPageData = list.slice(startIndex, endIndex);

        return (
            <Router>
                <div display="flex" alignItems="center" justifyContent="center">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '50px 50px 25px 50px', width: '100%' }}>
                        <h1 style={{ textAlign: 'left', margin: 0 }}> 댓글 관리 </h1>
                    </div>
                    <div className="toggle-container">
                        삭제된 댓글 보기
                        <label className={isOn ? 'toggle toggle-on' : 'toggle toggle-off'} onClick={this.toggleSwitch}>
                            <span className="handle" />
                        </label>
                    </div>
                    <p style={{ marginLeft: '50px', padding: '10px 0 25px 0', fontWeight: 'bold', fontSize: '1.4em' }}>
                        총 댓글 : {list.filter(item => isOn || item.show === 'y').length}
                    </p>

                    <div style={{ textAlign: 'right', margin: '0'}}>
                    <button className="insertBtn" onClick={this.showComment}> 보이기 </button>
                    &nbsp;&nbsp;
                    <button className="insertBtn" onClick={this.hideComment}> 숨기기 </button>
                    &nbsp;&nbsp;
                    <button className="insertBtn" onClick={this.handleDeleteButtonClick}> 영구삭제 </button>
                    </div>

                    <hr style={{ margin: '0 10px' }} />
                    <Table style={{ margin: '0 10px' }}>
                        <TableHead>
                            <TableRow style={{ borderBottom: '3px solid #ccc' }}>
                            <TableCell className="thCheckbox">
                                    <b>
                                        <Checkbox
                                            className="checkbox-small"
                                            size="small"
                                            checked={allChecked}
                                            onChange={this.handleAllCheckChange}
                                        />
                                    </b>
                                </TableCell>
                                <TableCell className="thEmplId"><b> 게시글번호 </b></TableCell>
                                &nbsp;&nbsp;
                                <TableCell className="thAuthority"><b> 작성자 </b></TableCell>
                                &nbsp;&nbsp;
                                <TableCell className="thEmplId"><b> 댓글내용 </b></TableCell>
                                &nbsp;&nbsp;
                                <TableCell className="thEmplDate"><b> 작성일 </b></TableCell>
                                &nbsp;&nbsp;
                                <TableCell className="thEmplDate"><b> show </b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* 현재 페이지에 해당하는 데이터 보여줌 */}
                            {currentPageData
                                .filter(item => isOn || item.show === 'y')
                                .map(item => (
                                    <TableRow key={item.cm_no}>
                                         <TableCell>
                                            <b>
                                            <Checkbox
                                                className="checkbox-small"
                                                size="small"
                                                checked={checkedItems[item.cm_no] || false}
                                                onChange={(event) => this.handleCheckChange(event, item.cm_no)}
                                            />
                                            </b>
                                        </TableCell>
                                        <TableCell><b> {item.fb_no} </b></TableCell>
                                        &nbsp;&nbsp;
                                        <TableCell><b> {item.employee_id} </b></TableCell>
                                        &nbsp;&nbsp;
                                        <TableCell><b> {item.cm_content} </b></TableCell>
                                        &nbsp;&nbsp;
                                        <TableCell><b> {item.cm_date} </b></TableCell>
                                        &nbsp;&nbsp;
                                        <TableCell><b> {item.show} </b></TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>

                {/* 페이징 처리  */}
                <div className='paging_div'>
                    <BoardPagination
                        total={list.length}
                        limit={limit}
                        page={page}
                        setPage={this.handlePageChange}
                    />
                </div>
            </Router>
        )
    }
}

export default CommentList;
