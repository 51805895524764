import React, { Component} from 'react';
import { TextField } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./MailRouter.css";
import ApiService from './ApiService';
import { IonIcon } from '@ionic/react';
import { sendOutline, refreshOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 임시보관함 세부페이지(쓰기페이지) */

class TempWriteMailComponent extends Component {
    
    componentDidMount() {
        this.loadMail();
    }
    
    constructor(props) {
        super(props);

        this.state = {
            emailNo: '',
            fromId: localStorage.getItem('LoginID'),
            toId: '',
            emailTitle: '',
            emailContent: '',
            emailFile: '',
            file: ''
        }
    }

    // 이메일 내용 읽어오기
    loadMail = () => {
        ApiService.emailByNOFrom(window.localStorage.getItem('emailNO'))
            .then(res => {
                let email = res.data;
                this.setState({
                    emailNo: email.emailNo,
                    toId: email.toId,
                    emailTitle: email.emailTitle,
                    emailContent: email.emailContent,
                    emailFile: email.emailFile,
                })
            })
            .catch(err => {
                console.log('loadMail() Error!!', err);
            });
    }

    // 화면에서 수정한 값으로 state 변경
    onChange = (e) => {   
        if (e.target.name === 'emailFile') {
            this.setState({ 
                emailFile: e.target.value,
                file: e.target.files[0]
            }); 
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    // 초기화버튼
    resetValues = () => {
        this.setState({
            toId: '',
            emailTitle: '',
            emailContent: '',
            emailFile: '',
        });
    }

    // 메일 발송
    sendMail = (e) => {
        e.preventDefault();

        if(this.state.toId === ""){
            window.alert("받는 사람을 입력해주세요.");

        }else if(this.state.emailTitle === ""){
            window.alert("제목을 입력해주세요.");

        } else {
            let emailFile = this.state.file;
            let formData = new FormData();

            let inputData ={
                emailNo: this.state.emailNo,
                fromId: this.state.fromId,
                toId: this.state.toId,
                emailTitle: this.state.emailTitle,
                emailContent: this.state.emailContent,
                emailFile: this.state.emailFile
            }

            formData.append('data', JSON.stringify(inputData));

            if (emailFile !== '' && emailFile !== null) {
                formData.append('file', emailFile);

                ApiService.resendMail(formData)
                .then(res => {
                    this.setState({
                    })
                    console.log('resendMail 성공 : ', res.data);
                    this.props.history.push('/mail/Temp');
                })
                .catch(err => {
                    console.log('resendMail 에러 : ', err);
                });

            } else {
            
                ApiService.resendMailnoFile(inputData)
                    .then(res => {
                        this.setState({

                        })
                        console.log('resendMailnoFile 성공 : ', res.data);
                        this.props.history.push('/mail/Temp');
                    })
                    .catch(err => {
                        console.log('resendMailnoFile 에러 : ', err);
                    });
            }
        }
    }
    

    render() {

        return (
            <div className='top' style={style}>
                <div className='mailHeader'>
                    <h1>임시보관메일</h1>
                </div>
                

                <div className='write'>
                    <hr/>
                    <div className='mailWrite'>
                        <button onClick={this.sendMail}> <IonIcon icon={sendOutline} /> 발송 </button>
                        <button onClick={this.resetValues}> <IonIcon icon={refreshOutline} /> 초기화 </button>
                    </div>
                    <hr/>

                    <div className='writeTo'> 
                        <TextField
                            sx={{ width: '30%' }}
                            id="standard-required"
                            variant="standard"
                            label="To"
                            type="text"
                            name="toId"
                            value={this.state.toId}
                            placeholder="받는사람"
                            onChange={this.onChange}
                        />
                    </div>


                    <div className='writeTitle'> 
                        <TextField
                            sx={{ width: '95%', marginTop: '40px' }}
                            id="standard-required"
                            variant="standard"
                            label="Title"
                            type="text"
                            name="emailTitle"
                            value={this.state.emailTitle}
                            placeholder="제목입력"
                            onChange={this.onChange}
                        />
                    <label htmlFor="fileInput" className="fileInput">파일첨부</label>
                    <input
                        id='fileInput'
                        type='file'
                        name='emailFile'
                        multiple='multiple'
                        style={{ display: 'none' }}
                        onChange={this.onChange}
                    />

                        <div className='prevFile' style={{ height: '90px'}}>
                            {this.state.emailFile ? this.state.emailFile : "첨부파일없음"}
                        </div>
                    </div>
                    <div className='writeCK'>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.emailContent}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                                this.setState({ emailContent: data });
                                
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                        }}
                        />
                    </div>

                </div>
            </div>
        );

    }

}

const style = {
    display: 'inline-block', 
    width: '100%',
}

export default TempWriteMailComponent;