import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import apiService from "../page_yoo/employee/ApiService";
import { IonIcon } from '@ionic/react';
import { briefcaseOutline, peopleOutline, homeOutline, mailOutline, listOutline, createOutline, todayOutline, personOutline, chatbubbleOutline, settingsOutline } from 'ionicons/icons';

const Ul = styled.ul`
  height: 100vh;  //추가
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 2;
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%; 
  background-color: #25BAD6; 
  z-index: 999; 

  > li {
    background: #25BAD6;
    opacity: 0;
    padding: 12px 22px;
    color: white;
  }

  &.in > li {
    opacity: 1;
  }

  .undermax {
    background: #25BAD6;
    height: 100vh;
  }
`;

const Li = styled.li`
  display: flex;
  align-items: center; // 수직 정렬
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center; // 수직 정렬
  cursor: pointer;
  margin-bottom: 5px;
`;

const StyledIonIcon = styled(IonIcon)`
  margin-bottom: -6px;
  font-size: 24px;
  color: white;
  margin-right: 15px;
`;

const StyledButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  position: fixed;
`;

const StyledButton1 = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  position: fixed;
`;

const StyledLiWrap = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
  height : 40px;
  margin-left: 12px;
  margin-bottom: 18px;
  padding-left: 10px;
  &:hover {
    transition: background-color 0.3s ease;
    background-color: #21A4BD;
    border-radius: 4px;
  }
`;

const StyledLiWrap1 = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  width: 220px;
  height : 40px;
  margin-left: 12px;
  margin-bottom: 18px;
  padding-left: 10px;
  top: 890px;
  &:hover {
    transition: background-color 0.4s ease;
    border: 1px solid white;
    border-radius: 4px;
  }
`;


const Menu = ({ isActive, openPopup }) => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    // 사용자 정보를 가져오는 API 호출
    apiService.fetchEmployeeByID(window.localStorage.getItem("LoginID"))
        .then(response => {
            // API 호출 성공 시 상태에 정보 저장
            setUserInfo(response.data);
        })
        .catch(error => {
            console.error('사용자정보 조회 오류', error);
        });
  }, []);

  return (
    <>
      <Ul className={isActive ? "in" : null}>
      <Li><LinkWrapper>
      <li style={{paddingTop: '90px'}}><Link to="/main" style={style.link}><StyledIonIcon icon={ homeOutline } />홈</Link></li></LinkWrapper></Li>
        <StyledLiWrap><li><Link to="/mail" style={style.link}><StyledIonIcon icon={ mailOutline } />메일</Link></li></StyledLiWrap>
        <StyledLiWrap><li><Link to="/board" style={style.link}><StyledIonIcon icon={ listOutline } />게시판</Link></li></StyledLiWrap>
        <StyledLiWrap><li><Link to="/cal" style={style.link}><StyledIonIcon icon={ todayOutline } />일정관리</Link></li></StyledLiWrap>
        <StyledLiWrap><li><Link to="/appr" style={style.link}><StyledIonIcon icon={ createOutline } />전자결재</Link></li></StyledLiWrap>
        <StyledLiWrap><li><Link to="/attend" style={style.link}><StyledIonIcon icon={ briefcaseOutline } />근태관리</Link></li></StyledLiWrap>
        <StyledLiWrap><li><Link to="/employee" style={style.link}><StyledIonIcon icon={ personOutline } />내 정보 확인</Link></li></StyledLiWrap>
        <StyledLiWrap><li><Link to="/chatbot" style={style.link}><StyledIonIcon icon={ chatbubbleOutline } />챗봇</Link></li></StyledLiWrap>
        {userInfo && userInfo.authorityId === 1 && <li><Link to="/admin/listempl" style={style.link}><StyledIonIcon icon={ settingsOutline } />관리자</Link></li>}
        <StyledLiWrap1><StyledButton1 onClick={openPopup}><StyledIonIcon icon={ peopleOutline } />조직도</StyledButton1></StyledLiWrap1>
        <li className="undermax"></li>
      </Ul>
    </>
  );
};

const style = {
  link: {
    textDecoration: "none",
    color: "white"
  }
};

export default Menu;
