import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, Typography} from '@mui/material';
import {Route, Link} from 'react-router-dom'
import FromMailComponent from './FromMailComponent';
import './MailRouter.css'
import '../../route/SubHeaderLayout.css'
import '../../route/SubHeaderStyle.css'
import ToMailComponent from './ToMailComponent';
import WriteMailComponent from './WriteMailComponent';
import TempMailComponent from './TempMailComponent';
import TrashMailComponent from './TrashMailComponent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import ToDetailMailComponent from './ToDetailMailComponent';
import FromDetailMailComponent from './FromDetailMailComponent';
import TrashDetailMailComponent from './TrashDetailMailComponent';
import TempWriteMailComponent from './TempWriteMailComponent';
import ReplyMailComponent from './ReplyMailComponent';


/* 작업자 : 강승재 */
/* 메일 서브헤더와 메일 관련 컴포넌트 연결해주는 라우터페이지 */


// 아코디언 그림자 제거 함수
const theme = createTheme({
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none', // 아코디언 컴포넌트의 그림자 제거
            padding: '0px 10px',
          },
        },
      },
    },
  });

export default function MailRouter() {
    const [expanded, setExpanded] = React.useState(true);
  
    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <ThemeProvider theme={theme}>
        <div style={style}>
            <div className='main'>  {/* 고정 */}
                <div className='left'>  {/* 고정 */}
                    <div className='sub_Header'>  {/* 고정 */}
                        <div className='title' style={style.title}> {/* 고정 */}
                        <Link to="/mail/">메일</Link> {/* 각자수정 */}
                        </div>
                        <div className='sub_mailHeader'> {/* 각자수정 */}
                            <Link to="/mail/Write">
                                <Button variant="outlined" style={style.first_Button}> 메일쓰기 </Button>
                            </Link>
                            <div className='mailMenu' style={style.apprMenu}> {/* 각자수정 */}
                                    <Accordion
                                        expanded={expanded}
                                        onChange={handleExpansion}
                                        slots={{ transition: Fade }}
                                        slotProps={{ transition: { timeout: 400 } }}
                                        sx={{
                                            '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                            '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                            boxShadow: 'none' // 그림자 스타일 제거
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            style={style.back}
                                        >
                                            <Typography style={style.middleMenu}>메일함</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <Link to="/mail/To" style={style.link}>받은메일함</Link>
                                            </Typography>
                                        </AccordionDetails>
                                        <AccordionDetails>
                                            <Typography>
                                                <Link to="/mail/From" style={style.link}>보낸메일함</Link>
                                            </Typography>
                                        </AccordionDetails>
                                        <AccordionDetails>
                                            <Typography>
                                                <Link to="/mail/Temp" style={style.link}>임시보관함</Link>
                                            </Typography>
                                        </AccordionDetails>
                                        <AccordionDetails>
                                            <Typography>
                                                <Link to="/mail/Trash" style={style.link}>휴지통</Link>
                                            </Typography>
                                        </AccordionDetails>
                                        
                                    </Accordion>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='right'>
                    <Route path="/mail/" exact={true} component={ToMailComponent}></Route>
                    <Route path="/mail/From" exact={true} component={FromMailComponent}></Route>
                    <Route path="/mail/To" exact={true} component={ToMailComponent}></Route>
                    <Route path="/mail/Write" exact={true} component={WriteMailComponent}></Route>
                    <Route path="/mail/Temp" exact={true} component={TempMailComponent}></Route>
                    <Route path="/mail/Trash" exact={true} component={TrashMailComponent}></Route>
                    <Route path="/mail/detailTo" exact={true} component={ToDetailMailComponent}></Route>
                    <Route path="/mail/detailFrom" exact={true} component={FromDetailMailComponent}></Route>
                    <Route path="/mail/detailTrash" exact={true} component={TrashDetailMailComponent}></Route>
                    <Route path="/mail/detailTemp" exact={true} component={TempWriteMailComponent}></Route>
                    <Route path="/mail/replyMail" exact={true} component={ReplyMailComponent}></Route>

                    
                </div>
                

                
            </div>
        
        </div>
        </ThemeProvider>
    );
}

const style = {
    link: {
      textDecoration: "none",
      color: "#646464",
      fontSize: "15px"
    },

    title: {
        marginTop: '30px',
        fontSize: '21px',
        textAlign: 'left',
        textDecoration: "none",
    },

    // title 아래 버튼
    first_Button: {
        marginTop: '5px',
        paddingTop: '10px',
        paddingRight: '60px',
        paddingBottom: '10px',
        paddingLeft: '60px',
        color: '#323232',
        borderColor: '#6e6e6e'
    },

    apprMenu: {
        marginTop: '30px'
    },

    middleMenu: {
        fontWeight: 550,
        color: '#5a5a5a'
    }
  };