import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './NewApprModal.module.css';

const NewApprModal = ({ isOpen, closeModal }) => {
    const [selectedOption, setSelectedOption] = useState("업무기안"); // 선택된 옵션 상태

    // 모달이 열릴 때 초기화
    // useEffect(() => {
    //     setSelectedOption(""); // 모달이 열릴 때 selectedOption 초기화
    // }, [isOpen]);

    // 옵션 선택 시 상태 업데이트
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        console.log("Selected Option:", selectedOption);
    };

    // 확인 버튼 클릭 시
    const handleConfirm = () => {
        console.log("Selected Option:", selectedOption);
        // 선택된 옵션과 함께 모달 닫기
        closeModal(selectedOption); // 선택된 옵션 전달
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customModalStyles}
            ariaHideApp={false}
            contentLabel="Modal"
        >
            <div class={styles.contentWrap}></div>
            {/* 모달 제목 */}
            <div class={styles.modalTitle}>결재양식 선택</div>
            <br/><br/>
            {/* 옵션 선택 */}
            <select className={styles.selectBox} value={selectedOption} onChange={handleOptionChange}>
                <option value="업무기안">업무기안</option>
                <option value="휴가신청">휴가신청</option>
            </select>
            <br/><br/>
            {/* 확인 및 취소 버튼 */}
            <div class={styles.btnWrap}>
                <button className={styles.confirmBtn} onClick={handleConfirm}>확인</button>
                <button className={styles.closeBtn} onClick={closeModal}>취소</button>
            </div>
        </Modal>
    );
};

const customModalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        zIndex: "1000"
    },
    content: {
        width: "300px",
        height: "210px",
        margin: "auto",
        backgroundColor: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "25px",
    }
};

export default NewApprModal;
