import React, { Component } from 'react';
import './KakaoEventModal.css'; // CSS 파일 임포트
import { withRouter} from 'react-router-dom';  
import KakaoMap from './MapModal';

class KakaoEventModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clickedAddress: '', // 초기 상태 설정
            clickedPlaceName: '' // 클릭한 위치의 업장명 초기 상태 설정
        };
    }

    handleClickedAddress = (address) => {
        this.setState({ clickedAddress: address });
    };

    handleClickedPlaceName = (placeName) => {
        this.setState({ clickedPlaceName: placeName });
    };

    handleSuccess = () => {
        const { clickedAddress } = this.state;
        // 확인 버튼 클릭 시, 클릭한 위치의 주소값 부모 컴포넌트로 전달
        this.props.onAddressSelect(clickedAddress);
        // 모달 닫기
        this.props.onClose();
    };
    

    render() {
        const { onClose} = this.props;

        return (
            <div className='kakao-modal'>
                <div className='kakao-box'>
                    <header className="kakao-header">
                        <h3>지도 보기</h3>
                    </header>
                    {/* 지도를 표시하는 컴포넌트 */}
                    <div className="map-container">
                        <KakaoMap 
                            onAddressClick={this.handleClickedAddress} 
                            /* onPlaceNameClick={this.handleClickedPlaceName} */
                        />
                    </div>
                    {/* 클릭한 위치의 업장명을 표시하는 부분 */}
                    {/* <div className="clicked-place">
                        클릭한 위치의 업장명: {this.state.clickedPlaceName}
                    </div> */}
                    {/* 클릭한 위치의 주소를 표시하는 부분 */}
                    <div className="clicked-address">
                        클릭한 위치의 주소: {this.state.clickedAddress}
                    </div>
                    <div className="content">
                        <button className="btn_kko1" type="button" onClick={this.handleSuccess} title="확인">
                                확인
                        </button>
                        <button className="btn_kko" type="button" onClick={onClose} title="취소">
                            취소
                        </button>
                    </div>
                </div>
            </div>
        );
    } 
}

export default withRouter(KakaoEventModal);
