import React, { Component, history} from 'react';
import ApiService from "../Attention/AttentionApiService"
import { Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import "../../page_kang/mail/MailRouter.css";
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { attachOutline } from 'ionicons/icons';

const StyledTableWrap = styled.div`
  padding: 8px; /* 원하는 패딩 값으로 설정 */
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding-bottom: 10px;
  display: flex;
`;

const StyledIonIcon = styled(IonIcon)`
  font-size: 22px;
  color: black;
  margin-right: 3px;
`;

class Jsoup extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {

            newsData : []

        }
    }
    
    // 라이프 사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    // render 후 dom이 반영되었을 때(로딩이 끝났을 때) 
    // componentDidMount 상태로 진입 이 상태일 때 아래 함수를 실행
    // 개발단계에선 이 부분이 두번 호출됨
    // 두번 안보고 싶으면 index.js 에서 StrictMode 컴포넌트를 없애면 된다.

    componentDidMount() {
        this.getNews();
       }

    getNews = () => {
        ApiService.getNews()  // 2.
        .then(res => {             // 4.
            
            this.setState({
                newsData : res.data
            });

        })
        .catch(err => {
            console.log('getNews() Error!', err);
        })
        
    }


    render() {

        let keyval = 1;

        return (
            <StyledTableWrap>
            <Table >
            <TableHead>
                <TableRow style={{borderBottom: "1px solid lightgrey"}}>
                <StyledTitle><StyledIonIcon icon={ attachOutline } />최신뉴스</StyledTitle>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.state.newsData.length > 0 ? (
                    this.state.newsData.slice(0, 5).map(dto => {
                        

                        return(
                        
                        <TableRow key={keyval = keyval + 1} style={{ textAlign: "center", borderBottom: '1px solid lightgray'}}>
                            
                            <TableCell style={{padding: '8px 0 8px 5px'}}> <a href={dto.link} target='_blank' style={{}}>{dto.title}</a> </TableCell>
                        </TableRow>
                        )

                        

                        
                    }
                    
                    )
                ) : (
                    <div>뉴스가 없습니다.</div>
                )}
            </TableBody>
        </Table>
        </StyledTableWrap>
        );

    }

}



export default Jsoup;