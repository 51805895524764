// SearchBar.js

import React, { Component } from 'react';
// import styles from './SearchBar.module.css';

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '', // 검색어
        };
    }

    handleInputChange = (event) => {
        this.setState({ query: event.target.value });
    };

    handleSearch = () => {
        const { query } = this.state;
        // 네이버 오픈 API 호출
        fetch('https://openapi.naver.com/v1/search/webkr?query=' + query, {
            headers: {
                'X-Naver-Client-Id': 'aQeQ7X5fSjr0hYNLH8OV',
                'X-Naver-Client-Secret': 'OaMD7hQTiJ'
            }
        })
        .then(response => response.json())
        .then(data => this.setState({ searchResults: data.items }));
        this.props.onSearch(query); // 부모 컴포넌트로 검색어 전달
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSearch();
        }
    };

    render() {
        const { query } = this.state;

        return (
            <div className='searchContainer'>
                <input
                    type="text"
                    value={query}
                    onChange={this.handleInputChange}
                    onKeyPress={this.handleKeyPress}
                    placeholder="검색어를 입력하세요"
                />
                <button onClick={this.handleSearch}>검색</button>
            </div>
        );
    }
}

export default SearchBar;
