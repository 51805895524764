import React, { Component } from 'react';
import './Reservation.css';
import { IonIcon } from '@ionic/react';
import { caretDownOutline } from 'ionicons/icons';
import CalendarApiService from './CalendarApiService.js';

const rows = [
    {
        id: 1,
        roomId: 1,
        room: 'Spring Room',
        slots: Array.from({ length: 26 }).fill(''),
    },
    {
        id: 2,
        roomId: 2,
        room: 'Summer Room',
        slots: Array.from({ length: 26 }).fill(''),
    },
    {
        id: 3,
        roomId: 3,
        room: 'Autumn Room',
        slots: Array.from({ length: 26 }).fill(''),
    },
    {
        id: 4,
        roomId: 4,
        room: 'Winter Room',
        slots: Array.from({ length: 26 }).fill(''),
    },
];

const slotTimesLookup = {
    0: '09:00',
    1: '09:30',
    2: '10:00',
    3: '10:30',
    4: '11:00',
    5: '11:30',
    6: '12:00',
    7: '12:30',
    8: '13:00',
    9: '13:30',
    10: '14:00',
    11: '14:30',
    12: '15:00',
    13: '15:30',
    14: '16:00',
    15: '16:30',
    16: '17:00',
    17: '17:30',
    18: '18:00',
    19: '18:30',
    20: '19:00',
    21: '19:30',
    22: '20:00',
    23: '20:30',
    24: '21:00',
    25: '21:30',
    26: '22:00'
};

class Reservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calNo:'',
            calStartDT:'',
            calStartTM: '',
            calEndTM: '',
            reservations: [],
        };
    }

    componentDidMount() {
        CalendarApiService.selectReservation()
        .then(response => {
            const reservationsData = response.data; // API로부터 받아온 예약 정보 데이터
            console.log('DB 데이터:', reservationsData); // DB 데이터 콘솔 출력
            
            // 각 방의 예약 정보를 담을 배열
            const roomReservations = [];
        
            // API 응답에서 필요한 정보를 추출하여 예약 정보 객체 생성 후 배열에 추가
            reservationsData.forEach(reservation => {
                const { roomId, calStartTM, calEndTM } = reservation;
                const roomReservation = {
                    roomId: roomId,
                    calStartTM: calStartTM,
                    calEndTM: calEndTM
                };
                console.log('생성된 예약 정보:', roomReservation); // 생성된 예약 정보 콘솔 출력
                roomReservations.push(roomReservation);
            });
        
            // 예약 정보 배열을 상태로 설정
            this.setState({ reservations: roomReservations }, () => {
                console.log('배열을 상태로 설정:', this.state.reservations);
                // 예약된 시간대에 해당하는 셀에 클래스 추가
                this.updateTimeSlotsHighlight();
            });
        });
    }
    
    // 주어진 시간에 해당하는 인덱스를 찾는 메서드
    findIndexByTime(time) {
        return Object.keys(slotTimesLookup).find(key => slotTimesLookup[key] === time);
    }
    
    // 예약된 시간대에 해당하는 셀에 클래스 추가하는 메서드
    updateTimeSlotsHighlight() {
        const { calStartTM, calEndTM, reservations } = this.state;
        const startSlotIndex = this.findIndexByTime(calStartTM);
        const endSlotIndex = this.findIndexByTime(calEndTM);
    
        if (startSlotIndex !== undefined && endSlotIndex !== undefined) {
            for (let i = startSlotIndex; i <= endSlotIndex; i++) {
                const slotCell = document.getElementById(`slot-${i}`);
                if (slotCell) {
                    slotCell.classList.add('start-time-highlight');
                }
            }
        }
        
        // 예약된 시간대에 해당하는 셀에 클래스 추가
        reservations.forEach(reservation => {
            const room = rows.find(row => row.roomId === reservation.roomId);
            if (room) {
                const startSlotIndex = this.findIndexByTime(reservation.calStartTM);
                const endSlotIndex = this.findIndexByTime(reservation.calEndTM);
                if (startSlotIndex !== undefined && endSlotIndex !== undefined) {
                    for (let i = startSlotIndex; i <= endSlotIndex; i++) {
                        const slotCell = document.getElementById(`slot-${i}`);
                        if (slotCell) {
                            slotCell.classList.add('reservation-highlight');
                        }
                    }
                }
            }
        });
    }
    

    render() {
        const { reservations } = this.state;
        return (
            <div className="full-res">
                <div className="reservation">
                    <header className="res-header">
                        회의실 예약현황
                    </header>
                    <div className="res-line"></div>
                    <div className="res-content">
                        <table>
                            <tbody>
                                {rows.map((row, rowIndex) => (
                                    <React.Fragment key={row.roomId}>
                                        <tr>
                                            <td
                                                // colSpan={row.slots.length} // 동적으로 colSpan 설정
                                                colSpan={2} // 룸이 3칸만 차지하도록 설정
                                                className={`reservation-row room-${row.roomId}`}
                                            >
                                                <div className="icon-container1">
                                                    {row.room}
                                                    {/* <IonIcon icon={caretDownOutline} className="caretDownOutline-icon" /> */}
                                                </div>
                                            </td>
                                        </tr>
                                        {[...Array(Math.ceil(row.slots.length / 9)).keys()].map((index) => (
                                            <tr key={index}>
                                                {row.slots.slice(index * 9, (index + 1) * 9).map((slot, slotIndex) => {
                                                    const startTime = slotTimesLookup[slotIndex + (index * 9)];
                                                    const endTime = slotTimesLookup[slotIndex + (index * 9) + 1]; // 다음 시간을 종료 시간으로 사용
                                                    const reservationsInRange = reservations.filter(reservation => reservation.roomId === row.roomId 
                                                                                                                    && reservation.calStartTM <= startTime 
                                                                                                                    && reservation.calEndTM >= endTime);
                                                    const className = reservationsInRange.length > 0 ? 'reservation-highlight' : '';
                                                    return (
                                                        <td
                                                            key={slotIndex}
                                                            className={`reservation-row ${className} room-${row.roomId}-slot`}
                                                        >
                                                            {slotTimesLookup[slotIndex + (index * 9)]}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                        {/* 룸 간 간격을 위한 추가 행 */}
                                        {rowIndex !== rows.length - 1 && (
                                            <tr key={`spacing-${rowIndex}`}>
                                                <td
                                                    colSpan={2} // 룸 간 간격을 위해 빈 셀을 추가
                                                    className="room-spacing"
                                                >
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reservation;
