import React, { Component } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Modal } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import './List.css';
import ApiService from './ApiService';

class DepartList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOn: false,
            isAddModalOpen: false,
            isDetailModalOpen: false,
            selectedDepartment: null,
            departments: [],
            selectedDepartments: [],
            isAllSelected: false
        };
    }
    
    componentDidMount() {
        this.fetchDepartment();
    }

    fetchDepartment() {
        ApiService.fetchDepartment()
            .then(res => {
                const { isOn } = this.state;
                let filteredDepartments;
                if (isOn) {
                    filteredDepartments = res.data;
                } else {
                    filteredDepartments = res.data.filter(department => department.departmentActive === 'Y');
                }
                this.setState({ departments: filteredDepartments });
            })
            .catch(err => {
                console.error('Error fetching departments:', err);
            });
    } 

    toggleSwitch = () => {
        this.setState(prevState => ({ isOn: !prevState.isOn }), () => {
            this.fetchDepartment();
        });
    }

    handleAddModalOpen = () => {
        this.setState({ isAddModalOpen: true });
    };

    handleAddModalClose = () => {
        this.setState({ isAddModalOpen: false });
    };

    handleDepartmentSubmit = (e) => {
        e.preventDefault();
    
        const departmentName = e.target.departmentName.value;
    
        if (!departmentName) {
            alert('부서명을 입력하세요.');
            return;
        }
    
        ApiService.addDepartment(departmentName)
            .then(res => {
                this.fetchDepartment();
                this.handleAddModalClose();
            })
            .catch(err => {
                console.error('Error adding department:', err);
                alert('부서를 추가하는데 문제가 발생했습니다.');
            });
    };

    handleDetailModalOpen = (department) => {
        this.setState({ selectedDepartment: department, isDetailModalOpen: true });
    };
    
    handleDetailModalClose = () => {
        this.setState({ isDetailModalOpen: false });
    };
    
    handleStatusChange = (departmentId, departmentActive) => {
        ApiService.updateDepartmentStatus(departmentId, departmentActive)
            .then(res => {
                this.fetchDepartment();
                this.handleDetailModalClose();
            })
            .catch(error => {
                console.error('부서 상태 변경 오류:', error);
            });
    };   
    
    handleToggleAllDepartments = () => {
        const { isOn, departments, selectedDepartments, isAllSelected } = this.state;
        if (isAllSelected) {
            const filteredDepartments = isOn ? departments.filter(department => department.departmentActive === 'Y') : departments;
            this.setState({ selectedDepartments: [], isAllSelected: false });
        } else {
            const filteredDepartments = isOn ? departments.filter(department => department.departmentActive === 'Y') : departments;
            const newSelectedDepartments = filteredDepartments.map(department => department.departmentId);
            this.setState({ selectedDepartments: newSelectedDepartments, isAllSelected: true });
        }
    };

    isDepartmentSelected = (departmentId) => {
        const { selectedDepartments } = this.state;
        return selectedDepartments.some(department => department === departmentId);
    };

    toggleActivation = () => {
        const { selectedDepartments, isOn } = this.state;
        const departmentActive = isOn ? 'N' : 'Y';
    
        Promise.all(selectedDepartments.map(department => {
            return ApiService.updateDepartmentStatus(department, departmentActive);
        }))
        .then(res => {
            this.fetchDepartment();
            this.setState({ selectedDepartments: [] });
        })
        .catch(error => {
            console.error('부서 상태 변경 오류:', error);
        });
    };

    toggleMapActivation = () => {
        const { selectedDepartments } = this.state;
        
        // 선택된 부서가 없는 경우 알림창 표시
        if (selectedDepartments.length === 0) {
            alert("선택된 부서가 없습니다. 부서를 선택해주세요.");
            return;
        }
        
        // 선택된 부서들의 상태를 각각 토글하여 업데이트합니다.
        selectedDepartments.forEach(departmentId => {
            // 선택된 부서의 현재 상태를 가져옵니다.
            ApiService.getDepartmentStatus(departmentId)
                .then(res => {
                    // 가져온 상태를 토글하여 새로운 상태를 계산합니다.
                    const newStatus = res.data === 'Y' ? 'N' : 'Y';
                    
                    // 새로운 상태를 서버에 업데이트합니다.
                    return ApiService.updateDepartmentStatus(departmentId, newStatus);
                })
                .then(() => {
                    // 각 부서의 상태를 변경한 후에는 부서 목록을 다시 가져와서 화면을 업데이트합니다.
                    this.fetchDepartment();
                    // 선택된 부서 목록을 초기화합니다.
                    this.setState({ selectedDepartments: [] });
                })
                .catch(error => {
                    console.error('부서 상태 변경 오류:', error);
                });
        });
    };
    
    handleToggleDepartment = (departmentId) => {
        const { selectedDepartments } = this.state;
        const updatedSelectedDepartments = this.isDepartmentSelected(departmentId)
            ? selectedDepartments.filter(department => department !== departmentId)
            : [...selectedDepartments, departmentId];
        this.setState({ selectedDepartments: updatedSelectedDepartments });
    };    
    
    handleSelectAllRows = () => {
        const { departments, selectedDepartments, isAllSelected } = this.state;
        if (isAllSelected) {
            this.setState({ selectedDepartments: [], isAllSelected: false });
        } else {
            const newSelectedDepartments = departments.map(department => department.departmentId);
            this.setState({ selectedDepartments: newSelectedDepartments, isAllSelected: true });
        }
    };    

    render() {
        const { isOn, departments, isAddModalOpen, isDetailModalOpen, selectedDepartment, isAllSelected } = this.state;

        return(
            <Router>
                <div display="flex" alignItems="center" justifyContent="center">
                    {/* 부서 추가 모달창 */}
                    <Modal
                        open={isAddModalOpen}
                        onClose={this.handleAddModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <div id="modalContent">
                            <h2 id="modal-modal-title" style={{ padding: '15px 15px 8px 15px' }}>부서 추가</h2>
                            <span id="modalCloseButton" className="close" onClick={this.handleAddModalClose}>&times;</span>
                            <div>
                                <form onSubmit={this.handleDepartmentSubmit}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ textAlign: 'left', padding: '15px', fontSize: '20px', margin: 0 }}>부서명</p>
                                        <input style={{ height: '25px', width: '250px', borderRadius: '3px', padding: '5px' }} id="departmentName" label="departmentName" placeholder="최대 10자 까지만 입력 가능" maxLength={10} />
                                    </div>
                                    <button id="modalClose" type="submit" variant="contained" > 추가 </button>
                                </form>
                            </div>
                        </div>
                    </Modal>

                    {/* 부서 상세 모달창 */}
                    <Modal
                        open={isDetailModalOpen}
                        onClose={this.handleDetailModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <div id="modalContent">
                            <h2 id="modal-modal-title" style={{ padding: '15px 15px 8px 15px' }}>부서 상세 정보</h2>
                            
                            <span id="modalCloseButton" className="close" onClick={this.handleDetailModalClose}>&times;</span>
                            <div>
                            {selectedDepartment && (
                                <div>
                                    <p style={{ padding: '0 15px 5px 15px' }}>부서 이름: {selectedDepartment.departmentName}</p>
                                    <p style={{ padding: '0 15px' }}>부서 상태: {selectedDepartment.departmentActive === 'Y' ? '활성화' : '비활성화'}</p>
                                    {/* 상태에 따라 버튼을 다르게 표시 */}
                                    {selectedDepartment.departmentActive === 'Y' ? (
                                        <button onClick={() => this.handleStatusChange(selectedDepartment.departmentId, 'N')} 
                                        style={{ cursor: 'pointer', position: 'absolute', fontSize: '13px', right: '30px', width: '100px', height: '30px', bottom: '40px', backgroundColor: 'white', border: '1px solid #ddd' }}>
                                            비활성화하기
                                        </button>
                                    ) : (
                                        <button onClick={() => this.handleStatusChange(selectedDepartment.departmentId, 'Y')}
                                        style={{ cursor: 'pointer', position: 'absolute', fontSize: '13px', right: '30px', width: '90px', height: '30px', bottom: '40px', backgroundColor: 'white', border: '1px solid #ddd' }}>
                                            활성화하기
                                        </button>
                                    )}
                                </div>
                            )}
                            </div>
                        </div>
                    </Modal>

                    {/* 부서 추가 및 부서 목록 테이블 */}
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '50px 50px 25px 50px', width: '100%' }}>
                            <h1 style={{ textAlign: 'left', margin: 0 }}> 부서 관리 </h1>
                            <button className="insertBtn" onClick={this.handleAddModalOpen}>+ 부서 추가하기</button>
                        </div>
                        <div className="toggle-container">
                            비활성화된 부서들 보기
                            <label className={isOn ? 'toggle toggle-on' : 'toggle toggle-off'} onClick={this.toggleSwitch}>
                                <span className="handle" />
                            </label>
                        </div>
                        <div className="activeBtn" style={{ marginLeft: '50px', paddingBottom: '25px', fontWeight: 'bold', fontSize: '1.4em' }}>
                            <button onClick={this.toggleMapActivation} style={{ marginRight: '10px' }}>활성화하기</button>
                            <button onClick={this.toggleMapActivation}>비활성화하기</button>
                        </div>
                        <hr style={{ margin: '0 10px' }} />
                        <Table style={{ margin: '0 10px' }}>
                            <TableHead>
                                <TableRow style={{ borderBottom: '3px solid #ccc' }}>
                                    <TableCell className='thCheckbox' onClick={this.handleToggleAllDepartments}>
                                        <b>
                                            <Checkbox
                                                className="checkbox-small"
                                                size="small"
                                                checked={isAllSelected}
                                                onChange={this.handleSelectAllRows}
                                            />
                                        </b>
                                    </TableCell>
                                    <TableCell style={{ width: '250px' }}><b>부서명</b></TableCell>
                                    <TableCell><b>부서 상태</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {departments.map(department => (
                                    <TableRow key={department.departmentId}
                                        className={this.isDepartmentSelected(department.departmentId) ? "selected-dept" : ""}>
                                        <TableCell>
                                            <Checkbox
                                                className="checkbox-small"
                                                size="small"
                                                checked={this.isDepartmentSelected(department.departmentId)}
                                                onChange={() => this.handleToggleDepartment(department.departmentId)}
                                            />
                                        </TableCell>
                                        <TableCell onClick={() => this.handleDetailModalOpen(department)}>{department.departmentName}</TableCell>
                                        <TableCell onClick={() => this.handleDetailModalOpen(department)}>{department.departmentActive}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Router>
        );
    }
}

export default DepartList;
