import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Table, TableCell, TableHead, TableRow, TableBody, Checkbox } from '@mui/material';
import Modal from '@mui/material/Modal';
import './List.css';
import AddForm from './AddForm';
import ApiService from './ApiService';
import Pagination from "./Pagination";

class ListEmpl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOn: false,
            open: false,
            employees: [], // 직원 목록을 저장할 배열
            selectedRows: new Set(), // 선택된 TableRow의 인덱스를 저장할 Set
            limit: 10,
            page: 1,
            isAllSelected: false, // 전체 선택 여부를 저장할 변수
            searchKeyword: '' // 검색어를 저장할 변수
        };
    }

    componentDidMount() {
        this.fetchEmployees();
    }

    fetchEmployees = (resignationDateFilter, searchKeyword) => {
        ApiService.fetchEmployees(resignationDateFilter, searchKeyword)
            .then(res => {
                console.log(res.data);
                this.setState({ employees: res.data });
            })
            .catch(err => {
                console.error('Error fetching employees:', err);
            });
    };       

    handleTableRowClick = (employeeId) => {
        window.localStorage.setItem("selectEmployeeId", employeeId);
        this.props.history.push(`/admin/editempl`);
    }

    toggleSwitch = () => {
        const { isOn } = this.state;
        const newIsOn = !isOn; // 토글된 값을 저장할 변수
        const resignationDateFilter = newIsOn ? 'N' : null; // 토글된 값에 따라 필터링할 퇴사 여부 설정
        
        this.setState({ isOn: newIsOn }, () => {
            // toggleSwitch 함수가 호출될 때마다 데이터를 다시 로드합니다.
            this.fetchEmployees(resignationDateFilter);
        });
    };
    
    // 퇴사자 포함 직원 전체 조회
    handleOpen = () => {
        this.setState({ open: true });
    };

    // 퇴사 여부 'N'인 직원들만 조회
    handleClose = () => {
        this.setState({ open: false });
    };

    // TableRow 전체 선택 혹은 해제
    handleSelectAllRows = () => {
        const { employees, selectedRows, isAllSelected } = this.state;
        if (isAllSelected) {
            this.setState({ selectedRows: new Set(), isAllSelected: false });
        } else {
            const newSelectedRows = new Set(employees.map((employee, index) => index));
            this.setState({ selectedRows: newSelectedRows, isAllSelected: true });
        }
    };

    // TableRow 선택 혹은 해제
    handleSelectRow = (index) => {
        const { selectedRows } = this.state;
        const newSelectedRows = new Set(selectedRows);
        if (newSelectedRows.has(index)) {
            newSelectedRows.delete(index);
        } else {
            newSelectedRows.add(index);
        }
        this.setState({ selectedRows: newSelectedRows });
    };

    toggleActivation = () => {
        const { employees, selectedRows } = this.state;
        
        if (selectedRows.size === 0) {
            alert("선택된 직원이 없습니다. 직원을 선택해주세요.");
            return;
        }
        
        const resignDate = 'N'; // 퇴사 처리되는 경우 'N'으로 설정
        
        // 업데이트 요청을 병렬로 보냄
        Promise.all([...selectedRows].map(index => {
            const employeeId = employees[index].employeeId;
            return ApiService.updateEmployeeResignationDate(employeeId, resignDate);
        }))
        .then(() => {
            this.setState({ selectedRows: new Set() });
            this.fetchEmployees();
        })
        .catch(error => {
            console.error('퇴사 여부 변경 오류:', error);
        });
    };

    toggleDisable = () => {
        const { employees, selectedRows, isOn } = this.state;
        
        if (selectedRows.size === 0) {
            alert("선택된 직원이 없습니다. 직원을 선택해주세요.");
            return;
        }
        
        // 현재 날짜 설정
        const currentDate = new Date().toISOString().split('T')[0];
        
        // 업데이트 요청을 병렬로 보냄
        Promise.all([...selectedRows].map(index => {
            const employeeId = employees[index].employeeId;
            return ApiService.updateEmployeeResignationDate(employeeId, currentDate);
        }))
        .then(() => {
            this.setState({ selectedRows: new Set() });
            this.fetchEmployees();
        })
        .catch(error => {
            console.error('퇴사 여부 변경 오류:', error);
        });
    };

    handleLimitChange = (event) => {
        const { isOn } = this.state;
        const limit = Number(event.target.value);
        const resignationDateFilter = isOn ? 'N' : null;
        this.setState({ limit }, () => {
            // 페이지당 보여지는 직원 수를 변경할 때마다 퇴사 여부에 따라 데이터 필터링 후 다시 로드
            this.fetchEmployees(resignationDateFilter);
        });
    };    

    handlePageChange = (pageNumber) => {
        this.setState({ page: pageNumber }, () => {
            this.fetchEmployees();
        });
    };

    handleSearchChange = (event) => {
        const searchKeyword = event.target.value;
        this.setState({ searchKeyword }, () => {
            const { isOn } = this.state;
            const resignationDateFilter = isOn ? 'N' : null;
            this.fetchEmployees(resignationDateFilter, searchKeyword);
        });
    };    

    handleSearch = () => {
        const { searchKeyword } = this.state;
        const resignationDateFilter = this.state.isOn ? 'N' : null;
        this.fetchEmployees(resignationDateFilter, searchKeyword);
    };    

    render() {
        const { isOn, open, employees, selectedRows, isAllSelected, searchKeyword, limit, page } = this.state;
        const offset = (page - 1) * limit;

        const filteredEmployees = employees.filter(employee => {
            return (
                employee.employeeId.includes(searchKeyword) ||
                employee.employeeName.includes(searchKeyword) ||
                employee.departmentName.includes(searchKeyword) ||
                employee.positionName.includes(searchKeyword)
            );
        });

        return (
            <Router>
                <div display="flex" alignItems="center" justifyContent="center">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '50px 50px 25px 50px', width: '100%' }}>
                        <h1 style={{ textAlign: 'left', margin: 0 }}> 직원 관리 </h1>
                        <Modal
                            open={open}
                            onClose={this.handleClose}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <AddForm onClose={this.handleClose} />
                        </Modal>
                    </div>
                    {/* <div className="toggle-container">
                        비활성화된 직원 보기
                        <label className={isOn ? 'toggle toggle-on' : 'toggle toggle-off'} onClick={this.toggleSwitch}>
                            <span className="handle" />
                        </label>
                    </div> */}
                    <p style={{ marginLeft: '50px', padding: '10px 0 25px 0', fontWeight: 'bold', fontSize: '1.4em' }}>
                        총 직원수 : {employees.filter(employee => employee.resignationDate === 'N').length}
                        <div className="activeBtn" style={{ display: 'flex' }}>
                            <button onClick={this.toggleActivation} style={{ marginRight: '10px' }}>활성화하기</button>
                            <button onClick={this.toggleDisable}>비활성화하기</button>
                            <div style={{ marginLeft: 'auto' }}>
                                <input className="rightAlignInput" value={searchKeyword} onChange={this.handleSearchChange} placeholder="검색어를 입력하세요"></input>
                                <button onClick={this.handleSearch} style={{marginRight: '50px'}}>검색</button>
                            </div>
                        </div>
                    </p>
                    <hr style={{ margin: '0 10px' }} />
                    <Table style={{ margin: '0 10px' }}>
                        <TableHead>
                            <TableRow style={{ borderBottom: '3px solid #ccc' }}>
                                <TableCell className="thCheckbox">
                                    <b>
                                        <Checkbox
                                            className="checkbox-small"
                                            size="small"
                                            checked={isAllSelected}
                                            onChange={this.handleSelectAllRows}
                                        />
                                    </b>
                                </TableCell>
                                <TableCell className="thEmplId"><b> 사원번호 </b></TableCell>
                                <TableCell className="thEmplName"><b> 이름 </b></TableCell>
                                <TableCell className="thAuthority"><b> 권한 </b></TableCell>
                                <TableCell className="thEmplDate"><b> 입사일 </b></TableCell>
                                <TableCell className="thDepartment"><b> 부서 </b></TableCell>
                                <TableCell className="thPosition"><b> 직위 </b></TableCell>
                                <TableCell className="thResignation"><b> 퇴사여부 </b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredEmployees.slice(offset, offset + limit)
                                .map((employee, index) => (
                                <TableRow
                                    key={employee.employeeId}
                                    selected={selectedRows.has(index)}
                                >
                                    <TableCell>
                                        <Checkbox
                                            className="checkbox-small"
                                            size="small"
                                            checked={selectedRows.has(index)}
                                            onChange={() => this.handleSelectRow(index)}
                                        />
                                    </TableCell>
                                    <TableCell onClick={() => this.handleTableRowClick(employee.employeeId)}><b> {employee.employeeId} </b></TableCell>
                                    <TableCell onClick={() => this.handleTableRowClick(employee.employeeId)}><b> {employee.employeeName} </b></TableCell>
                                    <TableCell onClick={() => this.handleTableRowClick(employee.employeeId)}><b> {employee.authorityId} </b></TableCell>
                                    <TableCell onClick={() => this.handleTableRowClick(employee.employeeId)}><b> {employee.employDate} </b></TableCell>
                                    <TableCell onClick={() => this.handleTableRowClick(employee.employeeId)}><b> {employee.departmentName} </b></TableCell>
                                    <TableCell onClick={() => this.handleTableRowClick(employee.employeeId)}><b> {employee.positionName} </b></TableCell>
                                    <TableCell onClick={() => this.handleTableRowClick(employee.employeeId)}><b> {employee.resignationDate} </b></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className='mail.paging_div'>
                        <Pagination
                            total={employees.length}
                            limit={limit}
                            page={page}
                            setPage={this.handlePageChange}
                        />
                    </div>
                </div>
                <label>
                    &nbsp;
                    <select
                        type="number"
                        value={limit}
                        onChange={this.handleLimitChange}
                    >
                        <option value="10">10개씩</option>
                        <option value="15">15개씩</option>
                        <option value="20">20개씩</option>
                        <option value="30">30개씩</option>
                        <option value="50">50개씩</option>
                    </select>
                </label>
            </Router>
        );
    }
}

export default ListEmpl;
