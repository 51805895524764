import React, { Component } from 'react';
import styled from "styled-components";
import { IonIcon } from '@ionic/react';
import { chevronBackOutline} from 'ionicons/icons';
import { chevronForwardOutline} from 'ionicons/icons';

class Pagination extends Component {
  render() {
    const { total, limit, page, setPage } = this.props;
    const numPages = Math.ceil(total / limit);
    const maxPageNumbers = 10; // 한 번에 보여줄 최대 페이지 번호 개수
    const halfPageNumbers = Math.floor(maxPageNumbers / 2); // 현재 페이지의 앞뒤에 보여질 페이지 번호의 개수

    let startPage = Math.max(1, page - halfPageNumbers);
    let endPage = Math.min(numPages, startPage + maxPageNumbers - 1);

    if (endPage - startPage < maxPageNumbers - 1) {
      startPage = Math.max(1, endPage - maxPageNumbers + 1);
    }

    return (
      <Nav>
        <Button className="mailprevButton" onClick={() => setPage(page - 1)} disabled={page === 1}>
          <IonIcon icon={chevronBackOutline} />
        </Button>
        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((pageNumber) => (
          <PageButton
            key={pageNumber}
            onClick={() => setPage(pageNumber)}
            aria-current={page === pageNumber ? "page" : undefined}
          >
            {pageNumber}
          </PageButton>
        ))}
        <Button className="mailnextButton" onClick={() => setPage(page + 1)} disabled={page === numPages}>
          <IonIcon icon={chevronForwardOutline} />
        </Button>
      </Nav>
    );
  }
}

const Nav = styled.nav`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  border: none;
  margin: 0;
  color: black;
  font-size: 14px;
  background-color: white;
  line-height: 10px;
  width: 28px;
  height: 28px;

  &:hover {
    cursor: pointer;
    border: 1px solid lightgray;
  }

  &[disabled] {
    cursor: revert;
    transform: revert;
  }
`;

const PageButton = styled(Button)`
  width: 28px;
  height: 28px;
  border: 1px solid transparent;
  transition: border-color 0.1s;
  margin-left: 2px;
  margin-right: 5px;

  &:hover {
    border-color: lightgray; 
  }

  ${(props) =>
    props['aria-current'] === 'page' &&
    `
    border-color: black; /* 현재 페이지일 때 border 색상 변경 */
  `}
`;

export default Pagination;
