import React from 'react';
import moment from "moment";
import Timeline from 'react-calendar-timeline';
import './Timeline.css'

const groups = [{ id: 1, title: "group 1" }];

const items = [
  {
    id: 1,
    group: 1,
    title: "",
    start_time: moment(),
    end_time: moment().add(2, "hour")
  }
  ,
  {
    id: 2,
    group: 1,
    title: "출근",
    start_time: moment(),
    end_time: moment().add(1, "hour")
  },
  {
    id: 3,
    group: 1,
    title: "퇴근",
    start_time: moment().add(-1, "hour"),
    end_time: moment()
  }
  
  
];

const visibleTimeStart = moment().startOf("day").valueOf();
const visibleTimeEnd = moment().startOf("day").add(1, "day").valueOf();

const defaultTimeRange = visibleTimeEnd - visibleTimeStart;

const handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
  updateScrollCanvas(moment().valueOf(), 
  moment().valueOf());
}

class CustomTimeline extends React.Component{
  
  
  
  render(){
    
    return(
      <div>
        <div style={style}> 
          <div style={style2}>
        <Timeline groups={groups} items={items}
          defaultTimeStart={visibleTimeStart}
          defaultTimeEnd={visibleTimeEnd}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          
          canMove= {false}
          canResize={false}
          minResizeWidth={0}
          
          buffer={1}
          
          
        />
        </div>
        </div>
      </div>
    );
  }

}

const style = {
  margin: "auto",
  maxWidth: "1500px", 
  width: "100%"
}

const style2 = {
  
}

export default CustomTimeline;
