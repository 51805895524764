import React, { Component} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { withRouter } from 'react-router-dom';  // 목록버튼 때문에 추가
import { TextField } from '@mui/material';
import Container from "@mui/material/Container";
import ApiService from './ApiService';

class NoticeWrite extends Component {

    constructor(props) {
        super(props)

        this.state = {
            employee_id: localStorage.getItem('LoginID'),
            notice_title: '',
            notice_content: ''
           
        }

    } 

    onChange = (e) => { // 1. 입력한 값으로 변경한다.
        this.setState({
            [e.target.name] : e.target.value
        });
    }

    // 공지사항 등록
    insertBoard = (e) => {
        e.preventDefault();

        if (this.state.notice_title.trim() === '') {
            alert('제목을 입력해주세요.');
            return;
        }
    
        if (this.state.notice_content.trim() === '') {
            alert('내용을 입력해주세요.');
            return;
        }
        
        const isConfirmed = window.confirm('공지사항을 등록하시겠습니까?');

        if (isConfirmed) {
            let inputData = {
                employee_id: this.state.employee_id,
                notice_title: this.state.notice_title,
                notice_content: this.state.notice_content
            }

        


        // 등록처리(화면에서 입력한 값 -> onChange() -> setState() -> inputData)
         ApiService.NoticeWrite(inputData)
            .then(res => {
                console.log('컨트롤러 전달 성공: ', res.data);
                this.props.history.push('/admin/noticeList');
                alert('공지사항이 등록되었습니다.'); // 성공 시 알림
            })
            .catch(err => {
                console.log('insertBoard() 에러!! : ', err);
                alert('공지사항 등록에 실패했습니다.'); // 실패 시 알림
            });
    }

}

    // 목록으로 돌아가기 버튼
    handleGoBack = () => {
        this.props.history.goBack();
    }
    
    render() {

        return (
            <Container>
                <Paper elevation={3}>
                    <Box padding={2} sx={{ marginTop: '20px' }}>
                        <Toolbar>
                            <TextField   sx={{width:"1300px",}}
                             id="outlined-basic"
                             variant="outlined"
                             label="공지사항 제목"
                             type="text"
                             name="notice_title"
                             value={this.state.notice_title}
                             placeholder="제목입력"
                             onChange={this.onChange}
                            />
                        </Toolbar>
                    </Box>
                </Paper>
                
                <Paper sx={{marginTop:"10px;"}} elevation={3}>
                        <Box padding={3}>
                        <div id='Boardeditor'>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.notice_content}
                            config={{
                                placeholder: '내용을 입력해주세요.',
                            }}
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                                this.setState({ notice_content: data });
                                
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                        }}
                        />
                        </div>    
                        </Box>
                </Paper>
                
                <Paper sx={{marginTop:"10px", marginBottom:"20px"}} elevation={3}>
                        <Toolbar sx={{ justifyContent: 'flex-end'}}>
                            <Button onClick={this.insertBoard}> 등록 </Button>
                            <Button onClick={this.handleGoBack}> 뒤로 </Button>
                        </Toolbar>
                </Paper>
            </Container>




        )

    }

}

const style = {
    display: 'inline-block', 
    width: '100%'
}

export default withRouter(NoticeWrite);

