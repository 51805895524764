import React, { Component } from 'react';
import { Table, TableBody, TableRow, TableCell, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DaumPostcode from 'react-daum-postcode';
import ApiService from './ApiService';

class AddEmpl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedImage: null,
            defaultImage: 'default_profile.png', // 기본 이미지 URL을 설정
            employeeName: '',
            gender: "-",
            authorityId:'-',
            departmentId: '-',
            positionId: '-',
            employDate: this.getCurrentDate(),
            employeeTelPart1: '',
            employeeTelPart2: '',
            employeeTelPart3: '',
            extensionTelPart1: '',
            extensionTelPart2: '',
            extensionTelPart3: '',
            employeeBirth: '1980-01-01', // 기본값
            employeeAddress: '',
            file: '',
            isAddressModalOpen: false 
        };
    }
    
    componentDidMount() {
        this.fetchActiveDepartList();
    }

    // 활성화된 부서 목록 조회
    fetchActiveDepartList() {
        ApiService.fetchActiveDepart()
            .then(response => {
                this.setState({ activeDepartList: response.data });
            })
            .catch(error => {
                console.error('활성화된 부서 목록 조회 오류:', error);
            });
    }

    handleImageChange = (e) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
            this.setState({
                selectedImage: reader.result,
                file: e.target.files[0]
            });
        };
    
        if (file) {
            reader.readAsDataURL(file);
        } else {
            // 이미지가 선택되지 않은 경우 기본 이미지로 설정
            this.setState({
                selectedImage: `/profiles/${this.state.defaultImage}`, // 기본 이미지 경로 설정
                file: null // 파일 초기화
            });
        }
    };    

    handleSaveClick = () => {
        const { selectedImage, employeeName, gender, departmentId, authorityId, positionId, employDate, employeeTelPart1, employeeTelPart2, employeeTelPart3, extensionTelPart1, extensionTelPart2, extensionTelPart3, employeeBirth, employeeAddress } = this.state;
    
        // 전화번호 조합
        const combinedExtensionTel = `${extensionTelPart1}${extensionTelPart2}${extensionTelPart3}`;
        const combinedEmployeeTel = `${employeeTelPart1}${employeeTelPart2}${employeeTelPart3}`;
        
        // 값이 비어있는지 확인
        if (
            authorityId === "-" ||
            positionId === "-" ||
            gender === "-" ||
            departmentId === "-" ||
            selectedImage === null ||
            employeeName.trim() === "" ||
            employDate.trim() === "" ||
            combinedEmployeeTel.trim() === "" ||
            combinedExtensionTel.trim() === "" ||
            employeeBirth.trim() === "" ||
            employeeAddress.trim() === ""
        ) {
            alert("모든 정보를 입력해주세요.");
            return;
        }

        // FormData 객체 생성
        let formData = new FormData();
        const inputData = {
            employeeName: employeeName,
            gender: gender,
            authorityId: authorityId,
            departmentId: departmentId,
            positionId: positionId,
            employDate: employDate,
            employeeTel: combinedEmployeeTel,
            extensionTel: combinedExtensionTel,
            employeeBirth: employeeBirth,
            employeeAddress: employeeAddress
        };

        formData.append('data', JSON.stringify(inputData));
        console.log('selectedImage',selectedImage);
        formData.append('file', this.state.file);

        // ApiService를 사용하여 직원 정보를 서버로 전송
        ApiService.addEmployee(formData)
            .then(response => {
                console.log("사원 추가 응답 데이터:", response.data);
                // alert(`${employeeName}님이 성공적으로 추가되었습니다.`);
                // 직원 목록으로 리다이렉션
                this.props.history.push('/admin/listempl');
            })
            .catch(error => {
                console.error('사원 추가 에러:', error);
                alert('사원을 추가하는데 문제가 발생했습니다.');
            });
    };
    

    handleCancelClick = () => {
        // 페이지 새로고침
        window.location.reload();
    }; 

    // 오늘 날짜를 반환
    getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        // yyyy-mm-dd 형식으로 반환
        return `${year}-${month}-${day}`;
    };

    handleChangeCell = (e, part) => {
        const value = e.target.value;
        // 숫자만 입력되고 3자리, 4자리, 4자리까지만 허용
        if (/^\d*$/.test(value)) {
            if (part === 'part1' && value.length <= 3) {
                this.setState({ employeeTelPart1: value });
            } else if (part === 'part2' && value.length <= 4) {
                this.setState({ employeeTelPart2: value });
            } else if (part === 'part3' && value.length <= 4) {
                this.setState({ employeeTelPart3: value });
            }
        }
    }

    handleKeyPressCell = (e, part) => {
        const { value } = e.target;
        // 최대에 도달하면 다음으로 포커스 이동
        if (part === 'part1' && value.length === 3) {
            this.employeeTelPart2.focus();
        } else if (part === 'part2' && value.length === 4) {
            this.employeeTelPart3.focus();
        }
    }

    handleChangeDir = (e, part) => {
        const value = e.target.value;
        // 숫자만 입력되고 3자리, 4자리, 4자리까지만 허용
        if (/^\d*$/.test(value)) {
            if (part === 'part1' && value.length <= 3) {
                this.setState({ extensionTelPart1: value });
            } else if (part === 'part2' && value.length <= 4) {
                this.setState({ extensionTelPart2: value });
            } else if (part === 'part3' && value.length <= 4) {
                this.setState({ extensionTelPart3: value });
            }
        }
    }

    handleKeyPressDir = (e, part) => {
        const { value } = e.target;
        // 최대에 도달하면 다음으로 포커스 이동
        if (part === 'part1' && value.length === 3) {
            this.extensionTelPart2.focus();
        } else if (part === 'part2' && value.length === 4) {
            this.extensionTelPart3.focus();
        }
    }

    // 다이얼로그 열기 함수
    openAddressModal = () => {
        this.setState({ isAddressModalOpen: true });
    };

    // 다이얼로그 닫기 함수
    closeAddressModal = () => {
        this.setState({ isAddressModalOpen: false });
    };

    // Daum 우편번호 선택 시 처리 함수
    handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress +=
                    extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        this.setState({
            employeeAddress: fullAddress,
        });
    };    

    render() {
        const { selectedImage, isAddressModalOpen  } = this.state;

        return (
            <div align="center">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '40px 50px 25px 20px', width: '100%' }}>
                    <h2 style={{ textAlign: 'left', margin: 0 }}> 직원 추가하기 </h2>
                </div>
                <Table className="my-info-table">
                    <TableBody>
                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>사진</span></TableCell>
                            <TableCell style={{ border: 'none' }}>
                                <input
                                    id='employeeImg'
                                    type='file'
                                    accept="image/*"
                                    name='employeeImg'
                                    multiple='multiple'
                                    onChange={this.handleImageChange}
                                />
                                {this.state.selectedImage ? (
                                    <div>
                                        <br />
                                        <img src={this.state.selectedImage} alt="Selected" style={{ height: '150px', width: '150px', borderRadius: '3px' }} />
                                    </div>
                                ) : (
                                    <div>
                                        <br />
                                        <img src="/profiles/default_profile.png" alt="Default" style={{ height: '150px', width: '150px', borderRadius: '3px' }} />
                                        <input type="hidden" name="employeeImg" value="default_profile.png" />
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>이름</span></TableCell>
                            <TableCell className="custom-cell2">
                                <input 
                                    type="text" 
                                    id="employeeName" 
                                    label="employeeName" 
                                    placeholder="최대 10자 까지만 입력 가능"
                                    value={this.state.employeeName}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value.length <= 10) {
                                            this.setState({ employeeName: value });
                                        }
                                    }}
                                    style={{ padding: '5px' }}
                                    required
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>성별</span></TableCell>
                            <TableCell className="custom-cell2">
                                <Select
                                    style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                    value={this.state.gender}
                                    name="gender"
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                >
                                    <MenuItem value="-">선택</MenuItem>
                                    <MenuItem value="M">남성</MenuItem>
                                    <MenuItem value="W">여성</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>권한</span></TableCell>
                            <TableCell className="custom-cell2">
                                <Select
                                    style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                    value={this.state.authorityId}
                                    name="authorityId"
                                    onChange={(e) => this.setState({ authorityId: e.target.value })}
                                >
                                    <MenuItem value="-">선택</MenuItem>
                                    <MenuItem value="0">사원</MenuItem>
                                    <MenuItem value="1">관리자</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>직위</span></TableCell>
                            <TableCell className="custom-cell2">
                                <Select
                                    value={this.state.positionId}
                                    onChange={(e) => this.setState({ positionId: e.target.value })}
                                    name="positionId"
                                    style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                >
                                    <MenuItem value="-">선택</MenuItem>
                                    <MenuItem value="BNK">사원</MenuItem>
                                    <MenuItem value="45E">팀장</MenuItem>
                                    <MenuItem value="W6O">과장</MenuItem>
                                    <MenuItem value="AW6">부장</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>부서</span></TableCell>
                            <TableCell className="custom-cell2">
                                <Select
                                    value={this.state.departmentId}
                                    onChange={(e) => this.setState({ departmentId: e.target.value })}
                                    name="departmentId"
                                    style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                >
                                    <MenuItem value="-">선택</MenuItem>
                                    {this.state.activeDepartList && this.state.activeDepartList.map(department => (
                                        <MenuItem key={department.departmentId} value={department.departmentId}>{department.departmentName}</MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>입사일</span></TableCell>
                            <TableCell className="custom-cell2">
                                <input 
                                    type="date" 
                                    id="employDate" 
                                    label="employDate" 
                                    defaultValue={this.getCurrentDate()}
                                    onChange={(e) => this.setState({ employDate: e.target.value })}
                                    style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                    required
                                />
                            </TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>Cell.</span></TableCell>
                            <TableCell className="custom-cell2">
                                <input 
                                    type="text" 
                                    value={this.state.employeeTelPart1}
                                    onChange={(e) => this.handleChangeCell(e, 'part1')}
                                    onKeyPress={(e) => this.handleKeyPressCell(e, 'part1')}
                                    maxLength={3}
                                    ref={(input) => { this.employeeTelPart1 = input; }}
                                    placeholder="000"
                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginRight: '5px', padding: '5px' }}
                                />
                                -
                                <input 
                                    type="text" 
                                    value={this.state.employeeTelPart2}
                                    onChange={(e) => this.handleChangeCell(e, 'part2')}
                                    onKeyPress={(e) => this.handleKeyPressCell(e, 'part2')}
                                    maxLength={4}
                                    ref={(input) => { this.employeeTelPart2 = input; }}
                                    placeholder="0000"
                                    style={{ height: '26px', width: '60px', borderRadius: '3px', margin: '0 5px', padding: '5px' }}
                                />
                                -
                                <input 
                                    type="text" 
                                    value={this.state.employeeTelPart3}
                                    onChange={(e) => this.handleChangeCell(e, 'part3')}
                                    maxLength={4}
                                    ref={(input) => { this.employeeTelPart3 = input; }}
                                    placeholder="0000"
                                    style={{ height: '26px', width: '60px', borderRadius: '3px', margin: '0 5px', padding: '5px' }}
                                />
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>Dir.</span></TableCell>
                            <TableCell className="custom-cell2">
                                <input 
                                    type="text" 
                                    id="extensionTelPart1" 
                                    value={this.state.extensionTelPart1}
                                    onChange={(e) => this.handleChangeDir(e, 'part1')}
                                    onKeyPress={(e) => this.handleKeyPressDir(e, 'part1')}
                                    maxLength={3}
                                    ref={(input) => { this.extensionTelPart1 = input; }}
                                    style={{ height: '26px', width: '60px', borderRadius: '3px', marginRight: '5px', padding: '5px' }}
                                    placeholder="000"
                                />
                                -
                                <input 
                                    type="text" 
                                    id="extensionTelPart2" 
                                    value={this.state.extensionTelPart2}
                                    onChange={(e) => this.handleChangeDir(e, 'part2')}
                                    onKeyPress={(e) => this.handleKeyPressDir(e, 'part2')}
                                    maxLength={4}
                                    ref={(input) => { this.extensionTelPart2 = input; }}
                                    placeholder="0000"
                                    style={{ height: '26px', width: '60px', borderRadius: '3px', margin: '0 5px', padding: '5px' }}
                                />
                                -
                                <input 
                                    type="text" 
                                    id="extensionTelPart3" 
                                    value={this.state.extensionTelPart3}
                                    onChange={(e) => this.handleChangeDir(e, 'part3')}
                                    maxLength={4}
                                    ref={(input) => { this.extensionTelPart3 = input; }}
                                    placeholder="0000"
                                    style={{ height: '26px', width: '60px', borderRadius: '3px', margin: '0 5px', padding: '5px' }}
                                />
                            </TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>거주지</span></TableCell>
                            <TableCell className="custom-cell2">
                                <input
                                    type="text"
                                    id="employeeAddress"
                                    label="employeeAddress"
                                    placeholder=""
                                    value={this.state.employeeAddress}
                                    onChange={(e) => this.setState({ employeeAddress: e.target.value })}
                                    onClick={this.openAddressModal}
                                />
                                <Button onClick={this.openAddressModal}>주소검색</Button>
                            </TableCell>
                        </TableRow>

                        {/* 다이얼로그 추가 */}
                        <Dialog open={isAddressModalOpen} onClose={this.closeAddressModal}>
                            <DialogTitle>주소검색</DialogTitle>
                            <DialogContent>
                                <DaumPostcode
                                    onComplete={this.handleComplete}
                                    autoClose
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeAddressModal}>닫기</Button>
                            </DialogActions>
                        </Dialog>

                        <TableRow>
                            <TableCell className="custom-cell"><span className='info-title'>생일</span></TableCell>
                            <TableCell className="custom-cell2">
                                <input 
                                    type="date" 
                                    id="employeeBirth" 
                                    label="employeeBirth" 
                                    value={this.state.employeeBirth || '1980-01-01'}
                                    onChange={(e) => this.setState({ employeeBirth: e.target.value })}
                                    style={{ height: '26px', width: '100px', borderRadius: '3px' }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <hr style={{ border: 'none', height: '1px', backgroundColor: '#ccc', margin: '20px 10px' }}/>
                <br /><br />
                <div>
                    <input className="infoBtn" type="submit" value="저장" onClick={this.handleSaveClick} />
                    <input className="infoBtn" type="submit" value="취소" onClick={this.handleCancelClick} />
                </div>
            </div>
        );
    }
}

export default AddEmpl;
