import React, { useState, useEffect } from 'react';
import ApiService from '../../page_yoo/employee/ApiService';
import './MainMyinfo.css'; // CSS 파일 import
import { IonIcon } from '@ionic/react';
import { mailOutline, settingsOutline, fileTrayFullOutline, logOutOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';


/* 작업자 : 강승재 */
/* 메인페이지의 로그인된 사원 정보 미니페이지 */

function MainMyinfo() {
    const [userInfo, setUserInfo] = useState(null); // 사용자 정보를 저장할 상태

    useEffect(() => {
        // 사용자 정보를 가져오는 API 호출
        ApiService.fetchEmployeeByID(window.localStorage.getItem("LoginID"))
            .then(response => {
                // API 호출 성공 시 상태에 정보 저장
                setUserInfo(response.data);
            })
            .catch(error => {
                console.error('사용자정보 조회 오류', error);
            });
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem("LoginID");
        window.location.href = "/login";
    };

    return (
        <div className="main-myinfo-container">
            {userInfo && (
                <div className="user-info">
                    <div className="user-photo-wrapper">
                        <img className="user-photo" src={ '/profiles/' + userInfo.employeeImg} alt="User" />

                    </div>
                    <div className="user-info-text">
                        <p className="userName">{userInfo.employeeName} {userInfo.positionName}</p>
                        <p className="departmentName">{userInfo.departmentName}</p>
                        <Link to="/mail" className="mail">
                            <IonIcon icon={mailOutline} style={{ fontSize: '15px' }} />  메일함
                        </Link><br />
                        <Link to="/appr" className="approval">
                            <IonIcon icon={fileTrayFullOutline} style={{ fontSize: '15px' }} />  결재문서함
                        </Link><br />
                        <Link to="/employee" className="myInfo">
                            <IonIcon icon={settingsOutline} style={{ fontSize: '15px' }} />  내정보설정
                        </Link><br />
                        <button onClick={handleLogout} className="LogoutBtn">
                            <IonIcon icon={logOutOutline} style={{ fontSize: '15px' }} />  로그아웃
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MainMyinfo;
