import axios from 'axios';  // npm install -f axios@^1.3.5

const Board_API_BASE_URL =  process.env.REACT_APP_BACK_HOST + "/board";

// 이부분 추가
const getAuthToken = () => {
    return window.localStorage.getItem("auth_token");
}

let headers = {};


if(getAuthToken() !== null && getAuthToken() !=="null") {
    headers = {"Authorization": `Bearer ${getAuthToken()}`};
    console.log('headers : ', headers);
}


class BoardService {

    

    // 게시판 전체조회
    BoardList() {
        console.log('BoardList() 호출!!')
        return axios.get(Board_API_BASE_URL,{headers});
    }

    // 게시판 insert
    insertBoard(inputData) {
        console.log('insertBoard 호출!!', inputData)
        return axios.post(Board_API_BASE_URL, inputData,{headers});
    }

    // 게시판 1건 상세조회
    BoardByID(fb_no) {
        console.log('BoardByID 호출!!', fb_no)
        return axios.get(Board_API_BASE_URL + "/detail/" + fb_no,{headers});
    }

    // 게시판 수정
    updateBoard(inputData) {
        console.log(' updateBoard 호출!!', inputData)
        return axios.put(Board_API_BASE_URL + "/" + inputData.fb_no, inputData,{headers});
    }

    // 게시판 삭제
    deleteBoard(fb_no) {
        console.log('deleteBoard 호출!!', fb_no)
        return axios.delete(Board_API_BASE_URL + "/" + fb_no,{headers});
    }

    // 게시글 조회수 증가
      increaseViews(fb_no) {
        console.log('increaseViews 호출!!', fb_no);
        return axios.put(Board_API_BASE_URL+ "/increase-views/" + fb_no, null, {headers});
    }

    // 댓글 목록 가져오기
    commentList(fb_no) {
    return axios.get(Board_API_BASE_URL + "/detail/" + fb_no + "/comments",{headers});
    }

    
    // 댓글 작성
    insertComment(fb_no, inputData) {
        console.log('insertComment 호출!!', fb_no, inputData);
        return axios.post(Board_API_BASE_URL+ "/detail/" + fb_no + "/comment" , inputData,{headers});
    }

    // 댓글 수정
    updateComment(inputData) {
        console.log('updateComment 호출!!',inputData);
        return axios.put(Board_API_BASE_URL+ "/commentupdate",inputData,{headers});
    }

    // 댓글 삭제
    deleteComment(fb_no, cm_no, inputData) {
        console.log('deleteComment 호출!!', inputData);
        return axios.delete(Board_API_BASE_URL+"/detail/"+ fb_no+ "/comment/"+ cm_no, { data: inputData, headers });
    }

    // 공지사항 전체조회
    NoticeList() {
        console.log('NoticeList() 호출!!')
        return axios.get(Board_API_BASE_URL + "/notice",{headers});
    }

    // 공지사항 1건 상세조회
    NoticeByID(notice_no) {
        console.log('NoticeByID 호출!!', notice_no)
        return axios.get(Board_API_BASE_URL + "/notice/detail/" + notice_no,{headers});
    }

     // 공지사항 조회수 증가
    increaseReadCount(notice_no) {
        console.log('increaseReadCount 호출!!', notice_no);
        return axios.put(Board_API_BASE_URL + "/notice/increase-readcount/" + notice_no, null, { headers });
        }


    // 게시판 검색
    searchBoard(searchOption, searchKeyword) {
    console.log('searchBoard 호출!!', searchKeyword);
    return axios.get(`${Board_API_BASE_URL}/search/${searchOption}/${searchKeyword}`,{headers});
    }

}

export default new BoardService();