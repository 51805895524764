import React, { Component} from 'react';
import { TextField } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./MailRouter.css";
import ApiService from './ApiService';
import { IonIcon } from '@ionic/react';
import { sendOutline, refreshOutline, saveOutline } from 'ionicons/icons';

/* 작업자 : 강승재 */
/* 메일 답장쓰기 페이지 */

class ReplyMailComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fromId: localStorage.getItem('LoginID'),
            toIdArr: [],
            emailTitle: '',
            emailContent: '',
            emailFile: '',
            countTemp: '',
            file: '',
            isPopupActive: false
        }
    }
    
    // 받은메일 상세페이지의 정보를 state에 담아와서 출력
    componentDidMount() {
        const stateString = window.localStorage.getItem("state");
        if (stateString) {
            const state = JSON.parse(stateString);
            // state 객체에서 필요한 데이터를 가져와서 사용
            this.setState({
                toIdArr: state.fromId,
                emailTitle: "RE:"+state.emailTitle,
                emailContent: "-----------Original Message ---------<br/> "+ state.emailContent + "<br/>----------------------------------------",
            });
        }

    }
    

    // 초기화버튼
    resetValues = () => {
        this.setState({
            emailTitle: '',
            emailContent: '',
            emailFile: ''
        });
    }

    onChange = (e) => {   
        if (e.target.name === 'emailFile') {
            this.setState({ 
                emailFile: e.target.value,
                file: e.target.files[0]
            }); 
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    // 발송
    sendMail = (e) => {
        e.preventDefault();

        if(this.state.toId === ""){
            window.alert("받는 사람을 입력해주세요.");

        }else if(this.state.emailTitle === ""){
            window.alert("제목을 입력해주세요.");
        } else {

            let emailFile = this.state.file;
            let formData = new FormData();

            let inputData = {   // 3. state값을 읽어온다.
                fromId: this.state.fromId,
                toIdArr: this.state.toIdArr,
                emailTitle: this.state.emailTitle,
                emailContent: this.state.emailContent,
            }

            formData.append('data', JSON.stringify(inputData));

            if (emailFile !== '') {
                formData.append('file', emailFile);
                // 등록처리(화면에서 입력한 값 -> onChange() -> setState() -> inputData)
                ApiService.sendMail(formData)      //스프링부트와 통신기능 호출
                .then(res => {
                    this.setState({
                    })
                    console.log('sendMail 성공 : ', res.data); 
                    this.props.history.push('/mail');    
                })
                .catch(err => {
                    console.log('sendMail() 에러!! : ', err);
                });
            } else {
                ApiService.sendMailnoFile(inputData)      //스프링부트와 통신기능 호출
                .then(res => {
                    this.setState({
                    })
                    console.log('sendMailnoFile 성공 : ', res.data); 
                    this.props.history.push('/mail');    
                })
                .catch(err => {
                    console.log('sendMailnoFile() 에러!! : ', err);
                });
            }
        }
    }
    // 임시저장
    saveMail = (e) => {
        e.preventDefault();

        if(this.state.toId === ""){
            window.alert("받는 사람을 입력해주세요.");

        }else if(this.state.emailTitle === ""){
            window.alert("제목을 입력해주세요.");

        } else {
            let emailFile = this.state.file;
            let formData = new FormData();

            let inputData = {   // 3. state값을 읽어온다.
                fromId: this.state.fromId,
                toIdArr: this.state.toIdArr,
                emailTitle: this.state.emailTitle,
                emailContent: this.state.emailContent,
            }

            formData.append('data', JSON.stringify(inputData));

            if (emailFile !== '') {
                formData.append('file', emailFile);

                ApiService.saveMail(formData) 
                    .then(res => {
                        this.setState({
                        })
                        console.log('saveMail 성공 : ', res.data); 
                        this.props.history.push('/mail');    
                    })
                    .catch(err => {
                        console.log('saveMail() 에러!! : ', err);
                    });

            } else {
                ApiService.saveMailnoFile(inputData) 
                    .then(res => {
                        this.setState({
                        })
                        console.log('saveMail 성공 : ', res.data); 
                        this.props.history.push('/mail');    
                    })
                    .catch(err => {
                        console.log('saveMail() 에러!! : ', err);
                    });
            }
        }     
    }


    render() {

        return (
            <div className='top' style={style}>
                <div className='mailHeader'>
                    <h1>답장하기</h1>
                </div>
                

                <div className='write'>
                    <hr/>
                    <div className='mailWrite'>
                        <button onClick={this.sendMail}> <IonIcon icon={sendOutline} /> 발송 </button>
                        <button onClick={this.resetValues}> <IonIcon icon={refreshOutline} /> 초기화 </button>
                        <button style={{width: '90px'}} onClick={this.saveMail}> <IonIcon icon={saveOutline} /> 임시저장 </button>
                    </div>
                    <hr/>

                    <div className='writeTo'> 
                        <TextField
                            sx={{ width: '30%' }}
                            id="standard-required"
                            variant="standard"
                            label="To"
                            type="text"
                            name="toIdArr"
                            value={this.state.toIdArr}
                            placeholder="받는사람"
                            readOnly
                        />
                    </div>


                    <div className='writeTitle'> 
                        <TextField
                            sx={{ width: '95%', marginTop: '40px'  }}
                            id="standard-required"
                            variant="standard"
                            label="Title"
                            type="text"
                            name="emailTitle"
                            value={this.state.emailTitle}
                            placeholder="제목입력"
                            onChange={this.onChange}
                        />

                    <label htmlFor="fileInput" className="fileInput">파일첨부</label>
                    <input
                        id='fileInput'
                        type='file'
                        name='emailFile'
                        multiple='multiple'
                        style={{ display: 'none' }}
                        onChange={this.onChange}
                    />

                        <div className='prevFile' style={{ height: '90px'}}>
                            {this.state.emailFile ? this.state.emailFile : "첨부파일없음"}
                        </div>

                    </div>
                    <div className='writeCK'>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.emailContent}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                                this.setState({ emailContent: data });
                                
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                        }}
                        />
                    </div>

                </div>
            </div>
        );

    }

}

const style = {
    display: 'inline-block', 
    width: '100%',
}

export default ReplyMailComponent;