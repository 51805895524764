import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { Link, Route } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import mainComponent from './mainComponent';
import ListEmpl from '../page_yoo/admin/ListEmpl';
import AddEmpl from '../page_yoo/admin/AddEmpl';
import EditEmpl from '../page_yoo/admin/EditEmpl';
import DepartList from '../page_yoo/admin/DepartList';
import FreeBoard from '../page_hwang/admin/FreeBoard';
import Notice from '../page_hwang/admin/Notice';
import CommentList from '../page_hwang/admin/CommentList';
import NoticeWrite from '../page_hwang/admin/NoticeWrite';
import NoticeEdit from '../page_hwang/admin/NoticeEdit';
import CompleteDocument from '../page_kim/admin/CompleteDocument';
import ProgressDocument from '../page_kim/admin/ProgressDocument';
import DocumentDetail from '../page_kim/admin/DocumentDetail';
import WorkDocumentDetail from '../page_kim/admin/WorkDocumentDetail';
import AttendList from '../page_heojun/admin/AttendList'
import AddVac from '../page_heojun/admin/AddVac'
import VacList from '../page_heojun/admin/VacList';
import AdminCalendarDetail from '../page_yoon/route/AdminCalendarDetail';
import AdminCalendarAdd from '../page_yoon/route/AdminCalendarAdd';
import AdminCalendar from '../page_yoon/route/AdminCalendar';

const EmplRouter = () => {

    // 아코디언 그림자 제거 함수
    const theme = createTheme({
        components: {
          MuiAccordion: {
            styleOverrides: {
              root: {
                boxShadow: 'none', // 아코디언 컴포넌트의 그림자 제거
                padding: '0px 10px',
              },
            },
          },
        },
    });

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel, setExpanded) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <ThemeProvider theme={theme}>
        <div style={style}>
        
            

            <div className='main'>
                <div className='left'>
                    <div className='sub_Header'>
                        <div className='title'>
                            <h1> 관리자 </h1>
                        </div>
                        <div className='adminMenu' style={style.apprMenu}> {/* 각자수정 */}
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1', setExpanded)}>
                                {/* 각자 메뉴 수정하시면 됩니다. */}
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography style={style.middleMenu}>직원 관리</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Link to="/admin/listempl" style={style.link}>직원 목록</Link>
                                    </Typography>
                                </AccordionDetails>

                                <AccordionDetails>
                                    <Typography>
                                        <Link to="/admin/addempl" style={style.link}>직원 추가하기</Link>
                                    </Typography>
                                </AccordionDetails>
                                
                            </Accordion>

                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2', setExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography style={style.middleMenu}>부서 관리</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Link to="/admin/departlist" style={style.link}>부서 목록</Link>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3', setExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                    style={style.back}
                                >
                                    <Typography style={style.middleMenu}>결재문서확인</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Link to="/admin/CompleteDocument" style={style.link}>완료 문서함</Link>
                                    </Typography>
                                </AccordionDetails>

                                <AccordionDetails>
                                        <Link to="/admin/ProgressDocument" style={style.link}>진행 문서함</Link>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4', setExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                    style={style.back}
                                >
                                    <Typography style={style.middleMenu}>근태관리</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                        <Link to="/admin/attendlist" style={style.link}>근태 조회</Link>
                                </AccordionDetails>

                                <AccordionDetails>
                                        <Link to="/admin/addvac" style={style.link}>휴가계 등록</Link>
                                </AccordionDetails>

                                <AccordionDetails>
                                        <Link to="/admin/vaclist" style={style.link}>휴가계 조회</Link>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5', setExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5-content"
                                    id="panel5-header"
                                    style={style.back}
                                >
                                    <Typography style={style.middleMenu}>게시판</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                        <Link to="/admin/noticeList" style={style.link}>공지사항 목록</Link>
                                </AccordionDetails>

                                <AccordionDetails>
                                        <Link to="/admin/boardList" style={style.link}>자유게시판 목록</Link>
                                </AccordionDetails>
                                
                                <AccordionDetails>
                                        <Link to="/admin/commentList" style={style.link}>댓글 목록</Link>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6', setExpanded)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6-content"
                                    id="panel6-header"
                                    style={style.back}
                                >
                                    <Typography style={style.middleMenu}>일정</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Link to="/admin/AdminCalendar" style={style.link}>일정관리</Link>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>


                <div className='right'>
                    <Route path="/admin/" exact={true} component={mainComponent}></Route>
                    <Route path="/admin/listempl" exact={true} component={ListEmpl}></Route>
                    <Route path="/admin/addempl" exact={true} component={AddEmpl}></Route>
                    <Route path="/admin/editempl" exact={true} component={EditEmpl}></Route>
                    <Route path="/admin/departlist" exact={true} component={DepartList}></Route>
                    <Route path="/admin/boardList" exact={true} component={FreeBoard}></Route>
                    <Route path="/admin/noticeList" exact={true} component={Notice}></Route>
                    <Route path="/admin/commentList" exact={true} component={CommentList}></Route>
                    <Route path="/admin/noticeWrite" exact={true} component={NoticeWrite}></Route>
                    <Route path="/admin/NoticeEdit/:notice_no" exact={true} component={NoticeEdit}></Route>
                    <Route path="/admin/CompleteDocument" exact={true} component={CompleteDocument}></Route>
                    <Route path="/admin/ProgressDocument" exact={true} component={ProgressDocument}></Route>
                    <Route path="/admin/attendlist" exact={true} component={AttendList}></Route>
                    <Route path="/admin/addvac" exact={true} component={AddVac}></Route>
                    <Route path="/admin/vaclist" exact={true} component={VacList}></Route>
                    <Route path="/admin/DocumentDetail" exact={true} component={DocumentDetail}></Route>
                    <Route path="/admin/WorkDocumentDetail" exact={true} component={WorkDocumentDetail}></Route>
                    <Route path="/admin/AdminCalendar" exact={true} component={AdminCalendar}></Route>
                    <Route path="/admin/AdminCalendarAdd" exact={true} component={AdminCalendarAdd}></Route>
                    <Route path="/admin/AdminCalendarDetail" exact={true} component={AdminCalendarDetail}></Route>

                </div>

                
            </div>
        
        </div>
        </ThemeProvider>
    );
}

const style = {
    link: {
        textDecoration: "none",
        color: "#646464",
        fontSize: "15px"
      },
  
      title: {
          marginTop: '30px',
          fontSize: '21px',
          textAlign: 'left',
      },
  
      // title 아래 버튼
      first_Button: {   
          color: '#323232',
          borderColor: '#6e6e6e'
      },
  
      apprMenu: {
          marginTop: '30px'
      },
  
      middleMenu: {
          fontWeight: 550,
          color: '#5a5a5a'
      }
}

export default EmplRouter;