import React, { Component} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { withRouter } from 'react-router-dom';  // 목록버튼 때문에 추가
import { TextField } from '@mui/material';
import Container from "@mui/material/Container";
import BoardService from './BoardService';
import htmlparser from 'html-react-parser';
import './WriteBoard.css';

class BoardEdit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            employee_id: localStorage.getItem('LoginID'),
            fb_no: '',
            fb_title: '',
            fb_content: ''
           
        }

    } 
    componentDidMount() {
        const { fb_no } = this.props.match.params; // fb_no 값을 props로 받아옴
        this.setState({ fb_no }, () => {
            this.loadBoard();
        });
    }

    loadBoard = () => {
        BoardService.BoardByID(this.state.fb_no) // this.state.fb_no를 사용하여 게시글을 가져옴
            .then(res => {
                const { employee_id, fb_title, fb_content } = res.data; // 서버에서 받은 데이터를 분해하여 state에 업데이트
                this.setState({ employee_id, fb_title, fb_content });
            })
            .catch(err => {
                console.log('loadBoard() Error !!', err);
            });
    }

    onChange = (e) => { // 1. 입력한 값으로 변경한다.
        this.setState({
            [e.target.name]: e.target.value,
            isContentChanged: true, // 내용이 변경되었음을 표시
        });
    }

  

    // 3.수정처리(화면에서 입력한 값 -> setState() -> inputData)
    editBoard = (e) => {
        e.preventDefault();

        const isConfirmed = window.confirm('게시글을 수정하시겠습니까?');

        if (isConfirmed) {
            let inputData = { // 3. state값을 읽어온다
                fb_no: this.state.fb_no,
                employee_id: this.state.employee_id,
                fb_title: this.state.fb_title,
                fb_content: this.state.fb_content,
                fb_readcnt: this.state.fb_readcnt,
                fb_comment_count: this.state.fb_comment_count,
                fb_date: this.state.fb_date
            }

            BoardService.updateBoard(inputData)
                .then(res => {
                    console.log('update 성공 ', res.data);
                    this.props.history.push(`/board/detail/${this.state.fb_no}`); // 글 수정시 수정한 상세페이지로 이동
                    alert('게시글이 수정되었습니다.'); // 성공 시 알림
                })
                .catch(err => {
                    console.log('editSample() 에러!! ', err);
                    alert('게시글이 수정이 실패했습니다.'); // 실패 시 알림
                })
        }
    }

    // 목록으로 돌아가기 버튼
    handleGoBack = () => {
        this.props.history.goBack();
    }
    
    render() {

        return (
            <Container>
                <Paper elevation={3} sx={{ marginTop: '20px' }}>
                    <Box padding={2}>
                        <Toolbar>
                            <TextField   
                            sx={{width:"800px"}}
                             id="outlined-basic"
                             variant="outlined"
                             label="게시글 제목"
                             type="text"
                             name="fb_title"
                             value={this.state.fb_title}
                             placeholder="제목입력"
                             onChange={this.onChange}
                            />
                        </Toolbar>
                    </Box>
                </Paper>
                
                <Paper sx={{ marginTop: "10px;" }} elevation={3}>
    <Box padding={3}>
        <div id='Boardeditor'>
            <CKEditor
                editor={ClassicEditor}
                data={this.state.fb_content}
                config={{
                    placeholder: '내용을 입력해주세요.',
                }}
                onReady={editor => {
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    this.setState({ fb_content: data, isContentChanged: true });
                }}
                onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                }}
            />
        </div>
    </Box>
</Paper>    


                <Paper sx={{marginTop:"10px"}} elevation={3}>
                    <Toolbar sx={{ justifyContent: 'flex-end'}}>
                        <Button onClick={this.editBoard}> 수정 </Button>
                        <Button onClick={this.handleGoBack}> 뒤로 </Button>
                    </Toolbar>
                </Paper>
            </Container>

        )

    }

}

const style = {
    display: 'inline-block', 
    width: '100%'
}

export default withRouter(BoardEdit);
