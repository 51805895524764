import React from 'react';
import { Button, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Route, Link } from 'react-router-dom';
import NoticeBoard from './NoticeBoard';
import Boardlist from './Boardlist';
import WriteBoard from './WriteBoard';
import Boarddetail from './Boarddetail';
import BoardEdit from './BoardEdit';
import Noticedetail from './Noticedetail';
import './BoardRouter.css';
import '../../route/SubHeaderLayout.css'
import '../../route/SubHeaderStyle.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';

// 아코디언 그림자 제거 함수
const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // 아코디언 컴포넌트의 그림자 제거
          padding: '0px 10px',
        },
      },
    },
  },
});


export default function BoardRouter () {
  const [expanded, setExpanded] = React.useState(true);
  
    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };
  

  return (
    <ThemeProvider theme={theme}>
          <div style={style}>
            <div className='main'>
              <div className='left'>
                <div className='sub_Header'>
                  <div className='title' style={style.title}>
                  <Link to="/board/">게시판</Link>
                  </div>
                        <div className='boardwrite'>
                        <Link to="/board/Write">
                        <Button variant="outlined" style={style.first_Button}>글쓰기</Button>
                        </Link>
                        <div className='boardMenu' style={style.apprMenu}> {/* 각자수정 */}
                                    <Accordion
                                        expanded={expanded}
                                        onChange={handleExpansion}
                                        slots={{ transition: Fade }}
                                        slotProps={{ transition: { timeout: 400 } }}
                                        sx={{
                                            '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                                            '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                                            boxShadow: 'none' // 그림자 스타일 제거
                                        }}
                                    >
                                        {/* 각자 메뉴 수정하시면 됩니다. */}
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            style={style.back}
                                        >   
                                            <Typography style={style.middleMenu}>게시판</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails style={{ textAlign: 'center' }}>
                                            <Typography>
                                                <Link to="/board" style={style.link}>자유게시판</Link>
                                            </Typography>
                                            &nbsp;&nbsp;
                                            <Typography>
                                                <Link to="/board/notice" style={style.link}>공지사항</Link>
                                            </Typography>
                                        </AccordionDetails>
                                        
                                    </Accordion>
                            </div>
                          </div>
                    </div>
                </div>


                    <div className='right'>
                      <Route path="/board" exact={true} component={Boardlist}></Route>
                      <Route path="/board/notice" exact={true} component={NoticeBoard}></Route>
                      <Route path="/board/write" exact={true} component={WriteBoard}></Route>
                      <Route path="/board/detail/:fb_no" exact={true} component={Boarddetail}></Route>
                      <Route path="/board/edit/:fb_no" exact={true} component={BoardEdit}></Route>
                      <Route path="/board/notice/detail/:notice_no" exact={true} component={Noticedetail}></Route>
                    </div>

           </div>
    </div>
  </ThemeProvider>
  );
}

const style = {
    
    first_Button: {
      marginTop: '5px',
      paddingTop: '10px',
      paddingRight: '60px',
      paddingBottom: '10px',
      paddingLeft: '60px',
      color: '#323232',
      borderColor: '#6e6e6e'
    },

    link: {
      textDecoration: "none",
      color: "#646464",
      fontSize: "15px"
    },

    title: {
      marginTop: '30px',
      fontSize: '21px',
      textAlign: 'left',
      textDecoration: "none",
    },
    
    apprMenu: {
      marginTop: '30px'
    },

    middleMenu: {
      fontWeight: 550,
      color: '#5a5a5a'
   }

};




