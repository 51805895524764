import React, { Component, useState } from 'react';
import {Typography} from '@mui/material';
import moment from 'moment';

import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

function transHoursAndMinutes(seconds) {
	if (seconds < 61) {
    	return '00:00:' + addZero(seconds)
	}
	// sec
	var hours = Math.floor(seconds/3600)
	var mins = Math.floor((seconds - hours*3600)/60)
	var secs = seconds - hours*3600 - mins*60
	return addZero(hours) + ':' + addZero(mins) + ':' + addZero(secs)
    
	function addZero(num) {
		return ((num < 10) ? '0' : '') + num
	}
}

class AttendStatus extends Component {

    
    constructor(props) {
        super(props);

        this.state = {
            employee_id: this.props.employee_id,

        }

        
    }

    
        
    firstWeekRun = () => {
        let sum = 0;
        this.props.attend_weekData.map( dto => {
            if(dto.att_etime != null){
                sum += moment(dto.att_etime).diff(moment(dto.att_stime), "s");
            }
            
        })

        let data = {
            work : transHoursAndMinutes(sum),
            rawwork : sum,
        }

        return data;
    }

    MonthRun = () => {
        let sum = 0;
        let latesum = 0;

        

        this.props.attend_MonthData.map( i => {
            
            i.week.map(dto => {
                if(dto.att_etime != null){
                    sum += moment(dto.att_etime).diff(moment(dto.att_stime), "s");
                }

                if(dto.att_result === 'y')
                    latesum += 1;
                
            })         
            
        })

        let data = {
            work : transHoursAndMinutes(sum),
            rawwork : sum,
            late : latesum
        }

        return data;
    }

    weekOfMonth = (m) => m.isoWeek() - moment(m).startOf('month').isoWeek() + 1;
    
    today = moment();

    // 라이프 사이클 중 컴포넌트가 생성된 후 사용자에게 보여지기 전까지의 전체 과정을 렌더링(데이터 로딩)
    // render 후 dom이 반영되었을 때(로딩이 끝났을 때) 
    // componentDidMount 상태로 진입 이 상태일 때 아래 함수를 실행
    // 개발단계에선 이 부분이 두번 호출됨
    // 두번 안보고 싶으면 index.js 에서 StrictMode 를 없애면 된다.

    componentDidMount() { 

    }


    render() {

        let thisweek = this.firstWeekRun()
        let data = this.MonthRun()

        return (
            <div>
                <div>
                    {this.today.format('YYYY.MM')} / {this.weekOfMonth(this.today)} 주차 누적 근무시간 : {thisweek.work}
                    <div>
                        <span style={{textAlign: 'right'}}>52시간 중</span>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={thisweek.rawwork /1872} />
                        </Box>
                    </div>

                    
                    <br/>
                    이번 달 누적 근무시간 : {data.work}
                    <div>
                        <span style={{textAlign: 'right'}}>226시간 중</span>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={data.rawwork /8136} />
                        </Box>
                    </div>
                    
                    
                    <br/>
                    이번 달 지각 횟수 : {data.late}
                    <br/>
                </div>

                
                    
                
                
            </div>

        );

    }

}

export default AttendStatus;

